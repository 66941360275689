import React from 'react';
// Components/ui
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';

function DropdownFieldsMenu({
  open,
  handleClose,
  selectedIndex,
  handleMenuItemClick,
  anchorRef,
  contactFields,
}) {
  return (
    <Popper
      sx={{
        zIndex: 1,
      }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu" autoFocusItem
               sx={{
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.panel,
                backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
              }}>
                {contactFields.map((field, index) => (
                  <MenuItem
                    key={field.name}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                   
                  >
                    {field.name}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default DropdownFieldsMenu;
