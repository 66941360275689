import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  IInitialState,
  IGoogleAccountData,
  ISetAutoUpdateData,
  ISyncConfig,
  ICreateTagData,
  IWhatsappContactsSyncData,
  IGoogleAccountLogOutData,
} from './types';
import axios from 'axios';
import initRequestData from '../../../ReduxToolkit/functions/initRequestData';
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';

const initialState: IInitialState = {
  status: 'idle',
  clientID:
    '687466120158-p5q3hvkv6i0h4e9jfv6noe8jfl8rl1k0.apps.googleusercontent.com',
  googleAccount: {
    accountData: {
      name: '',
      email: '',
      imageUrl: '',
    },
    status: 'idle',
  },
  autoUpdateContacts: {
    enabled: false,
    status: 'idle',
    error: null,
  },
  createTag: {
    status: 'idle',
    error: null,
  },
  whatsAppContactsSync: {
    status: 'idle',
  },
};

export const fetchSyncConfig = createAsyncThunk(
  'googleSync/fetchSyncConfig',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as any;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    const response = await axios.get(`${URL_CONTACTS}/GooglePeople`, config);

    return response.data as ISyncConfig;
  }
);

export const setAccountData = createAsyncThunk(
  'googleSync/setAccountData',
  async (data: IGoogleAccountData, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as any;
    const config = getRequestConfig.contentTypeJson(jwtToken);

    await axios.post(`${URL_CONTACTS}/GooglePeople`, data, config);

    return data.googleAccount;
  }
);

export const setAutoUpdate = createAsyncThunk(
  'googleSync/setAutoUpdate',
  async (data: ISetAutoUpdateData, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as any;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(`${URL_CONTACTS}/GooglePeople`, data, config);

    return response.data;
  }
);

export const createTag = createAsyncThunk(
  'googleSync/createTag',
  async (_, { dispatch }) => {
    const data: ICreateTagData = {
      action: 'createTag',
    };
    const dataJSON = JSON.stringify(data);
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as any;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    const response = await axios.put(
      `${URL_CONTACTS}/GooglePeople`,
      dataJSON,
      config
    );

    return response.data;
  }
);

export const syncWhatsAppContacts = createAsyncThunk(
  'googleSync/syncWhatsAppContacts',
  async (_, { dispatch }) => {
    const data: IWhatsappContactsSyncData = {
      action: 'runSync',
    };
    const dataJSON = JSON.stringify(data);
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as any;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${URL_CONTACTS}/GooglePeople`,
      dataJSON,
      config
    );

    return response.data;
  }
);

export const googleLogOut = createAsyncThunk(
  'googleSync/googleLogOut',
  async (_, { dispatch }) => {
    const data: IGoogleAccountLogOutData = {
      action: 'deleteAccount',
    };
    const dataJSON = JSON.stringify(data);
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as any;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${URL_CONTACTS}/GooglePeople`,
      dataJSON,
      config
    );

    return response.data;
  }
);

export const googleSyncSlice = createSlice({
  name: 'googleSync',
  initialState,
  reducers: {
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSyncConfig.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSyncConfig.fulfilled, (state, action) => {
        state.status = 'idle';
        if (
          action.payload !== null &&
          typeof action.payload === 'object' &&
          action.payload.hasOwnProperty('autoUpdate') &&
          action.payload.hasOwnProperty('googleAccount')
        ) {
          state.autoUpdateContacts.enabled = action.payload.autoUpdate;
          state.googleAccount.accountData = action.payload.googleAccount;
        }
        console.log('success googleSyncSlice fetchSyncConfig: ', action.payload);
      })
      .addCase(fetchSyncConfig.rejected, (state, action) => {
        state.status = 'idle';
        console.log('error', action.error);
      })
      .addCase(setAccountData.fulfilled, (state, action) => {
        const newGoogleAccountData = action.payload;

        state.googleAccount.accountData = newGoogleAccountData;
      })
      .addCase(setAccountData.rejected, (state, action) => {
        console.log('error', action.error);
      })
      .addCase(setAutoUpdate.pending, (state) => {
        state.autoUpdateContacts.status = 'loading';
      })
      .addCase(setAutoUpdate.fulfilled, (state, action) => {
        state.autoUpdateContacts.enabled = !state.autoUpdateContacts.enabled;
        state.autoUpdateContacts.status = 'idle';
        console.log('success', action.payload);
      })
      .addCase(setAutoUpdate.rejected, (state, action) => {
        state.autoUpdateContacts.status = 'idle';
        state.autoUpdateContacts.error = action.error.message;
        console.log('error', action.error);
      })
      .addCase(createTag.pending, (state) => {
        state.createTag.status = 'loading';
      })
      .addCase(createTag.fulfilled, (state, action) => {
        state.createTag.status = 'idle';
      })
      .addCase(createTag.rejected, (state, action) => {
        state.createTag.status = 'idle';
        state.createTag.error = action.error.message;
        console.log('error', action.error);
      })
      .addCase(syncWhatsAppContacts.pending, (state) => {
        state.whatsAppContactsSync.status = 'loading';
      })
      .addCase(syncWhatsAppContacts.fulfilled, (state, action) => {
        state.whatsAppContactsSync.status = 'idle';
      })
      .addCase(syncWhatsAppContacts.rejected, (state, action) => {
        state.whatsAppContactsSync.status = 'idle';
        console.log('error', action.error);
      })
      .addCase(googleLogOut.pending, (state, action) => {
        state.googleAccount.status = 'loginOut';
      })
      .addCase(googleLogOut.fulfilled, (state, action) => {
        state.googleAccount.status = 'idle';
        state.googleAccount.accountData = {
          email: '',
          name: '',
          imageUrl: '',
        };
      });
  },
});

export const selectGoogleSync = (state: RootState) => state.GoogleSync;
export const selectFetchSyncConfigStatus = createSelector(
  selectGoogleSync,
  (state: IInitialState) => state.status
);
export const selectClientID = createSelector(
  selectGoogleSync,
  (state: IInitialState) => state.clientID
);
export const selectAutoUpdateContactsEnabled = createSelector(
  selectGoogleSync,
  (state: IInitialState) => state.autoUpdateContacts.enabled
);
export const selectAutoUpdateContactsStatus = createSelector(
  selectGoogleSync,
  (state: IInitialState) => state.autoUpdateContacts.status
);
export const selectCreateTagStatus = createSelector(
  selectGoogleSync,
  (state: IInitialState) => state.createTag.status
);
export const selectWhatsAppContactsSyncStatus = createSelector(
  selectGoogleSync,
  (state: IInitialState) => state.whatsAppContactsSync.status
);
export const selectGoogleAccountData = createSelector(
  selectGoogleSync,
  (state: IInitialState) => state.googleAccount.accountData
);
export const selectGoogleAccountStatus = createSelector(
  selectGoogleSync,
  (state: IInitialState) => state.googleAccount.status
);

export default googleSyncSlice.reducer;
