import { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentsEditContext } from 'features/Views/SegmentsEdit/context/SegmentsEditProvider';
import { filterOptions as rawFilterOptions } from './utils/filterOptions';
import { fieldsSelectStyle } from './styles/fieldsSelectStyle';
import { useSelector } from 'react-redux';
import { selectCustomContactFields } from 'ReduxToolkit/features/configSlice/configSlice';
import { MenuItem, ListSubheader, Select, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

const StyledMenuItem = styled(MenuItem)({
  fontSize: '13px',
});

interface Props {
  value: string;
  itemId: string;
  groupId: string;
}

const FieldsSelect = ({ value, itemId, groupId }: Props) => {
  const { t } = useTranslation();
  const { handleFieldSelectChange } = useContext(SegmentsEditContext);
  const customContactFields = useSelector(selectCustomContactFields);

  const translatedFilterOptions = useMemo(() => {
    return rawFilterOptions.map(option => ({
      ...option,
      category: t(`segmentsEdit.filterCategories.${option.category}`),
      data: option.data.map(item => ({
        ...item,
        name: t(`segmentsEdit.filterNames.${item.name}`)
      }))
    }));
  }, [t]);

  const ContactDetailsMenuItems = translatedFilterOptions[0].data.map((data) => (
    <StyledMenuItem key={data.key} value={data.key}>
      {data.name}
    </StyledMenuItem>
  ));

  const GeographyMenuItems = translatedFilterOptions[1].data.map((data) => (
    <StyledMenuItem key={data.key} value={data.key}>
      {data.name}
    </StyledMenuItem>
  ));

  const AccountDetailsMenuItems = translatedFilterOptions[2].data.map((data) => (
    <StyledMenuItem key={data.key} value={data.key}>
      {data.name}
    </StyledMenuItem>
  ));

  const CustomContactFieldsMenuItems = customContactFields.map((field) => (
    <StyledMenuItem key={field.id} value={field.id}>
      {field.name}
    </StyledMenuItem>
  ));

  return (
    <FormControl sx={{ width: '40%' }}>
      <InputLabel htmlFor="grouped-select">
        {t('segmentsEdit.filterBy')}
      </InputLabel>
      <Select
        id="grouped-select"
        value={value}
        label={t('segmentsEdit.filterBy')}
        onChange={(e) => handleFieldSelectChange(e, itemId, groupId)}
        size="small"
        MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}
      >
        <ListSubheader sx={fieldsSelectStyle.subheaderStyle}>
          {translatedFilterOptions[0].category}
        </ListSubheader>
        {ContactDetailsMenuItems}
        <ListSubheader sx={fieldsSelectStyle.subheaderStyle}>
          {translatedFilterOptions[1].category}
        </ListSubheader>
        {GeographyMenuItems}
        <ListSubheader sx={fieldsSelectStyle.subheaderStyle}>
          {translatedFilterOptions[2].category}
        </ListSubheader>
        {AccountDetailsMenuItems}
        <ListSubheader sx={fieldsSelectStyle.subheaderStyle}>
          {t('segmentsEdit.filterCategories.customContactFields')}
        </ListSubheader>
        {CustomContactFieldsMenuItems}
      </Select>
    </FormControl>
  );
};

export default FieldsSelect;
