import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit/dist';
import { RootState } from '../../rootReducer';
// Functions
import initRequestData from '../../functions/initRequestData';
// Types
import { LabelState } from './types/LabelState';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ILabel } from '@trii/types/dist/Conversations';
import { NewLabel } from './types/NewLabel';
import type { NewBasicLabel } from './types/NewBasicLabel';
// Service
import labelService from './labelService';

const initialState: LabelState = {
  labels: [],
  status: {
    fetch: 'idle',
    create: 'idle',
    update: 'idle',
    delete: 'idle',
  },
};

export const fetchLabels = createAsyncThunk(
  'label/fetchLabels',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await labelService.fetchLabels(jwtToken, URL_CONTACTS);

    return response;
  }
);

export const createLabel = createAsyncThunk(
  'label/createLabel',
  async (label: NewLabel | NewBasicLabel, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await labelService.createLabel(jwtToken, URL_CONTACTS, label);

    return response;
  }
);

export const deleteLabel = createAsyncThunk(
  'label/deleteLabel',
  async (labelId: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const response = await labelService.deleteLabel(jwtToken, URL_CONTACTS, labelId);

    return response;
  }
);

export const updateLabel = createAsyncThunk(
  'label/updateLabel',
  async (label: ILabel, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    await labelService.updateLabel(jwtToken, URL_CONTACTS, label);

    return label;
  }
);

const labelSlice = createSlice({
  name: 'label',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLabels.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.labels = action.payload;
      })
      .addCase(createLabel.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createLabel.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.labels.push(action.payload);
      })
      .addCase(deleteLabel.pending, (state) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteLabel.fulfilled, (state, action) => {
        state.status.delete = 'succeeded';
        state.labels = state.labels.filter((label) => label.id !== action.payload);
      })
      .addCase(updateLabel.pending, (state) => {
        state.status.update = 'loading';
      })
      .addCase(updateLabel.fulfilled, (state, action) => {
        state.status.update = 'succeeded';
        const editLabel = action.payload;
        state.labels = state.labels.map((label) => {
          if (label.id === editLabel.id) {
            return editLabel;
          }
          return label;
        });
      });
  },
});

const selectLabelState = (state: RootState) => state.Label;
export const selectAllLabels = createSelector(
  selectLabelState,
  (state) => state.labels
);
export const selectLabelsFetchStatus = createSelector(
  selectLabelState,
  (state) => state.status.fetch
);
export const selectLabelCreateStatus = createSelector(
  selectLabelState,
  (state) => state.status.create
);
export const selectLabelDeleteStatus = createSelector(
  selectLabelState,
  (state) => state.status.delete
);
export const selectLabelUpdateStatus = createSelector(
  selectLabelState,
  (state) => state.status.update
);

export default labelSlice.reducer;
