import React, { useContext } from 'react';
import { SegmentsEditContext } from '../../context/SegmentsEditProvider';
// Components/ui
import { Divider, Box } from '@mui/material';
import { Header, Inputs, ActionButtons } from './components';

const DeletePanel = () => {
  const { deletePanelOpen } = useContext(SegmentsEditContext);

  if (!deletePanelOpen) return null;

  return (
    <Box height={'100%'} width={'25%'} py={2}>
      <Box
        height={'100%'}
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: '10px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Header />
          <Divider />
          <Inputs />
        </Box>
        <ActionButtons />
      </Box>
    </Box>
  );
};

export default DeletePanel;
