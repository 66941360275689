// Types
import { ReactNode } from 'react';
// Components/ui
import { Typography, Box } from '@mui/material';

interface Props {
  children: ReactNode;
  sectionTitle: string;
}

const CardSection = ({ children, sectionTitle }: Props) => {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={"start"}
      p={2}
      
    >
      <Typography
        fontWeight={"500"}
        sx={{ color: (theme) => theme.palette.text.primary }}
      >
        {sectionTitle}
      </Typography>
      {children}
    </Box>
  );
};

export default CardSection;
