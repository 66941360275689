// Provider
import CustomFieldProvider from './context/CustomFieldContext';
// Components/ui
import { CustomFieldsList, CrudPanel, CreateFieldErrorAlert } from './components';
import { Box } from '@mui/material';
// Types
import type For from 'types/For';

type Props = {
  fetchFor: For;
};

const CustomFields = ({ fetchFor }: Props) => {
  return (
    <CustomFieldProvider fetchFor={fetchFor}>
      <CreateFieldErrorAlert />
      <Box display={'flex'}>
        <CustomFieldsList fetchFor={fetchFor} />
        <CrudPanel />
      </Box>
    </CustomFieldProvider>
  );
};

export default CustomFields;
