import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NotMembersMessage = () => {
    const {t} = useTranslation()
  return (
    <Box margin={'20px'} marginLeft={'5%'}>
        <Typography variant='subtitle2' sx={{opacity: 0.5}}>
            {t('businessDetails.notMembersBusiness')}
        </Typography>
    </Box>
  )
}

export default NotMembersMessage