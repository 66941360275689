import { UseColorReturn } from 'hooks/useColor';

const selectColorInitialDef: UseColorReturn = {
  actions: {
    resetValue: () => {},
    changeValue: () => {},
  },
  attributes: {
    value: '',
    onChange: () => {},
  },
};

export default selectColorInitialDef;
