import { memo, useEffect, useState, ReactNode, FC } from 'react';
import { FilesCardContext } from './FilesCardContext';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchFiles, deleteFile, setDeletingFileStatus, removeDeletingFileStatus, sortFilesByColumn } from 'ReduxToolkit/features/fileSlice/fileSlice';
import { FilesCardContextType } from './types/FilesCardContextType';
import { FileTableColumn } from './types/FileTableColumn';
import { FileTableColumnName } from 'ReduxToolkit/features/fileSlice/types/FileTableColumnName';
import useFile from 'hooks/useFile';
import { fileTableColumns } from './utils/columns';
import { getOrderedColumns } from '../../../utils/getOrderedColumns';

const FilesCardContextProvider: FC<{ children: ReactNode }> = memo(({ children }) => {
  const dispatch = useAppDispatch();
  const fileHandler = useFile();
  const [columns, setColumns] = useState<FileTableColumn[]>(fileTableColumns);

  const [isModalDeleteFileOpen, setIsModalDeleteFileOpen] = useState(false);
  const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);

  const sortByColumn = (columnName: FileTableColumnName, sortOrder: 'ASC' | 'DESC') => {
    const newColumns: FileTableColumn[] = getOrderedColumns(columns, columnName, sortOrder);
    setColumns(newColumns);
    dispatch(sortFilesByColumn({ columnName, sortOrder }));
  };

  const openModalDeleteFile = (fileId: string) => {
    setFileIdToDelete(fileId);
    setIsModalDeleteFileOpen(true);
  };

  const closeModalDeleteFile = () => {
    setFileIdToDelete(null);
    setIsModalDeleteFileOpen(false);
  };

  const handleDeleteFile = async (fileId?: string) => {
    const idToDelete = fileId || fileIdToDelete;
    if (idToDelete) {
       fileHandler.action.handleDeleteFile(idToDelete);
      closeModalDeleteFile();
    }
  };

  const value: FilesCardContextType = {
    fileHandler,
    sortByColumn,
    columns,
    openModalDeleteFile,
    closeModalDeleteFile,
    isModalDeleteFileOpen,
    fileIdToDelete,
    handleDeleteFile,
  };

  useEffect(() => {
    dispatch(fetchFiles());
  }, []);

  return (
    <FilesCardContext.Provider value={value}>
      {children}
    </FilesCardContext.Provider>
  );
});

export default FilesCardContextProvider;