import React from 'react';
// Components/ui
import { Skeleton } from '@mui/material';

const GoogleButtonSkeleton = () => {
  return (
    <Skeleton
      sx={{
        backgroundColor: 'lightgrey',
        borderRadius: '4px',
      }}
      variant="rectangular"
      width={155}
      height={29}
    />
  );
};

export default GoogleButtonSkeleton;
