import { useRef, ChangeEvent } from 'react';
// Redux
import { useAppDispatch } from './useAppDispatch';
import {
  addFile,
  deleteFile,
  setDeletingFileStatus,
  removeDeletingFileStatus,
} from 'ReduxToolkit/features/fileSlice/fileSlice';

export type UseFileType = {
  inputAtributes: {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    ref: React.MutableRefObject<HTMLInputElement>;
    type: string;
  };
  action: {
    uploadFile: () => void;
    handleDeleteFile: (fileId: string) => void;
  };
};

const useFile = (): UseFileType => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files[0];

    await dispatch(
      addFile({
        file: fileUploaded,
        URLParams: { module: 'contacts', folderType: 'files', contactId: '' },
      })
    );
  };

  const uploadFile = () => {
    ref.current.click();
  };
  const handleDeleteFile = async (fileId: string) => {
    dispatch(setDeletingFileStatus(fileId));
    await dispatch(deleteFile(fileId));
    dispatch(removeDeletingFileStatus(fileId));
  };

  return {
    inputAtributes: {
      onChange,
      ref,
      type: 'file',
    },
    action: {
      uploadFile,
      handleDeleteFile,
    },
  };
};

export default useFile;
