import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { SegmentsEditContext } from '../../../../../../../../context/SegmentsEditProvider';
// Redux
import { useSelector } from 'react-redux';
import { selectSelectedSegmentFilterOperator } from '../../../../../../../../../../../ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Types
import type { Operator } from '../../../../../../../../../../../ReduxToolkit/features/segmentsSlice/types';
// Components/ui
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

const ToggleConditionButtons = () => {
  const { handleOperatorToggle } = useContext(SegmentsEditContext);
  const { t } = useTranslation();
  const selectedSegmentFilterOperator: Operator = useSelector(
    selectSelectedSegmentFilterOperator
  );

  return (
    <ToggleButtonGroup
      value={selectedSegmentFilterOperator}
      exclusive
      onChange={handleOperatorToggle}
      sx={{
        alignSelf: 'center',
        height: 25,
      }}
    >
      <ToggleButton value="AND">{t('segmentsEdit.and')}</ToggleButton>
      <ToggleButton value="OR">{t('segmentsEdit.or')}</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleConditionButtons;
