// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectUsers } from 'ReduxToolkit/features/userSlice/userSlice';
// Components/ui
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { style } from './style';
// Types
import type { UseSelectType } from 'hooks/useSelect';

interface Props {
  contactPropietary: UseSelectType;
}

const ContactPropietarySelect = ({ contactPropietary }: Props) => {
  const { t } = useTranslation();
  const users = useSelector(selectUsers);
  const isBusiness = window.location.pathname.includes('business');
  const contactPropietaryValue = contactPropietary?.attributes?.value

  const selectedUser = users?.find((user) => user.id === contactPropietaryValue);
  const MenuItems = users?.map((user) => {
    return (
      <MenuItem key={user.id} value={user.id}>
        {user.name}
      </MenuItem>
    );
  });

  return (
    <FormControl className="fadein" sx={{ marginBottom: '15px' }}>
      <InputLabel shrink id="owner-select-label">
        {isBusiness
          ? t('businessDetails.businessOwner')
          : t('contactDetails.contactOwner')}
      </InputLabel>
      <Select
        labelId="owner-select-label"
        label={t('contactDetails.contactOwner')}
        size="small"
        {...contactPropietary?.attributes}
        sx={style.input}
        value={selectedUser ? selectedUser.id : ''}
        MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'transparent' }} value={''}>
          {'No value'}
        </MenuItem>
        {MenuItems}
      </Select>
    </FormControl>
  );
};

export default ContactPropietarySelect;
