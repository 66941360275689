import React, { useEffect, useState } from 'react';
// redux
import { useSelector } from 'react-redux';
import {
  selectContactFields,
  selectPagination,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// components/ui
import { RowSkeleton } from './components';

const ContactsSkeleton = () => {
  const columns = useSelector(selectContactFields);
  const { perPage } = useSelector(selectPagination);
  const [skeletonsItems, setSkeletonsItems] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);

  useEffect(() => {
    const skeletons = [];
    const getVisibleColumns = columns.filter((column) => column.visible);

    for (let i = 0; i < perPage; i++) {
      skeletons.push(i);
    }

    setSkeletonsItems(skeletons);
    setVisibleColumns([...getVisibleColumns]);
  }, [perPage, columns]);

  return (
    <RowSkeleton
      rows={skeletonsItems}
      columns={columns}
      visibleColumns={visibleColumns}
    />
  );
};

export default ContactsSkeleton;
