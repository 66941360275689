import React, { useContext } from 'react';
// Components/ui
import { Box, InputLabel, TextField } from '@mui/material';
import { SubscriptionListContext } from '../../../../../context/SubscriptionListContext';

function DeletePanelContent({ t }) {
  const { deleteNameField, selectedField } = useContext(SubscriptionListContext);

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={'center'}
      p={2}
    >
      <InputLabel
        sx={{
          whiteSpace: 'break-spaces',
        }}
      >
        {t('settingsView.deleteSubscriptionLabel')}
        <strong style={{ paddingLeft: '2px' }}>{selectedField.name}</strong>
      </InputLabel>
      <TextField
        className="fadein"
        variant="outlined"
        {...deleteNameField.attributes}
        size="small"
        fullWidth
        sx={{
          marginBottom: '15px',
          '& legend': {
            display: 'none',
          },
          '& fieldset': {
            top: 0,
          },
        }}
      />
    </Box>
  );
}
export default DeletePanelContent;
