// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import { imageInputInitialDef } from 'utils/imageInputInitialDef';
import { multipleSelectInitialDef } from 'utils/multipleSelectInitialDef';
import { selectInputInitialDef } from 'utils/selectInputInitialDef';

export const initialDef = {
  action: {
    setCustomProperties: () => {},
    getContact: () => {},
    handlePhoneNumbersNumberFieldChange: () => {},
    handleEmailsEmailFieldChange: () => {},
    handleWhatsappsAddressFieldChange: () => {},
    handleNoteFieldChange: () => {},
    handleDeleteAddress: () => {},
    addPhone: () => {},
    addEmail: () => {},
    addWhatsapp: () => {},
    addBusiness: () => {},
    setBirthDate: () => {},
    setSelectedBusiness: () => {},
  },
  field: {
    birthDate: null,
    contactListSelectState: selectInputInitialDef,
    contactPropietary: selectInputInitialDef,
    contactFirstname: fieldInitialDef,
    contactLastname: fieldInitialDef,
    contactImage: imageInputInitialDef,
    imsFacebooks: [],
    imsWebchats: [],
    imsWhatsapp: [],
    imsInstagrams: [],
    emails: [],
    phoneNumbers: [],
    labelMultipleSelect: multipleSelectInitialDef,
    homeAddress: fieldInitialDef,
    secondaryHomeAddress: fieldInitialDef,
    city: fieldInitialDef,
    secondaryCity: fieldInitialDef,
    state: fieldInitialDef,
    secondaryState: fieldInitialDef,
    postalCode: fieldInitialDef,
    secondaryPostalCode: fieldInitialDef,
    country: fieldInitialDef,
    secondaryCountry: fieldInitialDef,
    customProperties: [],
    selectedBusiness: [],
  },
};
