import { useContext } from 'react';
// Context
import { StyleContext } from 'style/styleProvider';
// Components/ui
import { Box, Typography } from '@mui/material';

type Props = {
  title: string;
  value: string;
};

const ContactInfoLabel = ({ title, value }: Props) => {
  const { fontSize, mediaQuery } = useContext(StyleContext);

  return (
    <Box
      className="fadein contacts-col-5"
      sx={{
        display: 'flex',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? '1e1e1e'
            : theme.palette.background.default,
        // padding: '0 10px',
        flexDirection: 'column',
        borderRadius: 1,
        minWidth: "200px"

      }}
    >
      <Typography
        letterSpacing={-0.5}
        fontSize={fontSize.sm }
        fontWeight={'600'}
        width={'50%'}
        color={(theme) => theme.palette.text.primary}
      >
        {title}
      </Typography>
      <Box width={'50%'} position="relative">
        <Typography
          fontSize={ fontSize.sm }
          fontWeight={'400'}
          sx={{ padding: '2.5px 14px 8.5px 0px' }}
          color={(theme) => theme.palette.text.primary}
        >
          {value === '' ? '-' : value}
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactInfoLabel;
