import { useEffect, useState } from 'react';
import useSelect from 'hooks/useSelect';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchContactFields,
  selectContactFields,
  importContacts,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { Box, Checkbox, Fade, Typography, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FieldSelect } from './components';
import FileUploadIcon from '@mui/icons-material/FileUpload';
// Types
import type ContactFieldsFetchData from 'ReduxToolkit/features/contactsSlice/types/ContactFieldsFetchData';
import { XslxFile } from '../../types';

type ImportValidationProps = {
  xslxFile: XslxFile;
  handleNext: () => void;
};

export default function ImportValidation({
  xslxFile,
  handleNext,
}: ImportValidationProps) {
  const dispatch = useDispatch();
  const columns = useSelector(selectContactFields);
  const [show, setShow] = useState(false);
  const [updateChecked, setUpdateChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const fieldSelect = useSelect('');

  useEffect(() => {
    setShow(true);
  }, [dispatch]);

  useEffect(() => {
    const fetchContactFieldsData: ContactFieldsFetchData = {
      filterBy: 'all',
      fetchFor: 'contact',
    };

    dispatch(fetchContactFields(fetchContactFieldsData));
  }, [dispatch]);

  const handleImportContacts = async () => {
    const data = xslxFile.workbookSheet;

    if (updateChecked) {
      data.append('updateBy', fieldSelect.attributes.value);
    }
    console.log('data', data);
    setLoading(true);
    await dispatch(importContacts(data));
    setLoading(false);
    handleNext();
  };

  const handleUpdateChecked = (e) => {
    setUpdateChecked(e.target.checked);

    if (e.target.checked && fieldSelect.attributes.value === '') {
      fieldSelect.actions.changeValue(columns[0].nameKey);
    }
  };

  return (
    <Fade style={{ transitionDuration: '750ms' }} in={show}>
      <Box minHeight={'200px'} maxHeight={'calc(100vh - 330px)'} overflow={'auto'}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          p={3}
        >
          <Typography mb={2} sx={{ color: (theme) => theme.palette.text.primary }}>
            Cantidad de filas: {xslxFile.rowsAmount}
          </Typography>
          <Box display="flex" mb={2} flexDirection={'column'}>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    value={updateChecked}
                    onChange={(e) => handleUpdateChecked(e)}
                  />
                }
                sx={{ color: 'text.primary' }}
                label="Actualizar contactos existentes, segun:"
              />
            </Box>
            <FieldSelect
              columns={columns}
              fieldSelect={fieldSelect}
              updateChecked={updateChecked}
            />
          </Box>
          <LoadingButton
            loadingPosition="start"
            variant="contained"
            onClick={handleImportContacts}
            loading={loading}
            startIcon={<FileUploadIcon />}
            disabled={loading}
          >
            Importar Contactos
          </LoadingButton>
        </Box>
      </Box>
    </Fade>
  );
}
