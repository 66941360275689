import {
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, FormControlLabel, Radio, Typography } from '@mui/material';
// Context
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';
// Types
import { IContactInfo } from '@trii/types/dist/Contacts';

interface ContactProps {
  businessData: {
    name: string;
    id: string;
  };
  icon: React.ReactNode;
  contactId: string;
  isMerged?: boolean;
  setBusinessSelected: Dispatch<SetStateAction<boolean>>;
}

const Business = ({
  businessData,
  icon,
  contactId,
  isMerged = false,
  setBusinessSelected,
}: ContactProps) => {
  const {
    business,
    handleSelectBusiness,
    triggerSelectAllId,
    setTriggerSelectAllId,
  } = useContext(DuplicateContext);
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');

  const businessRadioRef = useRef(null);

  const handleSelected = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    if (businessData?.id) {
      // @ts-ignore
      setBusinessSelected(e.target.checked);
      handleSelectBusiness(businessData as IContactInfo, contactId);
    }
  };

  useEffect(() => {
    if (!isMerged) {
      setName(businessData?.name);
    } else {
      setName(business?.value?.name);
    }
  }, [business, businessData]);

  useEffect(() => {
    if (triggerSelectAllId !== '' && triggerSelectAllId === contactId) {
      setTriggerSelectAllId('');
      businessRadioRef.current?.click();
    }
  }, [triggerSelectAllId, contactId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'max-content',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Box
        sx={{
          width: '100%',
          borderBottom: '1px solid',
          borderColor: (theme) => theme.palette.divider,
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="semi-bold"
          color={'text.disabled'}
        >
          {t('duplicatesView.contactInfo.business')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: 'max-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 'max-content',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <FormControlLabel
            control={
              !isMerged ? (
                <Radio
                  size="small"
                  ref={businessRadioRef}
                  checked={business?.contactId === contactId}
                />
              ) : (
                <></>
              )
            }
            onClick={(e) => !isMerged && handleSelected(e)}
            disabled={!businessData?.id}
            label={
              <Box display="flex" alignItems="center" width="240px" gap={1}>
                {icon}
                <Typography
                  variant="subtitle1"
                  fontWeight="semi-bold"
                  fontSize={14}
                  color={'text.disabled'}
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    cursor: 'default',
                  }}
                >
                  {name}
                </Typography>
              </Box>
            }
            sx={{
              width: '100%',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Business;
