// Components
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

type Props = {
  nameKey: string;
};

const CustomPropertyOptionsSelect = ({ nameKey }: Props) => {
  return (
    <FormControl className="fadein" sx={{ width: '100%', padding: '0 10px' }}>
      <InputLabel shrink id="option-select-label">
        {nameKey}
      </InputLabel>
      <Select
        labelId="option-select-label"
        label={nameKey}
        size="small"
        // {...contactPropietary.attributes}
        sx={{
          fontSize: '0.8rem',
          '& legend': {
            maxWidth: '100%',
            padding: '0 4px',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'transparent' }} value={''}>
          {'No value'}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default CustomPropertyOptionsSelect;
