// Types
import { ContactField_type } from '@trii/types/dist/Contacts';
// Components
import {
  TextNumberField,
  DatePickerField,
  OptionSelect,
  TimePickerField,
} from './components';

type Props = {
  fieldFilterType: ContactField_type;
  value: string;
  itemId: string;
  groupId: string;
};

const ValueField = ({ fieldFilterType, value, itemId, groupId }: Props) => {
  if (
    fieldFilterType === ContactField_type.LONGTEXT ||
    fieldFilterType === ContactField_type.TEXT ||
    fieldFilterType === ContactField_type.NUMBER_CURRENCY ||
    fieldFilterType === ContactField_type.NUMBER_ACCOUNTING ||
    fieldFilterType === ContactField_type.NUMBER_DECIMAL ||
    fieldFilterType === ContactField_type.NUMBER_INTEGER ||
    fieldFilterType === ContactField_type.CURRENCY
  ) {
    return (
      <TextNumberField
        groupId={groupId}
        value={value}
        itemId={itemId}
        fieldFilterType={fieldFilterType}
      />
    );
  } else if (
    fieldFilterType === ContactField_type.DATE ||
    fieldFilterType === ContactField_type.DATERANGE
  ) {
    return <DatePickerField groupId={groupId} value={value} itemId={itemId} />;
  } else if (fieldFilterType === ContactField_type.OPTIONS) {
    return <OptionSelect groupId={groupId} value={value} itemId={itemId} />;
  } else if (
    fieldFilterType === ContactField_type.TIME ||
    fieldFilterType === ContactField_type.TIMERANGE
  ) {
    return <TimePickerField groupId={groupId} value={value} itemId={itemId} />;
  }
  return null;
};

export default ValueField;
