import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectDuplicatesItems,
  selectDuplicatesStatus,
} from '../../../../../ReduxToolkit/features/duplicatesSlice/duplicatesSlice';
import { DuplicateContext } from '../../context/DuplicateContext';
import Box from '@mui/material/Box';
import {
  DuplicatedContactPanel,
  DuplicatedContactPanelSkeleton,
} from './components';
import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios'; // Importa axios para hacer la solicitud HTTP

export default function DuplicatesContent() {
  const { t } = useTranslation();

  const { getContactFields } = useContext(DuplicateContext);

  const [isError, setIsError] = useState(false);

  const duplicatesItems = useSelector(selectDuplicatesItems);
  const duplicatesStatus = useSelector(selectDuplicatesStatus);

  const handleCloseSnackBar = () => {
    setIsError(false);
  };

  useEffect(() => {
    if (duplicatesStatus === 'idle') {
      setIsError(true);
    }
  }, [duplicatesStatus]);

  const DuplicatedContactPanels = duplicatesItems?.map((duplicatedItem, i) => (
    <DuplicatedContactPanel data={duplicatedItem} key={i} />
  ));

  const Duplicates =
    duplicatesStatus === 'succeeded' ? (
      DuplicatedContactPanels
    ) : duplicatesStatus === 'loading' ? (
      <DuplicatedContactPanelSkeleton />
    ) : null;

  useEffect(() => {
    getContactFields();
  }, []);

  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: 'calc(100% - 10rem)',
      }}
    >
      {Duplicates}
      <Snackbar
        open={isError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          sx={{ width: '100%' }}
          variant="filled"
        >
          {t('snackBarMessage.contactNotFound')}
        </Alert>
      </Snackbar>
    </Box>
  );
}
