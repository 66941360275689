import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectListsData } from 'ReduxToolkit/features/listsSlice/listsSlice';
import {
  selectList,
  setList,
} from 'ReduxToolkit/features/contactsFilterSlice/contactsFilterSlice';
// Components/ui
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { style } from './style';
import { useAppDispatch } from 'hooks/useAppDispatch';

const ListSelect = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const lists = useSelector(selectListsData);
  const list = useSelector(selectList);

  const listItems = lists.map((list) => (
    <MenuItem key={list.id} value={list.id}>
      {list.name}
    </MenuItem>
  ));

  return (
    <Box sx={style.container}>
      <FormControl size="small" sx={{ width: '100%' }}>
        <InputLabel>{t('global.lists')}</InputLabel>
        <Select
          fullWidth
          label={t('global.lists')}
          value={list}
          onChange={(e) => dispatch(setList(e.target.value))}
          MenuProps={{
            PaperProps: {
              sx: {
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.panel,
                backgroundImage:
                  'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
              },
            },
          }}
        >
          {listItems}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ListSelect;
