import axios from 'axios';
// Utils
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
// Types
import type For from '../../../types/For';

const fetchCustomFields = async (jwtToken: string, URL: string, fetchFor: For) => {
  const config = getRequestConfig.basic(jwtToken);
  const { data } = await axios.get(
    `${URL}/ContactFields?filter=custom&for=${fetchFor}`,
    config
  );
  return data;
};

export default {
  fetchCustomFields,
};
