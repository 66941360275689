import React from 'react';
import { Box } from '@mui/material';
import ExportContent from './ExportContent';

export default function ExportView() {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'85vh'}
      width={'100%'}
      className={'contacts-paddingmd'}
      borderRadius={2}
    >
      <ExportContent />
    </Box>
  );
}
