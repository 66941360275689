import React from 'react';
// Components/ui
import { Box, Divider } from '@mui/material';
import { ListHeader, ListBody } from './components';

const AppPanel = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      width={'100%'}
      className={'contacts-paddingmd paddingContent'}
      borderRadius={2}
      id={'listContentCP'}
    >
      <ListHeader />
      <Divider sx={{ marginY: '10px', marginX: '10px' }} />
      <ListBody />
    </Box>
  );
};

export default AppPanel;
