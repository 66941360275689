import { useEffect, ReactNode } from 'react';
// Redux
import {
  fetchContactFields,
  getContactFieldsFetchStatus,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { getUsers } from 'ReduxToolkit/features/userSlice/userSlice';
import { useDispatch, useSelector } from 'react-redux';
// Components/ui
import { Box } from '@mui/material';
// Types
import type ContactFieldsFetchData from 'ReduxToolkit/features/contactsSlice/types/ContactFieldsFetchData';
import { fetchLabels } from 'ReduxToolkit/features/labelSlice/labelSlice';

type Props = {
  children: ReactNode;
};

const BusinessCreateContainer = ({ children }: Props) => {
  const dispatch = useDispatch();
  const contactFieldsFetchStatus = useSelector(getContactFieldsFetchStatus);

  useEffect(() => {
    if (contactFieldsFetchStatus !== 'succeeded') {
      const fetchData: ContactFieldsFetchData = {
        filterBy: 'custom',
        fetchFor: 'business',
      };

      dispatch(fetchContactFields(fetchData));
    }
    dispatch(getUsers());
    dispatch(fetchLabels());
  }, [dispatch]);
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      // className={'contacts-paddingmd'}
      // py={'0.5rem'}
      // px={'0.5rem'}
      borderRadius={2}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
        // className={'contacts-paddingmd'}
        // py={'0.5rem'}
        // px={'0.5rem'}
        borderRadius={2}
      >
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark'
                ? '1e1e1e'
                : theme.palette.background.default,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default BusinessCreateContainer;
