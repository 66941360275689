import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Types
import InitRequestDataReturn from 'ReduxToolkit/types/InitRequestDataReturn';
import { IFile } from '@trii/types/dist/Contacts';
import type { RootState } from 'ReduxToolkit/store';
import type { FileSlice } from './types/FileSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AddFileRequestData } from './types/AddFileRequestData';
import type { FileTableColumnName } from './types/FileTableColumnName';
import type { SortOrder } from 'types/SortOrder';
// Utils
import fileSliceService from './utils/fileSliceService';
import initRequestData from 'ReduxToolkit/functions/initRequestData';
import { UploadMedia } from './types/UploadMedia';

const initialState: FileSlice = {
  files: [],
  status: {
    fetch: 'idle',
    add: 'idle',
    delete: [],
  },
};

export const fetchFiles = createAsyncThunk<IFile[], void>(
  'files/fetchFiles',
  async (_, { dispatch, getState }) => {
    const { Contacts } = getState() as RootState;
    const selectedContactId = Contacts.contactViewer.contactData.id;
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const fetchedFiles = await fileSliceService.fetchFiles(
      jwtToken,
      `${URL_CONTACTS}/files/${selectedContactId}`
    );

    return fetchedFiles;
  }
);

export const addFile = createAsyncThunk(
  'files/addFiles',
  async (data: UploadMedia, { dispatch, getState }) => {
    const { Contacts } = getState() as RootState;
    const { jwtToken, URL_CONTACTS, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { file, URLParams } = data;

    const selectedContactId = Contacts.contactViewer.contactData.id;
    const fd = new FormData();

    data.URLParams.contactId = selectedContactId;

    fd.append('file', file, file.name);

    const uploadedFileUrl = await fileSliceService.uploadMedia(
      jwtToken,
      URL_MEDIA,
      fd,
      URLParams
    );
    const addFileRequestData: AddFileRequestData = {
      fileName: file.name,
      mimeType: file.type,
      size: file.size,
      contactId: selectedContactId,
      url: uploadedFileUrl,
    };
    const newFile = await fileSliceService.addFile(
      jwtToken,
      `${URL_CONTACTS}/files`,
      addFileRequestData
    );

    return newFile;
  }
);

export const deleteFile = createAsyncThunk<string, string>(
  'files/deleteFiles',
  async (fileId, { dispatch, getState }) => {
    const { Contacts } = getState() as RootState;
    const selectedContactId = Contacts.contactViewer.contactData.id;
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await fileSliceService.deleteFile(
      jwtToken,
      `${URL_CONTACTS}/files/${selectedContactId}/${fileId}`
    );

    return fileId;
  }
);

const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setDeletingFileStatus(state, action: PayloadAction<string>) {
      state.status.delete.push(action.payload);
    },
    removeDeletingFileStatus(state, action: PayloadAction<string>) {
      const filteredFiles = state.status.delete.filter(
        (file) => file !== action.payload
      );
      state.status.delete = filteredFiles;
    },
    sortFilesByColumn(
      state,
      action: PayloadAction<{
        columnName: FileTableColumnName;
        sortOrder: SortOrder;
      }>
    ) {
      const { columnName, sortOrder } = action.payload;

      if (columnName !== 'createdBy') {
        state.files.sort((a, b) => {
          const valueA =
            columnName === 'fileName'
              ? String(a[columnName]).toLowerCase()
              : a[columnName];
          const valueB =
            columnName === 'fileName'
              ? String(b[columnName]).toLowerCase()
              : b[columnName];

          switch (sortOrder) {
            case 'ASC':
              return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            case 'DESC':
              return valueB < valueA ? -1 : valueB > valueA ? 1 : 0;
            default:
              throw new Error('Invalid sortOrder. Use "ASC" or "DESC".');
          }
        });
      } else {
        state.files.sort((a, b) => {
          if (a[columnName].name < b[columnName].name) {
            return sortOrder === 'ASC' ? -1 : 1;
          }
          if (a[columnName].name > b[columnName].name) {
            return sortOrder === 'ASC' ? 1 : -1;
          }
          return 0;
        });
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFiles.pending, (state, action) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchFiles.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.files = action.payload;
      })
      .addCase(addFile.pending, (state, action) => {
        state.status.add = 'loading';
      })
      .addCase(addFile.fulfilled, (state, action) => {
        state.files.push(action.payload);
        state.status.add = 'idle';
      })
      .addCase(addFile.rejected, (state, action) => {
        state.status.add = 'idle';
        console.log(action.error.message);
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const filteredFiles = state.files.filter(
          (file) => file.id !== action.payload
        );
        const filteredFileIds = state.status.delete.filter(
          (fileId) => fileId !== action.payload
        );
        state.status.delete = filteredFileIds;
        state.files = filteredFiles;
      });
  },
});

export const { setDeletingFileStatus, removeDeletingFileStatus, sortFilesByColumn } =
  fileSlice.actions;

export default fileSlice.reducer;
