import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SegmentsContext } from '../../../../../../context/SegmentsProvider';
// Types
import { ISegmentWithId } from 'ReduxToolkit/features/segmentsSlice/types';
// Components/ui
import { Box, Stack, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface IActionButtonsProps {
  segment: ISegmentWithId;
}

const ActionButtons = ({ segment }: IActionButtonsProps) => {
  const { openDeletePanel } = useContext(SegmentsContext);
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="end"
      justifyContent="space-evenly"
    >
      <Stack direction="row">
        <IconButton
          sx={{ color: (theme) => theme.palette.primary.main }}
          onClick={() => navigate(`edit/${segment.id}`)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => openDeletePanel(segment.id)}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default ActionButtons;
