import { useEffect, useState } from 'react';
import moment from 'moment';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import {
  selectContactData,
  selectContactFetchStatus,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { useSelector } from 'react-redux';
import { selectCustomContactFields } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import {
  fetchUserById,
  selectUser,
} from 'ReduxToolkit/features/userSlice/userSlice';
// Components/ui
import { Box, Divider, Skeleton } from '@mui/material';
import { CardSection } from './layout';
import { ContactInfoLabel, ContactCustomFieldLabel } from '../../layout';
import { AccordionCard } from '../../layout';
// Utils
import { isIContact } from 'utils/isIContact';
import { ContactSection } from './components';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import { UserTrii } from '@trii/types/dist/Users';

const skeletonMock = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

interface DetailsCardProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
}

const DetailsCard = ({ open, setOpen, title }: DetailsCardProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const contactData = useSelector(selectContactData);

  const contactViewerFetchStatus = useSelector(selectContactFetchStatus);
  const customContactFields = useSelector(selectCustomContactFields);
  const user = useSelector(selectUser);

  const [owner, setOwner] = useState<string>(null);
  const [updatedBy, setUpdatedBy] = useState<string>(null);
  const [createdBy, setCreatedBy] = useState<string>(null);

  let contactDataUpdate_at = moment(contactData.updatedAt).format('L, h:mm ');

  const birthDate = contactData.birthDate ? new Date(contactData.birthDate) : null;
  const dateOnlyString = birthDate ? birthDate.toLocaleDateString() : null;

  const getContactPropertyValue = (propertyName: string) => {
    const property = contactData.properties.find(
      (property) => property.nameKey === propertyName
    );
    return property ? property.value : '';
  };

  const Skeletons = skeletonMock.map((item, i) => {
    return (
      <Skeleton
        height={60}
        width={350}
        // @ts-ignore
        sx={{ backgroundColor: (theme) => theme.palette.Skeleton.bg }}
        animation="wave"
        key={i}
      />
    );
  });

  const getUserById = async (userId): Promise<UserTrii> => {
    const response = await dispatch(fetchUserById(userId));
    return response.payload as UserTrii;
  };

  const calculateAge = (birthDate: Date) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    async function fetchUpdatedByUser() {
      if (contactData.updatedBy) {
        if (contactData.updatedBy === user.uid) {
          setUpdatedBy(user.display_name || user.email);
          return;
        }

        const updatedByUser = await getUserById(contactData.updatedBy);
        setUpdatedBy(updatedByUser.display_name || updatedByUser.email);
      }
    }
    async function fetchCreayByUser() {
      if (contactData.createdBy) {
        if (contactData.createdBy === user.uid) {
          setCreatedBy(user.display_name || user.email);
          return;
        }

        const createdByUser = await getUserById(contactData.createdBy);
        setCreatedBy(createdByUser.display_name || createdByUser.email);
      }
    }
    async function fetchOwner() {
      if (contactData.owner) {
        if (contactData.owner === user.uid) {
          setOwner(user.display_name || user.email);
          return;
        }

        const owner = await getUserById(contactData.owner);
        setOwner(owner.display_name || owner.email);
      }
    }

    fetchUpdatedByUser();
    fetchCreayByUser();
    fetchOwner();
  }, [contactData]);

  return (
    <AccordionCard title={title} id="details" open={open} setOpen={setOpen}>
      <CardSection sectionTitle="General">
        <Box
          display={'flex'}
          flexDirection={'row'}
          pt={4}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          style={{ gap: 10 }}
          ml={'5%'}
          className="contacts-rowContacts"
        >
          {contactViewerFetchStatus === 'succeeded' ? (
            <>
              <ContactInfoLabel
                title={t('contactDetails.contactOwner')}
                value={owner || '-'}
              />
              <ContactInfoLabel
                title={t('contactDetails.business')}
                value={contactData.businessName || '-'}
              />
              <ContactInfoLabel
                title={t('contactDetails.email')}
                value={contactData.email}
              />
              <ContactInfoLabel
                title={t('contactDetails.phone')}
                value={contactData.phone}
              />
              <ContactInfoLabel
                title={t('contactDetails.createdBy')}
                value={createdBy || '-'}
                date={contactData.createdAt}
              />
              <ContactInfoLabel
                title={t('contactDetails.modifiedBy')}
                value={updatedBy || '-'}
                date={contactDataUpdate_at}
              />
              <ContactInfoLabel
                title={t('global.birthdate')}
                value={
                  dateOnlyString
                    ? `${dateOnlyString} (${calculateAge(birthDate)})`
                    : '-'
                }
              />
              <Box className="contacts-col-5" height={'80px'} />
              {customContactFields.map((contactField, i) =>
                contactField.type !== 1 ? (
                  <ContactInfoLabel
                    title={contactField.name}
                    value={getContactPropertyValue(contactField.nameKey)}
                    key={contactField.id}
                  />
                ) : (
                  <ContactCustomFieldLabel
                    title={contactField.name}
                    value={getContactPropertyValue(contactField.nameKey)}
                    key={contactField.id}
                  />
                )
              )}
            </>
          ) : contactViewerFetchStatus === 'loading' ? (
            <>{Skeletons}</>
          ) : null}
        </Box>
      </CardSection>
      <Divider />
      <CardSection sectionTitle={t('contactDetails.addressInformation')}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          pt={4}
          flexWrap={'wrap'}
          style={{ gap: 10 }}
          ml={'5%'}
          className="contacts-rowContacts"
          justifyContent={'space-between'}
        >
          {contactViewerFetchStatus === 'succeeded' && isIContact(contactData) ? (
            <>
              <ContactInfoLabel
                title={t('contactDetails.street')}
                value={contactData.address1?.street}
              />
              <ContactInfoLabel
                title={t('contactDetails.anotherStreet')}
                value={contactData.address2?.street}
              />
              <ContactInfoLabel
                title={t('contactDetails.city')}
                value={contactData.address1?.city}
              />
              <ContactInfoLabel
                title={t('contactDetails.anotherCity')}
                value={contactData.address2?.city}
              />
              <ContactInfoLabel
                title={t('contactDetails.state')}
                value={contactData.address1?.state}
              />
              <ContactInfoLabel
                title={t('contactDetails.anotherState')}
                value={contactData.address2?.state}
              />
              <ContactInfoLabel
                title={t('contactDetails.postcode')}
                value={contactData.address1?.zipcode}
              />
              <ContactInfoLabel
                title={t('contactDetails.anotherPostcode')}
                value={contactData.address2?.zipcode}
              />
              <ContactInfoLabel
                title={t('contactDetails.country')}
                value={contactData.address1?.country}
              />
              <ContactInfoLabel
                title={t('contactDetails.anotherCountry')}
                value={contactData.address2?.country}
              />
            </>
          ) : contactViewerFetchStatus === 'loading' ? (
            <>{Skeletons}</>
          ) : null}
        </Box>
      </CardSection>
      {/* <Divider />
      <CardSection sectionTitle={t("contactDetails.customFields")}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          py={4}
          flexWrap={"wrap"}
          style={{ gap: 10 }}
          ml={"5%"}
          className="contacts-rowContacts"
        >
          {customContactFields.map((contactField, i) =>
            contactField.type !== 1 ? (
              <ContactInfoLabel
                title={contactField.name}
                value={getContactPropertyValue(contactField.nameKey)}
                key={contactField.id}
              />
            ) : (
              <ContactCustomFieldLabel
                title={contactField.name}
                value={getContactPropertyValue(contactField.nameKey)}
                key={contactField.id}
              />
            )
          )}
        </Box>
      </CardSection> */}
      <Divider />
      <ContactSection
        facebook={contactData?.ims_facebook}
        whatsapp={contactData?.ims_whatsapp}
        mercadlibre={contactData?.ims_mercadolibre}
        webchat={contactData?.ims_webchat}
        instagram={contactData?.ims_instagram}
        emails={contactData?.emails}
        phones={contactData?.phones}
      />
    </AccordionCard>
  );
};

export default DetailsCard;
