import { useContext, useState, useEffect } from 'react';
import { ContactCreateContext } from 'features/Views/ContactCreate/context/ContactCreateContext';
import { ContactEditContext } from 'features/Views/ContactEdit/context/ContactEditContext';
import { CountrySelect } from './components';
import { WarningsDisplay } from 'components/ContactCreateEditAppBody/components/InformationSection/layout';
import { Box } from '@mui/material';
import { InformationItemContainer, NoteDeleteButton, InformationItemInputsContainer } from 'components/ContactCreateEditAppBody/components/InformationSection/layout';
import phoneStyle from './style';
import { NewAddress } from 'types/NewAddress';
import PhoneInput from 'components/ContactCreateEditAppBody/components/InformationSection/layout/PhoneInput';

type Props = {
  data: NewAddress;
};

const Phone = ({ data }: Props) => {
  const mode: 'edit' | 'create' = window.location.pathname.includes('edit') ? 'edit' : 'create';
  const { editContact } = useContext(ContactEditContext);
  const { contact } = useContext(ContactCreateContext);
  const editContactActions = editContact.action;
  const createContactActions = contact.action;
  const [warnings, setWarnings] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>(data.address || '');
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    setChecked(warnings.length === 0 && inputValue !== '');
  }, [warnings, inputValue]);

  const handleInputChange = (e, id, channelType) => {
    const value = e.target.value;
    setInputValue(value);
    if (mode === 'edit') {
      editContactActions.handleAddressFieldChange(e, id, channelType);
    } else {
      createContactActions.handlePhoneNumbersNumberFieldChange(e, id);
    }
  };

  return (
    <InformationItemContainer>
      <InformationItemInputsContainer>
        <Box sx={phoneStyle.numberCountryContainer}>
          <CountrySelect />
          <Box sx={phoneStyle.adressInputBox}>
            <PhoneInput
              data={{ ...data, address: inputValue }}
              mode={mode}
              setWarnings={setWarnings}
              onChange={handleInputChange}
            />
            {warnings.length > 0 && (
              <Box sx={phoneStyle.warningMessage}>
                <WarningsDisplay warnings={warnings} />
              </Box>
            )}
          </Box>
        </Box>
        <NoteDeleteButton
          mode={mode}
          addressData={data}
          setWarnings={setWarnings}
          handleNoteFieldChange={
            mode === "edit"
              ? editContactActions.handleNoteFieldChange
              : createContactActions.handleNoteFieldChange
          }
          handleDeleteAddress={
            mode === "edit"
              ? editContactActions.handleDeleteAddress
              : createContactActions.handleDeleteAddress
          }
          isDeletingAddress={
            mode === "edit" && editContact.state.isDeletingAddress
          }
          replaceAddress={mode === "edit" && editContactActions.replaceAddress}
          addWhatsappVerified={mode === "edit" && editContactActions.addWhatsappVerified}
          checked={checked}
        />
      </InformationItemInputsContainer>
    </InformationItemContainer>
  );
};

export default Phone;
