// Components/ui
import { Box, Card, Typography } from '@mui/material';

const ContactPanel = ({ children, title }) => {
  return (
    <Card
      sx={{
        margin: 2,
        border: "1px solid",
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
        borderColor: (theme) => theme.palette.divider,
        marginY: 0,
        marginBottom: 2,
        maxHeight: "calc(100vh - 195px)",
        borderRadius: 0,
        overflow: "auto",
        boxShadow: "unset",
        // @ts-ignore
        backgroundColor: (theme) => theme.palette.dropdownBox,
      }}
    >    
      <Box>{children}</Box>
    </Card>
  );
};

export default ContactPanel;
