import { createContext, useState } from 'react';
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Hooks
import useField from 'hooks/useField';
// Redux
import { useDispatch } from 'react-redux';
import {
  createList,
  selectListById,
  deleteList,
  removeSelectedList,
  editList,
} from 'ReduxToolkit/features/listsSlice/listsSlice';
// Types
import { IListsContext, ListsProviderProps } from './types';
import { UseFieldType } from 'hooks/useField';
import { IList } from '@trii/types/dist/Contacts';

export const ListsContext = createContext<IListsContext>({
  deletePanelOpen: false,
  editPanelOpen: false,
  createPanelOpen: false,
  createListNameField: fieldInitialDef,
  createListDescriptionField: fieldInitialDef,
  deleteListNameField: fieldInitialDef,
  editListDescriptionField: fieldInitialDef,
  editListNameField: fieldInitialDef,
  setDeletePanelOpen: () => {},
  setCreatePanelOpen: () => {},
  openCreatePanel: () => {},
  closeCreatePanel: () => {},
  resetCreateFields: () => {},
  sendList: () => {},
  openDeletePanel: () => {},
  closeDeletePanel: () => {},
  sendDeleteList: () => {},
  closeEditPanel: () => {},
  setEditPanelOpen: () => {},
  openEditPanel: () => {},
  sendEditedList: () => {},
});

export const ListsProvider = ({ children }: ListsProviderProps) => {
  const dispatch = useDispatch();
  // Create state
  const [createPanelOpen, setCreatePanelOpen] = useState<boolean>(false);
  const createListNameField: UseFieldType = useField('text');
  const createListDescriptionField: UseFieldType = useField('text');
  // Delete state
  const [deletePanelOpen, setDeletePanelOpen] = useState<boolean>(false);
  const deleteListNameField: UseFieldType = useField('text');
  // Edit state
  const [editPanelOpen, setEditPanelOpen] = useState<boolean>(false);
  const editListNameField: UseFieldType = useField('text');
  const editListDescriptionField: UseFieldType = useField('text');

  // Create functions
  const openCreatePanel = () => {
    closeDeletePanel();
    setCreatePanelOpen(true);
  };
  const closeCreatePanel = () => {
    resetCreateFields();
    setCreatePanelOpen(false);
  };
  const resetCreateFields = () => {
    createListNameField.actions.resetValue();
    createListDescriptionField.actions.resetValue();
  };
  const sendList = async () => {
    await dispatch(
      createList({
        name: createListNameField.attributes.value,
        description: createListDescriptionField.attributes.value,
      })
    );
    closeCreatePanel();
  };
  //

  // Delete functions
  const openDeletePanel = (listId: string) => {
    dispatch(selectListById(listId));
    closeCreatePanel();
    setDeletePanelOpen(true);
  };
  const closeDeletePanel = () => {
    deleteListNameField.actions.resetValue();
    dispatch(removeSelectedList());
    setDeletePanelOpen(false);
  };
  const sendDeleteList = async (listId: string) => {
    await dispatch(deleteList(listId));
    closeDeletePanel();
  };
  //

  // Edit functions
  const resetEditFields = () => {
    editListNameField.actions.resetValue();
    editListDescriptionField.actions.resetValue();
  };
  const openEditPanel = (list: IList) => {
    editListNameField.actions.changeValue(list.name);
    editListDescriptionField.actions.changeValue(list.description);
    closeCreatePanel();
    closeDeletePanel();
    dispatch(selectListById(list.id));
    setEditPanelOpen(true);
  };
  const closeEditPanel = () => {
    resetEditFields();
    dispatch(removeSelectedList());
    setEditPanelOpen(false);
  };
  const sendEditedList = async (listId: string) => {
    const editedList = {
      name: editListNameField.attributes.value,
      description: editListDescriptionField.attributes.value,
    };
    await dispatch(editList({ listId, editedList }));
    closeEditPanel();
  };

  return (
    <ListsContext.Provider
      value={{
        sendEditedList,
        openEditPanel,
        editPanelOpen,
        setEditPanelOpen,
        closeEditPanel,
        sendDeleteList,
        closeDeletePanel,
        deleteListNameField,
        openDeletePanel,
        deletePanelOpen,
        setDeletePanelOpen,
        createListNameField,
        createListDescriptionField,
        resetCreateFields,
        sendList,
        createPanelOpen,
        setCreatePanelOpen,
        openCreatePanel,
        closeCreatePanel,
        editListNameField,
        editListDescriptionField,
      }}
    >
      {children}
    </ListsContext.Provider>
  );
};

export default ListsProvider;
