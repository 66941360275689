export const style = {
  subContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
  },
  businessData: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
  },
  container: {
    display: 'flex',
    width: '100%',
    gap: '15px 50px',
    justifyContent: 'flex-end',
  },
};
