// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Warning } from './components';
import { Box, Grid } from '@mui/material';
import { style } from './style';

type WarningsDisplayProps = {
  warnings: string[];
};

const WarningsDisplay = ({ warnings }: WarningsDisplayProps) => {
  const { t } = useTranslation();

  return (
    <Box >
      <Grid container>
        {warnings?.map((warning, i) => (
          <Warning key={i} text={t(`contactAddressError.${warning}`)} />
        ))}
      </Grid>
    </Box>
  );
};

export default WarningsDisplay;
