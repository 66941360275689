import React, { useContext } from 'react';
import { ListsContext } from '../../../../../../context/ListsContext';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from '../../../../../../../../../types/reduxTypes';
import { IListsContext } from '../../../../../../context/types';
import { IList } from '@trii/types/dist/Contacts';
// Redux
import { useSelector } from 'react-redux';
import {
  selectListsEditStatus,
  selectSelectedList,
} from '../../../../../../../../../ReduxToolkit/features/listsSlice/listsSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeEditPanel, sendEditedList, editListNameField } =
    useContext<IListsContext>(ListsContext);
  const selectedList: IList = useSelector(selectSelectedList);
  const editStatus: RequestStatus = useSelector(selectListsEditStatus);
  const isLoading: boolean = editStatus === 'loading';
  const isDisabled: boolean =
    editStatus === 'loading' || !editListNameField.attributes.value;

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeEditPanel}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="contained"
        size="small"
        sx={{
          backgroundColor: (theme) => theme.palette.success.main,
          color: 'text.primary',
        }}
        disabled={isDisabled}
        onClick={() => sendEditedList(selectedList.id)}
      >
        {t('global.save')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;
