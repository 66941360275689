import React from 'react';
import { useTranslation } from 'react-i18next';

// Components/ui
import { MuiColorInput } from 'mui-color-input';
import {
  Box,
  Card,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { IconSelect } from './components';

function GeneralSection({
  enabledCheckboxState,
  nameFieldState,
  iconColorFieldState,
  iconFieldState,
}) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        margin: 2,
      }}
    >
      <Box
        display={'flex'}
        justifyContent="space-between"
        alignItems={'center'}
        py={1}
        px={2}
        sx={{
          backgroundColor: (theme) => theme.palette.background.dropdownMenu,
        }}
      >
        <Typography variant="subtitle2">General</Typography>
        <FormControlLabel
          sx={{
            userSelect: 'none',
          }}
          value="end"
          labelPlacement="start"
          control={<Checkbox {...enabledCheckboxState.attributes} size="small" />}
          label={
            <Typography variant="body2">{t('settingsView.disable')}</Typography>
          }
          
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems={'start'}
        p={2}
        sx={{
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
        }}
      >
        <TextField
          className="fadein"
          variant="outlined"
          label={t('global.name')}
          size="small"
          fullWidth
          {...nameFieldState.attributes}
          sx={{
            marginBottom: '15px',
          }}
        />
        <IconSelect iconFieldState={iconFieldState} />
        <MuiColorInput
          label={t('settingsView.iconColor')}
          fullWidth
          size="small"
          {...iconColorFieldState.attributes}
        />
      </Box>
    </Card>
  );
}

export default GeneralSection;
