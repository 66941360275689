import React from 'react';
// Components/ui
import { Box, Typography } from '@mui/material';
import { StateChip, StateChipSkeleton } from './components';

function Status({ fetchSyncConfigStatus }) {
  const Chip = fetchSyncConfigStatus === 'loading' ? StateChipSkeleton : StateChip;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
      }}
    >
      <Typography
        sx={{
          fontWeight: 'bold',
          mr: 1,
        }}
      >
        Status:
      </Typography>
      <Chip />
    </Box>
  );
}

export default Status;
