// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography } from '@mui/material';
// Dayjs
import dayjs from 'dayjs';

type Props = {
  nameKey: string;
  value: string;
  onChange: (name: string, value: any) => void;
  name: string;
};

const CustomPropertyDateRangePicker = ({
  value,
  onChange,
  nameKey,
  name,
}: Props) => {
  const { t } = useTranslation();
  const handleChange = (value: string) => {
    const formatedValue = dayjs(value).format('YYYY-MM-DD');

    // onChange(nameKey, formatedValue);
  };

  return (
    <Box
      className="fadein"
      sx={{
        display: 'flex',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? '1e1e1e'
            : theme.palette.background.default,
        // padding: '0 10px',
        flexDirection: 'column',
        borderRadius: 1,
        width: '45%',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <DatePicker
            label={`${name} - ${t('contactEdit.from')}`}
            value={value}
            onChange={handleChange}
            sx={{
              width: '30%',
            }}
            slotProps={{
              textField: {
                size: 'small',
                sx: {
                  width: '100%',
                  minWidth: '0px !important',
                },
                className: 'contacts-smallInput',
              },
            }}
          />
          <Typography variant="body1">-</Typography>
          <DatePicker
            label={`${nameKey} - ${t('contactEdit.to')}`}
            value={value}
            onChange={handleChange}
            sx={{
              width: '30%',
            }}
            slotProps={{
              textField: {
                size: 'small',
                sx: {
                  width: '100%',
                  minWidth: '0px !important',
                },
                className: 'contacts-smallInput',
              },
            }}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default CustomPropertyDateRangePicker;
