import { useState, useEffect } from 'react';
// Types
import { ChangeEvent } from 'react';

export const useCheckboxInitialDef = {
  actions: { resetValue: () => {} },
  attributes: { onChange: () => {}, checked: false },
};

export type UseCheckboxType = {
  actions: {
    resetValue: () => void;
  };
  attributes: {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
  };
};

const useCheckbox = (initialValue?: boolean): UseCheckboxType => {
  initialValue = initialValue || false;
  const [checked, setChecked] = useState(initialValue);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const resetValue = () => {
    setChecked(initialValue || false);
  };

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  return {
    actions: { resetValue },
    attributes: { onChange, checked },
  };
};

export default useCheckbox;
