// Drag and Drop
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
// Redux
import { useSelector } from 'react-redux';
import { selectCustomContactFieldsFetchStatus } from 'ReduxToolkit/features/configSlice/configSlice';
// Components/ui
import ConfigListItemsSkeleton from 'features/Views/SettingsView/components/ConfigListItemsSkeleton/ConfigListItemsSkeleton';
import { CustomContactFieldItem } from './components';
import { Box } from '@mui/material';
// Types
import { IContactField } from '@trii/types/dist/Contacts';
import type { RequestStatus } from 'types/reduxTypes';

interface Props {
  contactFields: IContactField[];
}
const Body = ({ contactFields }: Props) => {
  const customContactFieldsFetchStatus = useSelector(
    selectCustomContactFieldsFetchStatus
  );
  const ContactFields = contactFields?.map((field, i) => (
    <CustomContactFieldItem key={i} field={field} />
  ));

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 225px)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <SortableContext items={contactFields} strategy={verticalListSortingStrategy}>
        {customContactFieldsFetchStatus === 'loading' && <ConfigListItemsSkeleton />}
        {customContactFieldsFetchStatus === 'succeeded' && ContactFields}
      </SortableContext>
    </Box>
  );
};

export default Body;
