export const selectInputInitialDef = {
    actions: {
      resetValue: () => {},
      changeValue: () => {},
    },
    attributes: {
      value: "",
      onChange: () => {},
    }
  };
  