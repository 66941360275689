// Date
import moment from 'moment/moment';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Typography, Stack, Button, Divider, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

type FooterProps = {
  createdAtDate: Date;
  isEditing: boolean;
  isEditingLoading: boolean;
  noteFieldResetValue: () => void;
  cancelNoteEdit: () => void;
  acceptNoteEdit: () => void;
};
const Footer = ({
  createdAtDate,
  isEditing,
  isEditingLoading,
  cancelNoteEdit,
  acceptNoteEdit,
}: FooterProps) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        className="msg-nota__text"
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="caption">
          {moment(createdAtDate).format('L, h:mm ')}
        </Typography>
        {isEditing && (
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            <Button
              disabled={isEditingLoading}
              onClick={cancelNoteEdit}
              size="small"
            >
              {t('global.cancel')}
            </Button>
            <LoadingButton
              disabled={isEditingLoading}
              loading={isEditingLoading}
              onClick={acceptNoteEdit}
              size="small"
              variant={'contained'}
            >
              {t('contactsView.notes.saveNote')}
            </LoadingButton>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default Footer;
