import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, IconButton, Typography, Divider } from '@mui/material';
import {
  CreateFieldButtons,
  DeleteButtons,
  DeletePanel,
  CreateFieldPanel,
} from './components';
import CloseIcon from '@mui/icons-material/Close';
import { CustomFieldContext } from '../../context/CustomFieldContext';

const CrudPanel = () => {
  const {
    openPanelCP,
    isEditing,
    getFieldType,
    handleDrawerClose,
    isDelete,
    createField,
    createSelect,
    deleteField,
    selectedField,
    editField,
    editSelect,
  } = useContext(CustomFieldContext);
  const { t } = useTranslation();

  if (!openPanelCP) return null;

  return (
    <Box
      sx={{
        width: '25%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '25%',
        },
        marginLeft: '20px',
        height: 'calc(100vh - 86px)',
        position: 'relative',
        zIndex: 0,
        // @ts-ignore
        backgroundColor: (theme) => theme.palette.background.panel,
      }}
    >
      <Box
        display={'flex'}
        justifyContent="space-between"
        alignItems={'center'}
        pl={2}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: 'text.primary',
          }}
        >
          {isEditing
            ? t('settingsView.editCustomField')
            : isDelete
            ? t('settingsView.deleteCustomField')
            : t('settingsView.createCustomField')}
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      </Box>
      <Divider />
      {!isDelete && (
        <CreateFieldPanel
          isEditing={isEditing}
          createField={createField}
          createSelect={createSelect}
          getFieldType={getFieldType}
          editField={editField}
          editSelect={editSelect}
        />
      )}
      {isDelete && (
        <DeletePanel deleteField={deleteField} selectedField={selectedField} t={t} />
      )}
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      {isDelete ? (
        <DeleteButtons
          deleteField={deleteField}
          selectedField={selectedField}
          handleDrawerClose={handleDrawerClose}
        />
      ) : (
        <CreateFieldButtons
          editField={editField}
          createField={createField}
          handleDrawerClose={handleDrawerClose}
        />
      )}
    </Box>
  );
};

export default CrudPanel;
