import React, { useContext } from 'react';
import { StyleContext } from '../../style/styleProvider';
// Types
import { Style } from '../../style/types/Style';
//components/ui
import { Box, Typography, LinearProgress } from '@mui/material';

const PageLoading = () => {
  const { color } = useContext<Style>(StyleContext);
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: color.slate[700],
          marginBottom: 2,
        }}
      >
        Cargando...
      </Typography>
      <LinearProgress sx={{ width: '20%' }} />
    </Box>
  );
};

export default PageLoading;
