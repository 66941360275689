import { useState, useEffect } from 'react';
// Types
import { SelectChangeEvent } from '@mui/material';
import { UserInfo } from '@trii/types/dist/Users';
// Redux
import { useSelector } from 'react-redux';
import { selectUsers } from 'ReduxToolkit/features/userSlice/userSlice';

export const useShareWithUsersInitialDef = {
  users: [],
  field: {
    userIds: [],
    handleChange: (event: SelectChangeEvent<string[]>) => {},
  },
  action: { resetField: () => {}, getUserNameById: (userId: string) => '' },
};

export type UseShareWithUsersType = {
  users: UserInfo[];
  field: {
    userIds: string[];
    handleChange: (event: SelectChangeEvent<string[]>) => void;
  };
  action: { resetField: () => void; getUserNameById: (userId: string) => string };
};

export function useShareWithUsers(sharedUserIds?: string[]): UseShareWithUsersType {
  const [userIds, setUserIds] = useState<string[]>(sharedUserIds || []);
  const users = useSelector(selectUsers);
  function getUserNameById(userId: string) {
    if (users) {
      const userMap = new Map(users?.map((user) => [user.id, user]));
      const user = userMap.get(userId);
      return user.name;
    }
  }

  function resetField() {
    setUserIds([]);
  }

  function handleChange(event: SelectChangeEvent<string[]>) {
    const {
      target: { value },
    } = event;
    setUserIds(typeof value === 'string' ? value.split(',') : value);
  }

  useEffect(() => {
    if (sharedUserIds && sharedUserIds.length > 0 && userIds.length === 0) {
      setUserIds(sharedUserIds);
    }
  }, [sharedUserIds]);

  return {
    field: { userIds, handleChange },
    action: { resetField, getUserNameById },
    users,
  };
}
