import { useContext } from 'react';
// Context
import { SegmentsEditContext } from 'features/Views/SegmentsEdit/context/SegmentsEditProvider';
// Translation
import { useTranslation } from 'react-i18next';
// Utils
import conditionSelectHelper from './utils/conditionSelectHelper';
// Components
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
// Types
import { ContactField_type } from '@trii/types/dist/Contacts';

type Props = {
  condition: string;
  fieldFilterType: ContactField_type;
  itemId: string;
  groupId: string;
};

const ConditionSelect = ({ condition, fieldFilterType, itemId, groupId }: Props) => {
  const { t } = useTranslation();
  const { handleConditionOperatorChange } = useContext(SegmentsEditContext);
  const conditions = conditionSelectHelper.getConditions(fieldFilterType);

  return (
    <FormControl sx={{ width: '35%' }}>
      <InputLabel id="condition-select">{t('segmentsEdit.condition')}</InputLabel>
      <Select
        labelId="condition-select"
        value={condition}
        label={t('segmentsEdit.condition')}
        onChange={(e) => handleConditionOperatorChange(e, itemId, groupId)}
        size="small"
        MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}
      >
        {conditions?.map((condition) => (
          <MenuItem key={condition.name} value={condition.value}>
            {t(`segmentsEdit.filterOperator.${condition.name}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ConditionSelect;
