import { useState, useEffect } from 'react';

export interface UseColorReturn {
  actions: {
    resetValue: () => void;
    changeValue: (color: string) => void;
  };
  attributes: {
    value: string;
    onChange: (color: string) => void;
  };
}

const useColor = (initialColor?: string): UseColorReturn => {
  initialColor = initialColor || 'rgb(0,0,0)';

  const [color, setColor] = useState<string>(initialColor);

  const handleChange = (color: string) => {
    setColor(color);
  };

  const resetValue = () => {
    setColor(initialColor || 'rgb(0,0,0)');
  };

  const changeValue = (color: string) => {
    setColor(color);
  };

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  return {
    actions: { resetValue, changeValue },
    attributes: { value: color, onChange: handleChange },
  };
};

export default useColor;
