// Components/ui
import { SubsectionBodyContainer } from 'components/ContactCreateEditAppBody/components/InformationSection/layout';
import { Ims } from './components';
// Types
import { NewAddress } from 'types/NewAddress';

type Props = {
  IMSs: NewAddress[];
};

const Body = ({ IMSs }: Props) => {
  return (
    <SubsectionBodyContainer variant="medium">
      {IMSs?.map((ims) => (
        <Ims key={ims.id} data={ims} />
      ))}
    </SubsectionBodyContainer>
  );
};

export default Body;
