import React, { useContext } from 'react';
import { labelContext } from 'features/Views/Label/context/LabelProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Divider, Box } from '@mui/material';
import { EditButtons } from './components';
import CrudContainer from '../CrudContainer';
import PanelHeader from '../PanelHeader';
import CreateEditPanel from '../CreateEditPanel';

const EditPanel = () => {
  const { endEditing, editLabelState } = useContext(labelContext);
  const { t } = useTranslation();

  return (
    <CrudContainer>
      <PanelHeader
        title={t('labelsView.editPanel.title')}
        handleCloseButton={endEditing}
      />
      <Divider />
      <CreateEditPanel labelState={editLabelState} />
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <EditButtons />
    </CrudContainer>
  );
};

export default EditPanel;
