// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { CardSection } from '../../layout';
import { ContactMethod } from './components';
// Types
import { IContactAddress } from '@trii/types/dist/Contacts';
import { Box } from '@mui/material';

interface Props {
  phones: IContactAddress[];
  emails: IContactAddress[];
  facebook: IContactAddress[];
  whatsapp: IContactAddress[];
  mercadlibre: IContactAddress[];
  webchat: IContactAddress[];
  instagram: IContactAddress[];
}

const ContactSection = ({
  phones,
  emails,
  facebook,
  whatsapp,
  mercadlibre,
  webchat,
  instagram,
}: Props) => {
  const { t } = useTranslation();

  return (
    <CardSection sectionTitle={t('contactDetails.contactInformation')}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        pt={4}
        flexWrap={'wrap'}
        style={{ gap: 10 }}
        ml={'5%'}
        className="contacts-rowContacts"
        justifyContent={'space-between'}
      >
        <ContactMethod title={t('contactDetails.phones')} methodItems={phones} />
        <ContactMethod title={'Emails'} methodItems={emails} />
        <ContactMethod title={'Facebook'} methodItems={facebook} />
        <ContactMethod title={'Whatsapp'} methodItems={whatsapp} />
        <ContactMethod title={'Mercado libre'} methodItems={mercadlibre} />
        <ContactMethod title={'Webchat'} methodItems={webchat} />
        <ContactMethod title={'Instagram'} methodItems={instagram} />
      </Box>
    </CardSection>
  );
};

export default ContactSection;
