export const style = {
  input: {
    '& legend': {
      maxWidth: 'fit-content',
    },
    height: '2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiOutlinedInput-input': {
      fontSize: '14px'
    }
  },
  
};
