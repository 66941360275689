import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import initRequestData from '../../functions/initRequestData';
// Types
import { RootState } from '../../rootReducer';
import { Spaces } from '@trii/types';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { SpaceSliceState } from './types/SpaceSliceState';
// Service
import spaceSliceService from './spaceSliceService';
import { RequestStatus } from '../../../types/reduxTypes';

const initialState: SpaceSliceState = {
  spaceInfo: null,
  URL: {
    CONTACTS: '',
    USER: '',
    MEDIA: '',
    SETTINGS: '',
    CALENDAR: '',
  },
  status: {
    fetch: 'idle',
  },
};

export const fetchSpaceInfo = createAsyncThunk(
  'space/getSpaceInfo',
  async (_, { dispatch }) => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await spaceSliceService.fetchSpaceInfo(jwtToken);
    return response.data;
  }
);

const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    setSpaceInfo: (state, action: PayloadAction<Spaces.ISpaceInfo>) => {
      state.spaceInfo = action.payload;
    },
    setSpaceFetchStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status.fetch = action.payload;
    },
    setSpaceUrlContacts: (state, action: PayloadAction<string>) => {
      state.URL.CONTACTS = `https://agent-api.trii.app/api/v1/contacts/${action.payload}`;
    },
    setSpaceUrlMedia: (state, action: PayloadAction<string>) => {
      state.URL.MEDIA = `https://agent-api.trii.app/api/v1/media/${action.payload}`;
    },
    setSpaceUrlSettings: (state, action: PayloadAction<string>) => {
      state.URL.SETTINGS = `https://agent-api.trii.app/api/v1/settings/${action.payload}/users`;
    },
    setSpaceUrlCalendar: (state, action: PayloadAction<string>) => {
      state.URL.CALENDAR = `https://agent-api.trii.app/api/v1/calendar/${action.payload}`;
    },
    setSpaceUrlUser: (state, action: PayloadAction<string>) => {
      state.URL.USER = `https://agent-api.trii.app/api/v1/settings/${action.payload}/user`;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpaceInfo.pending, (state) => {
      state.status.fetch = 'loading';
    });
    builder.addCase(
      fetchSpaceInfo.fulfilled,
      (state, action: PayloadAction<Spaces.ISpaceInfo>) => {
        state.spaceInfo = action.payload;
        state.URL.CONTACTS = `https://agent-api.trii.app/api/v1/contacts/${action.payload.id}`;
        state.URL.MEDIA = `https://agent-api.trii.app/api/v1/media/${action.payload.id}`;
        state.URL.SETTINGS = `https://agent-api.trii.app/api/v1/settings/${action.payload.id}/users`;
        state.URL.CALENDAR = `https://agent-api.trii.app/api/v1/calendar/${action.payload.id}`;
        state.URL.USER = `https://agent-api.trii.app/api/v1/settings/${action.payload.id}/user`;
        state.status.fetch = 'succeeded';
      }
    );
  },
});

export const {
  setSpaceInfo,
  setSpaceFetchStatus,
  setSpaceUrlContacts,
  setSpaceUrlMedia,
  setSpaceUrlSettings,
  setSpaceUrlCalendar,
  setSpaceUrlUser,
} = spaceSlice.actions;

const selectSpaceState = (state: RootState) => state.Space;
export const selectSpaceInfo = createSelector(
  selectSpaceState,
  (space) => space.spaceInfo
);

export default spaceSlice.reducer;
