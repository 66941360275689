// Context provider
import ContactCreateContextProvider from './context/ContactCreateContextProvider';
// Components/ui
import { App } from './components';
import { ContactCreateContainer } from './layout';

const ContactCreate = () => {
  return (
    <ContactCreateContextProvider>
      <ContactCreateContainer>
        <App />
      </ContactCreateContainer>
    </ContactCreateContextProvider>
  );
};

export default ContactCreate;
