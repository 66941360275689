import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  selectClientID,
  setAccountData,
} from '../../../../../../../../../../../ReduxToolkit/features/googleSyncSlice/googleSyncSlice';
// Types
import { Contacts } from '@trii/types';
import { IGoogleAccountData } from '../../../../../../../../../../../ReduxToolkit/features/googleSyncSlice/types';
// Components/ui
import { Fade } from '@mui/material';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import GoogleIcon from './google-icon.png';

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4285F4',
  color: '#fff',
  textTransform: 'none',
  fontSize: 15,
  fontWeight: 'bold',
  padding: '2px 10px 2px 2px',
  border: 'none',
  borderRadius: 3,
  boxShadow: '0 3px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',
  '&:hover': {
    backgroundColor: '#357ae8',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: 3,
  padding: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function GoogleLogInButton() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const CLIENT_ID: string = useSelector(selectClientID);

  const handleCallback = async (response: any) => {
    console.log('google response', response);

    if (response.error) {
      console.log(response.error);
      return;
    }

    // const { name, picture, email }: IUserObject = jwtDecode(response.credential);
    const googleAccount: Contacts.GoogleAccountInfo = {
      name: '',
      imageUrl: '',
      email: '',
    };
    const googleAccountData: IGoogleAccountData = {
      googleAccount,
      code: response.code,
    };

    await dispatch(setAccountData(googleAccountData));
  };

  // useEffect(() => {
  //   // @ts-ignore
  //   const google = window.google;
  //   google?.accounts.id.initialize({
  //     client_id: CLIENT_ID,
  //     callback: handleCallback,
  //     scope: 'https://www.googleapis.com/auth/contacts',
  //   });
  //   google.accounts.id.renderButton(document.getElementById('google-button'), {});

  //   setShow(true);
  // }, []);
  useEffect(() => {
    /* global google */
    const initializeGoogleSignIn = () => {
      const google: any = (window as any).google;

      const client = google.accounts.oauth2.initCodeClient({
        client_id: CLIENT_ID,
        scope: 'email profile https://www.googleapis.com/auth/contacts',
        ux_mode: 'popup',
        callback: handleCallback,
      });

      document.getElementById('google-button').addEventListener('click', () => {
        client.requestCode();
      });
    };

    if ((window as any).google) {
      initializeGoogleSignIn();
      setShow(true);
    } else {
      window.addEventListener('google-loaded', initializeGoogleSignIn);
    }

    return () => {
      window.removeEventListener('google-loaded', initializeGoogleSignIn);
    };
  }, [(window as any).google]);

  return (
    <Fade in={show}>
      <GoogleButton id="google-button">
        <IconWrapper>
          <img src={GoogleIcon} alt="Google logo" width="24" height="24" />
        </IconWrapper>
        Sign in with Google
      </GoogleButton>
    </Fade>
  );
}

export default GoogleLogInButton;
