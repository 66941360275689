// Components/ui
import { Box, Typography } from '@mui/material';
import { ContactMethodItem } from './components';
// Types
import { IContactAddress } from '@trii/types/dist/Contacts';

interface Props {
  title: string;
  methodItems: IContactAddress[];
}

const ContactMethod = ({ title, methodItems }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 1,
        pb: 2,
      }}
      width={'100%'}
    >
      <Typography letterSpacing={-0.5} fontSize={14} fontWeight={600}>
        {title}
      </Typography>
      <Box width={'100%'} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {methodItems?.map((methodItem) => (
          <ContactMethodItem key={methodItem.id} item={methodItem} />
        ))}
      </Box>
    </Box>
  );
};

export default ContactMethod;
