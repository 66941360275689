import { RootState } from 'ReduxToolkit/store';
import { createSelector } from '@reduxjs/toolkit';

export const selectFileSlice = (state: RootState) => state.File;
export const selectFiles = createSelector(
  (state: RootState) => state.File.files,
  (files) => files
);
export const selectFilesFetchStatus = createSelector(
  (state: RootState) => state.File.status.fetch,
  (fetch) => fetch
);
export const selectFilesAddStatus = createSelector(
  (state: RootState) => state.File.status.add,
  (add) => add
);
export const selectFilesDeleteStatus = createSelector(
  (state: RootState) => state.File.status.delete,
  (status) => status
);
