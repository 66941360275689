import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import {
  addFilterItem,
  deleteSegmentGroup,
} from 'ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Types
import type { GroupWithId } from 'ReduxToolkit/features/segmentsSlice/types';
// Components/ui
import { Box, Button, Fade } from '@mui/material';
import { ToggleConditionButtons, ItemFilterInputs } from './components';
import AddIcon from '@mui/icons-material/Add';

interface ISegmentGroupProps {
  groupData: GroupWithId;
  index: number;
}

const SegmentGroup = ({ index, groupData }: ISegmentGroupProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState<boolean>(false);

  const InputItems = groupData.items.map((item, index) => (
    <ItemFilterInputs
      key={index}
      index={index}
      itemData={item}
      groupId={groupData.id}
      operator={groupData.operator}
    />
  ));

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (groupData.items.length === 0) dispatch(deleteSegmentGroup(groupData.id));
  }, [groupData.items]);

  const jsxElement = useMemo(() => {
    const handleAddFilterItem = () => {
      dispatch(addFilterItem(groupData.id));
    };

    return (
      <Fade in={show}>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {index > 0 && <ToggleConditionButtons />}
          <Box
            sx={{
              //@ts-ignore
              backgroundColor: (theme) => theme.palette.background.panel,
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
              padding: '16px',
              borderRadius: '4px',
              border: '1px solid lightgrey',
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
            }}
          >
            {InputItems}
            <Button
              sx={{
                alignSelf: 'flex-start',
              }}
              size="small"
              startIcon={<AddIcon />}
              onClick={handleAddFilterItem}
            >
              {t('segmentsEdit.addCondition')}
            </Button>
          </Box>
        </Box>
      </Fade>
    );
  }, [groupData, show, index]);

  return jsxElement;
};

export default SegmentGroup;
