import { useContext } from 'react';
// Context
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  changeFilterPanelVisibility,
  changeFilterPanelAnimate,
  selectFilterPanelVisibility,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { IconButton } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const FilterButton = () => {
  const { mode } = useContext(ContactBusinessTableContext);
  const isFilterPanelOpen = useSelector(selectFilterPanelVisibility);
  const dispatch = useDispatch();

  const handleClick = () => {
    const localStorageFilterName =
      mode === 'contact' ? 'isContactFilterPanelOpen' : 'isBusinessFilterPanelOpen';

    localStorage.setItem(localStorageFilterName, (!isFilterPanelOpen).toString());
    dispatch(changeFilterPanelAnimate(true));
    dispatch(changeFilterPanelVisibility(!isFilterPanelOpen));
  };

  const FilterIcon = isFilterPanelOpen ? FilterAltIcon : FilterAltOutlinedIcon;

  return (
    <IconButton onClick={handleClick}>
      {<FilterIcon color={'primary'} fontSize="medium" />}
    </IconButton>
  );
};

export default FilterButton;
