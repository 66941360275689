import { useEffect, useState, useRef, useContext } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Redux
import { useSelector } from 'react-redux';
import { selectBusinessData } from 'ReduxToolkit/features/businessSlice/selectors';
import {
  selectContactsFetchStatus,
  resetContactsTableDataStatus,
  clearTableData,
  fetchContactsTableData,
  selectContactsTableData,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  LinearProgress,
  TextField,
} from '@mui/material';
import { ArrowDropDown, Clear } from '@mui/icons-material';
import { style } from './style';
// Types
import { Pagination } from 'ReduxToolkit/features/contactsSlice/types/Pagination';
import { IContactInfo } from '@trii/types/dist/Contacts';
// Utils
import { v4 as uuidv4 } from 'uuid';
import { BusinessEditContext } from 'features/Views/BusinessEdit/context/BusinessEditContext';
import { BusinessCreateContext } from 'features/Views/BusinessCreate/context/BusinessCreateContext';

const PER_PAGE = 10;
const ORDER = 'DESC';
const ORDER_COLUMN = 'name';
const FORMAT = 'IContactInfo';

const DATA = {
  currentPage: 1,
  perPage: PER_PAGE,
  order: ORDER,
  orderColumn: ORDER_COLUMN,
  format: FORMAT,
  filter: [
    {
      column: 'Name',
      condition: '',
      value: '',
    },
  ],
};

const BusinessMembers = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [contacts, setContacts] = useState<IContactInfo[]>([]);
  const [globalTimer, setGlobalTimer] = useState<NodeJS.Timeout | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [inputValue, setInputValue] = useState<string>('');
  const [isNextPage, setIsNextPage] = useState<boolean>(false);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const [isLoadingTimer, setIsLoadingTimer] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const business = useSelector(selectBusinessData);
  const contactsTableData = useSelector(selectContactsTableData);
  const contactInfoFetchStatus = useSelector(selectContactsFetchStatus);

  const isLoading = contactInfoFetchStatus === 'loading';
  const isCreatePath = window.location.pathname.includes('create');

  const {
    editBusiness: {
      selectedContacts: editSelectedContacts,
      setSelectedContacts: setEditSelectedContacts,
    },
  } = useContext(BusinessEditContext);

  const {
    business: {
      selectedContacts: createSelectedContacts,
      setSelectedContacts: setCreateSelectedContacts,
    },
  } = useContext(BusinessCreateContext);

  const selectedContacts = isCreatePath
    ? createSelectedContacts
    : editSelectedContacts;
  const setSelectedContacts = isCreatePath
    ? setCreateSelectedContacts
    : setEditSelectedContacts;

  function resetContacts() {
    dispatch(resetContactsTableDataStatus());
    dispatch(clearTableData());
  }
  function handleChangeNameTo(event, newValue) {
    setSelectedContacts(newValue);
  }
  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setInputValue(value);
    setIsNextPage(false);
    setAllLoaded(false);
    setContacts([]);
    setCurrentPage(1);

    if (!value) return;
    if (globalTimer) clearTimeout(globalTimer);

    setIsLoadingTimer(true);

    const timer = setTimeout(() => {
      resetContacts();

      const data = {
        ...DATA,
        filter: [
          {
            ...DATA.filter[0],
            value,
          },
        ],
      } as Pagination;

      handleSearchContact(data);
      setIsLoadingTimer(false);
    }, 3000);

    setGlobalTimer(timer);

    return () => clearTimeout(timer);
  }
  function handleClose() {
    setInputValue('');
    setContacts([]);
  }
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();

      if (!isLoading && !isLoadingTimer && contacts.length === 0) {
        const newName = {
          id: uuidv4(),
          channelType: 0,
          channelId: null,
          address: inputValue,
          profileName: '',
          profileUrl: '',
          telInfo: null,
          note: '',
          isVerified: false,
          isFavorite: false,
          dateLastMsgIn: null,
          dateLastMsgOut: null,
          createdAt: new Date(),
          verifiedAt: new Date(),
          verifiedBy: '',
        };

        handleChangeNameTo(e, [...selectedContacts, newName]);
        setInputValue('');
      }
    }
  }
  async function handleSearchContact(data: Pagination) {
    resetContacts();
    await dispatch(fetchContactsTableData(data));
  }
  async function handleScroll(e: React.UIEvent<HTMLElement>) {
    const element = e.target as HTMLElement;
    const bottom =
      element.scrollHeight - element.scrollTop < element.clientHeight + 1;

    if (bottom && !allLoaded) {
      setIsNextPage(true);

      const newPage = currentPage + 1;

      setCurrentPage(newPage);

      const newData = {
        ...DATA,
        currentPage: newPage,
        filter: [
          {
            ...DATA.filter[0],
            value: inputValue,
          },
        ],
      };

      handleSearchContact(newData as Pagination);
    }
  }

  useEffect(() => {
    if (contactsTableData && contactsTableData.contactos.length > 0) {
      if (isNextPage) {
        if (contactsTableData?.paginacion.total === contacts.length) {
          setAllLoaded(true);
          return;
        }
        // @ts-ignore
        setContacts([...contacts, ...contactsTableData.contactos]);
      } else {
        // @ts-ignore
        setContacts(contactsTableData.contactos);
      }
    }
  }, [contactsTableData]);

  useEffect(() => {
    if (business) {
      setSelectedContacts(business.members);
    }
  }, [business]);

  return (
    <Box sx={style.container}>
      <Autocomplete
        id="asynchronous-search-contact"
        fullWidth
        multiple
        disableCloseOnSelect
        noOptionsText={t('businessEdit.noMembers')}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option.name}
        options={contacts}
        value={selectedContacts}
        onChange={handleChangeNameTo}
        onClose={handleClose}
        onOpen={handleClose}
        filterOptions={(x) => x}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              sx={style.tag}
              clickable={false}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            sx={style.textField}
            ref={inputRef}
            fullWidth
            label={t('businessEdit.members')}
            size="small"
            InputProps={{
              ...params.InputProps,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={inputValue}
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
          />
        )}
        ListboxProps={{
          sx: {
            maxHeight: '15rem',
            overflowY: 'auto',
          },
          onScroll: (e) => handleScroll(e),
        }}
        popupIcon={
          <ArrowDropDown
            sx={{
              color: (theme) => theme.palette.text.disabled,
            }}
            fontSize="small"
          />
        }
        clearIcon={
          (isLoading && inputRef.current?.value === inputValue) || isLoadingTimer ? (
            <CircularProgress size={20} />
          ) : (
            <Clear
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
              fontSize="small"
            />
          )
        }
      />
    </Box>
  );
};

export default BusinessMembers;
