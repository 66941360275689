import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
// Components/ui
import { Box, Avatar, Typography, FormControlLabel, Radio } from '@mui/material';
// Types
import { IContact } from '@trii/types/dist/Contacts';
// Context
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';
import useImage from 'hooks/useImage';

interface Props {
  contactInfo: IContact;
  setImgSelected: Dispatch<SetStateAction<boolean>>;
  setNameSelected: Dispatch<SetStateAction<boolean>>;
  isMerged?: boolean;
}

const Header = ({
  contactInfo,
  setImgSelected,
  setNameSelected,
  isMerged = false,
}: Props) => {
  const {
    name,
    imageUrl,
    handleSelectString,
    setImageUrl,
    setFirstName,
    setLastName,
    setName,
    triggerSelectAllId,
    setTriggerSelectAllId,
  } = useContext(DuplicateContext);

  const [imageProfile, setImageProfile] = useState<string>('');
  const [profileName, setProfileName] = useState<string>('');

  const accessImgUrl = useImage(imageProfile);

  const imageRadioRef = useRef(null);
  const nameRadioRef = useRef(null);

  const handleSelectName = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    const target = e.target as HTMLInputElement;

    setNameSelected(target.checked);

    const contactId = contactInfo?.id;

    if (contactInfo) {
      handleSelectString(setName, {
        contactId,
        value: contactInfo?.name,
      });
      handleSelectString(setFirstName, {
        contactId,
        value: contactInfo?.firstName,
      });
      handleSelectString(setLastName, {
        contactId,
        value: contactInfo?.lastName,
      });
    }
  };

  const handleSelectImage = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    const target = e.target as HTMLInputElement;

    setImgSelected(target.checked);

    const data = {
      contactId: contactInfo?.id,
      value: contactInfo?.imageUrl,
    };

    if (contactInfo) {
      handleSelectString(setImageUrl, data);
    }
  };

  useEffect(() => {
    // This useEffect is used across all radio buttons of the card to check them
    if (triggerSelectAllId !== '' && triggerSelectAllId === contactInfo?.id) {
      setTriggerSelectAllId('');
      imageRadioRef.current?.click();
      nameRadioRef.current?.click();
    }
  }, [triggerSelectAllId, contactInfo?.id]);

  useEffect(() => {
    if (contactInfo) {
      setImageProfile(contactInfo?.imageUrl);
      setProfileName(contactInfo?.name);
    }
  }, [contactInfo]);

  useEffect(() => {
    if (isMerged) {
      setImageProfile(imageUrl?.value);
      setProfileName(name?.value);
    }
  }, [imageUrl, name]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        position: 'relative',
        justifyContent: 'center',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        gap={'10px'}
        padding={'0 .5rem'}
        textAlign={'center'}
      >
        <FormControlLabel
          control={
            !isMerged ? (
              <Radio
                size="small"
                checked={contactInfo?.id === imageUrl?.contactId}
                ref={imageRadioRef}
              />
            ) : (
              <></>
            )
          }
          onClick={(e) => !isMerged && handleSelectImage(e)}
          label={
            <Avatar
              sx={{
                width: 90,
                height: 90,
                cursor: 'pointer',
              }}
              src={imageProfile ? accessImgUrl.imageUrl : null}
              alt={profileName}
            />
          }
        />
        <FormControlLabel
          control={
            !isMerged ? (
              <Radio
                size="small"
                checked={contactInfo?.id === name?.contactId}
                ref={nameRadioRef}
              />
            ) : (
              <></>
            )
          }
          onClick={(e) => !isMerged && handleSelectName(e)}
          label={
            <Typography
              variant="h6"
              fontWeight="semi-bold"
              color={'text.primary'}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {profileName}
            </Typography>
          }
        />
      </Box>
    </Box>
  );
};

export default Header;
