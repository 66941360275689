import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// Context
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Components/ui
import { Stack, Select, IconButton, MenuItem } from '@mui/material';
import { FilterButton } from './components';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { useTranslation } from 'react-i18next';

const Options = () => {
  const navigate = useNavigate();
  const { mode, paginationFolderSelect, handlePaginationFolderChange } = useContext(
    ContactBusinessTableContext
  );
  const modeURL =
    mode === 'business'
      ? '/a/contacts/business/create'
      : '/a/contacts/contacts/create';
  const {t} = useTranslation()
  return (
    <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
      <FilterButton />
      <Select
       MenuProps={{
        PaperProps: {
          sx: {
            //@ts-ignore
            backgroundColor: theme => theme.palette.background.panel,
            backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
          },
        },
      }}
        sx={{
          fontSize: 14,
          height: 28,
          minWidth: 180,
          '& .MuiMenu-paper': {
            backgroundColor: 'red',
          },
        }}
        size="small"
        variant="outlined"
        onChange={handlePaginationFolderChange}
        value={paginationFolderSelect.attributes.value}
      >
        <MenuItem value={'Main'}>{t('contactsView.contactBusinessTable.allContacts')}</MenuItem>
        <MenuItem value={'MyContacts'}>{t('contactsView.contactBusinessTable.myContacts')}</MenuItem>
        <MenuItem value={'Trash'}>{t('contactsView.contactBusinessTable.deleted')}</MenuItem>
      </Select>
      <IconButton onClick={() => navigate(modeURL)}>
        {mode === 'contact' ? (
          <PersonAddAlt1RoundedIcon color={'primary'} fontSize="medium" />
        ) : (
          <DomainAddIcon color={'primary'} fontSize="medium" />
        )}
      </IconButton>
    </Stack>
  );
};

export default Options;
