import React, { useContext } from 'react';
import { ListsContext } from '../../../../../../context/ListsContext';
// Types
import { IListsContext } from '../../../../../../context/types';
import { IList } from '@trii/types/dist/Contacts';
// Components/ui
import { Box, Stack, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface IActionButtonsProps {
  list: IList;
}

const ActionButtons = ({ list }: IActionButtonsProps) => {
  const { openDeletePanel, openEditPanel } = useContext<IListsContext>(ListsContext);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="end"
      justifyContent="space-evenly"
    >
      <Stack direction="row">
        <IconButton
          sx={{ color: (theme) => theme.palette.primary.main }}
          onClick={() => openEditPanel(list)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => openDeletePanel(list.id)}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default ActionButtons;
