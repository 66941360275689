// Types
import { ActivitiesTableColumn } from '../components/ActivitiesCard/context/types/ActivitiesTableColumn';
import { FileTableColumn } from '../components/FilesCard/context/types/FileTableColumn';

export function getOrderedColumns<T extends FileTableColumn | ActivitiesTableColumn>(
  columns: T[],
  columnName: string,
  sortOrder: 'ASC' | 'DESC'
): T[] {
  return columns.map((column) => {
    if (column.name === columnName) {
      return {
        ...column,
        sortDirection: sortOrder,
        sorted: true,
      };
    }

    return {
      ...column,
      sortDirection: 'DESC',
      sorted: false,
    };
  });
}
