// Components/ui
import { Box, InputLabel, TextField } from '@mui/material';

function DeletePanel({ deleteField, selectedField, t }) {
  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={'center'}
      p={2}
    >
      <InputLabel
        sx={{
          whiteSpace: 'break-spaces',
        }}
      >
        {t('settingsView.deleteCustomFieldLabel')}{' '}
        <strong>{selectedField?.name}</strong>
      </InputLabel>
      <TextField
        {...deleteField.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        sx={{
          marginBottom: '15px',
          '& legend': {
            display: 'none',
          },
          '& fieldset': {
            top: 0,
          },
        }}
      />
    </Box>
  );
}
export default DeletePanel;
