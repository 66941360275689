import { useEffect } from 'react';
import LabelProvider from './context/LabelProvider';
// Redux
import { useDispatch } from 'react-redux';
import { fetchLabels } from 'ReduxToolkit/features/labelSlice/labelSlice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'ReduxToolkit/rootReducer';
// Components/ui
import { Box } from '@mui/material';
import { AppPanel, CrudPanel } from './components/index.js';

const LabelContainer = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(fetchLabels());
  }, [dispatch]);

  return (
    <LabelProvider>
      <Box display={'flex'}>
        <AppPanel />
        <CrudPanel />
      </Box>
    </LabelProvider>
  );
};

export default LabelContainer;
