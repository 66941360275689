import { memo, useContext } from 'react';
// Context
import { ActivitiesCardContext } from '../../../../../../context/ActivitiesCardContext';
// Redux
import { useSelector } from 'react-redux';
import {
  selectActivitiesDeleteStatus,
  selectActivitiesUpdateEndingStatus,
} from 'ReduxToolkit/features/activitiesSlice/utils/selectors';
// Date
import moment from 'moment/moment';
// Components/ui
import { StyledTableRow, StyledTableCell, StyledTypography } from './styles';
import { ActionButton } from './components';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
// Types
import { IEvent } from '@trii/types/dist/Calendar';
interface ActivitiesRowProps {
  activityData: IEvent;
}

const ActivitiesRow = memo(({ activityData }: ActivitiesRowProps) => {
  const { handleDeleteActivity, handleEndActivity, openEditEventPopUp } =
    useContext(ActivitiesCardContext);
  const activitiesDeleteStatus = useSelector(selectActivitiesDeleteStatus);
  const activitiesEndingStatus = useSelector(selectActivitiesUpdateEndingStatus);
  const isDeleteLoading = activitiesDeleteStatus.includes(activityData.id);
  const isEndingLoading = activitiesEndingStatus.includes(activityData.id);

  return (
    <StyledTableRow>
      <StyledTableCell>
        <StyledTypography variant="body2">{activityData.title}</StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <StyledTypography variant="body2">
          {moment(activityData.date).format('L')}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <StyledTypography variant="body2">
          {moment(activityData.createdAt).format('h:mm ')}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <StyledTypography variant="body2">{activityData.duration}</StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <StyledTypography variant="body2">
          {activityData.createdBy.name}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <StyledTypography variant="body2">
          {/* {activityData.createdBy.name} */}
        </StyledTypography>
      </StyledTableCell>
      <StyledTableCell>
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <ActionButton
            activityId={activityData.id}
            handleActivityAction={handleEndActivity}
            isLoading={isEndingLoading}
          >
            <EventBusyIcon />
          </ActionButton>
          <IconButton onClick={() => openEditEventPopUp(activityData)}>
            <EditCalendarIcon />
          </IconButton>
          <ActionButton
            activityId={activityData.id}
            handleActivityAction={handleDeleteActivity}
            isLoading={isDeleteLoading}
          >
            <DeleteIcon />
          </ActionButton>
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
});

export default ActivitiesRow;
