import React from 'react';
// components/ui
import { Skeleton, Stack, Theme } from '@mui/material';

const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const ConfigListItemsSkeleton = () => {
  const Skeletons = skeletons.map((skeleton) => (
    <Skeleton
      key={skeleton}
      variant="rounded"
      width={'100%'}
      height={46}
      // sx={{ bgcolor: '#d1d5db' }}
      sx={{ backgroundColor: (theme: Theme) => theme.palette.background.default }}
    />
  ));

  return <Stack spacing={1}>{Skeletons}</Stack>;
};

export default ConfigListItemsSkeleton;
