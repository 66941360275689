import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { TextField } from '@mui/material';

interface DateSelectProps {
  birthDate: Dayjs | null;
  setBirthDate: (date: Dayjs | null) => void;
}

const DateSelect = ({ birthDate, setBirthDate }: DateSelectProps) => {
  const { t } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label={t('duplicatesView.contactInfo.birthday')}
          value={birthDate}
          onChange={(newValue) => setBirthDate(newValue)}
          slotProps={{ textField: { size: 'small', fullWidth: true } }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DateSelect;
