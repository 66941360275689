import React, { useContext } from 'react';
// Components/ui
import { Box, TextField } from '@mui/material';
import { SubscriptionListContext } from '../../../../../context/SubscriptionListContext';

function CreatePanelContent({ t }) {
  const {
    createNameField,
    createDescriptionField,
    editNameField,
    editDescriptionField,
    isEditing,
  } = useContext(SubscriptionListContext);
  const nameFieldAttributes = isEditing
    ? editNameField.attributes
    : createNameField.attributes;
  const descriptionFieldAttributes = isEditing
    ? editDescriptionField.attributes
    : createDescriptionField.attributes;

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={'center'}
      p={2}
    >
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('settingsView.suscriptionName')}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        {...nameFieldAttributes}
      />
      <TextField
        id="outlined-multiline-static"
        multiline
        label={t('settingsView.description')}
        rows={4}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        {...descriptionFieldAttributes}
      />
    </Box>
  );
}

export default CreatePanelContent;
