import { useContext } from 'react';
// Context
import { SegmentsEditContext } from 'features/Views/SegmentsEdit/context/SegmentsEditProvider';
// Components
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  itemId: string;
  groupId: string;
};

const DeleteItemButton = ({ itemId, groupId }: Props) => {
  const { handleDeleteItem } = useContext(SegmentsEditContext);

  return (
    <IconButton
      sx={{
        height: 'fit-content',
        width: 'fit-content',
      }}
      onClick={() => handleDeleteItem(itemId, groupId)}
    >
      <CloseIcon
        sx={{
          color: 'red',
          height: '20px',
          width: '20px',
        }}
      />
    </IconButton>
  );
};

export default DeleteItemButton;
