// Components/ui
import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useContext } from 'react';
import { NotesCardContext } from '../../../../context/NotesCardContext';

type HeaderProps = {
  createdBy: string;
  isDeletingLoading: boolean;
  handleEditNoteStart: (noteIdToAdd: string) => void;
  noteId: string;
};
const Header = ({
  createdBy,
  isDeletingLoading,
  handleEditNoteStart,
  noteId,
}: HeaderProps) => {
  const { openModalDeleteNote } = useContext(NotesCardContext);

  return (
    <Box className="msg-header">
      <Typography variant="subtitle2" fontSize={'12px'}>
        {createdBy}
      </Typography>
      <Box className="actionButtonsNotes">
        <IconButton
          disabled={isDeletingLoading}
          onClick={() => handleEditNoteStart(noteId)}
          size="small"
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          disabled={isDeletingLoading}
          onClick={() => openModalDeleteNote(noteId)}

          size="small"
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Header;
