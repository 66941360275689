import React from 'react';
// Redux
import {
  selectSegmentsData,
  selectSegmentsStatus,
} from '../../../../../ReduxToolkit/features/segmentsSlice/segmentsSlice';
import { useSelector } from 'react-redux';
// Components/ui
import { Box } from '@mui/material';
import { SegmentItem, SegmentItemSkeleton } from './components';

export default function SegmentsContent() {
  const segmentsData = useSelector(selectSegmentsData);
  const segmentsStatus = useSelector(selectSegmentsStatus);

  const Segments =
    segmentsStatus === 'succeeded' ? (
      segmentsData.map((segment) => <SegmentItem key={segment.id} data={segment} />)
    ) : (
      <SegmentItemSkeleton />
    );

  return (
    <Box sx={{ maxHeight: '90%' }}>
      <Box
        sx={{
          py: 2,
          px: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          maxHeight: 'calc(90vh - 64px - 48px)',
          overflowY: 'auto',
        }}
      >
        {Segments}
      </Box>
    </Box>
  );
}
