import { useContext, useState } from 'react';
// Context
import { ContactCreateContext } from 'features/Views/ContactCreate/context/ContactCreateContext';
import { ContactEditContext } from 'features/Views/ContactEdit/context/ContactEditContext';
// Components/ui
import {
  AddressInput,
  InformationItemContainer,
  InformationItemInputsContainer,
  NoteDeleteButton,
  WarningsDisplay,
} from 'components/ContactCreateEditAppBody/components/InformationSection/layout';
// Types
import { NewAddress } from 'types/NewAddress';
import { Box } from '@mui/material';
import mailStyle from './style';

type Props = {
  data: NewAddress;
};

const Mail = ({ data }: Props) => {
  const mode: 'edit' | 'create' = window.location.pathname.includes('edit')
    ? 'edit'
    : 'create';
  const { editContact } = useContext(ContactEditContext);
  const { contact } = useContext(ContactCreateContext);
  const editContactActions = editContact.action;
  const createContactActions = contact.action;
  const [warnings, setWarnings] = useState<string[]>(null);

  return (
    <InformationItemContainer>
      <InformationItemInputsContainer>
        <Box sx={mailStyle.numberCountryContainer}>
          <Box sx={mailStyle.adressInputBox}>
            <AddressInput
              data={data}
              mode={mode}
              setWarnings={setWarnings}
              onChange={
                mode === "edit"
                  ? editContactActions.handleAddressFieldChange
                  : createContactActions.handleEmailsEmailFieldChange
              }
            />
          </Box>
        </Box>
        <NoteDeleteButton
          mode={mode}
          addressData={data}
          setWarnings={setWarnings}
          handleNoteFieldChange={
            mode === "edit"
              ? editContactActions.handleNoteFieldChange
              : createContactActions.handleNoteFieldChange
          }
          handleDeleteAddress={
            mode === "edit"
              ? editContactActions.handleDeleteAddress
              : createContactActions.handleDeleteAddress
          }
          isDeletingAddress={
            mode === "edit" && editContact.state.isDeletingAddress
          }
          replaceAddress={mode === "edit" && editContactActions.replaceAddress}
        />
      </InformationItemInputsContainer>
      {warnings && <WarningsDisplay warnings={warnings} />}
    </InformationItemContainer>
  );
};

export default Mail;
