// Translation
import { useTranslation } from 'react-i18next';
//Redux
import { useSelector } from 'react-redux';
import {
  getSectionActivities,
  getSectionDebts,
  getSectionDetails,
  getSectionDiffusion,
  getSectionFiles,
  getSectionNotes,
  getSectionRepair,
  getSectionTickets,
  getSectionTasks,
  sectionMenuOpenDetails,
  sectionMenuOpenNotes,
  sectionMenuOpenFiles,
  sectionMenuOpenTasks,
  sectionMenuOpenActivities,
  sectionMenuOpenDiffusion,
  sectionMenuOpenRepair,
  sectionMenuOpenDebts,
  sectionMenuOpenTickets,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import AccordionCard from './layout/AccordionCard';
import { Box } from '@mui/material';
import { DetailsCard, FilesCard, NotesCard } from './components';
import {
  DebtsTable,
  DifussionTable,
  RepairTable,
  TicketsTable,
  TasksTable,
} from './components/Tables';
// Utils
import {
  tableDebts,
  tableDifussion,
  tableRepair,
  tableTickets,
  tableTasks,
} from './utils/tableItems';
import ActivitiesCard from './components/ActivitiesCard';

const BodyBusinessViewer = () => {
  const { t } = useTranslation();
  const detailsOpen = useSelector(getSectionDetails);
  const notesOpen = useSelector(getSectionNotes);
  const filesOpen = useSelector(getSectionFiles);
  const activitiesOpen = useSelector(getSectionActivities);
  const tasksOpen = useSelector(getSectionTasks);
  const diffusionOpen = useSelector(getSectionDiffusion);
  const repairOpen = useSelector(getSectionRepair);
  const debtsOpen = useSelector(getSectionDebts);
  const ticketsOpen = useSelector(getSectionTickets);

  return (
    <Box
      width={'80%'}
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? '1e1e1e'
            : theme.palette.background.default,
        overflow: 'auto',
      }}
    >
      <DetailsCard
        open={detailsOpen}
        setOpen={sectionMenuOpenDetails}
        title={t('contactsView.details')}
      />
      <NotesCard
        title={t('contactsView.notes.title')}
        open={notesOpen}
        setOpen={sectionMenuOpenNotes}
      />
      <FilesCard
        title={t('contactsView.files.title')}
        open={filesOpen}
        setOpen={sectionMenuOpenFiles}
      />
      {/* tasks */}
      <AccordionCard
        title={t('contactsView.tasks.title')}
        id="diffusion"
        open={tasksOpen}
        setOpen={sectionMenuOpenTasks}
      >
        <TasksTable tableDifussion={tableTasks}></TasksTable>
      </AccordionCard>
      <ActivitiesCard
        title={t('contactsView.activities.title')}
        open={activitiesOpen}
        setOpen={sectionMenuOpenActivities}
      />
      {/* diffusion */}
      <AccordionCard
        title={t('contactsView.diffusion.title')}
        id="diffusion"
        open={diffusionOpen}
        setOpen={sectionMenuOpenDiffusion}
      >
        <DifussionTable tableDifussion={tableDifussion}></DifussionTable>
      </AccordionCard>
      {/* repair */}
      <AccordionCard
        title={t('contactsView.repair.title')}
        id="repair"
        open={repairOpen}
        setOpen={sectionMenuOpenRepair}
      >
        <RepairTable tableRepair={tableRepair}> </RepairTable>
      </AccordionCard>
      {/* debts */}
      <AccordionCard
        title={t('contactsView.debts.title')}
        id="debts"
        open={debtsOpen}
        setOpen={sectionMenuOpenDebts}
      >
        <DebtsTable tableDebts={tableDebts}> </DebtsTable>
      </AccordionCard>
      {/* tickets */}
      <AccordionCard
        title={'Tickets'}
        id="tickets"
        open={ticketsOpen}
        setOpen={sectionMenuOpenTickets}
      >
        <TicketsTable tableTickets={tableTickets}> </TicketsTable>
      </AccordionCard>
    </Box>
  );
};

export default BodyBusinessViewer;
