import React from 'react';
import { useTranslation } from 'react-i18next';
import { Contacts } from '@trii/types';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAutoUpdateContactsEnabled,
  selectAutoUpdateContactsStatus,
  selectGoogleAccountData,
  setAutoUpdate,
} from '../../../../../../../../../../../ReduxToolkit/features/googleSyncSlice/googleSyncSlice';
// Types
import {
  setAutoUpdateAction,
  ISetAutoUpdateData,
} from '../../../../../../../../../../../ReduxToolkit/features/googleSyncSlice/types';
import type { RequestStatus } from '../../../../../../../../../../../types/reduxTypes';
// Components/ui
import { FormControlLabel, Switch, Fade } from '@mui/material';

interface IAutoUpdateSwitchProps {
  show: boolean;
}

const AutoUpdateSwitch = ({ show }: IAutoUpdateSwitchProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const autoUpdateContactsEnabled: boolean = useSelector(
    selectAutoUpdateContactsEnabled
  );
  const autoUpdateContactsStatus: RequestStatus = useSelector(
    selectAutoUpdateContactsStatus
  );
  const googleAccountData: Contacts.GoogleAccountInfo = useSelector(
    selectGoogleAccountData
  );

  const SwitchLabel = autoUpdateContactsEnabled
    ? t('settingsView.enabled')
    : t('settingsView.disabled');

  const handleChange = async () => {
    const action: setAutoUpdateAction = autoUpdateContactsEnabled
      ? 'setAutoUpdateOFF'
      : 'setAutoUpdateON';
    const data: ISetAutoUpdateData = {
      action,
    };
    // const dataJSON: string = JSON.stringify(data);
    // console.log('dataJSON', dataJSON);

    await dispatch(setAutoUpdate(data));
  };
  return (
    <Fade in={show}>
      <FormControlLabel
        control={
          <Switch
            checked={autoUpdateContactsEnabled}
            onChange={handleChange}
            color="primary"
            disabled={
              autoUpdateContactsStatus === 'loading' || !googleAccountData?.name
            }
          />
        }
        label={SwitchLabel}
        labelPlacement="start"
      />
    </Fade>
  );
};

export default AutoUpdateSwitch;
