export const styles = {
  dialogPaper: {
    width: '100%',
    maxWidth: '390px',
    height: '100%',
    maxHeight: '750px',
    borderRadius: '3px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    border: '1px solid #E5E5E5',
    padding: '6px',
  },
};
