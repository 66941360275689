import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import initRequestData from '../../functions/initRequestData';
import { UserSliceState } from './types/UserSliceState';
// Types
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { RootState } from '../../store';
import { UserTrii, UserInfo, StorageAzureSAS } from '@trii/types/dist/Users';
// Service
import userSliceService from './userSliceService';

const initialState: UserSliceState = {
  user: null,
  users: null,
  userById: null,
  status: {
    users: 'idle',
    userById: 'idle',
  },
};

export const getUser = createAsyncThunk<UserTrii, undefined, { state: RootState }>(
  'User/getUser',
  async (_, { dispatch }) => {
    const { jwtToken, URL_USER } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const triiUser = await userSliceService.fetchUserInfo(jwtToken, URL_USER);

    return triiUser;
  }
);

export const updateSas = createAsyncThunk(
  'contacts/updateSas',
  async (_, { dispatch }) => {
    const response = await dispatch(getUser());
    const userTrii = response.payload as UserTrii;
    const { storageAzureSAS } = userTrii;

    return storageAzureSAS;
  }
);

export const getUsers = createAsyncThunk<
  UserInfo[],
  undefined,
  { state: RootState }
>('User/getUsers', async (_, { dispatch }) => {
  const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const triiUsers = await userSliceService.fetchUsers(jwtToken, URL_SETTINGS);

  return triiUsers;
});

export const fetchUserById = createAsyncThunk<
  UserTrii,
  string,
  { state: RootState }
>('User/fetchUserById', async (userId: string, { dispatch }) => {
  const { jwtToken, URL_SETTINGS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const triiUser = await userSliceService.fetchUserById(
    jwtToken,
    URL_SETTINGS,
    userId
  );

  return triiUser;
});

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserTrii>) => {
      state.user = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUser.fulfilled, (state, action: PayloadAction<UserTrii>) => {
        console.log('success fetching user: ', action.payload);
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        console.log('error fetching user: ', action.error);
      })
      .addCase(getUsers.pending, (state, action) => {
        state.status.users = 'loading';
      })
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<UserInfo[]>) => {
        state.status.users = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUserById.pending, (state, action) => {
        state.status.userById = 'loading';
      })
      .addCase(fetchUserById.fulfilled, (state, action: PayloadAction<UserTrii>) => {
        state.status.userById = 'succeeded';
        state.userById = action.payload;
      })
      .addCase(
        updateSas.fulfilled,
        (state, action: PayloadAction<StorageAzureSAS>) => {
          state.user.storageAzureSAS = action.payload;
        }
      );
  },
});

export const { setUser } = userSlice.actions;

// Selectors
export const selectUser = (state: RootState) => state.User.user;
// export const selectUsersNames = (state: RootState) => {
//   const users = state.User.users;
//   if (users) {
//     return users.map((user) => user.name);
//   }
//   return [];
// };
export const selectUsers = (state: RootState) => state.User.users;
export const selectUsersStatus = (state: RootState) => state.User.status.users;
export const selectUserById = (state: RootState) => state.User.userById;
export const selectUserByIdStatus = (state: RootState) => state.User.status.userById;

export default userSlice.reducer;
