import { Box, styled } from '@mui/material';

export const StyledContactDataContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '20rem',
  minWidth: '20rem',
  alignItems: 'center',
  gap: 10,
  padding: 10,
});
