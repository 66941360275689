import React from "react";
// Redux
import { useSelector } from "react-redux";
import {
  selectListsData,
  selectListsStatus,
} from "../../../../../ReduxToolkit/features/listsSlice/listsSlice";
// Components/ui
import { Box } from "@mui/material";
import { ListItem, ListItemSkeleton } from "./components";

export default function ManageListContent() {
  const listsData = useSelector(selectListsData);
  const listsStatus = useSelector(selectListsStatus);
  const isLoading = listsStatus === "loading";

  return (
    <Box sx={{ maxHeight: "90%" }}>
      <Box
        sx={{
          py: 2,
          px: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          maxHeight: "calc(90vh - 64px - 58px)",
          overflowY: "auto",
        }}
      >
        {isLoading ? (
          <ListItemSkeleton />
        ) : (
          listsData.map((list) => <ListItem key={list.id} data={list} />)
        )}
      </Box>
    </Box>
  );
}
