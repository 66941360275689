import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
// Redux
import {
  fetchBusinessById,
  fetchBusinessFields,
} from 'ReduxToolkit/features/businessSlice/businessSlice';
import {
  selectBusinessStatusFetchBusinessById,
  selectBusinessStatusfetchBussinesFields,
} from 'ReduxToolkit/features/businessSlice/selectors';
import { getUsers } from 'ReduxToolkit/features/userSlice/userSlice';
import { useSelector } from 'react-redux';
// Components/ui
import { Box } from '@mui/material';
import Spinner from 'components/Spinners/Spinner';
import { useAppDispatch } from 'hooks/useAppDispatch';

type Props = {
  children: React.ReactNode;
};

const BusinessContainer = ({ children }: Props) => {
  const { businessId } = useParams();
  const dispatch = useAppDispatch();
  const businessFetchStatus = useSelector(selectBusinessStatusFetchBusinessById);
  const fetchBussinesFieldsStatus = useSelector(
    selectBusinessStatusfetchBussinesFields
  );

  useEffect(() => {
    dispatch(fetchBusinessById(businessId));
    dispatch(fetchBusinessFields('custom'));
    dispatch(getUsers());
  }, [dispatch, businessId]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      className={'contacts-paddingmd'}
      borderRadius={2}
    >
      <Box display={'flex'} flexDirection={'column'} width={'100%'} borderRadius={2}>
        {(businessFetchStatus === 'loading' ||
          fetchBussinesFieldsStatus === 'loading') && <Spinner texto={''} />}
        {businessFetchStatus === 'succeeded' &&
          fetchBussinesFieldsStatus === 'succeeded' && (
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
                backgroundImage:
                  'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
              }}
            >
              {children}
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default BusinessContainer;
