// Components/ui
import { Box } from '@mui/material';
import { PageNavigator, ButtonsStack, ContactsInfo, Options } from './components';

export default function AppHeader() {
  return (
    <Box
      display="flex"
      gap={6}
      alignItems="center"
      sx={{
        // @ts-ignore
        backgroundColor: (theme) => theme.palette.background.dropdownMenu,
        color: (theme) => theme.palette.text.primary,
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        '@media (min-width: 1024px)': {
          overflowX: 'unset',
        },
      }}
      boxShadow={'0px 1px 2px rgba(0,0,0,0.2)'}
      borderRadius="2px"
      height="5%"
      px={0}
      py={1}
      whiteSpace={'nowrap'}
    >
      <Options />
      <ButtonsStack />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ContactsInfo />
        <PageNavigator />
      </Box>
    </Box>
  );
}
