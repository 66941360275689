import React from 'react';
import { useTranslation } from 'react-i18next';
// Assets
import { IconsSVG } from '../../../../../../../../../../../../../../../components/IconsSVG/IconsSVG';
// Redux
import { useSelector } from 'react-redux';
import { selectedTheme } from 'ReduxToolkit/features/themeSlice.js';
// Component/ui
import {
  TextField,
  InputAdornment,
  Popper,
  ClickAwayListener,
  Box,
  List,
  ListItem,
  Fade,
  Paper,
  ListItemButton,
  IconButton,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SvgIcon from 'components/SvgIcon';

const IconSelect = ({ iconFieldState }) => {
  const { t } = useTranslation();

  const theme = useSelector(selectedTheme);
  // @ts-ignore
  const themeColorMode: 'light' | 'dark' = theme.palette.mode;

  const [anchorElIconPopover, setAnchorElIconPopover] = React.useState(null);

  const openIconPopover = Boolean(anchorElIconPopover);
  const id = openIconPopover ? 'simple-popover' : undefined;

  const selectIcon = (src: string) => {
    if (src !== null && src !== undefined) {
      iconFieldState.actions.changeValue(src);
      setAnchorElIconPopover(null);
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    if (anchorElIconPopover === null) {
      setAnchorElIconPopover(event.currentTarget);
    } else {
      setAnchorElIconPopover(null);
    }
  };

  const handleCloseIconPopover = () => {
    setAnchorElIconPopover(null);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TextField
        id="input-with-icon-textfield"
        aria-describedby={id}
        InputProps={{
          startAdornment: (
            <InputAdornment
              sx={{
                cursor: "pointer",
                color: "white",
                width:'24px',
                height:'24px'
              }}
              position="start"
            >
              <SvgIcon
                src={iconFieldState.attributes.value}
                className={themeColorMode}
                
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ marginRight: "-8px", cursor: "pointer" }}
            >
              <IconButton size="small">
                <ArrowDropDownIcon />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
          sx: {
            cursor: "default",
          },
        }}
        label={t("settingsView.icon")}
        variant="outlined"
        fullWidth
        size="small"
        onClick={handleClick}
        sx={{
          marginBottom: "15px",
          padding: 0,
          "& .MuiOutlinedInput-input": {
            padding: "10px 14px",
            cursor: "pointer",
          },
        }}
      />
      <Popper
        id={id}
        open={openIconPopover}
        anchorEl={anchorElIconPopover}
        transition
        disablePortal={false}
        sx={{ zIndex: 1, maxWidth: "20vw" }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleCloseIconPopover}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1,
                  py: 1,
                  px: 0,
                }}
                elevation={0}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <List
                    sx={{
                      flexWrap: "wrap",
                      width: "100%",
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {IconsSVG.map((value, i) => (
                      <ListItem key={i} sx={{ width: "14.28%" }}>
                        <ListItemButton
                          sx={{
                            padding: 0,
                            width: "24px",
                            height: "24px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            selectIcon(
                              `${__webpack_public_path__}${value.icon}`
                            )
                          }
                        >
                          <SvgIcon
                            src={`${__webpack_public_path__}${value.icon}`}
                            className={themeColorMode}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

export default IconSelect;
