import { useContext } from 'react';
import { StyleContext } from '../../../../../../../../../style/styleProvider';
// Types
import { Style } from '../../../../../../../../../style/types/Style';
import { useTranslation } from 'react-i18next';
import { Contacts } from '@trii/types';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  createTag,
  selectCreateTagStatus,
  selectGoogleAccountData,
  selectFetchSyncConfigStatus,
} from '../../../../../../../../../ReduxToolkit/features/googleSyncSlice/googleSyncSlice';
// Types
import type { RequestStatus } from '../../../../../../../../../types/reduxTypes';
// Components/ui
import SyncSection from '../SyncSection';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';

function CreateTagSection() {
  const { t } = useTranslation();
  const { fontSize } = useContext<Style>(StyleContext);
  const dispatch = useDispatch();
  const createTagStatus: RequestStatus = useSelector(selectCreateTagStatus);
  const fetchSyncConfigStatus: RequestStatus = useSelector(
    selectFetchSyncConfigStatus
  );
  const googleAccountData: Contacts.GoogleAccountInfo = useSelector(
    selectGoogleAccountData
  );
  const isLoading =
    createTagStatus === 'loading' || fetchSyncConfigStatus === 'loading';

  const handleCreateTag = () => {
    dispatch(createTag());
  };

  return (
    <SyncSection>
      <Typography
        fontSize={fontSize.sm}
        sx={{
          marginRight: '20px',
        }}
      >
        {t('settingsView.createTagTriiContactsOnGoogle')}
      </Typography>

      <LoadingButton
        loading={isLoading}
        disabled={isLoading || googleAccountData?.name === undefined}
        startIcon={<AddIcon />}
        onClick={handleCreateTag}
        variant="outlined"
        size="small"
      >
        {t('global.create')}
      </LoadingButton>
    </SyncSection>
  );
}
export default CreateTagSection;
