import React, { useContext } from 'react';
import { labelContext } from '../../context/LabelProvider';
// Components/ui
import { CreatePanel, DeletePanel, EditPanel } from './components/index.js';

function CrudPanel() {
  const { crudPanelState } = useContext(labelContext);

  return (
    <>
      {crudPanelState === 'create' && <CreatePanel />}
      {crudPanelState === 'delete' && <DeletePanel />}
      {crudPanelState === 'edit' && <EditPanel />}
    </>
  );
}

export default CrudPanel;
