const noteDeleteButtonStyle = {
  container: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'end'
  },
  noteTextField: {
    width: '100%',
    marginRight: '15px'
  },
};

export default noteDeleteButtonStyle;
