import React, { useContext, useEffect } from 'react';
import { ButtonsListContext } from '../../../../context/ButtonListContext.tsx';
// Components/ui
import { Box } from '@mui/material';
import { GeneralSection, ResponseSection } from './components';
import ApiSection from './components/ApiSection/ApiSection';

const CreateEditPanel = () => {
  const { editButtonState, createButtonState, isEditing, endCreating } =
    useContext(ButtonsListContext);
  const buttonState = isEditing ? editButtonState : createButtonState;

  useEffect(() => {
    const cleanUp = () => {
      if (!isEditing) endCreating();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ maxHeight: 'calc(100vh - 230px)', overflow: 'auto' }}>
      <GeneralSection
        enabledCheckboxState={buttonState.input.enabledCheckbox}
        nameFieldState={buttonState.input.nameField}
        iconColorFieldState={buttonState.input.iconColorField}
        iconFieldState={buttonState.input.iconField}
      />
      <ApiSection
        urlFieldState={buttonState.input.urlField}
        methodSelectState={buttonState.input.methodSelect}
        authTypeSelectState={buttonState.input.authTypeSelect}
        bodyFieldState={buttonState.input.bodyField}
        contentTypeFieldState={buttonState.input.contentTypeField}
        usernameFieldState={buttonState.input.usernameField}
        passwordFieldState={buttonState.input.passwordField}
        fixedTokenFieldState={buttonState.input.fixedTokenField}
        dynamicUrlFieldState={buttonState.input.dynamicUrlField}
        bearerTokenBodyFieldState={buttonState.input.bearerTokenBodyField}
        apiKeyFieldState={buttonState.input.apiKeyField}
        valueFieldState={buttonState.input.valueField}
        addToSelectState={buttonState.input.addToSelect}
      />
      <ResponseSection
        isEditing={isEditing}
        templateFieldState={buttonState.input.templateField}
        outputFormatSelectState={buttonState.input.outputFormatSelect}
      />
    </Box>
  );
};

export default CreateEditPanel;
