import { useState } from 'react';

export interface ItemWithId {
  id: string;
  [key: string]: any;
}

type InitialStateType<T> = T extends (infer U)[]
  ? U extends ItemWithId
    ? T
    : never
  : never;

interface Props<T extends ItemWithId[]> {
  initialState?: InitialStateType<T>;
}

export type UseMultipleSelectReturnType<T> = {
  attributes: {
    value: T;
    onChange: (event: any, newValue: T) => void;
  };
  actions: {
    addValue: (newValue: any) => void;
    setValue: (newValue: T) => void;
  };
};

export const useMultipleSelect = <T extends ItemWithId[]>({
  initialState = [] as InitialStateType<T>,
}: Props<T> = {}): UseMultipleSelectReturnType<T> => {
  const [value, setValue] = useState<T>(initialState);

  const onChange = (event: any, newValue: T) => {
    const hasNewObject = newValue.some(
      (newItem) => !value.some((currentItem) => newItem.id === currentItem.id)
    );
    const removedObjects = value.filter(
      (currentItem) => !newValue.some((newItem) => newItem.id === currentItem.id)
    );

    if (removedObjects.length > 0 || hasNewObject) {
      setValue(newValue);
    }
  };

  const addValue = (newValue: any) => {
    setValue((prevState) => prevState.concat(newValue) as unknown as T);
  };

  const setValueWrapper = (newValue: T) => {
    setValue(newValue);
  };

  return {
    attributes: { value, onChange },
    actions: {
      addValue,
      setValue: setValueWrapper,
    },
  };
};
