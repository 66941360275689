import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Box } from '@mui/material';

type Props = {
  nameKey: string;
  value: string;
  onChange: (name: string, value: any) => void;
};

const CustomPropertyTimePicker = ({ value, onChange, nameKey }: Props) => {
  const handleChange = (value: Dayjs) => {
    const formatedValue = dayjs(value).format('HH:mm');

    onChange(nameKey, formatedValue);
  };

  return (
    <Box
      className="fadein"
      sx={{
        display: 'flex',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? '1e1e1e'
            : theme.palette.background.default,
        flexDirection: 'column',
        borderRadius: 1,
        width: '100%',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={['TimePicker']}
          sx={{
            minWidth: '0px',
            overflow: 'visible',
            paddingTop: '0px',
          }}
        >
          <TimePicker
            label={nameKey}
            value={dayjs(value)}
            onChange={handleChange}
            sx={{
              width: '20%',
            }}
            slotProps={{
              textField: {
                className: 'contacts-smallInput',
                size: 'small',
                sx: {
                  width: '100%',
                  minWidth: '0px !important',
                },
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default CustomPropertyTimePicker;
