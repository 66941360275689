import React, { useContext } from 'react';
// Translate
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// components/ui
import {
  Stack,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  CircularProgress,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// Context
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Slice
import {
  getCheckedContactsAmount,
  fetchLabels,
  getCheckedContactsIds,
  restoreContacts,
  deleteContactsPermanently,
  selectRestoreContactsStatus,
  selectDeleteContactsPermanentlyStatus,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { getUsers } from 'ReduxToolkit/features/userSlice/userSlice';
import { Pagination } from 'ReduxToolkit/features/contactsSlice/types/Pagination';
import { PaginationFolder } from 'ReduxToolkit/features/contactsSlice/types/PaginationFolder';
import { useNavigate } from 'react-router-dom';

const ButtonsStack = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    setOpenModalList,
    setModalTitle,
    handleContactClick,
    paginationFolderSelect,
    fetchTableData,
    getFetchData,
  } = useContext(ContactBusinessTableContext);

  const checkedContactsAmount = useSelector(getCheckedContactsAmount);
  const checkedContactsIds = useSelector(getCheckedContactsIds);
  const restoreContactsStatus = useSelector(selectRestoreContactsStatus);
  const deleteContactsPermanentlyStatus = useSelector(
    selectDeleteContactsPermanentlyStatus
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const trashFolderSelected = paginationFolderSelect.attributes.value === 'Trash';
  const someContactsChecked = checkedContactsAmount > 0;
  const open = Boolean(anchorEl);
  // /a/contacts/duplicates?ids=667c62970050b5fc78017b6c,xxxxxxxxxxxxxxxxxxxxxx,yyyyyyyyyyyyyyyyyyyyy
  const joinUrl = `/a/contacts/duplicates?ids=${checkedContactsIds.join(',')}`;

  const handleJoinDuplicates = () => {
    navigate(joinUrl);
  };

  const handleOpenColumnsModal = () => {
    handleCloseMenu();
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenLabelModal = (action: string) => {
    setModalTitle(t(`contactsView.contactBusinessTable.${action}`));
    setOpenModalList(true);
    dispatch(fetchLabels());
    handleCloseMenu();
  };

  const handleOpenChangeOwnerModal = () => {
    setModalTitle(t('contactsView.contactBusinessTable.selectUser'));
    setOpenModalList(true);
    handleCloseMenu();
    dispatch(getUsers());
  };

  const handleOpenDeleteModal = () => {
    setModalTitle(t('contactsView.contactBusinessTable.confirmDeletion'));
    setOpenModalList(true);
    handleCloseMenu();
  };

  const handleRestoreContacts = async (ids: string[]) => {
    await dispatch(restoreContacts(ids));

    handleCloseMenu();

    const newPaginationData: Pagination = getFetchData(
      paginationFolderSelect.attributes.value as PaginationFolder
    );

    fetchTableData(newPaginationData);
  };

  const handleDeletePermanently = async (ids: string[]) => {
    await dispatch(deleteContactsPermanently(ids));

    handleCloseMenu();

    const newPaginationData: Pagination = getFetchData(
      paginationFolderSelect.attributes.value as PaginationFolder
    );

    fetchTableData(newPaginationData);
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <IconButton
          size="small"
          disabled={!someContactsChecked || trashFolderSelected}
          onClick={handleJoinDuplicates}
        >
          <MergeTypeIcon fontSize="medium" />
        </IconButton>
        <IconButton
          size="small"
          disabled={!someContactsChecked || trashFolderSelected}
          onClick={() => handleOpenLabelModal('addLabel')}
        >
          <LabelOutlinedIcon fontSize="medium" />
        </IconButton>
        <IconButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickMenu}
          size="small"
          disabled={!someContactsChecked}
        >
          <MoreVertIcon fontSize="medium" />
        </IconButton>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {trashFolderSelected ? (
          <div>
            <MenuItem onClick={() => handleRestoreContacts(checkedContactsIds)}>
              {t('contactsView.contactBusinessTable.restore')}
              {restoreContactsStatus === 'loading' && <CircularProgress size={16} />}
            </MenuItem>
            <MenuItem onClick={() => handleDeletePermanently(checkedContactsIds)}>
              {t('contactsView.contactBusinessTable.deletePermanently')}
            </MenuItem>
            {deleteContactsPermanentlyStatus === 'loading' && (
              <CircularProgress size={16} />
            )}
          </div>
        ) : (
          <div>
            <MenuItem onClick={() => handleOpenLabelModal('removeLabel')}>
              {t('contactsView.contactBusinessTable.removeLabel')}
            </MenuItem>
            <MenuItem onClick={handleOpenChangeOwnerModal}>
              {t('contactsView.contactBusinessTable.changeOwner')}
            </MenuItem>
            <MenuItem>Actualizacion masiva</MenuItem>
            <MenuItem onClick={handleOpenDeleteModal}>
              {t('contactsView.contactBusinessTable.delete')}
            </MenuItem>
            {checkedContactsAmount === 1 && (
              <MenuItem onClick={() => handleContactClick(checkedContactsIds[0])}>
                {t('global.edit')}
              </MenuItem>
            )}
          </div>
        )}
      </Menu>
    </>
  );
};

export default ButtonsStack;
