import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import { RootState } from 'ReduxToolkit/store';
import { TableContactField } from '../contactsSlice/types/TableContactField';
import { Filter } from '../contactsSlice/types/Filter';

type ContactsFilterState = {
  fields: { [key: string]: TableContactField & { value: string | null } };
  tag: string;
  list: string;
  avoidReset: boolean;
};

const initialState: ContactsFilterState = {
  fields: {},
  tag: '',
  list: '',
  avoidReset: false,
};

const contactsFilterSlice = createSlice({
  name: 'contactsFilter',
  initialState,
  reducers: {
    setField(
      state,
      action: PayloadAction<{ fieldKey: string; value: string | null }>
    ) {
      const { fieldKey, value } = action.payload;

      state.fields[fieldKey].value = value;
    },
    setInitialFields(
      state,
      action: PayloadAction<{ [key: string]: TableContactField }>
    ) {
      const newInitialFields = Object.keys(action.payload).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...action.payload[key],
            value: null,
          },
        }),
        {}
      );
      state.fields = newInitialFields;
    },
    deleteInitialFields(state) {
      state.fields = {};
    },
    setTag(state, action: PayloadAction<string>) {
      state.tag = action.payload;
    },
    setList(state, action: PayloadAction<string>) {
      state.list = action.payload;
    },
    setAvoidReset(state, action: PayloadAction<boolean>) {
      state.avoidReset = action.payload;
    },
  },
});

export const {
  setField,
  setInitialFields,
  deleteInitialFields,
  setAvoidReset,
  setTag,
  setList,
} = contactsFilterSlice.actions;

export const selectFields = (
  state: RootState
): { [key: string]: TableContactField & { value: string | null } } =>
  state.ContactsFilter.fields;
export const selectAvoidReset = (state: RootState) =>
  state.ContactsFilter.avoidReset;
export const selectTag = (state: RootState) => state.ContactsFilter.tag;
export const selectList = (state: RootState) => state.ContactsFilter.list;

export default contactsFilterSlice.reducer;

export const generateFilters = (fields: {
  [key: string]: TableContactField & { value: string | null };
}): Filter[] => {
  return Object.entries(fields)
    .filter(([_, field]) => field.value !== '' && field.value !== null)
    .map(([nameKey, field]) => ({
      column: nameKey,
      condition: '=',
      value: field.value as string,
    }));
};
