const informationItemContainerStyle = {
  container: {
    border: '1px solid lightgray',
    borderRadius: 1,
    p: 2,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
};

export default informationItemContainerStyle;
