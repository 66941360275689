import { Dispatch, createContext } from 'react';
import { DuplicateContextState } from './types/DuplicateContextState';
import {
  IContactInfo,
  IContactAddress,
  IContactPostalAddress,
  Property,
} from '@trii/types/dist/Contacts';
import { LabelSelected, TextSelected } from './types/FieldSelected';

export const DuplicateContext = createContext<DuplicateContextState | null>({
  // General State
  contactMerged: null,
  setContactMerged: () => {},
  contactsId: [],
  setContactsId: () => {},
  contactList: [],
  setContactList: () => {},
  // Contact Data State
  name: null,
  setName: () => {},
  firstName: null,
  setFirstName: () => {},
  lastName: null,
  setLastName: () => {},
  imageUrl: null,
  setImageUrl: () => {},
  birthDate: null,
  setBirthDate: () => {},
  owner: null,
  setOwner: () => {},
  listId: null,
  setListId: () => {},
  businessId: '',
  setBusinessId: () => {},
  business: null,
  setBusiness: () => {},
  phone: null,
  setPhone: () => {},
  phones: [],
  setPhones: () => {},
  email: null,
  setEmail: () => {},
  emails: [],
  setEmails: () => {},
  ims_instagram: [],
  setIms_instagram: () => {},
  ims_facebook: [],
  setIms_facebook: () => {},
  ims_webchat: [],
  setIms_webchat: () => {},
  ims_whatsapp: [],
  setIms_whatsapp: () => {},
  address1: null,
  setAddress1: () => {},
  address2: null,
  setAddress2: () => {},
  tags: [],
  setTags: () => {},
  properties: [],
  setProperties: () => {},
  triggerSelectAllId: '',
  setTriggerSelectAllId: () => {},
  // Functions
  removeContactById: () => {},
  resetFields: () => {},
  compareAddress: (
    address1: IContactPostalAddress,
    address2: IContactPostalAddress
  ) => {
    return false;
  },
  addContactById: () => {},
  // Contact Handlers
  handleSelectString: (
    setState: Dispatch<React.SetStateAction<TextSelected>>,
    value: TextSelected
  ) => {},
  handleSelectDate: (date: Date, contactId: string) => {},
  handleSelectContactAddress: (
    setState: Dispatch<React.SetStateAction<IContactAddress[]>>,
    value: IContactAddress,
    contactId: string
  ) => {},
  handleSelectContactPostalAddress: (
    value: IContactPostalAddress,
    isAdd: boolean,
    contactId: string
  ) => {},
  handleSelectBusiness: (value: IContactInfo, contactId: string) => {},
  handleSelectContactTag: (value: LabelSelected) => {},
  handleSelectProperty: (value: Property, contactId: string) => {},
  handleJoinDuplicates: () => {},
  getContactFields: () => {},
});
