import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  exportContacts,
  selectContactExportStatus,
  changeContactExportStatus,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { StyleContext } from 'style/styleProvider';
// Types
import { Style } from 'style/types/Style';
import { Box, Fade, Theme, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SuccessExport, ErrorExport } from './components';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function ExportContent() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { shadow } = useContext<Style>(StyleContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const contactExportStatus = useSelector(selectContactExportStatus);

  const handleExportContacts = async () => {
    setLoading(true);
    await dispatch(exportContacts());
    setLoading(false);
  };

  const Message =
    contactExportStatus === 'idle' || contactExportStatus === 'loading' ? (
      <Typography
        sx={{ color: (theme: Theme) => theme.palette.text.primary }}
        mb={5}
      >
        {t('exportContactsView.info')}
      </Typography>
    ) : contactExportStatus === 'succeeded' ? (
      <SuccessExport />
    ) : (
      <ErrorExport />
    );

  useEffect(() => {
    setShow(true);

    return () => {
      dispatch(changeContactExportStatus('idle'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Fade
      style={{ transitionDuration: '750ms', justifyContent: 'center' }}
      in={show}
    >
      <Box className="px-2 py-2">
        <Box
          className="panel-inner contacts-my-0"
          sx={{
            //@ts-ignore
            backgroundColor: (theme: Theme) => theme.palette.dropdownBox,
            display: 'flex',
            boxShadow: shadow.md,
            borderRadius: 1,
            alignItems: 'center',
            justifyContent: 'space-evenly',
            minHeight: '300px',
            flexDirection: 'column',
            py: 2,
          }}
        >
          <Typography
            sx={{ color: (theme: Theme) => theme.palette.text.primary }}
            variant="h5"
          >
            {t('exportContactsView.exportContacts')}
          </Typography>
          <Box height={90} alignItems="center" display={'flex'} mb={2}>
            {Message}
          </Box>
          <LoadingButton
            loadingPosition="start"
            variant="contained"
            onClick={handleExportContacts}
            loading={loading}
            startIcon={<CloudDownloadIcon />}
            disabled={contactExportStatus === 'succeeded'}
          >
            {t('navMenu.export')}
          </LoadingButton>
        </Box>
      </Box>
    </Fade>
  );
}
