// Types
import { TableContactField } from 'ReduxToolkit/features/contactsSlice/types/TableContactField';
import { Property } from '@trii/types/dist/Contacts';

const getField = (key: string, value) => {
  if (value !== '') {
    const field = {};
    field[key] = value;
    return field;
  }
  return {
    [key]: '',
  };
};

const createProperty = (customField: TableContactField): Property => {
  const property = {
    nameKey: customField.nameKey,
    value: '',
    type: customField.type,
  };
  return property;
};

export default { getField, createProperty };
