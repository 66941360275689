import { useCallback } from 'react';
// Components/ui
import {
  Accordion,
  AccordionSummary,
  Box,
  Card,
  styled,
  Theme,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { style } from './style';
// Redux
import { useDispatch } from 'react-redux';
// Types
import { ReactNode } from 'react';
import { MouseEvent } from 'react';

interface AccordionCardProps {
  children: ReactNode;
  title: string;
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  button?: ReactNode;
}

const AccordionCard = ({
  children,
  title,
  id,
  open,
  setOpen,
  button,
}: AccordionCardProps) => {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    // @ts-ignore
    backgroundColor: theme.palette.background.panel
  }));
  const dispatch = useDispatch();

  const handleButtonClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const handleChange = useCallback(() => {
    dispatch(setOpen(!open));
  }, [open]);

  return (
    <Card sx={style.cardContainer} id={id}>
      <Accordion
        expanded={open}
        onChange={handleChange}
        sx={{ backgroundColor: (theme: Theme) => theme.palette.background.default }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              color: (theme: Theme) => theme.palette.text.primary,
              ...style.titleContainer,
            }}
          >
            <Typography variant="subtitle2">{title}</Typography>
            {button && <div onClick={(e) => handleButtonClick(e)}>{button}</div>}
          </Box>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default AccordionCard;
