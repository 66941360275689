import { useContext } from 'react';
// Context
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Utils
import { pageAmountMenuItems } from './utils/pageAmountMenuItems';
// Redux
import { useSelector } from 'react-redux';
import { selectPagination } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { IconButton, MenuItem, Select, Box, Skeleton } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import { useTranslation } from 'react-i18next';

const PageNavigator = () => {
  const { handlePageNumberChange, handlePageAmountChange } = useContext(
    ContactBusinessTableContext
  );
  const { currentPage, perPage, lastPage } = useSelector(selectPagination);
  
  const {t} = useTranslation()
  const PageAmountMenuItems = pageAmountMenuItems.map((item) => (
    <MenuItem key={item} value={item}>
      {item} {t('contactsView.contactBusinessTable.recordsPerPage')}
    </MenuItem>
  ));

  const PageNumbers =
    !currentPage && !lastPage ? (
      <Skeleton
        variant="text"
        width={36}
        // @ts-ignore
        sx={{ backgroundColor: (theme) => theme.palette.Skeleton.bg }}
      />
    ) : (
      <span>
        {currentPage} - {lastPage}
      </span>
    );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Select
        sx={{ fontSize: 14, height: 28 }}
        value={perPage}
        size="small"
        onChange={handlePageAmountChange}
        MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}
      >
        {PageAmountMenuItems}
      </Select>
      <AutoStoriesRoundedIcon sx={{ fontSize: 14, marginRight: 1 }} />
      <Box display="flex" alignItems="center" mx={1} mr={0} gap={1}>
        {PageNumbers}
        <IconButton
          disabled={currentPage === 1}
          onClick={() => handlePageNumberChange(currentPage - 1)}
        >
          <ArrowBackIosNewRoundedIcon sx={{ fontSize: 14 }} />
        </IconButton>
        <IconButton
          disabled={currentPage === lastPage}
          onClick={() => handlePageNumberChange(currentPage + 1)}
        >
          <ArrowForwardIosRoundedIcon sx={{ fontSize: 14 }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PageNavigator;
