import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { labelContext } from 'features/Views/Label/context/LabelProvider';
// Redux
import { useSelector } from 'react-redux';
import { selectLabelCreateStatus } from 'ReduxToolkit/features/labelSlice/labelSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';

function CreateButtons() {
  const labelCreateStatus = useSelector(selectLabelCreateStatus);
  const { t } = useTranslation();
  const { endCreating, handleCreateLabel } = useContext(labelContext);
  const isLoading = labelCreateStatus === 'loading';

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={endCreating}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loadingPosition="start"
        disabled={isLoading}
        loading={isLoading}
        sx={{
          backgroundColor: (color) => color.palette.primary.main,
          '&:hover': {
            backgroundColor: (color) => color.palette.primary.dark,
          },
          color: (color) => color.palette.text.primary
        }}
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        // color="success"

        onClick={handleCreateLabel}
      >
        {t('global.create')}
      </LoadingButton>
    </Box>
  );
}

export default CreateButtons;
