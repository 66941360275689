// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Typography, Button, Box } from '@mui/material';
import subsectionHeaderWithButtonStyle from './style';
import AddIcon from '@mui/icons-material/Add';

type Props = {
  title: string;
  handleAdd: () => void;
};

const SubsectionHeaderWithButton = ({ title, handleAdd }: Props) => {
  const { t } = useTranslation();
  return (
    <Box sx={subsectionHeaderWithButtonStyle.container}>
      <Typography variant="subtitle2">{title}</Typography>
      <Button
        onClick={handleAdd}
        size="small"
        startIcon={<AddIcon />}
        variant="text"
        color="primary"
      >
        {t('global.add')}
      </Button>
    </Box>
  );
};

export default SubsectionHeaderWithButton;
