import { createContext, useState } from 'react';
import { Contacts } from '@trii/types';
import { useTranslation } from 'react-i18next';
import useField from 'hooks/useField';
import useSelect from 'hooks/useSelect';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// redux
import { useDispatch } from 'react-redux';
import {
  deleteCustomContactField,
  createCustomContactField,
  updateCustomContactField,
  changeCreateCustomFieldsStatus,
} from 'ReduxToolkit/features/configSlice/configSlice';
// Types
import { CustomField, CustomFieldProps, SelectedField } from './types/CustomField';
import { IContactField } from '@trii/types/dist/Contacts';

export const CustomFieldContext = createContext<CustomField>({
  createField: fieldInitialDef,
  createLoading: false,
  createSelect: fieldInitialDef,
  deleteField: fieldInitialDef,
  editField: fieldInitialDef,
  editLoading: false,
  editSelect: fieldInitialDef,
  getFieldType: () => '',
  handleCloseCreateError: () => { },
  handleCreateContactField: () => { },
  handleDeleteContactField: () => { },
  handleDrawerClose: () => { },
  handleDrawerOpen: () => { },
  handleEditContactField: () => { },
  isDelete: false,
  isEditing: false,
  loadingDelete: false,
  openDelete: () => { },
  openEdit: () => { },
  resetCreate: () => { },
  resetDelete: () => { },
  selectedField: null,
  setOpenPanelCP: () => { },
  openPanelCP: false,
});

export const CustomFieldProvider = ({ children, fetchFor }: CustomFieldProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openPanelCP, setOpenPanelCP] = useState(false);
  const [selectedField, setSelectedField] = useState<IContactField | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  // Edit functionality state
  const [editLoading, setEditLoading] = useState(false);
  const editField = useField('text', '');
  const editSelect = useSelect('');
  // End edit functionality state
  // Create functionality state
  const [createLoading, setCreateLoading] = useState(false);
  const createField = useField('text', '');
  const createSelect = useSelect('Text');
  // End create functionality state
  // Delete functionality state
  const [isDelete, setIsDelete] = useState(false);
  const [loadingDelete, setIsLoadingDelete] = useState(false);
  const deleteField = useField('text', '');
  // End delete functionality state

  //Edit functionality functions
  const openEdit = (field: IContactField) => {
    setSelectedField(field);
    editField.actions.changeValue(field.name);
    editSelect.actions.changeValue(Contacts.ContactField_type[field.type]);
    setOpenPanelCP(true);
    setIsEditing(true);
    setIsDelete(false);
  };

  const handleEditContactField = async () => {
    const newField = {
      id: selectedField.id,
      name: editField.attributes.value,
    };
    const newFieldJSON = JSON.stringify(newField);

    setEditLoading(true);
    await dispatch(updateCustomContactField(newFieldJSON));
    setEditLoading(false);
    resetEdit();
    handleDrawerClose();
  };
  const resetEdit = () => {
    setSelectedField(null);
    editField.actions.resetValue();
    editSelect.actions.changeValue('Text');
  };
  // End edit functionality functions

  // Delete functionality functions
  const handleDeleteContactField = async () => {
    if (deleteField.attributes.value === selectedField.name) {
      setIsLoadingDelete(true);
      await dispatch(deleteCustomContactField(selectedField.id));
      setIsLoadingDelete(false);
      deleteField.actions.onSuccess();
      resetDelete();
      handleDrawerClose();

      return;
    }

    deleteField.actions.onError('El nombre del campo personalizado no coincide');
  };
  const openDelete = (field) => {
    setSelectedField(field);

    setOpenPanelCP(true);
    setIsEditing(false);
    setIsDelete(true);
  };
  const resetDelete = () => {
    setSelectedField(null);
    deleteField.actions.resetValue();
    deleteField.actions.onSuccess();
  };
  // End delete functionality functions

  // Create functionality functions
  const handleCreateContactField = async () => {
    const newField = {
      Name: createField.attributes.value,
      Type: createSelect.attributes.value,
      Options: [],
      forBusiness: fetchFor === 'business',
    };
    const newFieldJSON = JSON.stringify(newField);

    setCreateLoading(true);
    await dispatch(createCustomContactField(newFieldJSON));
    setCreateLoading(false);
    resetCreate();
    handleDrawerClose();
  };
  const resetCreate = () => {
    createField.actions.resetValue();
    createSelect.actions.changeValue('Text');
  };
  const handleCloseCreateError = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(changeCreateCustomFieldsStatus('idle'));
  };
  // End create functionality functions

  // Drawer functions
  const handleDrawerOpen = () => {
    setOpenPanelCP(true);
  };
  const handleDrawerClose = () => {
    setOpenPanelCP(false);
    setIsEditing(false);
    setIsDelete(false);
  };
  // End drawer functions

  // Field types translations
  const getFieldType = (type) => {
    switch (type) {
      case 'TEXT':
        return t('global.text');
      case 'LONGTEXT':
        return t('global.longText');
      case 'NUMBER':
        return t('global.number');
      case 'CURRENCY':
        return t('global.currency');
      case 'OPTIONS':
        return t('global.options');
      case 'TIME':
        return t('global.time');
      case 'DATE':
        return t('global.date');
      case 'TIMERANGE':
        return t('global.timeRange');
      case 'DATERANGE':
        return t('global.dateRange');
      case 'ADDRESS':
        return t('global.address');
      case 'NUMBER_INTEGER':
        return t('global.numberInteger');
      case 'NUMBER_DECIMAL':
        return t('global.numberDecimal');
      case 'NUMBER_CURRENCY':
        return t('global.numberCurrency');
      case 'NUMBER_ACCOUNTING':
        return t('global.numberAccounting');
      default:
        return t('global.text');
    }
  };

  // End field types translations
  return (
    <CustomFieldContext.Provider
      value={{
        createField,
        createLoading,
        createSelect,
        deleteField,
        editField,
        editLoading,
        editSelect,
        getFieldType,
        handleCloseCreateError,
        handleCreateContactField,
        handleDeleteContactField,
        handleDrawerClose,
        handleDrawerOpen,
        handleEditContactField,
        isDelete,
        isEditing,
        loadingDelete,
        openDelete,
        openEdit,
        resetCreate,
        resetDelete,
        selectedField,
        setOpenPanelCP,
        openPanelCP,
      }}
    >
      {children}
    </CustomFieldContext.Provider>
  );
};
export default CustomFieldProvider;
