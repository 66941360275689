import React, { useContext, useEffect } from 'react';
import { ButtonsListContext } from './context/ButtonListContext';
//Sort lib
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
// Types
import type { ButtonsListContextType } from './context/types';
import type { RequestStatus } from '../../../../../../../types/reduxTypes';
import { IIntegrationButton } from '@trii/types/dist/Contacts';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchButtons,
  selectButtonsFetchStatus,
  selectButtons,
  setButtons,
} from '../../../../../../../ReduxToolkit/features/configSlice/configSlice';
// Components/ui
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ListHeader, ButtonItem, CrudPanel } from './components';
import { ConfigListItemsSkeleton } from '../../../../components';

const ButtonsList = () => {
  const {
    handleDrawerOpen,
    openPanelCP,
    handleDrawerClose,
    setOriginalButtonsOrder,
    setCanSaveOrder,
    originalButtonsOrder,
    compareButtonsOrder,
  } = useContext(ButtonsListContext) as ButtonsListContextType;
  const dispatch = useDispatch();
  const buttons = useSelector(selectButtons);
  const buttonsFetchStatus: RequestStatus = useSelector(selectButtonsFetchStatus);

  const Buttons = buttons?.map((item) => <ButtonItem key={item.id} data={item} />);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = buttons.findIndex((item) => item.id === active.id);
      const newIndex = buttons.findIndex((item) => item.id === over.id);
      const newButtons: IIntegrationButton[] = arrayMove(
        buttons,
        oldIndex,
        newIndex
      );

      setCanSaveOrder(!compareButtonsOrder(newButtons));
      dispatch(setButtons(newButtons));
    }
  };

  // On component mount
  useEffect(() => {
    if (buttonsFetchStatus === 'idle') {
      const fetchButtonsAsync = async () => {
        const response: any = await dispatch(fetchButtons());
        setOriginalButtonsOrder(response.payload);
      };

      fetchButtonsAsync();
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  // On buttons original (the one loaded when first loading the page) change
  useEffect(() => {
    setCanSaveOrder(!compareButtonsOrder(buttons));
  }, [originalButtonsOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const cleanUp = () => {
      handleDrawerClose();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <Box display={'flex'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          height={'100%'}
          width={'100%'}
          className={'contacts-paddingmd paddingContent'}
          borderRadius={2}
          id={'listContentCP'}
        >
          <ListHeader open={handleDrawerOpen} show={openPanelCP} />
          <Divider sx={{ marginY: '10px', marginX: '10px' }} />
          <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 185px)' }}>
            <SortableContext items={buttons} strategy={verticalListSortingStrategy}>
              {buttonsFetchStatus === 'succeeded' ? (
                Buttons
              ) : (
                <ConfigListItemsSkeleton />
              )}
            </SortableContext>
          </Box>
        </Box>
        {/* Box desplegable */}
        {openPanelCP && <CrudPanel />}
      </Box>
    </DndContext>
  );
};

export default ButtonsList;
