import { useContext } from 'react';
// Context
import { ContactCreateContext } from '../../../../context/ContactCreateContext';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { getContactCreateStatus } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components
import { LoadingButton } from '@mui/lab';

const CreateButton = () => {
  const { t } = useTranslation();
  const { handleCreateButton } = useContext(ContactCreateContext);
  const contactCreateStatus = useSelector(getContactCreateStatus);
  const isLoading = contactCreateStatus === 'loading';
  return (
    <LoadingButton
      onClick={handleCreateButton}
      loading={isLoading}
      disabled={isLoading}
      size="small"
      variant="contained"
    >
      {t('global.create')}
    </LoadingButton>
  );
};

export default CreateButton;
