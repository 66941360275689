import { useContext } from 'react';
// Context
import { FilesCardContext } from '../../context/FilesCardContext';
// Redux
import { useSelector } from 'react-redux';
import { selectFilesAddStatus } from 'ReduxToolkit/features/fileSlice/utils/selectors';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { LoadingButton } from '@mui/lab';

const UploadButton = () => {
  const { t } = useTranslation();
  const { fileHandler } = useContext(FilesCardContext);
  const addStatus = useSelector(selectFilesAddStatus);
  const isLoading = addStatus === 'loading';
  return (
    <>
      <input {...fileHandler.inputAtributes} style={{ display: 'none' }} />
      <LoadingButton
        disabled={isLoading}
        loading={isLoading}
        variant="contained"
        color="primary"
        size="small"
        sx={{ mr: 1 }}
        onClick={fileHandler.action.uploadFile}
      >
        {t('contactsView.files.upload')}
      </LoadingButton>
    </>
  );
};

export default UploadButton;
