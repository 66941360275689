import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

const ListHeader = ({ open, show }) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    <NavLink underline="hover" key="1" to="/a/contacts/settings">
      <Typography color="secondary">{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink underline="hover" key="2" to="/a/contacts/settings/subscriptions">
      <Typography color="secondary">{t('settingsView.subscriptions')}</Typography>
    </NavLink>,
  ];
  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      borderRadius={'4px'}
      height="50px"
      className="m-0"
      position={'relative'}

    >
      <Box position={'absolute'} left="0rem" top="-1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Button
        sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
        variant="contained"
        size="small"
        onClick={open}
        disabled={show ? true : false}
      >
        {t('settingsView.newSubscription')}
      </Button>
    </Box>
  );
};

export default ListHeader;
