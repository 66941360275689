import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
// Context
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  selectContactsFetchStatus,
  selectBusinessFetchStatus,
  getCheckedContactsAmount,
  getAllContactsAmount,
  getAreAllContactsChecked,
  checkAllContacts,
  checkAllBusiness,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import CheckAllButton from './components/CheckAllButton';
import { Skeleton, Box } from '@mui/material';

const ContactsInfo = () => {
  const { t } = useTranslation();
  const { mode } = useContext(ContactBusinessTableContext);
  const dispatch = useDispatch();
  const allChecked = useSelector(getAreAllContactsChecked);
  const itemsFetchStatus = useSelector(
    mode === 'contact' ? selectContactsFetchStatus : selectBusinessFetchStatus
  );
  const checkedContactsAmount = useSelector(getCheckedContactsAmount);
  const allContactsAmount = useSelector(getAllContactsAmount);

  const handleCheckAll = () => {
    if (mode === 'contact')
      allChecked
        ? dispatch(checkAllContacts(false))
        : dispatch(checkAllContacts(true));
    else
      allChecked
        ? dispatch(checkAllBusiness(false))
        : dispatch(checkAllBusiness(true));
  };

  const RegisterAmount =
    itemsFetchStatus !== 'succeeded' ? (
      <Skeleton variant="text" width={20} sx={{ backgroundColor: 'lightgray' }} />
    ) : (
      allContactsAmount
    );

  const CheckedContacts = (
    <span
      style={{ marginRight: 8 }}
    >{`${checkedContactsAmount} filas seleccionadas`}</span>
  );

  return checkedContactsAmount > 0 ? (
    <Box style={{ userSelect: 'none' }}>
      {CheckedContacts}
      <CheckAllButton handleClick={handleCheckAll} isSelected={allChecked} />
    </Box>
  ) : (
    <Box style={{ userSelect: 'none' }} display={'flex'} gap={0.7}>
      {`${t('contactsView.totalRegisters')}: `}
      {RegisterAmount}
    </Box>
  );
};

export default ContactsInfo;
