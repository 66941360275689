import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsListContext } from '../../../../context/ButtonListContext';
// Redux
import { useSelector } from 'react-redux';
//@ts-ignore
import { selectButtonsDeleteStatus } from '../../../../../../../../../../../ReduxToolkit/features/configSlice/configSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteButtons() {
  const { t } = useTranslation();
  const { deleteField, selectedButton, handleDeleteButton, handleDrawerClose } =
    useContext(ButtonsListContext);
  const deleteStatus = useSelector(selectButtonsDeleteStatus);
  const isDisabled =
    deleteStatus === 'loading' ||
    selectedButton.name !== deleteField.attributes.value;
  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={handleDrawerClose}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        startIcon={<DeleteIcon />}
        disabled={isDisabled}
        onClick={handleDeleteButton}
        loading={deleteStatus === 'loading'}
        variant="contained"
        size="small"
        color="error"
      >
        {t('global.delete')}
      </LoadingButton>
    </Box>
  );
}
export default DeleteButtons;
