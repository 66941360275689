import { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
// Components/ui
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';
import { UploadButton, NextButton } from './components';
import { XslxFile } from '../../types';

interface ImportUploadProps {
  xslxFile: XslxFile;
  setXslxFile: React.Dispatch<React.SetStateAction<XslxFile>>;
  handleNext: () => void;
}
export default function ImportUpload({
  xslxFile,
  setXslxFile,
  handleNext,
}: ImportUploadProps) {
  const [show, setShow] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const handleFile = async (e) => {
    setLoadingFile(true);
    setTimeout(() => {
      setTimeout(() => {
        setExcel(e.target.files[0]);
      }, 100);
    }, 100);
  };

  const setExcel = async (file: File) => {
    const fd = new FormData();
    fd.append('contacts', file, file.name);

    // Read the file to get the rowsAmount of the file
    const data = await file.arrayBuffer();
    const workbook = read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const workbookSheetJson = utils.sheet_to_json(worksheet);

    setXslxFile({
      name: file.name,
      workbookSheet: fd,
      rowsAmount: workbookSheetJson.length,
    });
  };

  const Button =
    xslxFile.name === null ? (
      <UploadButton
        sx={{ backgroundColor: (theme: Theme) => theme.palette.background.default }}
        isLoading={loadingFile}
        onChange={handleFile}
      />
    ) : (
      <NextButton onClick={handleNext} />
    );

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade style={{ transitionDuration: '750ms' }} in={show}>
      <Box
        display={'flex'}
        alignItems="center"
        justifyContent="center"
        minHeight={'200px'}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography
            sx={{ color: (theme: Theme) => theme.palette.text.primary }}
            mb={2}
          >
            *Formato requerido .xlsx
          </Typography>
          {Button}
          <Typography sx={{ color: (theme: Theme) => theme.palette.text.primary }}>
            {xslxFile.name === null
              ? 'Ningun Archivo seleccionado'
              : `Archivo Seleccionado: ${xslxFile.name}`}
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
}
