import { useState, useEffect } from 'react';
// Types
import { SelectChangeEvent } from '@mui/material';

export type UseSelectType = {
  actions: {
    resetValue: () => void;
    changeValue: (newValue: string) => void;
  };
  attributes: {
    value: string;
    onChange: (event: SelectChangeEvent<string>) => void;
  };
};

const useSelect = (initialValue): UseSelectType => {
  const [value, setValue] = useState(initialValue);
  const onChange = (event) => {
    setValue(event.target.value);
  };

  const changeValue = (newValue) => {
    setValue(newValue);
  };

  const resetValue = () => {
    setValue(initialValue || '');
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return {
    actions: { resetValue, changeValue },
    attributes: { value, onChange },
  };
};

export default useSelect;
