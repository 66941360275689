import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { fetchSyncConfig } from '../../../../../../../ReduxToolkit/features/googleSyncSlice/googleSyncSlice';
// Components/ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { ListHeader } from '../components';
import { StyleContext } from '../../../../../../../style/styleProvider';
import {
  GoogleSignInSection,
  UpdateContactsSection,
  CreateTagSection,
  WhatsAppSyncSection,
} from './components';

const GoogleSyncView = () => {
  const { fontSize, fontWeight } = useContext(StyleContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchSyncConfig());
  }, [dispatch]);

  return (
    <Box display={"flex"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        width={"100%"}
        className={"contacts-paddingmd paddingContent"}
        borderRadius={2}
        id={"listContentCP"}
      >
        <ListHeader />
        <Divider sx={{ marginY: "10px"}}  />
        <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 185px)" }}>
          <Box
            className="panel"
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "background.panel"
            }}
          >
            <Typography
              fontWeight={fontWeight.semibold}
              fontSize={fontSize.sm}
              sx={{ marginTop: 2, ml: 2 }}
              color="text.primary"
            >
              {t("settingsView.signInGoogleOrUpdateToken")}
            </Typography>
            <GoogleSignInSection />
            <Divider sx={{ width: "100%" }} />
            <UpdateContactsSection />
            <Divider sx={{ width: "100%" }} />
            <CreateTagSection />
            <Divider sx={{ width: "100%" }} />
            <WhatsAppSyncSection />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleSyncView;
