// Types
import type Mode from '../context/types/Mode';
import type ColumnOrderData from '../context/types/ColumnOrderData';
import type Checked from '../context/types/Checked';
import type { TableContactField } from 'ReduxToolkit/features/contactsSlice/types/TableContactField';
import type { Order } from 'ReduxToolkit/features/contactsSlice/types/Order';

const getIsFilterPanelOpen = (mode: Mode) => {
  return mode === 'contact'
    ? localStorage.getItem('isContactFilterPanelOpen')
    : localStorage.getItem('isBusinessFilterPanelOpen');
};

const getAmountOfItems = (mode: Mode): string => {
  return (
    (mode === 'contact'
      ? localStorage.getItem('amountOfContacts')
      : localStorage.getItem('amountOfBusiness')) || '10'
  );
};

const getItemsOrder = (mode: Mode) => {
  return mode === 'contact'
    ? JSON.parse(localStorage.getItem('contactOrder'))
    : JSON.parse(localStorage.getItem('businesstOrder'));
};

const getFields = (mode: Mode): TableContactField[] => {
  return mode === 'contact'
    ? JSON.parse(localStorage.getItem('contactFields'))
    : JSON.parse(localStorage.getItem('businessFields'));
};

const setFields = (mode: Mode, fields: TableContactField[]) => {
  return mode === 'contact'
    ? localStorage.setItem('contactFields', JSON.stringify(fields))
    : localStorage.setItem('businessFields', JSON.stringify(fields));
};

const setAmoutOfItems = (mode: Mode, amount: string) => {
  return mode === 'contact'
    ? localStorage.setItem('amountOfContacts', amount)
    : localStorage.setItem('amountOfBusiness', amount);
};

const setItemsOrder = (mode: Mode, columnOrderData: ColumnOrderData) => {
  return mode === 'contact'
    ? localStorage.setItem('contactOrder', JSON.stringify(columnOrderData))
    : localStorage.setItem('businesstOrder', JSON.stringify(columnOrderData));
};

const getNewSortDirection = (order: Order): Order => {
  switch (order) {
    case 'ASC':
      return 'DESC';
    case 'DESC':
      return 'ASC';
    default:
      return 'ASC';
  }
};

const getParentCheck = (checked: Checked): boolean => {
  switch (checked) {
    case 'none':
      return true;
    case 'some':
      return false;
    case 'all':
      return false;
    default:
      return false;
  }
};

export default {
  getAmountOfItems,
  getItemsOrder,
  setAmoutOfItems,
  getIsFilterPanelOpen,
  getNewSortDirection,
  setItemsOrder,
  getParentCheck,
  getFields,
  setFields,
};
