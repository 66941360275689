import React from 'react';
// components/ui
import { Skeleton, TableCell, TableRow } from '@mui/material';

const RowSkeleton = ({ rows, visibleColumns, columns }) => {
  const CellsSkeleton = visibleColumns.map((column) => (
    <TableCell key={column.name} sx={{ padding: '0' }}>
      <Skeleton variant="rectangular" height={30} />
    </TableCell>
  ));

  return (
    <>
      {rows.map((contact) => (
        <TableRow sx={{ height: 43 }} key={contact}>
          <TableCell sx={{ padding: '0', px: columns.length === 0 ? 10 : 0 }}>
            {columns.length === 0 && <Skeleton variant="rectangular" height={30} />}
          </TableCell>
          {CellsSkeleton}
        </TableRow>
      ))}
    </>
  );
};

export default RowSkeleton;
