export const IconsSVG = [
  {
    icon: 'img/iconsMaterialSVG/app_registration.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/captive_portal.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/check_circle.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/close.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/dataset.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/delete.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/download.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/download_for_offline.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/face.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/favorite.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/fit_screen.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/group.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/heart_plus.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/home.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/manage_search.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/menu.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/pip_exit.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/public.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/rainy.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/rebase.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/rocket_launch.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/send_time_extension.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/settings.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/settings_applications.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/shopping_cart_checkout.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/star_half.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/check_circle.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/thumb_up.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/transcribe.svg',
  },
  {
    icon: 'img/iconsMaterialSVG/event.svg',
  },
];
