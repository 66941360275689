const containerStyle = {
  display: 'flex',
  width: '100%',
  gap: 2,
  flexDirection: 'column',
};

const inputContainerStyle = {
  display: 'flex',
  width: '100%',
  gap: 2,
};

export const itemFilterInputsStyle = {
  containerStyle,
  inputContainerStyle,
};
