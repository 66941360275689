import React from 'react';
// components/ui
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const Title = () => {
  const {t} = useTranslation()
  return (
    <Typography
      color={'text.primary'}
      fontSize={15}
      fontWeight={500}
      sx={{ mb: '20px'}}
    >
      {t('contactsView.contactBusinessTable.advancedFilters')}
    </Typography>
  );
};

export default Title;
