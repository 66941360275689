// Context provider
import BusinessEditContextProvider from './context/BusinessEditContextProvider';
// Components/ui
import BusinessContainer from 'components/BusinessContainer/BusinessContainer';
import { App } from './components';

const BusinessEdit = () => {
  return (
    <BusinessEditContextProvider>
      <BusinessContainer>
        <App />
      </BusinessContainer>
    </BusinessEditContextProvider>
  );
};

export default BusinessEdit;
