// Redux
import {
  setUpdatingNoteStatus,
  removeUpdatingNoteStatus,
  updateNote,
} from 'ReduxToolkit/features/noteSlice/noteSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Types
import { UseFieldType } from 'hooks/useField';

export const useNoteEditorActions = (
  handleEditNoteEnd: (noteIdToRemove: string) => void,
  noteId: string,
  noteField: UseFieldType,
  titleField: UseFieldType
) => {
  const dispatch = useAppDispatch();

  const cancelNoteEdit = () => {
    noteField.actions.resetValue();
    titleField.actions.resetValue();
    handleEditNoteEnd(noteId);
  };

  const acceptNoteEdit = async () => {
    const baseUpdatedNote = {
      noteId,
      text: noteField.attributes.value,
      title: titleField.attributes.value,
    };

    await dispatch(setUpdatingNoteStatus(noteId));
    await dispatch(updateNote(baseUpdatedNote));
    await dispatch(removeUpdatingNoteStatus(noteId));

    handleEditNoteEnd(noteId);
  };

  return {
    cancelNoteEdit,
    acceptNoteEdit,
  };
};
