// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  getContactCreateStatus,
  setContactCreateStatus,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { Snackbar, Alert } from '@mui/material';
// Types
import { SyntheticEvent } from 'react';

const ErrorAlert = () => {
  const dispatch = useDispatch();
  const createContactStatus = useSelector(getContactCreateStatus);
  const isThereError = typeof createContactStatus === 'object';

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setContactCreateStatus('idle'));
  };

  return (
    <Snackbar open={isThereError} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {typeof createContactStatus === 'object' && createContactStatus.error}
      </Alert>
    </Snackbar>
  );
};

export default ErrorAlert;
