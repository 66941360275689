import React, { useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
import {
  selectSubscriptionCreateStatus,
  selectSubscriptionEditStatus,
} from '../../../../../../../../../../../ReduxToolkit/features/configSlice/configSlice';

// Components/ui
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import { SubscriptionListContext } from '../../../../../context/SubscriptionListContext';

function CreateButtons({ t }) {
  const {
    handleCreate,
    handleEdit,
    isEditing,
    createNameField,
    editNameField,
    closeResetDrawer,
  } = useContext(SubscriptionListContext);
  const subscriptionsCreateStatus = useSelector(selectSubscriptionCreateStatus);
  const subscriptionsEditStatus = useSelector(selectSubscriptionEditStatus);
  const isLoading =
    subscriptionsCreateStatus === 'loading' || subscriptionsEditStatus === 'loading';
  const startIcon = isEditing ? <EditIcon /> : <AddBoxIcon />;
  const buttonText = isEditing ? t('global.save') : t('global.accept');
  const isDisabled = isEditing
    ? editNameField.attributes.value === '' || subscriptionsEditStatus === 'loading'
    : createNameField.attributes.value === '' ||
      subscriptionsCreateStatus === 'loading';

  const createSubscription = async () => {
    await handleCreate();
  };

  const editSubscription = async () => {
    await handleEdit();
  };

  return (
    <Box
      display={"flex"}
      justifyContent="flex-end"
      alignItems={"center"}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: "15px",
        }}
        onClick={closeResetDrawer}
      >
        {t("global.cancel")}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={startIcon}
        variant="contained"
        size="small"
        color="success"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.text.primary,
        }}
        disabled={isDisabled}
        onClick={isEditing ? editSubscription : createSubscription}
      >
        {buttonText}
      </LoadingButton>
    </Box>
  );
}

export default CreateButtons;
