import { createContext, useState } from 'react';
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Hooks
import useField from 'hooks/useField';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  createSegment,
  selectSegmentById,
  removeSelectedSegment,
  deleteSegment,
  selectSegmentsCreateStatus,
} from 'ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Types
import { ISegmentsContext, SegmentsProviderProps } from './types';
import { UseFieldType } from 'hooks/useField';

export const SegmentsContext = createContext<ISegmentsContext>({
  deletePanelOpen: false,
  createPanelOpen: false,
  createSegmentNameField: fieldInitialDef,
  createSegmentDescriptionField: fieldInitialDef,
  deleteSegmentNameField: fieldInitialDef,
  setDeletePanelOpen: () => {},
  setCreatePanelOpen: () => {},
  openCreatePanel: () => {},
  closeCreatePanel: () => {},
  resetCreateFields: () => {},
  sendSegment: () => {},
  openDeletePanel: () => {},
  closeDeletePanel: () => {},
  sendDeleteSegment: () => {},
});

export const SegmentsProvider = ({ children }: SegmentsProviderProps) => {
  const dispatch = useAppDispatch();
  // Create state
  const [createPanelOpen, setCreatePanelOpen] = useState<boolean>(false);
  const createSegmentNameField: UseFieldType = useField('text');
  const createSegmentDescriptionField: UseFieldType = useField('text');
  const segmentCreateStatus = useSelector(selectSegmentsCreateStatus);
  // Delete state
  const [deletePanelOpen, setDeletePanelOpen] = useState<boolean>(false);
  const deleteSegmentNameField: UseFieldType = useField('text');

  // Create functions
  const openCreatePanel = () => {
    closeDeletePanel();
    setCreatePanelOpen(true);
  };
  const closeCreatePanel = () => {
    resetCreateFields();
    setCreatePanelOpen(false);
  };
  const resetCreateFields = () => {
    createSegmentNameField.actions.resetValue();
    createSegmentDescriptionField.actions.resetValue();
  };
  const sendSegment = async () => {
    const response = await dispatch(
      createSegment({
        name: createSegmentNameField.attributes.value,
        description: createSegmentDescriptionField.attributes.value,
      })
    );
    if (!response.hasOwnProperty('error')) closeCreatePanel();
  };
  //

  // Delete functions
  const openDeletePanel = (segmentId: string) => {
    dispatch(selectSegmentById(segmentId));
    closeCreatePanel();
    setDeletePanelOpen(true);
  };
  const closeDeletePanel = () => {
    deleteSegmentNameField.actions.resetValue();
    dispatch(removeSelectedSegment());
    setDeletePanelOpen(false);
  };
  const sendDeleteSegment = async (segmentId: string) => {
    await dispatch(deleteSegment(segmentId));
    closeDeletePanel();
  };
  //

  return (
    <SegmentsContext.Provider
      value={{
        sendDeleteSegment,
        closeDeletePanel,
        deleteSegmentNameField,
        openDeletePanel,
        deletePanelOpen,
        setDeletePanelOpen,
        createSegmentNameField,
        createSegmentDescriptionField,
        resetCreateFields,
        sendSegment,
        createPanelOpen,
        setCreatePanelOpen,
        openCreatePanel,
        closeCreatePanel,
      }}
    >
      {children}
    </SegmentsContext.Provider>
  );
};

export default SegmentsProvider;
