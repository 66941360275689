import React from 'react';
// Components/ui
import { Skeleton } from '@mui/material';

const StateChipSkeleton = () => {
  return (
    <Skeleton
      sx={{ backgroundColor: 'lightgray', borderRadius: 10 }}
      variant="rounded"
      width={80}
      height={24}
    />
  );
};

export default StateChipSkeleton;
