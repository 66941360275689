import { useMemo } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, MenuItem, Select, OutlinedInput, Chip } from '@mui/material';
import { InputContainer } from '../../layout';
import { StyledChipArray } from './styles';
// Types
import { UseShareWithUsersType } from '../../../../../../hooks/useShareWithUsers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

type ShareUsersSelectProps = {
  shareWithUsersField: UseShareWithUsersType;
};
const ShareUsersSelect = ({ shareWithUsersField }: ShareUsersSelectProps) => {
  const { t } = useTranslation();
  const {
    users,
    field: { handleChange, userIds },
  } = shareWithUsersField;

  const MenuItems = useMemo(() => {
    return users?.map((user) => {
      return (
        <MenuItem key={user.id} value={user.id}>
          {user.name}
        </MenuItem>
      );
    });
  }, [users]);

  return (
    <InputContainer title={t('contactsView.activities.shareWithUsers')}>
      <Select
        size="small"
        variant="outlined"
        fullWidth
        multiple
        value={userIds}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" />}
        renderValue={(selected) => (
          <StyledChipArray>
            {selected.map((userId) => (
              <Chip
                key={userId}
                label={shareWithUsersField.action.getUserNameById(userId)}
              />
            ))}
          </StyledChipArray>
        )}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'transparent' }} value={''}>
          {'No value'}
        </MenuItem>
        {MenuItems}
      </Select>
    </InputContainer>
  );
};

export default ShareUsersSelect;
