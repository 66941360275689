import { useContext, useState } from 'react';
// Context
import { BusinessEditContext } from 'features/Views/BusinessEdit/context/BusinessEditContext';
import { BusinessCreateContext } from 'features/Views/BusinessCreate/context/BusinessCreateContext';
// Components/ui
import {
  AddressInput,
  InformationItemContainer,
  InformationItemInputsContainer,
  NoteDeleteButton,
  WarningsDisplay,
} from 'components/ContactCreateEditAppBody/components/InformationSection/layout';
// Types
import { NewAddress } from 'types/NewAddress';
import { Box } from '@mui/material';
import mailStyle from './style';
import { IContactAddress } from '@trii/types/dist/Contacts';

type Props = {
  data: NewAddress;
};

const Whatsapp = ({ data }: Props) => {
  const mode: 'edit' | 'create' = window.location.pathname.includes('edit')
    ? 'edit'
    : 'create';
  const { editBusiness } = useContext(BusinessEditContext);
  const { business } = useContext(BusinessCreateContext);
  const editBusinessActions = editBusiness.action;
  const createBusinessActions = business.action;
  const [warnings, setWarnings] = useState<string[]>(null);

  return (
    <InformationItemContainer>
      <InformationItemInputsContainer>
        <Box sx={mailStyle.numberCountryContainer}>
          <Box sx={mailStyle.adressInputBox}>
            <AddressInput
              data={data}
              mode={mode}
              setWarnings={setWarnings}
              onChange={
                mode === 'edit'
                  ? editBusinessActions.handleAddressFieldChange
                  : createBusinessActions.handleWhatsappsAddressFieldChange
              }
            />
          </Box>
        </Box>
        <NoteDeleteButton
          mode={mode}
          addressData={data}
          setWarnings={setWarnings}
          handleNoteFieldChange={
            mode === 'edit'
              ? editBusinessActions.handleNoteFieldChange
              : createBusinessActions.handleNoteFieldChange
          }
          handleDeleteAddress={
            mode === 'edit'
              ? editBusinessActions.handleDeleteAddress
              : createBusinessActions.handleDeleteAddress
          }
          isDeletingAddress={mode === 'edit' && editBusiness.state.isDeletingAddress}
          replaceAddress={mode === 'edit' && editBusinessActions.replaceAddress}
        />
      </InformationItemInputsContainer>
      {warnings && <WarningsDisplay warnings={warnings} />}
    </InformationItemContainer>
  );
};

export default Whatsapp;
