// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  selectContactData,
  selectContactFields,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { ContactSection } from 'components/ContactCreateEditAppBody/layout';
import {
  CustomPropertyDatePicker,
  CustomPropertyDateRangePicker,
  CustomPropertyInput,
  CustomPropertyOptionSelect,
  CustomPropertyTimePicker,
  CustomPropertyTimeRangePicker,
} from './components';
// Types
import type UseEditContact from 'features/Views/ContactEdit/hooks/useEditContact/types/UseEditContact';
import type UseCreateContact from 'features/Views/ContactCreate/hooks/useCreateContact/types/UseCreateContact';
import { ContactField_type } from '@trii/types/dist/Contacts';
import { Box } from '@mui/material';
import { style } from '../DetailsSection/style';

interface Props {
  contact: UseEditContact | UseCreateContact;
}

const CustomFieldSection = ({ contact }: Props) => {
  const { t } = useTranslation();
  const { customProperties } = contact.field;
  const { setCustomProperties } = contact.action;
  const customContactFields = useSelector(selectContactFields);

  const getPropertyName = (propertyNameKey: string) => {
    const foundField = customContactFields.find(
      (field) => field.nameKey === propertyNameKey
    );

    return foundField?.name;
  };

  const handleInputChange = (name: string, value: any) => {
    const updatedProperties = customProperties.map((property) =>
      property.nameKey === name ? { ...property, value } : property
    );
    setCustomProperties(updatedProperties);
  };

  const Fields = customProperties?.map((customProperty) => {
    if (customProperty.type === ContactField_type.DATE)
      return (
        <CustomPropertyDatePicker
          key={customProperty.nameKey}
          nameKey={customProperty.nameKey}
          name={getPropertyName(customProperty.nameKey)}
          value={customProperty.value}
          onChange={handleInputChange}
        />
      );
    else if (customProperty.type === ContactField_type.OPTIONS)
      return (
        <CustomPropertyOptionSelect
          key={customProperty.nameKey}
          name={getPropertyName(customProperty.nameKey)}
          nameKey={customProperty.nameKey}
        />
      );
    else if (customProperty.type === ContactField_type.TIME)
      return (
        <CustomPropertyTimePicker
          key={customProperty.nameKey}
          name={getPropertyName(customProperty.nameKey)}
          nameKey={customProperty.nameKey}
          value={customProperty.value}
          onChange={handleInputChange}
        />
      );
    else if (customProperty.type === ContactField_type.TIMERANGE)
      return (
        <CustomPropertyTimeRangePicker
          name={getPropertyName(customProperty.nameKey)}
          key={customProperty.nameKey}
          nameKey={customProperty.nameKey}
          value={customProperty.value}
          onChange={handleInputChange}
        />
      );
    else if (customProperty.type === ContactField_type.DATERANGE)
      return (
        <CustomPropertyDateRangePicker
          key={customProperty.nameKey}
          name={getPropertyName(customProperty.nameKey)}
          nameKey={customProperty.nameKey}
          value={customProperty.value}
          onChange={handleInputChange}
        />
      );
    else
      return (
        <CustomPropertyInput
          type={customProperty.type}
          name={getPropertyName(customProperty.nameKey)}
          key={customProperty.nameKey}
          nameKey={customProperty.nameKey}
          value={customProperty.value}
          onChange={handleInputChange}
        />
      );
  });

  return (
    // <ContactSection sectionTitle={t('contactDetails.customFields')}>
    <Box sx={style.contactCustomFields}>
      {customContactFields && customProperties.length > 0 && Fields}
    </Box>

    // </ContactSection>
  );
};

export default CustomFieldSection;
