import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
// Redux
import {
  fetchContact,
  selectContactById,
  selectContactFetchStatus,
  setContactViewerData,
  setContactViewerFetchStatus,
  fetchContactFields,
  getContactFieldsFetchStatus,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { fetchLists } from 'ReduxToolkit/features/listsSlice/listsSlice';
import { getUsers } from 'ReduxToolkit/features/userSlice/userSlice';
import { useDispatch, useSelector } from 'react-redux';
// Components/ui
import { Box } from '@mui/material';
import Spinner from 'components/Spinners/Spinner';
// Types
import type { RootState } from 'ReduxToolkit/store';
import type ContactFieldsFetchData from 'ReduxToolkit/features/contactsSlice/types/ContactFieldsFetchData';
import { fetchLabels } from 'ReduxToolkit/features/labelSlice/labelSlice';

type Props = {
  children: React.ReactNode;
};

const ContactContainer = ({ children }: Props) => {
  const { contactId } = useParams();
  const dispatch = useDispatch();
  const contactFetchStatus = useSelector(selectContactFetchStatus);
  const contactFieldsFetchStatus = useSelector(getContactFieldsFetchStatus);

  let selectedContactById = useSelector((state: RootState) =>
    selectContactById(state, contactId)
  );

  useEffect(() => {
    // if (selectedContactById) {
    //   dispatch(setContactViewerData(selectedContactById));
    //   dispatch(setContactViewerFetchStatus('succeeded'));
    // } else {
    //   dispatch(fetchContact(contactId));
    // }

    dispatch(fetchContact(contactId));

    if (contactFieldsFetchStatus !== 'succeeded') {
      const fetchData: ContactFieldsFetchData = {
        filterBy: 'custom',
        fetchFor: 'contact',
      };

      dispatch(fetchContactFields(fetchData));
    }
    dispatch(fetchLists());
    dispatch(fetchLabels());
    dispatch(getUsers());
  }, [dispatch, contactId]);

  useEffect(() => {
    return () => {
      dispatch(setContactViewerData(null));
      dispatch(setContactViewerFetchStatus('idle'));
    };
  }, []);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      className={'contacts-paddingmd'}
      borderRadius={2}
    >
      <Box display={'flex'} flexDirection={'column'} width={'100%'} borderRadius={2}>
        {contactFetchStatus === 'loading' && <Spinner texto={''} />}
        {contactFetchStatus === 'succeeded' && (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            }}
          >
            {children}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ContactContainer;
