// Context provider
import BusinessCreateContextProvider from './context/BusinessCreateContextProvider';
// Components/ui
import { BusinessCreateContainer, App } from './components';

const BusinessCreate = () => {
  return (
    <BusinessCreateContextProvider>
      <BusinessCreateContainer>
        <App />
      </BusinessCreateContainer>
    </BusinessCreateContextProvider>
  );
};

export default BusinessCreate;
