import { useState, useContext } from "react"
// Translation
import { useTranslation } from "react-i18next"
// Redux
import { useSelector } from "react-redux"
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch"
// Slice
import {
  getCheckedContactsIds,
  getLabelsFetchStatus,
  selectAllLabels,
} from "ReduxToolkit/features/contactsSlice/contactsSlice"
import { updateContactLabels } from "ReduxToolkit/features/contactsSlice/contactsSlice"
//Components/ui
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
// Icons
import { Label as LabelIcon } from "@mui/icons-material"
// Types
import { ILabel } from "@trii/types/dist/Conversations"
import { UpdateContactLabels } from "ReduxToolkit/features/contactsSlice/types/UpdateContactLabels"
// Context
import { ContactBusinessTableContext } from "components/ContactsBusinessTable/context/ContactBusinessTableContext"

const Label = () => {
  const [selectedLabels, setSelectedLabels] = useState<ILabel[]>([]);
  const [labelIdsSelected, setLabelIdsSelected] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    handleCloseModal,
    modalTitle,
  } = useContext(ContactBusinessTableContext);
  const labels = useSelector(selectAllLabels);
  const labelsFetchStatus = useSelector(getLabelsFetchStatus);
  const isLoading = labelsFetchStatus === 'loading';
  const contactIds = useSelector(getCheckedContactsIds);

  const handleSelect = (label: ILabel) => {
    const labelIds = selectedLabels.map((label) => label.id);
    if (labelIds.includes(label.id)) {
      setLabelIdsSelected(labelIdsSelected.filter((oldLabelId) => oldLabelId !== label.id));
      setSelectedLabels(selectedLabels.filter((oldLabel) => oldLabel.id !== label.id));
    } else {
      setLabelIdsSelected([...labelIdsSelected, label.id]);
      setSelectedLabels([...selectedLabels, label]);
    }
  }

  const handleSave = () => {
    const action = modalTitle === t(
      'contactsView.contactBusinessTable.addLabel'
    ) ? 'addLabel' : 'removeLabel';
    selectedLabels.map((label) => {
      const data = {
        ids: contactIds,
        action: action,
        labelId: label.id,
      } as UpdateContactLabels;
      dispatch(updateContactLabels(data));
    });
    handleCloseModal();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      {
        isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: '1em',
            }}
          >
            <CircularProgress
              color="primary"
              size={30}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
              width: '100%',
            }}
          >
            {labels && labels.length > 0 && labels.map((label, i) => (
              <ListItem
                key={label.id}
                onClick={() => handleSelect(label)}
                sx={{
                  padding: 0,
                }}
              >
                <ListItemButton
                  sx={{
                    borderBottom: (theme) => i === labels.length - 1 ? 'none' : `1px solid ${theme.palette.grey[300]}`,
                  }}
                >
                  <Checkbox
                    checked={labelIdsSelected.includes(label.id)}
                    color="primary"
                    disableRipple
                    size="small"
                  />
                  <ListItemIcon>
                    <LabelIcon
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={label.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        )
      }
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '.5rem',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: ".5rem",
          }}
          onClick={handleCloseModal}
        >
          {t('global.cancel')}
        </Button>
        <Button
          variant="contained"
          color='primary'
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: ".5rem",
          }}
          onClick={handleSave}
          disabled={selectedLabels.length === 0}
        >
          {t('global.save')}
        </Button>
      </Box>
    </Box>
  )
}

export default Label