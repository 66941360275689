// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSegmentsCreateStatus,
  setSegmentCreateStatus,
} from 'ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Components/ui
import { Snackbar, Alert } from '@mui/material';
// Types
import { SyntheticEvent } from 'react';

const SegmentsErrorAlert = () => {
  const dispatch = useDispatch();
  const createSegmentStatus = useSelector(selectSegmentsCreateStatus);
  const isThereError = typeof createSegmentStatus === 'object';

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setSegmentCreateStatus('idle'));
  };

  return (
    <Snackbar open={isThereError} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {typeof createSegmentStatus === 'object' && createSegmentStatus.error}
      </Alert>
    </Snackbar>
  );
};

export default SegmentsErrorAlert;
