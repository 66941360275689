import { BusinessEditContext } from './BusinessEditContext';
// Redux
import { useSelector } from 'react-redux';
import { selectBusinessData } from 'ReduxToolkit/features/businessSlice/selectors';
// Types
import type { ReactNode } from 'react';
import type BusinessEditContextType from './types/BusinessEditContextType';
// Hook
import useEditBusiness from '../hooks/useEditBusiness/useEditBusiness';

type Props = {
  children: ReactNode;
};

const BusinessEditContextProvider = ({ children }: Props) => {
  const business = useSelector(selectBusinessData);
  const editBusiness = useEditBusiness(business || null);

  const contextValue: BusinessEditContextType = {
    editBusiness,
  };

  return (
    <BusinessEditContext.Provider value={contextValue}>
      {children}
    </BusinessEditContext.Provider>
  );
};

export default BusinessEditContextProvider;
