import { useContext, useState } from 'react';
// Context
import { BusinessCreateContext } from 'features/Views/BusinessCreate/context/BusinessCreateContext';
import { BusinessEditContext } from 'features/Views/BusinessEdit/context/BusinessEditContext';
// Components/ui
import { CountrySelect, WhatsAppToggle } from './components';
import { WarningsDisplay } from 'components/ContactCreateEditAppBody/components/InformationSection/layout';
import { Box } from '@mui/material';
import {
  InformationItemContainer,
  NoteDeleteButton,
  InformationItemInputsContainer,
} from 'components/ContactCreateEditAppBody/components/InformationSection/layout';
import phoneStyle from './style';
import PhoneInput from 'components/ContactCreateEditAppBody/components/InformationSection/layout/PhoneInput';
// Types
import { NewAddress } from 'types/NewAddress';

type Props = {
  data: NewAddress;
};

const Phone = ({ data }: Props) => {
  const mode: 'edit' | 'create' = window.location.pathname.includes('edit')
    ? 'edit'
    : 'create';
  const { editBusiness } = useContext(BusinessEditContext);
  const { business } = useContext(BusinessCreateContext);
  const editBusinessActions = editBusiness.action;
  const createBusinessActions = business.action;
  const [warnings, setWarnings] = useState<string[]>([]);

  return (
    <InformationItemContainer>
      <InformationItemInputsContainer>
        <Box sx={phoneStyle.numberCountryContainer}>
          <CountrySelect />
          <Box sx={phoneStyle.adressInputBox}>
            <PhoneInput
              data={data}
              mode={mode}
              setWarnings={setWarnings}
              onChange={
                mode === 'edit'
                  ? editBusinessActions.handleAddressFieldChange
                  : createBusinessActions.handlePhoneNumbersNumberFieldChange
              }
            />
           {warnings.length > 0 && (
              <Box sx={phoneStyle.warningMessage}>
                <WarningsDisplay warnings={warnings} />
              </Box>
            )}
          </Box>
        </Box>
        <NoteDeleteButton
          mode={mode}
          addressData={data}
          setWarnings={setWarnings}
          handleNoteFieldChange={
            mode === 'edit'
              ? editBusinessActions.handleNoteFieldChange
              : createBusinessActions.handleNoteFieldChange
          }
          handleDeleteAddress={
            mode === 'edit'
              ? editBusinessActions.handleDeleteAddress
              : createBusinessActions.handleDeleteAddress
          }
          isDeletingAddress={mode === 'edit' && editBusiness.state.isDeletingAddress}
          replaceAddress={mode === 'edit' && editBusinessActions.replaceAddress}
          addWhatsappVerified={mode === "edit" && editBusinessActions.addWhatsappVerified}
      
        />
      </InformationItemInputsContainer>
    </InformationItemContainer>
  );
};

export default Phone;
