import { Box, Card, CardActionArea, Theme, Typography } from '@mui/material'
import React from 'react'
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Buttons = () => {
    const {t} = useTranslation()
    return (
        <Box
            component={Card}
            borderRadius={2}
            width={"fit-content"}
            data-aos={'fade-up'}
            marginRight="15px"
            minWidth={"225px"}
            sx={{backgroundColor: (theme: Theme) => theme.palette.background.default}}
        >
            <CardActionArea>
                <NavLink to="/a/contacts/settings/buttons" >

                    <Box display={'flex'} flexDirection={'column'} justifyContent="center" alignItems={"center"}
                        padding={4} sx={{ color: (theme: Theme) => theme.palette.text.primary }}
                    >
                        <Box
                            marginBottom={4}

                        >
                            <RecentActorsIcon sx={{ fontSize: "2.5rem" }} />
                        </Box>
                        <Typography variant={'body2'}>{t("settingsView.buttons")}</Typography>
                    </Box>
                </NavLink>

            </CardActionArea>
        </Box>
    )
}

export default Buttons