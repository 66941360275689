import React, { useContext, useEffect, useState } from 'react';
import { Iprops } from './types/types';
import { StyleContext } from '../../../../../../../../../style/styleProvider';
import { ButtonsListContext } from '../../context/ButtonListContext';
//Sort lib
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
// Types
import type { ButtonsListContextType } from '../../context/types';
import { Style } from '../../../../../../../../../style/types/Style';
// Components/ui
import { Box, Fade, Typography, IconButton, Stack } from '@mui/material';
// Icons
import { Delete, Edit, DragIndicator } from '@mui/icons-material';

const ButtonItem = ({ data }: Iprops) => {
  const { fontSize, fontWeight, shadow } = useContext<Style>(StyleContext);
  const { openEdit, openDelete } = useContext(
    ButtonsListContext
  ) as ButtonsListContextType;
  const [show, setShow] = useState(false);
  // Sort lib
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: data.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade style={{ transitionDuration: '750ms' }} in={show}>
      <Box
        ref={setNodeRef}
        style={style}
        {...attributes}
        className="py-2"
        sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            //@ts-ignore
            backgroundColor: (theme) => theme.palette.dropdownBox,
            borderRadius: '4px',
            color: (theme) => theme.palette.text.primary,
          }}
        >
          <Box
            className="contacts-d-flex contacts-justify-content-between contacts-w-100"
            alignItems={'center'}
          >
            <>
              <Box sx={{ cursor: 'pointer' }} {...listeners}>
                <DragIndicator
                  sx={{
                    color: 'color.icons',
                    fontSize: '1.5rem',
                    marginRight: '10px',
                  }}
                />
              </Box>
              <Box
                className="contacts-mr-auto contacts-py-1 contacts-pr-4 pl-2"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box display={'flex'} alignItems="center">
                  <Typography fontWeight={fontWeight.normal} fontSize={fontSize.sm}>
                    {data.name}
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="end"
                justifyContent="space-evenly"
              >
                <Stack direction="row">
                  <IconButton
                    onClick={() => openEdit(data)}
                    sx={{ color: 'primary.main' }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    sx={{ color: 'error.main' }}
                    onClick={() => openDelete(data)}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              </Box>
            </>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default ButtonItem;
