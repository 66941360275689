import {
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, FormControlLabel, Radio, Typography } from '@mui/material';
// Context
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';
import moment from 'moment';

const FORMAT_DATE = 'DD/MM/YYYY';

interface ContactProps {
  dateContact: Date;
  icon: React.ReactNode;
  contactId: string;
  isMerged?: boolean;
  setBirthDateSelected: Dispatch<SetStateAction<boolean>>;
}

const BirthDate = ({
  dateContact,
  icon,
  contactId,
  isMerged = false,
  setBirthDateSelected,
}: ContactProps) => {
  const { birthDate, handleSelectDate, triggerSelectAllId, setTriggerSelectAllId } =
    useContext(DuplicateContext);
  const { t } = useTranslation();
  const [date, setDate] = useState<string>('');
  const today = moment().format(FORMAT_DATE);

  const birthDateRadioRef = useRef(null);

  const handleSelected = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    if (dateContact) {
      // @ts-ignore
      setBirthDateSelected(e.target.checked);
      handleSelectDate(dateContact, contactId);
    }
  };

  useEffect(() => {
    if (!isMerged) {
      if (dateContact) {
        setDate(moment(dateContact).format(FORMAT_DATE));
      } else {
        setDate('');
      }
    } else {
      if (birthDate && birthDate.value) {
        setDate(moment(birthDate?.value).format(FORMAT_DATE));
      }
    }
  }, [dateContact, birthDate, isMerged]);

  useEffect(() => {
    if (triggerSelectAllId !== '' && triggerSelectAllId === contactId) {
      setTriggerSelectAllId('');
      birthDateRadioRef.current?.click();
    }
  }, [triggerSelectAllId, contactId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'max-content',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Box
        sx={{
          width: '100%',
          borderBottom: '1px solid',
          borderColor: (theme) => theme.palette.divider,
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="semi-bold"
          color={'text.disabled'}
        >
          {t('duplicatesView.contactInfo.birthday')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: 'max-content',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 'max-content',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <FormControlLabel
            control={
              !isMerged ? (
                <Radio
                  size="small"
                  checked={birthDate?.contactId === contactId}
                  ref={birthDateRadioRef}
                />
              ) : (
                <></>
              )
            }
            onClick={(e) => !isMerged && handleSelected(e)}
            disabled={today == date || !dateContact}
            label={
              <Box display="flex" alignItems="center" width="240px" gap={1}>
                {icon}
                <Typography
                  variant="subtitle1"
                  fontWeight="semi-bold"
                  fontSize={14}
                  color={'text.disabled'}
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    cursor: 'default',
                  }}
                >
                  {date}
                </Typography>
              </Box>
            }
            sx={{
              width: '100%',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BirthDate;
