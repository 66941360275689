// Types
import { UseImageType } from 'hooks/useImage';

export const imageInputInitialDef: UseImageType = {
  inputAtributes: {
    onChange: () => {},
    ref: { current: null },
    type: '',
  },
  action: {
    uploadImage: () => {},
    deleteImage: () => {},
  },
  imageUrl: '',
  removeParams: () => '',
};
