import { useContext } from 'react';
// Context
import { ContactCreateContext } from '../../context/ContactCreateContext';
// Components
import { ContactCreateEditAppHeader, ContactCreateEditAppBody } from 'components';
import { CreateButton } from './components';

const App = () => {
  const { contact } = useContext(ContactCreateContext);

  return (
    <>
      <ContactCreateEditAppHeader
        mode="create"
        navigateBackPath="/a/contacts/contacts"
        contact={contact}
      >
        <CreateButton />
      </ContactCreateEditAppHeader>
      <ContactCreateEditAppBody mode="create" contact={contact} />
    </>
  );
};

export default App;
