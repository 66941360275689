import React, { useContext, useEffect, useState } from 'react';
// import { Contacts } from '@trii/types';
// components/ui
import { StyleContext } from '../../../../../../../../../style/styleProvider';
// Types
import { Style } from '../../../../../../../../../style/types/Style';
import { IconButton, Stack, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const SubscriptionItem = ({ item, openEdit, openDelete }) => {
  const { fontSize, fontWeight, shadow } = useContext<Style>(StyleContext);
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade style={{ transitionDuration: '750ms' }} in={show}>
      <Box className="py-2" sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            width: '100%',
            backgroundColor: "dropdownBox",
            display: 'flex',
            boxShadow: shadow.md,
            borderRadius: 1,
            color: (theme: Theme) => theme.palette.text.primary,
          }}
          // gridcolumngap={{ md: '7rem', lg: '13rem' }}
        >
          <Box
            className="contacts-d-flex contacts-justify-content-between contacts-w-100"
            alignItems={'center'}
          >
            <Box sx={{ cursor: 'pointer' }}>
              <DragIndicatorIcon></DragIndicatorIcon>
            </Box>
            <Box
              className="contacts-mr-auto contacts-py-1 contacts-pr-4 pl-2"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography
                fontWeight={fontWeight.semibold}
                fontSize={fontSize.sm}
                sx={{ marginRight: '20px',minWidth: "150px", }}
                
              >
                {item.name}
              </Typography>
              <Typography fontWeight={fontWeight.normal} fontSize={fontSize.sm}>
                {item.details}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="end"
              justifyContent="space-evenly"
            >
              <Stack direction="row">
                <IconButton
                  onClick={() => openEdit(item)}
                  sx={{ color: 'primary.main' }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'error.main' }}
                  onClick={() => openDelete(item)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default SubscriptionItem;
