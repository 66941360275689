// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import { imageInputInitialDef } from 'utils/imageInputInitialDef';
import { multipleSelectInitialDef } from 'utils/multipleSelectInitialDef';
import { selectInputInitialDef } from 'utils/selectInputInitialDef';

export const initialDef = {
  action: {
    setCustomProperties: () => {},
    getEditedContact: () => {},
    handleAddressFieldChange: () => {},
    handleNoteFieldChange: () => {},
    handleDeleteAddress: () => {},
    addPhone: () => {},
    addEmail: () => {},
    addWhatsapp: () => {},
    addWhatsappVerified: () => {},
    addBusiness: () => {},
    replaceAddress: () => {},
    setBirthDate: () => {},
    setSelectedBusiness: () => {},
  },
  field: {
    birthDate: null,
    emails: [],
    phoneNumbers: [],
    imsWhatsapp: [],
    imsFacebooks: [],
    imsWebchats: [],
    imsInstagrams: [],
    contactFirstname: fieldInitialDef,
    contactLastname: fieldInitialDef,
    contactImage: imageInputInitialDef,
    contactListSelectState: selectInputInitialDef,
    contactPropietary: selectInputInitialDef,
    accountName: fieldInitialDef,
    homeAddress: fieldInitialDef,
    secondaryHomeAddress: fieldInitialDef,
    city: fieldInitialDef,
    secondaryCity: fieldInitialDef,
    state: fieldInitialDef,
    secondaryState: fieldInitialDef,
    postalCode: fieldInitialDef,
    secondaryPostalCode: fieldInitialDef,
    country: fieldInitialDef,
    secondaryCountry: fieldInitialDef,
    customProperties: [],
    labelMultipleSelect: multipleSelectInitialDef,
    selectedBusiness: [],
  },
  state: {
    isDeletingAddress: false || '',
  },
};
