export const style = {
    titleContainter: {
      display: 'flex',
      maxWidth: '40vw',
      marginLeft: '16px',    
    },
    businessTitle: {
        marginLeft: '15px',
        fontWeight: 'bold'
    }
  };
  