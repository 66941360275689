import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
// Icons
import { LocationOn } from '@mui/icons-material';
// Types
import { IContactPostalAddress } from '@trii/types/dist/Contacts';
// Context
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';

interface Props {
  address: IContactPostalAddress;
  setAddressSelected: Dispatch<SetStateAction<boolean>>;
  contactId: string;
  showTitle?: boolean;
  isMerged?: boolean;
}

const Address = ({
  address,
  setAddressSelected,
  contactId,
  showTitle = true,
  isMerged = false,
}: Props) => {
  const { t } = useTranslation();
  const {
    address1,
    address2,
    compareAddress,
    handleSelectContactPostalAddress,
    triggerSelectAllId,
  } = useContext(DuplicateContext);
  const { street, city, state, zipcode, country } = address || {};
  const [addressText, setAddressText] = useState<string>();

  const checkBoxRef = useRef(null);

  const handleSelectAddress = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    if (street || city || state || zipcode || country) {
      // @ts-ignore
      const isChecked = e.target.checked;
      setAddressSelected(isChecked);
      handleSelectContactPostalAddress(address, isChecked, contactId);
    }
  };

  useEffect(() => {
    let newAddress = '';
    if (street) {
      newAddress += street;
    }
    if (city) {
      newAddress += `, ${city}`;
    }
    if (state) {
      newAddress += `, ${state}`;
    }
    if (zipcode) {
      newAddress += `, ${zipcode}`;
    }
    if (country) {
      newAddress += `, ${country}`;
    }
    setAddressText(newAddress);
  }, [address]);

  useEffect(() => {
    if (
      triggerSelectAllId !== '' &&
      triggerSelectAllId === contactId &&
      checkBoxRef.current &&
      !checkBoxRef.current.checked &&
      (street || city || state || zipcode || country)
    ) {
      setTimeout(() => {
        checkBoxRef.current.click();
      }, 0);
    }
  }, [triggerSelectAllId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'max-content',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {showTitle && (
        <Box
          sx={{
            width: '100%',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight="semi-bold"
            color={'text.disabled'}
          >
            {t('duplicatesView.contactInfo.address')}
          </Typography>
        </Box>
      )}
      {
        <Box
          sx={{
            width: '100%',
            height: 'max-content',
            display: 'flex',
            alignItems: 'start',
            gap: 1,
          }}
        >
          <FormControlLabel
            control={
              !isMerged ? (
                <Checkbox
                  size="small"
                  checked={
                    compareAddress(address, address1) ||
                    compareAddress(address, address2)
                  }
                  ref={checkBoxRef}
                />
              ) : (
                <></>
              )
            }
            //@ts-ignore
            onClick={(e) => !isMerged && address && handleSelectAddress(e)}
            disabled={street || city || state || zipcode || country ? false : true}
            label={
              <Box display="flex" alignItems="center" maxWidth="240px">
                <LocationOn
                  fontSize="small"
                  sx={{
                    mt: 0.5,
                    color: (theme) => theme.palette.primary.dark,
                  }}
                />
                <Typography
                  variant="subtitle1"
                  fontWeight="semi-bold"
                  fontSize={14}
                  color={'text.disabled'}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {addressText}
                </Typography>
              </Box>
            }
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          />
        </Box>
      }
    </Box>
  );
};

export default Address;
