import React from 'react';
// components/ui
import LoadingButton from '@mui/lab/LoadingButton';
import AddBoxIcon from '@mui/icons-material/AddBox';

const UploadButton = ({ onChange, isLoading }) => {
  console.log(isLoading);

  return (
    <LoadingButton
      sx={{ mb: 2 }}
      component="label"
      variant="contained"
      startIcon={<AddBoxIcon />}
      loadingPosition="start"
      loading={isLoading}
    >
      {isLoading ? 'Loading selected file' : 'Select File'}
      <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        hidden
        onChange={(e) => onChange(e)}
      />
    </LoadingButton>
  );
};

export default UploadButton;
