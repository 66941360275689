import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  Typography,
} from '@mui/material';
import ActionButtons from './components/ActionButtons';
import { FilesCardContext } from '../../context/FilesCardContext';

const DeleteFileModal = () => {
  const { t } = useTranslation();
  const {
    isModalDeleteFileOpen,
    closeModalDeleteFile,
    handleDeleteFile,
    fileIdToDelete,
  } = useContext(FilesCardContext);

  const theme = useTheme();
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '50%',
          //@ts-ignore
          backgroundColor: theme.palette.background.panel,
          backgroundImage: 'unset',
        },
      }}
      maxWidth={false}
      open={isModalDeleteFileOpen}
      onClose={closeModalDeleteFile}
    >
      <DialogTitle>{t('global.delete')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography>{t('contactsView.files.deleteFile')}</Typography>
      </DialogContent>
      <ActionButtons
        closeEventPopUp={closeModalDeleteFile}
        handleDeleteFile={handleDeleteFile}
        fileId={fileIdToDelete}
      />
    </Dialog>
  );
};

export default DeleteFileModal;
