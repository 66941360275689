// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { BusinessSection } from 'components/BusinessCreateEditAppBody/layout';
import {
  Mails,
  Phones,
  WebChat,
  Facebook,
  Whatsapps,
  Instagram,
} from './components';
// Types
import type UseEditBusiness from 'features/Views/BusinessEdit/hooks/useEditBusiness/types/UseEditBusiness';
import type UseCreateBusiness from 'features/Views/BusinessCreate/hooks/useCreateBusiness/types/UseCreateBusiness';

interface Props {
  business: UseEditBusiness | UseCreateBusiness;
}

const InformationSection = ({ business }: Props) => {
  const { t } = useTranslation();
  const {
    imsFacebooks,
    imsInstagrams,
    imsWebchats,
    emails,
    phoneNumbers,
    imsWhatsapp,
  } = business.field;
  const { addEmail, addPhone, addWhatsapp } = business.action;

  return (
    <BusinessSection sectionTitle={t('businessDetails.businessInformation')}>
      <Phones handleAdd={addPhone} phoneNumbers={phoneNumbers} />
      <Mails handleAdd={addEmail} emails={emails} />
      <Whatsapps handleAdd={addWhatsapp} whatsapps={imsWhatsapp} />
      <Facebook />
      <Instagram />
      <WebChat />
    </BusinessSection>
  );
};

export default InformationSection;
