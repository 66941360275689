// Types
import FilterOption from '../types/FilterOption';
import {
  Segment_Filter_Details,
  Segment_Filter_Account,
  Segment_Filter_Geography,
} from '@trii/types/dist/Contacts';

export const filterOptions: FilterOption[] = [
  {
    category: 'CONTACT DETAILS',
    data: [
      { ...Segment_Filter_Details[0], name: 'Name' },
      { ...Segment_Filter_Details[1], name: 'Email Address' },
    ],
  },
  {
    category: 'GEOGRAPHY',
    data: [
      { ...Segment_Filter_Geography[0], name: 'Country' },
      { ...Segment_Filter_Geography[1], name: 'City' },
    ],
  },
  {
    category: 'ACCOUNT DETAILS',
    data: [
      { ...Segment_Filter_Account[0], name: 'Account Name' },
      { ...Segment_Filter_Account[1], name: 'Account Email' },
      { ...Segment_Filter_Account[2], name: 'Account number of employees' },
    ],
  },
];
