// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import { imageInputInitialDef } from 'utils/imageInputInitialDef';
import { multipleSelectInitialDef } from 'utils/multipleSelectInitialDef';
import { selectInputInitialDef } from 'utils/selectInputInitialDef';

export const initialDef = {
  action: {
    setCustomProperties: () => {},
    getBusiness: () => {},
    handlePhoneNumbersNumberFieldChange: () => {},
    handleEmailsEmailFieldChange: () => {},
    handleWhatsappsAddressFieldChange: () => {},
    handleNoteFieldChange: () => {},
    handleDeleteAddress: () => {},
    addPhone: () => {},
    addWhatsapp: () => {},
    addEmail: () => {},
  },
  field: {
    name: fieldInitialDef,
    contactImage: imageInputInitialDef,
    contactPropietary: selectInputInitialDef,
    imsFacebooks: [],
    imsWebchats: [],
    imsInstagrams: [],
    imsWhatsapp: [],
    emails: [],
    labelMultipleSelect: multipleSelectInitialDef,
    phoneNumbers: [],
    homeAddress: fieldInitialDef,
    secondaryHomeAddress: fieldInitialDef,
    city: fieldInitialDef,
    secondaryCity: fieldInitialDef,
    state: fieldInitialDef,
    secondaryState: fieldInitialDef,
    postalCode: fieldInitialDef,
    secondaryPostalCode: fieldInitialDef,
    country: fieldInitialDef,
    secondaryCountry: fieldInitialDef,
    customProperties: [],
  },
  selectedContacts: [],
  setSelectedContacts: () => {},
};
