const subsectionBodyContainerStyle = {
  container: {
    width: '100%',
    // border: '1px solid lightgray',
    borderRadius: 1,
    px: 1.5,
    py: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

export default subsectionBodyContainerStyle;
