import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleContext } from '../../../../../../../../../style/styleProvider';
import { Contacts } from '@trii/types';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  selectWhatsAppContactsSyncStatus,
  syncWhatsAppContacts,
  selectGoogleAccountData,
  selectFetchSyncConfigStatus,
} from '../../../../../../../../../ReduxToolkit/features/googleSyncSlice/googleSyncSlice';
// Types
import type { RequestStatus } from '../../../../../../../../../types/reduxTypes';
import { Style } from '../../../../../../../../../style/types/Style';
// Components/ui
import { Typography } from '@mui/material';
import SyncSection from '../SyncSection';
import { LoadingButton } from '@mui/lab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function WhatsAppSyncSection() {
  const { t } = useTranslation();
  const { fontSize } = useContext<Style>(StyleContext);
  const dispatch = useDispatch();
  const whatsAppContactsSyncStatus = useSelector(selectWhatsAppContactsSyncStatus);
  const fetchSyncConfigStatus: RequestStatus = useSelector(
    selectFetchSyncConfigStatus
  );
  const googleAccountData: Contacts.GoogleAccountInfo = useSelector(
    selectGoogleAccountData
  );
  const isLoading =
    whatsAppContactsSyncStatus === 'loading' || fetchSyncConfigStatus === 'loading';

  const handleSync = async () => {
    await dispatch(syncWhatsAppContacts());
  };

  return (
    <SyncSection>
      <Typography
        fontSize={fontSize.sm}
        sx={{
          marginRight: '20px',
        }}
      >
        {t('settingsView.syncManuallyWhatsApp')}
      </Typography>
      <LoadingButton
        startIcon={<WhatsAppIcon />}
        variant="contained"
        disabled={isLoading || googleAccountData?.name === undefined}
        loading={isLoading}
        size="small"
        onClick={handleSync}
      >
        {t('settingsView.sync')}
      </LoadingButton>
    </SyncSection>
  );
}

export default WhatsAppSyncSection;
