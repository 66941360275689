import { useContext } from 'react';
import { useParams } from 'react-router-dom';
// Context
import { ContactEditContext } from '../../context/ContactEditContext';
// Components
import { SaveButtonGroup } from './components';
import { ContactCreateEditAppBody, ContactCreateEditAppHeader } from 'components';

const App = () => {
  const mode = window.location.pathname.includes('business')
    ? 'business'
    : 'contact';
  const navigateBackPath =
    mode === 'business' ? '/a/contacts/business' : '/a/contacts/contacts';
  const { editContact } = useContext(ContactEditContext);
  const { contactId } = useParams();
  return (
    <div>
      <ContactCreateEditAppHeader
        mode="edit"
        navigateBackPath={`${navigateBackPath}/${contactId}`}
        contact={editContact}
        contactId={contactId}
      >
        <SaveButtonGroup contactId={contactId} />
      </ContactCreateEditAppHeader>
      <ContactCreateEditAppBody mode="edit" contact={editContact} />
    </div>
  );
};

export default App;
