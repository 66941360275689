import { createContext } from 'react';
// Types
import { FilesCardContextType } from './types/FilesCardContextType';
// Utils
import { fileHandlerInitialDef } from 'utils/fileHandlerInitialDef';

export const FilesCardContext = createContext<FilesCardContextType>({
  fileHandler: fileHandlerInitialDef,
  columns: [],
  sortByColumn: () => {},
  openModalDeleteFile: () => {},
  closeModalDeleteFile: () => {},
  isModalDeleteFileOpen: false,
  fileIdToDelete: '',
  handleDeleteFile: () => {}
});
