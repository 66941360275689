import { useContext, useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { StyleContext } from 'style/styleProvider';
// Types
import { IList } from '@trii/types/dist/Contacts';
import { Style } from 'style/types/Style';
// Components/ui
import { Box, Typography, Fade } from '@mui/material';
import { ActionButtons } from './components';
// Moment
import moment from 'moment';

const DATE_FORMAT = 'DD/MM/YYYY';

interface IListItemProps {
  data: IList;
}

const ListItem = ({ data }: IListItemProps) => {
  const [show, setShow] = useState<boolean>(false);
  const { fontSize, fontWeight, shadow } = useContext<Style>(StyleContext);
  const { t } = useTranslation();

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <Box
        className="panel-inner contacts-my-0"
        display={'flex'}
        boxShadow={shadow.md}
        px={{ md: '1rem', lg: '1rem' }}
        sx={{
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.dropdownBox,

          color: (theme) => theme.palette.text.primary,
          borderRadius: '4px',
        }}
      >
        <Box className="contacts-d-flex contacts-justify-content-between contacts-w-100">
          <Box className="d-inline-block contacts-mr-auto contacts-py-1 contacts-pr-4">
            <Typography
              fontWeight={fontWeight.semibold}
              fontSize={fontSize.sm}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {data.name}
            </Typography>
            <br></br>
            <Typography
              fontWeight={fontWeight.normal}
              fontSize={fontSize.sm}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {t('manageList.contacts', { count: data.count })}
            </Typography>
            <Typography
              fontWeight={fontWeight.normal}
              fontSize={fontSize.xs}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {t('manageList.createdAt', {
                date: moment(data.createdAt).format(DATE_FORMAT),
              })}
            </Typography>
          </Box>
          <ActionButtons list={data} />
        </Box>
      </Box>
    </Fade>
  );
};

export default ListItem;
