// Types
import { ReactNode } from 'react';
// Components/ui
import informationItemContainerStyle from './style';
import { Box } from '@mui/material';

interface Props {
  children: ReactNode;
}

const InformationItemContainer = ({ children }: Props) => {
  return <Box sx={informationItemContainerStyle.container}>{children}</Box>;
};

export default InformationItemContainer;
