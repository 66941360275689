import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  sectionMenuOpenDetails,
  sectionMenuOpenNotes,
  sectionMenuOpenFiles,
  sectionMenuOpenTasks,
  sectionMenuOpenActivities,
  sectionMenuOpenDiffusion,
  sectionMenuOpenRepair,
  sectionMenuOpenDebts,
  sectionMenuOpenTickets,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
const SectionsMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mockList = [
    { name: t('contactsView.details'), id: 'details' },
    { name: t('contactsView.notes.title'), id: 'notes' },
    { name: t('contactsView.files.title'), id: 'files' },
    { name: t('contactsView.tasks.title'), id: 'tasks' },
    { name: t('contactsView.activities.title'), id: 'activities' },
    { name: t('contactsView.diffusion.title'), id: 'diffusion' },
    { name: t('contactsView.repair.title'), id: 'repair' },
    { name: t('contactsView.debts.title'), id: 'debts' },
    { name: 'Tickets', id: 'tickets' },
  ];
  const navCard = useCallback(
    (value) => {
      try {
        const anchor = document.querySelector('#' + value);
        switch (value) {
          case 'details':
            dispatch(sectionMenuOpenDetails(true));
            break;
          case 'notes':
            dispatch(sectionMenuOpenNotes(true));
            break;
          case 'files':
            dispatch(sectionMenuOpenFiles(true));
            break;
          case 'tasks':
            dispatch(sectionMenuOpenTasks(true));
            break;
          case 'activities':
            dispatch(sectionMenuOpenActivities(true));
            break;
          case 'diffusion':
            dispatch(sectionMenuOpenDiffusion(true));
            break;
          case 'repair':
            dispatch(sectionMenuOpenRepair(true));
            break;
          case 'debts':
            dispatch(sectionMenuOpenDebts(true));
            break;
          case 'tickets':
            dispatch(sectionMenuOpenTickets(true));
            break;
          default:
            dispatch(sectionMenuOpenDetails(true));
            break;
        }
        anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } catch (error) {}
    },
    [dispatch]
  ); //eslint-disable-line
  // Events.scrollEvent.register('begin', function(to, element) {
  //   console.log('begin', to, element);
  // });

  // Events.scrollEvent.register('end', function () {
  //   console.log("end", arguments);

  // });

  return (
    <Box
      width={'20%'}
      sx={{
        borderRight: '1px solid',
        borderColor: (theme) => theme.palette.divider,
        // maxHeight: "calc(100vh - 191px)",
        maxHeight: 'inherit',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 360,
          // @ts-ignore
          bgcolor: (theme) => theme.palette.background.panel,
        }}
      >
        <nav aria-label="secondary mailbox folders">
          <List>
            <ListSubheader
              sx={{
                fontSize: '16px',
                // @ts-ignore

                bgcolor: (theme) => theme.palette.background.panel,
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {t('contactsView.sections')}
            </ListSubheader>
            {mockList.map((value) => (
              <ListItem
                disablePadding
                sx={{ color: (theme) => theme.palette.text.primary }}
                key={value.id}
              >
                <ListItemButton
                  sx={{
                    py: 0,
                    pl: 3,
                    minHeight: 32,
                    // @ts-ignore
                    color: (theme) => theme.palette.color.secondary,
                  }}
                  onClick={() => navCard(value.id)}
                >
                  <ListItemText
                    primary={value.name}
                    primaryTypographyProps={{ fontSize: 14 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>
    </Box>
  );
};

export default SectionsMenu;
