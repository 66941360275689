import { useState, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  fetchContactsTableData,
  selectContactData,
  selectContacts,
  selectContactsFetchStatus,
  selectPagination,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Types
import { IContact } from '@trii/types/dist/Contacts';
import { Pagination } from 'ReduxToolkit/features/contactsSlice/types/Pagination';
import { UIEvent } from 'react';
import { Dispatch, SetStateAction } from 'react';

export const useLinkContactsInitialDef = {
  state: {
    isLoading: false,
    contactSelectedId: null,
    search: '',
  },
  action: {
    resetField: () => {},
    handleSearch: (value: string) => {},
    handleScroll: (e: UIEvent<HTMLElement>) => {},
    handleSelectContact: (contact: IContact) => {},
  },
  contactList: [],
};

export type UseLinkContactsType = {
  state: {
    isLoading: boolean;
    contactSelectedId: string;
    search: string;
  };
  action: {
    resetField: () => void;
    handleSearch: (value: string) => void;
    handleScroll: (e: UIEvent<HTMLElement>) => void;
    handleSelectContact: (contactId: IContact) => void;
  };
  contactList: IContact[];
};

export function useLinkContacts(contactId?: string): UseLinkContactsType {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const contactProfile = useSelector(selectContactData);
  const [contactSelectedId, setContactSelectedId] = useState<string>(
    contactId || null
  );
  const [contactList, setContactList] = useState<IContact[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isNextPage, setIsNextPage] = useState<boolean>(false);
  const contacts = useSelector(selectContacts);
  const contactsFetchStatus = useSelector(selectContactsFetchStatus);
  const pagination = useSelector(selectPagination);
  const isLoading = contactsFetchStatus === 'loading';

  const handleSearch = async (value: string) => {
    const newPagination: Pagination = {
      ...pagination,
      currentPage: 1,
      filter: [
        {
          value,
          column: 'name',
          condition: '',
        },
      ],
    };
    setSearch(value);
    setIsNextPage(false);
    setCurrentPage(1);
    setContactList([]);

    if (value) {
      await dispatch(fetchContactsTableData(newPagination));
    }
  };

  const handleSelectContact = (contact: IContact) => {
    setSearch(contact.name);
    setContactSelectedId(contact.id);
    setContactList([]);
  };

  const handleScroll = async (e: UIEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    const bottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    const newPagination: Pagination = {
      ...pagination,
      currentPage: 1,
      filter: [
        {
          value: search,
          column: 'name',
          condition: '',
        },
      ],
    };

    if (bottom) {
      setIsNextPage(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      await dispatch(fetchContactsTableData(newPagination));
    }
  };

  function resetField() {
    handleSelectContact(contactProfile as IContact);
  }

  useEffect(() => {
    if (contacts) {
      if (isNextPage) {
        setContactList([...contactList, ...contacts]);
      } else {
        setContactList(contacts);
      }
    }
  }, [contacts]);

  useEffect(() => {
    if (!contactId) handleSelectContact(contactProfile as IContact);
  }, []);

  return {
    action: {
      resetField,
      handleSearch,
      handleScroll,
      handleSelectContact,
    },
    state: { isLoading, contactSelectedId, search },
    contactList,
  };
}
