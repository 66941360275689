import { useContext } from 'react';
// Context
import { BusinessCreateContext } from '../../context/BusinessCreateContext';
// Components
import { BusinessCreateEditAppBody, BusinessCreateEditAppHeader } from 'components';
import { CreateButton } from './components';

const App = () => {
  const { business } = useContext(BusinessCreateContext);

  return (
    <div>
      <BusinessCreateEditAppHeader
        mode="create"
        navigateBackPath="/a/contacts/contacts"
        business={business}
      >
        <CreateButton />
      </BusinessCreateEditAppHeader>
      <BusinessCreateEditAppBody mode="create" business={business} />
    </div>
  );
};

export default App;
