const phoneStyle = {
  numberCountryContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '50%',
  },
  warningMessage: {
    // position: "absolute",
    // bottom: "-30px",
    marginTop: "10px"
  },
  adressInputBox: {
    position: "relative",
    width: "45%"
  }
};

export default phoneStyle;
