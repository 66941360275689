import axios from 'axios';
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import initRequestData from '../../../ReduxToolkit/functions/initRequestData';
import { RootState } from '../../store';
// Types
import { IInitialState, INewList, IEditListData } from './types';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { IList } from '@trii/types/dist/Contacts';

const initialState: IInitialState = {
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
    edit: 'idle',
  },
  data: [],
  selectedList: null,
};

export const fetchLists = createAsyncThunk<IList[]>(
  'lists/fetchLists',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };
    const { data } = await axios.get(`${URL_CONTACTS}/Lists`, config);

    return data;
  }
);

export const createList = createAsyncThunk(
  'lists/createList',
  async (newList: INewList, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const newListJSON = JSON.stringify(newList);
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${URL_CONTACTS}/Lists`, newListJSON, config);

    return data as IList;
  }
);

export const deleteList = createAsyncThunk(
  'lists/deleteList',
  async (listId: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    await axios.delete(`${URL_CONTACTS}/Lists/${listId}`, config);

    return listId;
  }
);

export const editList = createAsyncThunk(
  'lists/editList',
  async (editData: IEditListData, { dispatch }) => {
    const { listId, editedList } = editData;
    const editedListJson = JSON.stringify(editedList);
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.put(`${URL_CONTACTS}/Lists/${listId}`, editedListJson, config);

    return editData;
  }
);

export const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    selectListById: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const list = state.data.find((list) => list.id === id);
      state.selectedList = list;
    },
    removeSelectedList: (state) => {
      state.selectedList = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLists.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchLists.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchLists.rejected, (state, action) => {
        state.status.fetch = 'idle';
        console.log(action.error);
      })
      .addCase(createList.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createList.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.data.push(action.payload);
      })
      .addCase(deleteList.pending, (state, action) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteList.fulfilled, (state, action) => {
        const deletedListId = action.payload;

        state.status.delete = 'succeeded';
        state.data = state.data.filter((list) => list.id !== deletedListId);
      })
      .addCase(editList.pending, (state, action) => {
        state.status.edit = 'loading';
      })
      .addCase(editList.fulfilled, (state, action) => {
        const { editedList, listId } = action.payload;
        const editedListIndex = state.data.findIndex((list) => list.id === listId);

        state.status.edit = 'succeeded';
        state.data[editedListIndex].name = editedList.name;
        state.data[editedListIndex].description = editedList.description;
      });
  },
});

const selectLists = (state: RootState) => state.Lists;
export const selectListsData = createSelector(selectLists, (lists) => lists.data);
export const selectListsStatus = createSelector(
  selectLists,
  (lists) => lists.status.fetch
);
export const selectListsCreateStatus = createSelector(
  selectLists,
  (lists) => lists.status.create
);
export const selectSelectedList = createSelector(
  selectLists,
  (lists) => lists.selectedList
);
export const selectListsDeleteStatus = createSelector(
  selectLists,
  (lists) => lists.status.delete
);
export const selectListsEditStatus = createSelector(
  selectLists,
  (lists) => lists.status.edit
);

// Actions
export const { selectListById, removeSelectedList } = listsSlice.actions;

export default listsSlice.reducer;
