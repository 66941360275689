// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { StyledButton } from './styles';
import AddIcon from '@mui/icons-material/Add';
// Custom hooks
import { useActivitiesCardContext } from '../../hooks/useActivitiesCardContext';

const CreateEventButton = () => {
  const { t } = useTranslation();
  const { openCreateEventPopUp } = useActivitiesCardContext();

  return (
    <StyledButton
      variant="contained"
      color="primary"
      size="small"
      startIcon={<AddIcon />}
      onClick={openCreateEventPopUp}
    >
      {t('contactsView.activities.createEvent')}
    </StyledButton>
  );
};

export default CreateEventButton;
