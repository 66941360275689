import { IBusiness, IContact, IContactField } from '@trii/types/dist/Contacts';
import { IContactWithCheckbox } from '../types/IContactWithCheckBox';
import { TableContactField } from '../types/TableContactField';
import { IBusinessWithCheckbox } from '../types/IBusinessWithCheckBox';

const addContactCheckboxes = (
  contacts: IContact[],
  checkedContactsIds: string[]
): IContactWithCheckbox[] => {
  return contacts.map((contact: IContact) => {
    const contactWithCheckbox: IContactWithCheckbox = {
      ...contact,
      checked: checkedContactsIds.some(
        (checkedContactId) => checkedContactId === contact.id
      ),
    };
    return contactWithCheckbox;
  });
};
const addBusinessCheckboxes = (
  businesses: IBusiness[],
  checkedContactsIds: string[]
): IBusinessWithCheckbox[] => {
  return businesses.map((business: IBusiness) => {
    const businessWithCheckbox: IBusinessWithCheckbox = {
      ...business,
      checked: checkedContactsIds.some(
        (checkedContactId) => checkedContactId === business.id
      ),
    };
    return businessWithCheckbox;
  });
};
const fieldsToTableFields = (fields: IContactField[]): TableContactField[] => {
  const newContactFields = fields.map((contactField: IContactField) => {
    const tableContactField: TableContactField = {
      ...contactField,
      visible: contactField.isDefault && contactField.name !== 'Id' ? true : false,
      sort: false,
    };
    return tableContactField;
  });

  return newContactFields;
};

export default {
  addContactCheckboxes,
  addBusinessCheckboxes,
  fieldsToTableFields,
};
