import { useNavigate, useParams } from 'react-router-dom';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { useContext } from 'react';
import { SegmentsEditContext } from '../../../../../../context/SegmentsEditProvider';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  saveSegment,
  selectSegmentsEditStatus,
  selectSegmentsDeleteStatus,
} from '../../../../../../../../../ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Components/ui
import { Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { openDeletePanel, deletePanelOpen } = useContext(SegmentsEditContext);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const segmentsEditStatus = useSelector(selectSegmentsEditStatus);
  const segmentsDeleteStatus = useSelector(selectSegmentsDeleteStatus);

  const isLoading: boolean =
    segmentsEditStatus === 'loading' || segmentsDeleteStatus === 'loading';
  const isDeleting: boolean = segmentsDeleteStatus === 'loading';

  function handleSave() {
    dispatch(saveSegment());
  }

  function handleViewContacts() {
    navigate(`/a/contacts/contacts?segmentId=${params.id}`);
  }

  return (
    <Stack direction={'row'} gap={1} alignSelf={'flex-start'}>
      <LoadingButton
        disabled={isLoading}
        loading={isLoading}
        onClick={handleSave}
        size={'small'}
        variant="contained"
      >
        {t('global.save')}
      </LoadingButton>
      <Button
        size={'small'}
        variant="contained"
        sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}
        onClick={handleViewContacts}
      >
        {t('segmentsEdit.viewContacts')}
      </Button>
      <LoadingButton
        loading={isDeleting}
        disabled={deletePanelOpen || isLoading}
        onClick={openDeletePanel}
        size={'small'}
        variant="contained"
      >
        {t('global.delete')}
      </LoadingButton>
    </Stack>
  );
};

export default ActionButtons;
