// Components/ui
import { styled, Box, DialogContentText } from '@mui/material';

export const StyledBoxContainer = styled(Box)(() => ({
  display: 'flex',
  gap: 2,
  alignItems: 'center',
}));
export const StyledDialogContextText = styled(Box)(() => ({
  width: '15%',
}));
