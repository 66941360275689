// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Body } from './components';
import { SubsectionHeaderWithButton } from '../../layout';
// Types
import { NewAddress } from 'types/NewAddress';
import { Box } from '@mui/material';

type Props = {
  phoneNumbers: NewAddress[];
  handleAdd: () => void;
};

const Phones = ({ phoneNumbers, handleAdd }: Props) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '100%' }}>
      <SubsectionHeaderWithButton handleAdd={handleAdd} title={t('global.phones')} />
      <Body phoneNumbers={phoneNumbers} />
    </Box>
  );
};

export default Phones;
