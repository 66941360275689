// Utils
import axios from 'axios';
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
// Types
import { IBusiness, IContactField } from '@trii/types/dist/Contacts';
import { FieldsFilter } from '../contactsSlice/types/FieldsFilter';
import For from 'types/For';

const update = async (
  jwtToken: string,
  URL: string,
  businessJson: any,
  businessId: string
): Promise<IBusiness> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.put<IBusiness>(
    `${URL}/business/${businessId}`,
    businessJson,
    config
  );
  return response.data;
};

const create = async (jwtToken: string, URL: string, businessJson: string) => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.post(`${URL}/business`, businessJson, config);
  return response.data;
};

const fetchFields = async (
  jwtToken: string,
  URL: string,
  filterBy: FieldsFilter
): Promise<IContactField[]> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<IContactField[]>(
    `${URL}/ContactFields?filter=${filterBy}&for=business`,
    config
  );
  return response.data;
};

const fetchById = async (
  jwtToken: string,
  URL: string,
  businessId: string
): Promise<IBusiness> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<IBusiness>(
    `${URL}/business/${businessId}`,
    config
  );

  return response.data;
};

const updatePhoto = async (
  jwtToken: string,
  URL_MEDIA: string,
  photo: FormData,
  contactId: string
): Promise<string> => {
  const config = getRequestConfig.contentTypeFormData(jwtToken);
  const response = await axios.post<string>(
    `${URL_MEDIA}?module=contacts&folderType=avatars&contactId=${contactId}`,
    photo,
    config
  );

  return response.data;
};

const deletePhoto = async (
  jwtToken: string,
  URL: string,
  businessId: string
): Promise<void> => {
  const config = getRequestConfig.basic(jwtToken);
  await axios.delete(`${URL}/business/${businessId}/photo`, config);
};

export default {
  update,
  create,
  fetchById,
  updatePhoto,
  deletePhoto,
  fetchFields,
};
