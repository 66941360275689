import { Box, Card, CardActionArea, Theme, Typography } from '@mui/material'
import React from 'react'
import GoogleIcon from '@mui/icons-material/Google';
import { NavLink } from 'react-router-dom';
const GoogleSync = () => {
  return (
    <Box
      component={Card}
      borderRadius={2}
      width={"fit-content"}
      data-aos={'fade-up'}
      minWidth={"225px"}
      sx={{ backgroundColor: (theme: Theme) => theme.palette.background.default }}
    >
      <CardActionArea>
        <NavLink to="/a/contacts/settings/googlesync" >

          <Box display={'flex'} flexDirection={'column'} justifyContent="center" alignItems={"center"}
            padding={4} sx={{ color: (theme: Theme) => theme.palette.text.primary }}
          >
            <Box
              marginBottom={4}

            >
              <GoogleIcon sx={{ fontSize: "2.5rem" }} />
            </Box>
            <Typography variant={'body2'}>GoogleSync</Typography>
          </Box>
        </NavLink>
      </CardActionArea>
    </Box>
  )
}

export default GoogleSync