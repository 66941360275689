import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentsContext } from '../../../../../../context/SegmentsProvider';
// Components/ui
import { Box, TextField, Typography } from '@mui/material';

const Inputs = () => {
  const { t } = useTranslation();
  const { deleteSegmentNameField } = useContext(SegmentsContext);

  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <Typography
        variant="subtitle2"
        color={'text.primary'}
        sx={{ marginBottom: '15px' }}
      >
        {t('segments.deleteSegmentLabel')}
      </Typography>
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('segments.segmentName')}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        {...deleteSegmentNameField.attributes}
      />
    </Box>
  );
};

export default Inputs;
