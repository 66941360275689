import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsListContext } from '../../../../context/ButtonListContext.tsx';
// Redux
import { useSelector } from 'react-redux';
import { selectButtonsAddStatus } from '../../../../../../../../../../../ReduxToolkit/features/configSlice/configSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';

function CreateButtons() {
  const buttonAddStatus = useSelector(selectButtonsAddStatus);
  const { t } = useTranslation();
  const { handleCreateButton, handleDrawerClose, isEditing } =
    useContext(ButtonsListContext);
  const isButtonLoading = buttonAddStatus === 'loading';

  const handleAccept = () => {
    if (!isEditing) handleCreateButton();
  };
  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
      sx={{backgroundColor: "background.panel"}}
      width={"100%"}
      zIndex={1}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={handleDrawerClose}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loadingPosition="start"
        disabled={isButtonLoading}
        loading={isButtonLoading}
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.text.primary,
        }}
        onClick={handleAccept}
      >
        {t('global.create')}
      </LoadingButton>
    </Box>
  );
}

export default CreateButtons;
