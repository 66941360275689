import { useEffect, useMemo } from 'react';
import Router from './router';
// Context
import SubscriptionsListProvider from './features/Views/SettingsView/Settings/Subscriptions/Components/context/SubscriptionListContext';
import ButtonsListProvider from './features/Views/SettingsView/Integrations/Buttons/Components/ButtonsList/context/ButtonListContext';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import {
  selectedTheme,
  toggleTheme,
  selectThemeToggleStatus,
} from './ReduxToolkit/features/themeSlice';
import { createTheme } from '@mui/material';
import {
  fetchSpaceInfo,
  setSpaceInfo,
  setSpaceFetchStatus,
  setSpaceUrlContacts,
  setSpaceUrlMedia,
  setSpaceUrlSettings,
  setSpaceUrlCalendar,
  setSpaceUrlUser,
} from './ReduxToolkit/features/spaceSlice/spaceSlice';
import { setSession } from './ReduxToolkit/features/sessionSlice/sessionSlice';
// Components/ui
import { PageLoading } from './components';

import moment from 'moment';
import {
  selectUser,
  getUser,
  setUser,
} from './ReduxToolkit/features/userSlice/userSlice';

import './App.css';
// Types
import RootProps from './types/RootProps';
import { useLocation } from 'react-router-dom';
import { setPreviousPath } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import MuiComponentsConfig from './style/MuiComponentesConfig';

interface Props {
  rootProps: RootProps;
}

const App = ({ rootProps }: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const themeRedux = useSelector(selectedTheme);
  const theme = useMemo(() => MuiComponentsConfig(themeRedux), [themeRedux]);
  const themeToggleStatus = useSelector(selectThemeToggleStatus);

  useEffect(() => {
    dispatch(setPreviousPath(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (rootProps.spaceInfo && rootProps.userInfo) {
      const { spaceInfo, userInfo } = rootProps;

      dispatch(setSpaceInfo(spaceInfo));
      dispatch(setSpaceFetchStatus('succeeded'));
      dispatch(setSpaceUrlContacts(spaceInfo.id));
      dispatch(setSpaceUrlMedia(spaceInfo.id));
      dispatch(setSpaceUrlSettings(spaceInfo.id));
      dispatch(setSpaceUrlCalendar(spaceInfo.id));
      dispatch(setSpaceUrlUser(spaceInfo.id));
      dispatch(setUser(userInfo));
      dispatch(setSession());
    } else {
      dispatch(fetchSpaceInfo());
      dispatch(getUser());
      dispatch(setSession());
    }
  }, []);

  let userLang = document.documentElement.lang;
  useEffect(() => {
    userLang === 'En' ? moment.locale('En') : moment.locale('ES');
  }, [userLang]);

  useEffect(() => {
    if (user !== null) {
      dispatch(toggleTheme(user.theme));
    }
  }, [user, dispatch]);

  useEffect(() => {
    // Load the google script and append it to the document body
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!user || themeToggleStatus !== 'toggled') {
    return <PageLoading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <ButtonsListProvider>
        <SubscriptionsListProvider>
          <Router />
        </SubscriptionsListProvider>
      </ButtonsListProvider>
    </ThemeProvider>
  );
};

export default App;
