import { useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { NotesCardContext } from '../../context/NotesCardContext';
// Redux
import { useSelector } from 'react-redux';
import { selectNoteCreateStatus } from 'ReduxToolkit/features/noteSlice/noteSlice';
// Components/ui
import './InputAddNote.css';
import { Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

const InputAddNote = () => {
  const { noteField, handleCreateNote } = useContext(NotesCardContext);
  const { t } = useTranslation();
  const noteCreateStatus = useSelector(selectNoteCreateStatus);
  const isLoading = noteCreateStatus === 'loading';

  return (
    <Box
      className="input-add-note"
      sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
    >
      <TextField
        {...noteField.attributes}
        multiline
        fullWidth
        placeholder={t('contactsView.notes.addNote')}
      />
      <LoadingButton
        loading={isLoading}
        disabled={isLoading || noteField.attributes.value === ''}
        startIcon={<NoteAddIcon />}
        onClick={handleCreateNote}
        color="primary"
        variant="contained"
      >
        {t('global.add')}
      </LoadingButton>
    </Box>
  );
};

export default InputAddNote;
