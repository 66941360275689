import axios from 'axios';
// Types
import { IFile } from '@trii/types/dist/Contacts';
// Utils
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
import { AddFileRequestData } from '../types/AddFileRequestData';
import { UploadURLParams } from '../types/UploadURLParams';

const fetchFiles = async (jwtToken: string, URL: string): Promise<IFile[]> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<IFile[]>(URL, config);

  return response.data;
};
const uploadMedia = async (
  jwtToken: string,
  URL_MEDIA: string,
  media: FormData,
  URLParams?: UploadURLParams
): Promise<string> => {
  const config = getRequestConfig.contentTypeFormData(jwtToken);
  const UPLOAD_URL = URLParams
    ? `${URL_MEDIA}/upload?module=${URLParams.module}&folderType=${URLParams.folderType}&contactId=${URLParams.contactId}`
    : `${URL_MEDIA}/upload`;

  const response = await axios.post<string>(UPLOAD_URL, media, config);

  return response.data;
};
const addFile = async (
  jwtToken: string,
  URL: string,
  addFileRequestData: AddFileRequestData
): Promise<IFile> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.post<IFile>(URL, addFileRequestData, config);

  return response.data;
};

const deleteFile = async (jwtToken: string, URL: string): Promise<boolean> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.delete(URL, config);

  return response.status === 200;
};

export default { fetchFiles, addFile, deleteFile, uploadMedia };
