import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectAllLabels } from 'ReduxToolkit/features/labelSlice/labelSlice';
import {
  selectTag,
  setTag,
} from 'ReduxToolkit/features/contactsFilterSlice/contactsFilterSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Components/ui
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { style } from './style';

const TagSelect = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const labels = useSelector(selectAllLabels);
  const tag = useSelector(selectTag);

  const tagItems = labels.map((label) => (
    <MenuItem key={label.id} value={label.id}>
      {label.name}
    </MenuItem>
  ));

  return (
    <Box sx={style.container}>
      <FormControl size="small" sx={{ width: '100%' }}>
        <InputLabel>{t('global.tags')}</InputLabel>
        <Select
          fullWidth
          label={t('global.tags')}
          value={tag}
          onChange={(e) => dispatch(setTag(e.target.value))}
          MenuProps={{
            PaperProps: {
              sx: {
                //@ts-ignore
                backgroundColor: (theme) => theme.palette.background.panel,
                backgroundImage:
                  'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
              },
            },
          }}
        >
          {tagItems}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TagSelect;
