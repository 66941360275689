import { useContext } from 'react';
// Context
import { StyleContext } from 'style/styleProvider';
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useSelector } from 'react-redux';
import {
  selectContactFields,
  isContactPageChecked,
  isBusinessPageChecked,
  getContactFieldsFetchStatus,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { Column, TuneButton } from './components';
import { TableHead, Checkbox, TableCell, TableRow, Theme } from '@mui/material';

const Head = () => {
  const { color } = useContext(StyleContext);
  const { handleParentCheck, mode } = useContext(ContactBusinessTableContext);
  const columns = useSelector(selectContactFields);
  const checkedStatuses = useSelector(
    mode === 'contact' ? isContactPageChecked : isBusinessPageChecked
  );
  const contactFieldsFetchStatus = useSelector(getContactFieldsFetchStatus);

  const AtributeColumns = columns.map(
    (column, i) => column.visible && <Column key={i} data={column} />
  );

  return (
    <TableHead
      sx={{
        //@ts-ignore
        backgroundColor: (theme: Theme) => theme.palette.background.panelHeader,
        position: 'sticky',
        top: '0',
        zIndex: '1',
      }}
    >
      {columns.length > 0 && (
        <TableRow>
          <TableCell sx={{ width: '5%', padding: '0 16px' }}>
            <Checkbox
              checked={checkedStatuses === 'all'}
              indeterminate={checkedStatuses === 'some'}
              onChange={() => handleParentCheck(checkedStatuses)}
            />
          </TableCell>
          {contactFieldsFetchStatus === 'succeeded' && AtributeColumns}
          <TableCell
            align="center"
            sx={{
              width: '5%',
              padding: '0',
              borderLeft: `1px solid ${color.gray[300]}`,
            }}
          >
            <TuneButton />
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
};

export default Head;
