import React from 'react';
// Types
import { TableContactField } from 'ReduxToolkit/features/contactsSlice/types/TableContactField';
// Components/ui
import { FormControlLabel, Checkbox } from '@mui/material';

type Props = {
  columns: TableContactField[];
  handleOptionCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ColumnOptions = ({ columns, handleOptionCheck }: Props) => {
  return (
    <>
      {columns?.map((column, i) => (
        <FormControlLabel
          key={i}
          control={
            <Checkbox
              size="small"
              checked={column.visible}
              onChange={handleOptionCheck}
              id={column.name}
            />
          }
          label={column.name}
        />
      ))}
    </>
  );
};

export default ColumnOptions;
