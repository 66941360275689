import { useState, useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Types
import { ISegmentWithId } from 'ReduxToolkit/features/segmentsSlice/types';
//Components/ui
import { Box, Fade, Theme, Typography } from '@mui/material';
import { SegmentGroup, ContentSkeleton, AddSegmentButton } from './components';

interface ISegmentFiltersProps {
  dataReady: boolean;
  segmentData: ISegmentWithId;
}

const SegmentFilters = ({ dataReady, segmentData }: ISegmentFiltersProps) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  const Groups = segmentData?.filter?.groups?.map((group, index) => (
    <SegmentGroup key={group.id} index={index} groupData={group} />
  ));

  useEffect(() => {
    if (dataReady) {
      setShow(true);
    }
  }, [dataReady]);

  if (!dataReady) {
    return <ContentSkeleton />;
  }

  return (
    <Fade in={show}>
      <Box
        sx={{
          // @ts-ignore
          backgroundColor: (theme) => theme.palette.dropdownBox,
          borderRadius: 1,
          boxShadow: 1,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: 1,
        }}
      >
        <Typography
          fontWeight={'500'}
          sx={{ color: (theme: Theme) => theme.palette.text.primary }}
          mb={1}
        >
          {t('segmentsEdit.filters')}
        </Typography>
        {Groups}
        <AddSegmentButton />
      </Box>
    </Fade>
  );
};

export default SegmentFilters;
