import React from 'react';
//components/ui
import { TableCell, TableRow, Link, Typography, styled } from '@mui/material';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  height: 35,
}));

const DebtsRow = ({filesData, key}) => {
    

  return (
    <StyledTableRow key={key}>

      <TableCell sx={{ padding: '0 24px' }}>
        <Typography variant="body2" style={{ userSelect: 'none' }}>{filesData.management}</Typography>
      </TableCell>
      <TableCell sx={{ padding: '0 24px' }}>
        <Typography variant="body2" style={{ userSelect: 'none' }}>{filesData.amount}</Typography>
      </TableCell>
      <TableCell sx={{ padding: '0 24px' }}>
        <Typography variant="body2" style={{ userSelect: 'none' }}>{filesData.balance}</Typography>
      </TableCell>
      <TableCell sx={{ padding: '0 24px' }}>
        <Typography variant="body2" style={{ userSelect: 'none' }}>{filesData.agent}</Typography>
      </TableCell>
      <TableCell sx={{ padding: '0 24px' }}>
        <Typography variant="body2" style={{ userSelect: 'none' }}>{filesData.status}</Typography>
      </TableCell>

    </StyledTableRow>
  );
};

export default DebtsRow;
