import { createContext } from 'react';
// Types
import type { NotesCardContextType } from './types/NotesCardContextType';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';

export const NotesCardContext = createContext<NotesCardContextType>({
  noteField: fieldInitialDef,
  editingNoteIds: [],
  handleEditNoteStart: () => {},
  handleEditNoteEnd: () => {},
  handleCreateNote: () => {},
  handleDeleteNote: () => {},
  isModalDeleteNoteOpen: false,
  openModalDeleteNote: () => {},
  closeModalDeleteNote: () => {},
  noteIdToDelete: ''
});
