import React from 'react'
import { Box, Card, Typography, Theme } from '@mui/material';
// import { useTranslation } from 'react-i18next'
// import { useNavigate } from "react-router-dom";
import { alpha, useTheme } from '@mui/material';
const Dashboard = () => {
  // const {t} = useTranslation('translation')
  // let navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/dashboard");
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const theme = useTheme();

  return (
      <Box className={'contacts-paddingmd'}>
        <Box className="contacts-rowContacts" style={{ justifyContent: 'center' }}>
          <Box className="contacts-col-4">
            <Card
            //@ts-ignore
              sx={{ backgroundColor: (theme) => theme.palette.background.default }}
            >
              <Box
                sx={{
                  p: { xs: 2, md: 4 },
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <Box>
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.primary }}
                    gutterBottom
                  >
                    Cantidad de usuarios
                  </Typography>
                  <Typography
                    variant={'h4'}
                    color={'primary'}
                    sx={{ color: (theme) => theme.palette.info.main }}
                    fontWeight={700}
                    display={'flex'}
                    alignItems={'flex-end'}
                    lineHeight={1}
                  >
                    30
                    <Typography
                      component={'span'}
                      variant={'subtitle2'}
                      color={'text.secondary'}
                      sx={{ color: (theme) => theme.palette.text.secondary }}
                      marginLeft={1}
                    >
                      from 50
                    </Typography>
                  </Typography>
                </Box>
                <Typography
                  component={'span'}
                  variant={'caption'}
                  fontWeight={700}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginLeft: 1,
                    bgcolor: alpha(theme.palette.success.light, 0.2),
                    // color: "text.primary",
                    color: (theme) => theme.palette.text.primary,
                    paddingX: 1,
                    paddingY: 0.5,
                    borderRadius: 4,
                  }}
                >
                  20%
                </Typography>
              </Box>
            </Card>
          </Box>
          <Box className="contacts-col-4">
            <Card
              sx={{ backgroundColor: (theme) => theme.palette.background.default }}
            >
              <Box
                sx={{
                  p: { xs: 2, md: 4 },
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <Box>
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.primary }}
                    gutterBottom
                  >
                    Espacio en disco
                  </Typography>
                  <Typography
                    variant={'h4'}
                    sx={{ color: (theme) => theme.palette.info.main }}
                    fontWeight={700}
                    display={'flex'}
                    alignItems={'flex-end'}
                    lineHeight={1}
                  >
                    30 MB
                    <Typography
                      component={'span'}
                      variant={'subtitle2'}
                      sx={{ color: (theme) => theme.palette.text.secondary }}
                      marginLeft={1}
                    >
                      from 50 GB
                    </Typography>
                  </Typography>
                </Box>
                <Typography
                  component={'span'}
                  variant={'caption'}
                  fontWeight={700}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginLeft: 1,
                    bgcolor: alpha(theme.palette.success.light, 0.2),
                    color: (theme) => theme.palette.text.primary,
                    paddingX: 1,
                    paddingY: 0.5,
                    borderRadius: 4,
                  }}
                >
                  20%
                </Typography>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
  );
};

export default Dashboard;
