import { ChangeEvent, useEffect, useState } from 'react';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import {
  deleteContactAddress,
  selectCustomContactFields,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Types
import {
  Property,
  IBusiness,
  IContactAddress,
  IContactInfo,
} from '@trii/types/dist/Contacts';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import UseEditBusiness from './types/UseEditBusiness';
// Hooks
import useField from 'hooks/useField';
import useImage from 'hooks/useImage';
import useSelect from 'hooks/useSelect';
// Utils
import contactHelper from 'utils/contactHelper';
import editContactHelper from 'features/Views/ContactEdit/hooks/useEditContact/utils/editContactHelper';
import { TableContactField } from 'ReduxToolkit/features/contactsSlice/types/TableContactField';
import { ItemWithId, useMultipleSelect } from 'hooks/useMultipleSelect';

const useEditBusiness = (business: IBusiness | null): UseEditBusiness => {
  const dispatch = useAppDispatch();
  const [isDeletingAddress, setIsDeletingAddress] = useState<false | string>(false);
  const customContactFields = useSelector(selectCustomContactFields);
  const [customProperties, setCustomProperties] = useState<Property[]>([]);
  // General section fields
  const name = useField('text', business?.name || '');
  const contactImage = useImage(business?.imageUrl || '');
  const contactPropietary = useSelect(business?.owner);
  const labelMultipleSelect = useMultipleSelect({
    initialState: business?.tags as ItemWithId[],
  });
  const [selectedContacts, setSelectedContacts] = useState<IContactInfo[]>([]);
  // Business information fields
  const [imsFacebooks, setImsFacebooks] = useState(business?.ims_facebook || []);
  const [imsWebchats, setImsWebchats] = useState(business?.ims_webchat || []);
  const [imsInstagrams, setImsInstagrams] = useState(business?.ims_instagram || []);
  const [imsWhatsapp, setImsWhatsApp] = useState(business?.ims_whatsapp || []);
  const [emails, setEmails] = useState(business?.emails || []);
  const [phoneNumbers, setPhoneNumbers] = useState(business?.phones || []);
  // Address iformation fields
  const homeAddress = useField('text', business?.address1?.street || '');
  const secondaryHomeAddress = useField('text', business?.address2?.street || '');
  const city = useField('text', business?.address1?.city || '');
  const secondaryCity = useField('text', business?.address2?.city || '');
  const state = useField('text', business?.address1?.state || '');
  const secondaryState = useField('text', business?.address2?.state || '');
  const postalCode = useField('text', business?.address1?.zipcode || '');
  const secondaryPostalCode = useField('text', business?.address2?.zipcode || '');
  const country = useField('text', business?.address1?.country || '');
  const secondaryCountry = useField('text', business?.address2?.country || '');

  const getEditedField = (key: string, originalValue, editedValue) => {
    if (
      originalValue !== editedValue &&
      !(originalValue === null && editedValue === '')
    ) {
      const editedField = {};
      editedField[key] = editedValue;
      return editedField;
    }
    return null;
  };
  // Actions
  const getEditedBusiness = () => {
    const address1 = {
      street: homeAddress.attributes.value,
      city: city.attributes.value,
      state: state.attributes.value,
      zipcode: postalCode.attributes.value,
      country: country.attributes.value,
    };
    const address2 = {
      street: secondaryHomeAddress.attributes.value,
      city: secondaryCity.attributes.value,
      state: secondaryState.attributes.value,
      zipcode: secondaryPostalCode.attributes.value,
      country: secondaryCountry.attributes.value,
    };
    const editedBusiness = {
      id: business.id,
      ...getEditedField('owner', business.owner, contactPropietary.attributes.value),
      ...getEditedField('name', business.name, name.attributes.value),
      imageUrl: contactImage.imageUrl
        ? contactImage.removeParams(contactImage.imageUrl)
        : null,
      tags: labelMultipleSelect.attributes.value,
      properties: customProperties,
      members: selectedContacts,
      phoneNumbers,
      emails,
      address1,
      address2,
      // ims_whatsapp: modifiedImsWhatsapp,
    };
    return editedBusiness;
  };
  // Utils
  const addPhone = () => {
    const newPhone = contactHelper.createNewAddress(ChannelType.PHONE);
    const newPhoneNumbers = [...phoneNumbers, newPhone];
    setPhoneNumbers(newPhoneNumbers as IContactAddress[]);
  };
  const replaceAddress = (addressId: string, newAddress: IContactAddress) => {
    if (newAddress?.channelType === 3) {
      const newEmails = emails.map((email) => {
        if (email.id === addressId) {
          return newAddress;
        }
        return email;
      });

      setEmails(newEmails);
    }
    if (newAddress?.channelType === 5) {
      const newPhoneNumbers = phoneNumbers.map((phoneNumber) => {
        if (phoneNumber.id === addressId) {
          return newAddress;
        }
        return phoneNumber;
      });

      setPhoneNumbers(newPhoneNumbers);
    }
    if (newAddress?.channelType === 13) {
      const newImsWhatsapp = imsWhatsapp.map((imsWhatsapp) => {
        if (imsWhatsapp.id === addressId) {
          return newAddress;
        }
        return imsWhatsapp;
      });

      setImsWhatsApp(newImsWhatsapp);
    }
  };
  const addEmail = () => {
    const newEmail = contactHelper.createNewAddress(ChannelType.EMAIL);
    const newEmails = [...emails, newEmail];
    setEmails(newEmails as IContactAddress[]);
  };
  const addWhatsapp = () => {
    const newWhatsapp = contactHelper.createNewAddress(ChannelType.WHATSAPP);
    const newWhatsapps = [...imsWhatsapp, newWhatsapp];
    setImsWhatsApp(newWhatsapps as IContactAddress[]);
  };
  const addWhatsappVerified = (newWhatsapp: IContactAddress) => {
    const updatedWhatsapps = [...imsWhatsapp, newWhatsapp];
    setImsWhatsApp(updatedWhatsapps);
  };
  const handleAddressFieldChange = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string,
    type: ChannelType
  ) => {
    const newValue = e.target.value;

    if (type === 3) {
      const newEmails = editContactHelper.getAddressesWithUpdatedValues(
        newValue,
        fieldId,
        emails
      );
      setEmails(newEmails);
    } else if (type === 5) {
      const newPhoneNumbers = editContactHelper.getAddressesWithUpdatedValues(
        newValue,
        fieldId,
        phoneNumbers
      );

      setPhoneNumbers(newPhoneNumbers);
    } else if (type === 13) {
      const newImsWhatsapp = editContactHelper.getAddressesWithUpdatedValues(
        newValue,
        fieldId,
        imsWhatsapp
      );

      setImsWhatsApp(newImsWhatsapp);
    }
  };
  const handleNoteFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string,
    addressType: ChannelType
  ) => {
    if (addressType === 14) {
      const newImsFacebooks = imsFacebooks.map((imsFacebook) => {
        if (imsFacebook.id === fieldId) {
          return {
            ...imsFacebook,
            note: e.target.value,
          };
        }
        return imsFacebook;
      });
      setImsFacebooks(newImsFacebooks);
    }
    if (addressType === 20) {
      const newImsWebchats = imsWebchats.map((imsWebchat) => {
        if (imsWebchat.id === fieldId) {
          return {
            ...imsWebchat,
            note: e.target.value,
          };
        }
        return imsWebchat;
      });
      setImsWebchats(newImsWebchats);
    }
    if (addressType === 16) {
      const newImsInstagrams = imsInstagrams.map((imsInstagram) => {
        if (imsInstagram.id === fieldId) {
          return {
            ...imsInstagram,
            note: e.target.value,
          };
        }
        return imsInstagram;
      });
      setImsInstagrams(newImsInstagrams);
    }
    if (addressType === 3) {
      const newEmails = emails.map((email) => {
        if (email.id === fieldId) {
          return {
            ...email,
            note: e.target.value,
          };
        }
        return email;
      });
      setEmails(newEmails);
    }
    if (addressType === 5) {
      const newPhoneNumbers = phoneNumbers.map((phoneNumber) => {
        if (phoneNumber.id === fieldId) {
          return {
            ...phoneNumber,
            note: e.target.value,
          };
        }
        return phoneNumber;
      });
      setPhoneNumbers(newPhoneNumbers);
    }
    if (addressType === 13) {
      const newImsWhatsapp = imsWhatsapp.map((imsWhatsapp) => {
        if (imsWhatsapp.id === fieldId) {
          return {
            ...imsWhatsapp,
            note: e.target.value,
          };
        }
        return imsWhatsapp;
      });
      setImsWhatsApp(newImsWhatsapp);
    }
  };

  const handleDeleteAddress = async (fieldId: string, addressType: ChannelType) => {
    if (
      addressType === 3 ||
      addressType === 5 ||
      addressType === 13 ||
      addressType === 14 ||
      addressType === 20 ||
      addressType === 16
    ) {
      setIsDeletingAddress(fieldId);
      await dispatch(
        deleteContactAddress({
          contactId: business.id,
          contactAddressId: fieldId,
          type: addressType,
        })
      );
      setIsDeletingAddress(false);
      switch (addressType) {
        case 3:
          const newEmails = emails.filter((email) => email.id !== fieldId);
          setEmails(newEmails);
          break;
        case 5:
          const newPhoneNumbers = phoneNumbers.filter(
            (phoneNumber) => phoneNumber.id !== fieldId
          );
          setPhoneNumbers(newPhoneNumbers);
          break;
        case 13:
          const newImsWhatsapp = imsWhatsapp.filter(
            (imsWhatsapp) => imsWhatsapp.id !== fieldId
          );
          setImsWhatsApp(newImsWhatsapp);
          break;
        case 14:
          const newImsFacebooks = imsFacebooks.filter(
            (imsFacebook) => imsFacebook.id !== fieldId
          );
          setImsFacebooks(newImsFacebooks);
          break;
        case 20:
          const newImsWebchats = imsWebchats.filter(
            (imsWebchat) => imsWebchat.id !== fieldId
          );
          setImsWebchats(newImsWebchats);
          break;
        case 16:
          const newImsInstagrams = imsInstagrams.filter(
            (imsInstagram) => imsInstagram.id !== fieldId
          );
          setImsInstagrams(newImsInstagrams);
          break;
        default:
          break;
      }
    }
  };
  const createProperty = (customField: TableContactField): Property => {
    const property = {
      nameKey: customField.name,
      value: '',
      type: customField.type,
    };
    return property;
  };

  useEffect(() => {
    const newCustomProperties = customContactFields.map((customField) =>
      createProperty(customField)
    );
    setCustomProperties(newCustomProperties);
  }, [business, customContactFields]);

  useEffect(() => {
    if (business?.emails && business?.phones) {
      setEmails(business.emails);
      setPhoneNumbers(business.phones);
      labelMultipleSelect.actions.setValue(business.tags);
    }
    setImsWhatsApp(business?.ims_whatsapp || []);
    setImsFacebooks(business?.ims_facebook || []);
    setImsWebchats(business?.ims_webchat || []);
    setImsInstagrams(business?.ims_instagram || []);
  }, [business]);

  return {
    field: {
      name,
      imsWhatsapp,
      contactImage,
      contactPropietary,
      imsFacebooks,
      imsWebchats,
      imsInstagrams,
      emails,
      phoneNumbers,
      homeAddress,
      secondaryHomeAddress,
      city,
      secondaryCity,
      state,
      secondaryState,
      postalCode,
      secondaryPostalCode,
      country,
      secondaryCountry,
      customProperties,
      labelMultipleSelect,
    },
    action: {
      setCustomProperties,
      addWhatsapp,
      getEditedBusiness,
      handleAddressFieldChange,
      handleNoteFieldChange,
      handleDeleteAddress,
      addPhone,
      addEmail,
      replaceAddress,
      addWhatsappVerified,
    },
    state: {
      isDeletingAddress,
    },
    selectedContacts,
    setSelectedContacts,
  };
};

export default useEditBusiness;
