import { useContext, useEffect } from 'react';
// Components/ui
import {
  ValueField,
  Title,
  ApplyFilterButton,
  TagSelect,
  ListSelect,
} from './components';
import { Box } from '@mui/material';
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import {
  setField,
  selectFields,
  setInitialFields,
  generateFilters,
  selectAvoidReset,
  setAvoidReset,
  selectList,
  selectTag,
} from 'ReduxToolkit/features/contactsFilterSlice/contactsFilterSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  getPreviousPath,
  selectContactFields,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Types
import { Filter } from 'ReduxToolkit/features/contactsSlice/types/Filter';
const AdvancedFilter = () => {
  const avoidFilterTypes = [4, 5, 6, 7, 8, 11];

  const dispatch = useAppDispatch();

  const { applyFilter } = useContext(ContactBusinessTableContext);

  const contactFields = useSelector(selectContactFields);
  const fields = useSelector(selectFields);
  const list = useSelector(selectList);
  const tag = useSelector(selectTag);
  const avoidReset = useSelector(selectAvoidReset);

  const handleChange = (fieldKey: string, value: string) => {
    dispatch(setField({ fieldKey, value }));
  };

  const handleApplyFilter = () => {
    const columnFilters = generateFilters(fields);
    const listFilter = getFilter(list, 'list');
    const tagFilter = getFilter(tag, 'tags');
    const filters = [listFilter, tagFilter].filter((filter) => filter != null);

    applyFilter([...columnFilters, ...filters]);
  };

  const getFilter = (value: string, type: 'list' | 'tags'): Filter => {
    if (!value || value === '') return;

    return {
      column: type,
      condition: '=',
      value,
    };
  };

  useEffect(() => {
    if (contactFields.length === 0) return;

    if (avoidReset) {
      dispatch(setAvoidReset(false));
      return;
    }

    const filteredFields = contactFields.filter(
      (field) =>
        field.nameKey !== '' &&
        field.nameKey !== 'BirthDate' &&
        !avoidFilterTypes.includes(field.type)
    );

    const initialFields = filteredFields.reduce((acc, field) => {
      acc[field.nameKey] = {
        ...field,
        value: null,
      };
      return acc;
    }, {});

    dispatch(setInitialFields(initialFields));
  }, [dispatch, contactFields]);

  return (
    <Box
      sx={{
        // maxHeight: 'calc(100vh - 230px)',
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
      }}
    >
      <Title />
      {Object.keys(fields).map((nameKey) => {
        const field = fields[nameKey];
        return (
          <ValueField
            key={nameKey}
            isDefault={field.isDefault}
            name={field.name}
            nameKey={field.nameKey}
            value={field.value as string}
            onChangeHandler={handleChange}
          />
        );
      })}
      <TagSelect />
      <ListSelect />
      <ApplyFilterButton onClick={handleApplyFilter} />
    </Box>
  );
};

export default AdvancedFilter;
