import React from 'react';
// //components/ui
import { TableBody } from '@mui/material';
import { RepairRow } from './components';

const Body = ({ tableRepair }) => {
  // const columnsFetchStatus = useSelector(getFetchContactFieldsStatus);
  // const contactsFetchStatus = useSelector(getFetchContactsStatus);
  // const contacts = useSelector(selectContacts);
  const Files = tableRepair?.map((filesData, i) => (
    <RepairRow filesData={filesData} key={i} />
  ));

  return (
    <TableBody>
      {/* {columnsFetchStatus === 'loading' || contactsFetchStatus === 'loading' ? (
        <FilesSkeleton />
      ) : (
        Contacts
        )} */}
      {/* {Files} */}
    </TableBody>
  );
};

export default Body;
