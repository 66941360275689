import { createContext } from 'react';
// Types
import type ContactCreateContextType from './types/ContactCreateContextType';
// Utils
import { initialDef } from '../hooks/useCreateContact/utils/initialDef';

export const ContactCreateContext = createContext<ContactCreateContextType>({
  contact: initialDef,
  handleCreateButton: () => {},
});
