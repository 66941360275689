import React, { useContext } from 'react';
import { ListsContext } from '../../context/ListsContext';
import { useTranslation } from 'react-i18next';
// Types
import { IListsContext } from '../../context/types';
// Components/ui
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

export default function ManageListHeader() {
  const { t } = useTranslation();
  const { openCreatePanel, createPanelOpen } =
    useContext<IListsContext>(ListsContext);

  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      sx={{ paddingRight: 2 }}
      borderRadius={'4px'}
      height="50px"
      className="m-0"
    >
      <Button
        sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
        variant="contained"
        size="small"
        onClick={openCreatePanel}
        disabled={createPanelOpen}
      >
        {t('listsView.createList')}
      </Button>
    </Box>
  );
}
