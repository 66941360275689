// Types
import { IContactAddress } from '@trii/types/dist/Contacts';
// Components/ui
import { InformationSocialNetworkItem } from '../../layout'; // Types

const Facebook = () => {
  return <InformationSocialNetworkItem socialNetwork={'facebook'} />;
};

export default Facebook;
