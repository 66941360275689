import React from 'react';
// components/ui
import { Button, Theme } from '@mui/material';

const NextButton = ({ onClick }) => {
  return (
    <Button onClick={onClick} sx={{ mb: 2, backgroundColor: (theme: Theme) => theme.palette.success.main  }} variant="contained">
      Next
    </Button>
  );
};

export default NextButton;
