// Components/ui
import { SubsectionBodyContainer } from 'components/ContactCreateEditAppBody/components/InformationSection/layout';
import { Mail } from './components';
// Types
import { NewAddress } from 'types/NewAddress';

type Props = {
  emails: NewAddress[];
};

const Body = ({ emails }: Props) => {
  return (
    <SubsectionBodyContainer variant="large">
      {emails?.map((email) => (
        <Mail key={email.id} data={email} />
      ))}
    </SubsectionBodyContainer>
  );
};

export default Body;
