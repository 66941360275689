import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, IconButton, Typography, styled } from '@mui/material';
import ContactImg from '/public/img/Icons/SVG/TriiContacts.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// Redux
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';
import { MoreOptionsMenu } from './components';

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0rem 0.1rem 0.5rem 0.1rem',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const ContactImage = styled('img')({
  height: '30px',
});

const TitleHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  color: theme.palette.text.primary,
  userSelect: 'none',
}));

const HeaderButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const HeaderButton = styled(Button)(({ theme }) => ({
  padding: '0.15rem 0.45rem',
}));

const CardHeader = ({
  isMerged,
  handleSelectAll,
  contactId,
}: {
  isMerged: boolean;
  handleSelectAll: () => void;
  contactId: string;
}) => {
  const { t } = useTranslation();

  const { removeContactById } = useContext(DuplicateContext);

  // Menu logic
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderContainer>
      {isMerged && (
        <TitleContainer>
          <ContactImage src={ContactImg} alt="" />
          <TitleHeader>{t('duplicatesView.contactInfo.header')}</TitleHeader>
        </TitleContainer>
      )}
      {!isMerged && (
        <HeaderButtonContainer>
          <HeaderButton
            onClick={() => removeContactById(contactId)}
            variant="outlined"
            size="small"
            color="error"
          >
            {t('global.remove')}
          </HeaderButton>
          <Box>
            <HeaderButton onClick={handleSelectAll} variant="contained" size="small">
              {t('duplicatesView.contactInfo.selectAll')}
            </HeaderButton>
            <IconButton onClick={handleClick} size="small">
              <MoreVertIcon />
            </IconButton>
            <MoreOptionsMenu
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              contactId={contactId}
              removeContactById={removeContactById}
            />
          </Box>
        </HeaderButtonContainer>
      )}
    </HeaderContainer>
  );
};

export default CardHeader;
