import { useTranslation } from 'react-i18next';
// Components/ui
import { BasicInputContainer } from '../../layout';
import { TextField } from '@mui/material';
// Types
import { UseFieldType } from 'hooks/useField';

type TitleTextFieldProps = {
  titleField: UseFieldType;
};

const TitleTextField = ({ titleField }: TitleTextFieldProps) => {
  const { t } = useTranslation();
  return (
    <BasicInputContainer name={t('global.title')}>
      <TextField
        autoFocus
        type="text"
        fullWidth
        variant="outlined"
        size="small"
        {...titleField.attributes}
      />
    </BasicInputContainer>
  );
};

export default TitleTextField;
