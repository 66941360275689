// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';
// Dayjs
import dayjs from 'dayjs';

type Props = {
  nameKey: string;
  value: string;
  onChange: (name: string, value: any) => void;
};

const CustomPropertyDateRangePicker = ({ value, onChange, nameKey }: Props) => {
  const { t } = useTranslation();
  const handleChange = (value: string) => {
    const formatedValue = dayjs(value).format('YYYY-MM-DD');

    // onChange(nameKey, formatedValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display={'flex'} width={'100%'} gap={1} alignItems={'center'}>
        <DatePicker
          label={`${nameKey} - ${t('contactEdit.from')}`}
          value={value}
          onChange={handleChange}
        />
        -
        <DatePicker
          label={`${nameKey} - ${t('contactEdit.to')}`}
          value={value}
          onChange={handleChange}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CustomPropertyDateRangePicker;
