import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentsContext } from '../../../../../../context/SegmentsProvider';
// Types
import { RequestStatus } from 'types/reduxTypes';
// Redux
import { useSelector } from 'react-redux';
import { selectSegmentsCreateStatus } from 'ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeCreatePanel, sendSegment, createSegmentNameField } =
    useContext(SegmentsContext);
  const createStatus: RequestStatus = useSelector(selectSegmentsCreateStatus);
  const isLoading: boolean = createStatus === 'loading';
  const isDisabled: boolean =
    createStatus === 'loading' || !createSegmentNameField.attributes.value;

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeCreatePanel}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<AddIcon />}
        variant="contained"
        size="small"
        color="success"
        sx={{
          backgroundColor: (theme) => theme.palette.success.main,
          color: 'text.primary',
        }}
        disabled={isDisabled}
        onClick={sendSegment}
      >
        {t('global.create')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;
