import React, { useState, useRef, useEffect, useContext } from 'react';
import { defaultFields } from './utils/defaultFields';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { selectCustomContactFields } from '../../../../../../../ReduxToolkit/features/configSlice/configSlice';
import {
  fetchDuplicates,
  setFetchData,
} from '../../../../../../../ReduxToolkit/features/duplicatesSlice/duplicatesSlice';
// Components/ui
import { DropdownFieldsMenu } from './components';
import { Box, Typography, styled } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import { IFetchDuplicatesData } from 'ReduxToolkit/features/duplicatesSlice/types';
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: '4px',
    },
    '&:first-of-type': {
      borderRadius: '4px',
    },
  },
}));

interface Props {
  quantity: number;
}

export default function DuplicatesFilter({ quantity }: Props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { resetFields } = useContext(DuplicateContext);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedToggle, setSelectedToggle] = useState(false);
  const [alignment, setAlignment] = useState('name');
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const contactFields = useSelector(selectCustomContactFields);

  const fetchDuplicatedContacts = async (fieldValue) => {
    const fetchData: IFetchDuplicatesData = {
      page: 1,
      key: fieldValue,
      quantity,
    };

    resetFields();

    await dispatch(fetchDuplicates(fetchData));
    dispatch(setFetchData(fetchData));
  };

  const handleDefaultFieldButtonClick = async () => {
    // setSelectedIndex(selectedIndex);
    await fetchDuplicatedContacts(contactFields[selectedIndex].name);
  };

  const handleMenuItemClick = (event, index) => {
    fetchDuplicatedContacts(contactFields[index].name);
    setSelectedIndex(index);
    event && setSelectedToggle(true);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleAlignment = (event, newAlignment) => {
    const url = window.location.href;

    if (url.includes('?')) {
      const urlArray = url.split('?');
      const newUrl = urlArray[0];

      window.history.pushState({}, document.title, newUrl);
    }

    if (newAlignment !== null) {
      setAlignment(newAlignment);
      if (contactFields[selectedIndex] === newAlignment) {
        setSelectedToggle(true);
      } else {
        setSelectedToggle(false);
      }
    }
  };

  const DefaultFieldsButtons = defaultFields.map((field) => (
    <ToggleButton
      sx={{
        mr: 1,
        border: '1px solid lightgray !important',
        backgroundColor: (theme) => theme.palette.background.default,
      }}
      key={field.value}
      value={field.value}
      onClick={() => fetchDuplicatedContacts(field.value)}
    >
      {field.name}
    </ToggleButton>
  ));

  return (
    <>
      <Box>
        <Typography mr={5} sx={{ color: (theme) => theme.palette.text.primary }}>
          {t('duplicatesView.filterBy')}
        </Typography>
        <StyledToggleButtonGroup
          size="small"
          value={alignment}
          exclusive
          onChange={handleAlignment}
        >
          {DefaultFieldsButtons}
          {contactFields.length > 0 && (
            <ToggleButton
              size="small"
              ref={anchorRef}
              sx={{
                border: '1px solid lightgray !important',
                borderRight: '0px !important',
                borderTopRightRadius: '0 !important',
                borderBottomRightRadius: '0 !important',
                backgroundColor: (theme) => theme.palette.background.default,
              }}
              value={contactFields[selectedIndex].id}
              onClick={handleDefaultFieldButtonClick}
            >
              {contactFields[selectedIndex].name}
            </ToggleButton>
          )}
          {contactFields.length > 0 && (
            <ToggleButton
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              sx={{
                px: 0,
                border: '1px solid lightgray !important',
                borderLeft: '0px !important',
                borderTopLeftRadius: '0 !important',
                borderBottomLeftRadius: '0 !important',
                backgroundColor: (theme) => theme.palette.background.default,
              }}
              size="small"
              value={contactFields[selectedIndex]}
              onClick={handleToggle}
              selected={selectedToggle}
            >
              <ArrowDropDownIcon />
            </ToggleButton>
          )}
        </StyledToggleButtonGroup>
      </Box>

      <DropdownFieldsMenu
        open={open}
        handleClose={handleClose}
        selectedIndex={selectedIndex}
        handleMenuItemClick={handleMenuItemClick}
        anchorRef={anchorRef}
        contactFields={contactFields}
      />
    </>
  );
}
