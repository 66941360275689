// Types
import { Pagination } from 'ReduxToolkit/features/contactsSlice/types/Pagination';
import { TableContactField } from 'ReduxToolkit/features/contactsSlice/types/TableContactField';
// Components/ui
import { styled } from '@mui/material';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

type Props = {
  pagination: Pagination;
  columnData: TableContactField;
};

const StyledArrowUpwardRoundedIcon = styled(ArrowUpwardRoundedIcon)(({ theme }) => ({
  fontSize: '17px',
  color: theme.palette.primary.main,
}));
const StyledArrowDownwardRoundedIcon = styled(ArrowDownwardRoundedIcon)(
  ({ theme }) => ({
    fontSize: '17px',
    color: theme.palette.primary.main,
  })
);

const ArrowIcon = ({ pagination, columnData }: Props) => {
  if (pagination.orderColumn === columnData.name) {
    switch (pagination.order) {
      case 'ASC':
        return <StyledArrowUpwardRoundedIcon />;
      case 'DESC':
        return <StyledArrowDownwardRoundedIcon />;
      default:
        return <StyledArrowUpwardRoundedIcon color="disabled" />;
    }
  }
  return (
    <ArrowUpwardRoundedIcon
      sx={{
        fontSize: '17px',
      }}
      color="disabled"
    />
  );
};

export default ArrowIcon;
