import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ListsContext } from '../../../../../../context/ListsContext';
import { IListsContext } from '../../../../../../context/types';
// Components/ui
import { Box, TextField, Typography } from '@mui/material';

const Inputs = () => {
  const { t } = useTranslation();
  const { deleteListNameField } = useContext<IListsContext>(ListsContext);

  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <Typography
        variant="subtitle2"
        color={'text.primary'}
        sx={{ marginBottom: '15px' }}
      >
        {t('listsView.deleteListLabel')}
      </Typography>
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('listsView.listName')}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        {...deleteListNameField.attributes}
      />
    </Box>
  );
};

export default Inputs;
