import { useState, useEffect, useContext } from 'react';
// Context
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useSelector } from 'react-redux';
import { selectContactFields } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  FormGroup,
  Theme,
} from '@mui/material';
import { ColumnOptions } from './components';
// Types
import type { TableContactField } from 'ReduxToolkit/features/contactsSlice/types/TableContactField';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

const ColumnOptionsDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();

  const { handleColumnsChange } = useContext(ContactBusinessTableContext);
  const [columnsCopy, setColumnsCopy] = useState<TableContactField[]>();
  const columns = useSelector(selectContactFields);

  const handleOptionCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Take into account that the id of the checkbox is the name of the column
    const checkboxId = e.target.id;
    const updatedColumns = columnsCopy.map((column) => {
      if (checkboxId === column.name) {
        return { ...column, visible: !column.visible };
      }
      return column;
    });

    setColumnsCopy([...updatedColumns]);
  };

  const handleAccept = () => {
    handleColumnsChange(columnsCopy);
    handleClose();
  };

  useEffect(() => {
    setColumnsCopy(columns);
  }, [columns]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': styles.dialogPaper,
      }}
    >
      <DialogTitle>{t('contactsView.contactBusinessTable.manageColumns')}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <FormGroup>
            <ColumnOptions
              columns={columnsCopy}
              handleOptionCheck={handleOptionCheck}
            />
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={handleClose}>
        {t('global.cancel')}
        </Button>
        <Button variant="contained" size="small" onClick={handleAccept}>
        {t('global.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColumnOptionsDialog;
