import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
// Types
import { IContactAddress } from '@trii/types/dist/Contacts';
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';

interface ContactProps {
  contactAddresses: IContactAddress[];
  icon: React.ReactNode;
  title: string;
  showCampaign?: boolean;
  showTitle?: boolean;
  isMerged?: boolean;
  mergedData?: IContactAddress[];
  handleSelect: (value: IContactAddress) => void;
  setSelected: Dispatch<SetStateAction<boolean>>;
  contactId: string;
}

const ContactList = ({
  contactAddresses,
  icon,
  title,
  showTitle = true,
  isMerged = false,
  mergedData = [],
  handleSelect,
  setSelected,
  contactId,
}: ContactProps) => {
  const { t } = useTranslation();

  const { triggerSelectAllId, setTriggerSelectAllId } = useContext(DuplicateContext);

  const [newList, setNewList] = useState<IContactAddress[]>([]);
  const [idList, setIdList] = useState<string[]>([]);

  const checkboxesRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleSelected = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>,
    contact: IContactAddress
  ) => {
    if (contact?.id) {
      // @ts-ignore
      setSelected(e.target.checked);
      handleSelect(contact);
    }
  };

  useEffect(() => {
    if (!isMerged) {
      setNewList(contactAddresses);
    } else {
      setNewList(mergedData);
    }
  }, [contactAddresses, mergedData]);

  useEffect(() => {
    if (mergedData) {
      const ids = mergedData.map((contact: IContactAddress) => contact.id);
      setIdList(ids);
    }
  }, [mergedData]);

  useEffect(() => {
    if (triggerSelectAllId !== '' && triggerSelectAllId === contactId) {
      checkboxesRef.current.forEach((checkbox) => {
        if (checkbox && !checkbox.checked) {
          checkbox.click();
        }
      });
    }
  }, [triggerSelectAllId, contactId, setTriggerSelectAllId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'max-content',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {showTitle && (
        <Box
          sx={{
            width: '100%',
            borderBottom: '1px solid',
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight="semi-bold"
            color={'text.disabled'}
          >
            {t(title)}
          </Typography>
        </Box>
      )}
      {newList?.length > 0 &&
        newList.map((contact: IContactAddress, index: number) => (
          <Box
            key={contact.id || index}
            sx={{
              width: '100%',
              height: 'max-content',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: 'max-content',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <FormControlLabel
                control={
                  !isMerged ? (
                    <Checkbox
                      size="small"
                      checked={idList.includes(contact.id)}
                      inputRef={(el) => (checkboxesRef.current[index] = el)}
                    />
                  ) : (
                    <></>
                  )
                }
                onClick={(e) =>
                  !isMerged && contact?.id && handleSelected(e, contact)
                }
                disabled={!contact?.id}
                label={
                  <Box display="flex" alignItems="center" width="240px" gap={0.5}>
                    {icon}
                    <Typography
                      variant="subtitle1"
                      fontWeight="semi-bold"
                      fontSize={14}
                      color={'text.disabled'}
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        cursor: 'default',
                      }}
                    >
                      {contact.address}
                    </Typography>
                  </Box>
                }
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default ContactList;
