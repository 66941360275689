import React from 'react';
import { useTranslation } from 'react-i18next';
// components/ui
import { Alert, AlertTitle, Fade } from '@mui/material';

const ErrorExport = () => {
  const { t } = useTranslation();
  const [fadeIn, setFadeIn] = React.useState(false);

  React.useEffect(() => {
    setFadeIn(true);
  }, []);
  return (
    <Fade in={fadeIn}>
      <Alert severity="error">
        <AlertTitle>{t('global.success')}</AlertTitle>
        {t('exportContactsView.error')}
      </Alert>
    </Fade>
  );
};

export default ErrorExport;
