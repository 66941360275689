import { useState, useContext } from 'react';
// Context
import { FilesCardContext } from '../../../../../../context/FilesCardContext';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { HeadCellTitle } from './components';
import { TableCell, IconButton, Fade, styled } from '@mui/material';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
// Types
import { FileTableColumn } from '../../../../../../context/types/FileTableColumn';
import { SortOrder } from 'types/SortOrder';

const StyledArrowUpwardRoundedIcon = styled(ArrowUpwardRoundedIcon)(({ theme }) => ({
  fontSize: '18px',
}));

interface ColumnProps {
  data: FileTableColumn;
  sortFunction: (columnName: string, sortOrder: SortOrder) => void;
}

const Column = ({ data, sortFunction }: ColumnProps) => {
  const { t } = useTranslation();
  const { name, sortDirection, sorted, title } = data;
  const [isHovered, setIsHovered] = useState(false);

  const ArrowIcon = () => {
    switch (sortDirection) {
      case 'ASC':
        return <StyledArrowUpwardRoundedIcon color="primary" />;
      case 'DESC':
        return (
          <ArrowDownwardRoundedIcon sx={{ fontSize: '18px' }} color="primary" />
        );
      default:
        return <StyledArrowUpwardRoundedIcon color="disabled" />;
    }
  };

  const handleHover = () => setIsHovered(true);

  const handleLeave = () => setIsHovered(false);

  const getNewSortDirection = (order: SortOrder): SortOrder => {
    switch (order) {
      case 'ASC':
        return 'DESC';
      case 'DESC':
        return 'ASC';
      default:
        return 'ASC';
    }
  };

  const handleColumnClick = async () => {
    const newSortDirection = getNewSortDirection(sortDirection);
    sortFunction(name, newSortDirection);
  };

  return (
    <TableCell
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      sx={{
        py: '10px',
        '&:hover': { cursor: 'pointer' },
        userSelect: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      onClick={handleColumnClick}
    >
      <HeadCellTitle title={t(`filesTable.${title}`)} />
      <Fade in={isHovered || sorted}>
        <IconButton sx={{ padding: '2px', ml: '2px', mb: '2px' }}>
          {ArrowIcon()}
        </IconButton>
      </Fade>
    </TableCell>
  );
};

export default Column;
