// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import countrySelectStyle from './style';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
// Utils
import countryList from './utils/countryList';

const CountrySelect = () => {
  const { t } = useTranslation();
  return (
    <FormControl sx={countrySelectStyle.form}>
      <InputLabel id="country-select-label">{t('global.country')}</InputLabel>
      <Select
        labelId="country-select-label"
        value={'AR'}
        label={t('global.country')}
        size="small"
        sx={{
          '& legend': {
            maxWidth: 'fit-content',
          },
          height: '2em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiOutlinedInput-input': {
            fontSize: '14px'
          }
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}
      >
        {countryList.map((country) => {
          return (
            <MenuItem key={country.value} value={country.value}>
              {country.description}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CountrySelect;
