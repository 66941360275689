import React, { useContext, useEffect, useState } from 'react';
import { StyleContext } from '../../../../../../style/styleProvider';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';

export default function ImportAccept() {
  const { shadow } = useContext(StyleContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade style={{ transitionDuration: '750ms' }} in={show}>
        <Box
          display={'flex'}
          alignItems="center"
          justifyContent="center"
          minHeight={'200px'}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography sx={{ color: (theme: Theme) => theme.palette.text.primary }} mb={2}>
              Resultados
            </Typography>
          </Box>
        </Box>
    </Fade>
  );
}
