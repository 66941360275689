import { createContext } from 'react';
// Utils
import { selectInputInitialDef } from 'utils/selectInputInitialDef';
// Types
import { ContactBusinessTableContextType } from './types/ContactBusinessTableContextType';
import { Pagination } from 'ReduxToolkit/features/contactsSlice/types/Pagination';

export const ContactBusinessTableContext =
  createContext<ContactBusinessTableContextType>({
    getFetchData: () => ({} as Pagination),
    fetchTableData: (fetchData: Pagination): Promise<void> => Promise.resolve(),
    paginationFolderSelect: selectInputInitialDef,
    contacts: [],
    isFilterPanelOpen: false,
    isFetchingFilters: false,
    applyFilter: () => {},
    handleFilterButton: () => {},
    handlePageNumberChange: () => {},
    handleColumnsChange: () => {},
    handleContactClick: () => {},
    handleColumnClick: () => {},
    handleItemCheck: () => {},
    handlePageAmountChange: () => {},
    handleParentCheck: () => {},
    getContactCellValue: () => {},
    getBusinessCellValue: () => {},
    handlePaginationFolderChange: () => {},
    mode: 'contact',
    openModalList: false,
    setOpenModalList: () => {},
    modalTitle: '',
    setModalTitle: () => {},
    handleCloseModal: () => {},
  });
