import React from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { addSegmentGroup } from '../../../../../../../../../ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Components/ui
import { Button } from '@mui/material';

const AddSegmentButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(addSegmentGroup());
  };

  return (
    <Button onClick={handleClick} sx={{ alignSelf: 'center' }} variant="contained">
      {t('segmentsEdit.addNewGroup')}
    </Button>
  );
};

export default AddSegmentButton;
