import { ContactEditContext } from './ContactEditContext';
// Redux
import { useSelector } from 'react-redux';
import { selectContactData } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Types
import type { ReactNode } from 'react';
import type ContactEditContextType from './types/ContactEditContextType';
import { IContact } from '@trii/types/dist/Contacts';
// Hook
import useEditContact from '../hooks/useEditContact/useEditContact';

type Props = {
  children: ReactNode;
};

const ContactEditContextProvider = ({ children }: Props) => {
  const contact = useSelector(selectContactData) as IContact;
  const editContact = useEditContact(contact || null);

  const contextValue: ContactEditContextType = {
    editContact,
  };

  return (
    <ContactEditContext.Provider value={contextValue}>
      {children}
    </ContactEditContext.Provider>
  );
};

export default ContactEditContextProvider;
