import React, { useContext } from 'react';
import { StyleContext } from '../../../../../../../../../style/styleProvider';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  selectFetchSyncConfigStatus,
} from '../../../../../../../../../ReduxToolkit/features/googleSyncSlice/googleSyncSlice';
// Types
import type { RequestStatus } from '../../../../../../../../../types/reduxTypes';
import { Style } from '../../../../../../../../../style/types/Style';
// Components/ui
import SyncSection from '../SyncSection';
import { AutoUpdateSwitch, SwitchSkeleton } from './components';
import { Typography } from '@mui/material';

function UpdateContactsSection() {
  const { fontSize } = useContext<Style>(StyleContext);
  const { t } = useTranslation();

  const fetchSyncConfigStatus: RequestStatus = useSelector(
    selectFetchSyncConfigStatus
  );

  return (
    <SyncSection>
      <Typography
        fontSize={fontSize.sm}
        sx={{
          marginRight: '20px',
        }}
      >
        {t('settingsView.updateContactsAutomatically')}
      </Typography>
      {fetchSyncConfigStatus === 'loading' && <SwitchSkeleton />}
      {fetchSyncConfigStatus !== 'loading' && (
        <AutoUpdateSwitch show={fetchSyncConfigStatus === 'idle'} />
      )}
    </SyncSection>
  );
}

export default UpdateContactsSection;
