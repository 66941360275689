import React from 'react';
//components/ui
import { Table, TableContainer, styled } from '@mui/material';
import { Head, Body } from './components';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export default function DebtsTable({tableDebts}) {
  return (
    <StyledTableContainer>
      <Table>
        <Head/>
        <Body tableDebts={tableDebts}/>
      </Table>
    </StyledTableContainer>
  );
}
