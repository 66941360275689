import { useEffect, useState } from 'react';
import moment from 'moment';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  selectBusinessData,
  selectBusinessFields,
  selectBusinessStatusFetchBusinessById,
} from 'ReduxToolkit/features/businessSlice/selectors';
import {
  fetchUserById,
  selectUser,
} from 'ReduxToolkit/features/userSlice/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Components/ui
import { Box, Divider, Skeleton } from '@mui/material';
import { CardSection } from './layout';
import { ContactInfoLabel, ContactCustomFieldLabel } from '../../layout';
import { AccordionCard } from '../../layout';
import { ContactSection } from './components';
// Utils
import { isIContact } from 'utils/isIContact';
// Types
import { UserTrii } from '@trii/types/dist/Users';
import { MembersItem } from 'features/Views/ContactView/components/BodyContactViewer/layout';
import NotMembersMessage from 'features/Views/ContactView/components/BodyContactViewer/layout/NotMembersMessage';

const skeletonMock = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

interface DetailsCardProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
}

const DetailsCard = ({ open, setOpen, title }: DetailsCardProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const businessData = useSelector(selectBusinessData);
  const businessFetchStatus = useSelector(selectBusinessStatusFetchBusinessById);
  const businessFields = useSelector(selectBusinessFields);
  const user = useSelector(selectUser);

  let businessDataUpdate_at = businessData?.updatedAt ? moment(businessData.updatedAt).format('L, h:mm ') : '';

  const [owner, setOwner] = useState<string>(null);
  const [updatedBy, setUpdatedBy] = useState<string>(null);
  const [createdBy, setCreatedBy] = useState<string>(null);

  const getContactPropertyValue = (propertyName: string) => {
    const property = businessData?.properties?.find(
      (property) => property.nameKey === propertyName
    );
    return property ? property.value : '';
  };

  const Skeletons = skeletonMock.map((item, i) => {
    return (
      <Skeleton
        height={60}
        width={350}
        // @ts-ignore
        sx={{ backgroundColor: (theme) => theme.palette.Skeleton.bg }}
        animation="wave"
        key={i}
      />
    );
  });

  const getUserById = async (businessId): Promise<UserTrii> => {
    const response = await dispatch(fetchUserById(businessId));
    return response.payload as UserTrii;
  };

  useEffect(() => {
    async function fetchUpdatedByUser() {
      if (businessData?.updatedBy) {
        if (businessData.updatedBy === user.uid) {
          setUpdatedBy(user.display_name || user.email);
          return;
        }

        const updatedByUser = await getUserById(businessData.updatedBy);
        setUpdatedBy(updatedByUser.display_name || updatedByUser.email);
      }
    }
    async function fetchCreayByUser() {
      if (businessData?.createdBy) {
        if (businessData.createdBy === user.uid) {
          setCreatedBy(user.display_name || user.email);
          return;
        }

        const createdByUser = await getUserById(businessData.createdBy);
        setCreatedBy(createdByUser.display_name || createdByUser.email);
      }
    }
    async function fetchOwner() {
      if (businessData?.owner) {
        if (businessData.owner === user.uid) {
          setOwner(user.display_name || user.email);
          return;
        }
        console.log('businessData.owner', businessData.owner);
        const owner = await getUserById(businessData.owner);
        setOwner(owner?.display_name || owner?.email);
      }
    }

    fetchUpdatedByUser();
    fetchCreayByUser();
    fetchOwner();

  }, [businessData]);

  return (
    <AccordionCard title={title} id="details" open={open} setOpen={setOpen}>
      <CardSection sectionTitle="General">
        <Box
          display={"flex"}
          flexDirection={"row"}
          pt={4}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          style={{ gap: 10 }}
          ml={"5%"}
          className="contacts-rowContacts"
        >
          {businessFetchStatus === "succeeded" && businessData ? (
            <>
              <ContactInfoLabel
                title={t("businessDetails.businessOwner")}
                value={owner || "-"}
              />
              <ContactInfoLabel
                title={t("contactDetails.email")}
                value={businessData.email}
              />
              <ContactInfoLabel
                title={t("contactDetails.phone")}
                value={businessData.phone}
              />
              <ContactInfoLabel
                title={t("contactDetails.createdBy")}
                value={createdBy || "-"}
                date={businessData.createdAt}
              />
              <ContactInfoLabel
                title={t("contactDetails.modifiedBy")}
                value={updatedBy || "-"}
                date={businessDataUpdate_at}
              />
              <Box className="contacts-col-5" height={"80px"}></Box>
              {businessFields.map((contactField, i) =>
                contactField.type !== 1 ? (
                  <ContactInfoLabel
                    title={contactField.name}
                    value={getContactPropertyValue(contactField.nameKey)}
                    key={contactField.id}
                  />
                ) : (
                  <ContactCustomFieldLabel
                    title={contactField.name}
                    value={getContactPropertyValue(contactField.nameKey)}
                    key={contactField.id}
                  />
                )
              )}
            </>
          ) : businessFetchStatus === "loading" ? (
            <>{Skeletons}</>
          ) : null}
        </Box>
      </CardSection>
      <Divider />
      <CardSection sectionTitle={t("contactDetails.members")}>
        {businessFetchStatus === "succeeded" &&
        businessData &&
        businessData.members.length > 0 ? (
          <Box
            display={"flex"}
            flexDirection={"row"}
            pt={2}
            flexWrap={"wrap"}
            style={{ gap: 10 }}
            ml={"5%"}        
            className="contacts-rowContacts"
            justifyContent={"flex-start"}
          >
            {businessData.members.map((item) => (
                <MembersItem memberData={item} key={item.id} />
            ))}
          </Box>
        ) : businessFetchStatus === "loading" ? (
          <>{Skeletons}</>
        ) : <NotMembersMessage/>}
      </CardSection>
      <Divider />
      <CardSection sectionTitle={t("contactDetails.addressInformation")}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          py={4}
          flexWrap={"wrap"}
          style={{ gap: 10 }}
          ml={"5%"}
          className="contacts-rowContacts"
          justifyContent={"space-between"}
        >
          {businessFetchStatus === "succeeded" &&
          businessData &&
          isIContact(businessData) ? (
            <>
              <ContactInfoLabel
                title={t("contactDetails.street")}
                value={businessData.address1?.street}
              />
              <ContactInfoLabel
                title={t("contactDetails.anotherStreet")}
                value={businessData.address2?.street}
              />
              <ContactInfoLabel
                title={t("contactDetails.city")}
                value={businessData.address1?.city}
              />
              <ContactInfoLabel
                title={t("contactDetails.anotherCity")}
                value={businessData.address2?.city}
              />
              <ContactInfoLabel
                title={t("contactDetails.state")}
                value={businessData.address1?.state}
              />
              <ContactInfoLabel
                title={t("contactDetails.anotherState")}
                value={businessData.address2?.state}
              />
              <ContactInfoLabel
                title={t("contactDetails.postcode")}
                value={businessData.address1?.zipcode}
              />
              <ContactInfoLabel
                title={t("contactDetails.anotherPostcode")}
                value={businessData.address2?.zipcode}
              />
              <ContactInfoLabel
                title={t("contactDetails.country")}
                value={businessData.address1?.country}
              />
              <ContactInfoLabel
                title={t("contactDetails.anotherCountry")}
                value={businessData.address2?.country}
              />
            </>
          ) : businessFetchStatus === "loading" ? (
            <>{Skeletons}</>
          ) : null}
        </Box>
      </CardSection>
      <Divider />
      <ContactSection
        facebook={businessData?.ims_facebook}
        whatsapp={businessData?.ims_whatsapp}
        mercadlibre={businessData?.ims_mercadolibre}
        webchat={businessData?.ims_webchat}
        instagram={businessData?.ims_instagram}
        emails={businessData?.emails}
        phones={businessData?.phones}
      />
    </AccordionCard>
  );
};

export default DetailsCard;
