import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionListContext } from '../../../../../context/SubscriptionListContext';
// Redux
import { useSelector } from 'react-redux';
import { selectSubscriptionDeleteStatus } from '../../../../../../../../../../../ReduxToolkit/features/configSlice/configSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';

function DeleteButtons() {
  const { deleteNameField, selectedField, handleDelete, closeDelete } = useContext(
    SubscriptionListContext
  );
  const { t } = useTranslation();
  const subscriptionsDeleteStatus = useSelector(selectSubscriptionDeleteStatus);
  const isDisabled =
    subscriptionsDeleteStatus === 'loading' ||
    deleteNameField.attributes.value !== selectedField.name;

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeDelete}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        variant="contained"
        size="small"
        color="error"
        disabled={isDisabled}
        loading={subscriptionsDeleteStatus === 'loading'}
        onClick={handleDelete}
        startIcon={<DeleteIcon />}
      >
        {t('global.delete')}
      </LoadingButton>
    </Box>
  );
}
export default DeleteButtons;
