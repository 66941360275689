import React, { useContext, useEffect } from 'react';
//@ts-ignore
import { ButtonsListContext } from '../../../../context/ButtonListContext.tsx';
import { useTranslation } from 'react-i18next';
// Types
import { ButtonsListContextType } from '../../../../context/types';

// Components/ui
import { Box, InputLabel, TextField } from '@mui/material';

function DeletePanel() {
  const { t } = useTranslation();
  const { selectedButton, deleteField } = useContext(
    ButtonsListContext
  ) as ButtonsListContextType;

  useEffect(() => {
    const cleanUp = () => {
      deleteField.actions.resetValue();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={'center'}
      p={2}
    >
      <InputLabel
        sx={{
          whiteSpace: 'break-spaces',
        }}
      >
        {t('settingsView.deleteButtonLabel')}
        <strong style={{ marginLeft: 3 }}>{selectedButton?.name}</strong>
      </InputLabel>
      <TextField
        {...deleteField.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        sx={{
          marginBottom: '15px',
          '& legend': {
            display: 'none',
          },
          '& fieldset': {
            top: 0,
          },
        }}
      ></TextField>
    </Box>
  );
}

export default DeletePanel;
