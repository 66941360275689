// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Phones,
  Mails,
  Facebook,
  Instagram,
  WebChat,
  Whatsapps,
} from './components';
import { ContactSection } from 'components/ContactCreateEditAppBody/layout';
// Types
import type UseEditContact from 'features/Views/ContactEdit/hooks/useEditContact/types/UseEditContact';
import type UseCreateContact from 'features/Views/ContactCreate/hooks/useCreateContact/types/UseCreateContact';
import { useEffect } from 'react';

interface Props {
  contact: UseEditContact | UseCreateContact;
}

const InformationSection = ({ contact }: Props) => {
  const { t } = useTranslation();
  const isBusiness = window.location.pathname.includes('business');
  const title = isBusiness
    ? t('businessDetails.businessInformation')
    : t('contactDetails.contactInformation');
  const {
    imsFacebooks,
    imsInstagrams,
    imsWebchats,
    emails,
    phoneNumbers,
    imsWhatsapp,
  } = contact.field;
  const { addEmail, addPhone, addWhatsapp } = contact.action;

  return (
    <ContactSection sectionTitle={title}>
      <Phones handleAdd={addPhone} phoneNumbers={phoneNumbers} />
      <Mails handleAdd={addEmail} emails={emails} />
      <Whatsapps handleAdd={addWhatsapp} whatsapps={imsWhatsapp} />
      <Facebook />
      <Instagram />
      <WebChat />
    </ContactSection>
  );
};

export default InformationSection;
