import React, { useContext } from 'react';
import { ListsContext } from '../../../../../../context/ListsContext';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectSelectedList } from '../../../../../../../../../ReduxToolkit/features/listsSlice/listsSlice';
// Types
import { IList } from '@trii/types/dist/Contacts';
import { IListsContext } from '../../../../../../context/types';
// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const { t } = useTranslation();
  const { closeDeletePanel } = useContext(ListsContext) as IListsContext;
  const selectedList: IList = useSelector(selectSelectedList);

  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
    >
      <Box display={'flex'} gap={1}>
        <Typography variant="subtitle2" color="text.primary">
          {t('listsView.deleteList')}
        </Typography>
        <Typography variant="subtitle2" color="text.primary">
          {selectedList.name}
        </Typography>
      </Box>
      <IconButton onClick={closeDeletePanel}>
        <CloseIcon
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  );
};

export default Header;
