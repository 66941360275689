import { useEffect } from 'react';
import SegmentsProvider from './context/SegmentsProvider';
// Redux
import { useDispatch } from 'react-redux';
import { fetchSegments } from 'ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Components
import { Box, Divider } from '@mui/material';
import {
  SegmentsSidePanel,
  SegmentsContent,
  SegmentsHeader,
  SegmentsErrorAlert,
} from './components';

export default function SegmentsContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSegments());
  }, [dispatch]);

  return (
    <SegmentsProvider>
      <Box display={'flex'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          height={'100%'}
          width={'100%'}
          className={'contacts-paddingmd'}
          borderRadius={2}
        >
          <SegmentsHeader />
          <Divider sx={{ marginTop: '10px', marginX: '10px' }} />
          <SegmentsContent />
        </Box>
        <SegmentsSidePanel />
      </Box>
      <SegmentsErrorAlert />
    </SegmentsProvider>
  );
}
