// Types
import { RootState } from 'ReduxToolkit/store';
import { createSelector } from '@reduxjs/toolkit';

const createStatusSelector = (statusKey: string) =>
  createSelector(
    (state: RootState) => state.Business.status[statusKey],
    (status) => status
  );

export const businessState = (state: RootState) => state.Business;

export const selectBusinessStatusCreate = createStatusSelector('create');
export const selectBusinessStatusUpdate = createStatusSelector('update');
export const selectBusinessStatusFetchBusinessById =
  createStatusSelector('fetchBusinessById');
export const selectBusinessStatusfetchBussinesFields =
  createStatusSelector('fetchBussinesFields');
export const selectBusinessData = createSelector(
  businessState,
  (state) => state.businessData
);
export const selectBusinessFields = createSelector(
  businessState,
  (state) => state.businessFields
);
export const selectBusinessUpdatePhotoStatus = createStatusSelector('updatePhoto');
// export const selectBusinessDeletePhotoStatus = createStatusSelector('deletePhoto');
