// Components/ui
import { Column } from "./components";
import { TableCell, TableRow } from "@mui/material";
import { StyledTableHead } from "./styles";
// Types
import { ActivitiesTableColumn } from "../../../../context/types/ActivitiesTableColumn";
import { SortOrder } from "types/SortOrder";
import { useContext } from "react";
import { Style } from "style/types/Style";
import { StyleContext } from "style/styleProvider";

interface HeadProps {
  columns: ActivitiesTableColumn[];
  sortFunction: (columnName: string, sortOrder: SortOrder) => void;
}

const Head = ({ columns, sortFunction }: HeadProps) => {
  const { color } = useContext<Style>(StyleContext);

  return (
    <StyledTableHead>
      <TableRow>
        {columns.map((column) => (
          <Column sortFunction={sortFunction} key={column.name} data={column} />
        ))}
        <TableCell
          sx={{
            py: "10px",
            userSelect: "none",
          }}
        >
          <span style={{ color: color.slate[400], marginRight: 6 }}>|</span>
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export default Head;
