import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Context
import { SegmentsEditContext } from 'features/Views/SegmentsEdit/context/SegmentsEditProvider';
// Types
import type { Operator } from 'ReduxToolkit/features/segmentsSlice/types';
// Components/ui
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

interface ToggleConditionsButtonsProps {
  itemOperator: Operator;
  groupId: string;
}

const ToggleConditionButtons = ({
  itemOperator,
  groupId,
}: ToggleConditionsButtonsProps) => {
  const { handleItemOperatorToggle } = useContext(SegmentsEditContext);
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup
      value={itemOperator}
      exclusive
      onChange={(e) => handleItemOperatorToggle(e, groupId)}
      sx={{
        height: 23,
        mt: 1.5,
      }}
    >
      <ToggleButton value="AND">{t('segmentsEdit.and')}</ToggleButton>
      <ToggleButton value="OR">{t('segmentsEdit.or')}</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleConditionButtons;
