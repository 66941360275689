// Redux
import { useSelector } from 'react-redux';
import { RequestStatus } from '../../../../../../../types/reduxTypes';
import {
  selectDuplicatesTotalItems,
  selectDuplicatesStatus,
} from '../../../../../../../ReduxToolkit/features/duplicatesSlice/duplicatesSlice';
// Components/ui
import { Box, Typography, Skeleton } from '@mui/material';

function DuplicatesAmount() {
  const duplicatesStatus: RequestStatus = useSelector(selectDuplicatesStatus);
  const duplicatesTotalItems: number = useSelector(selectDuplicatesTotalItems);

  const DuplicatesAmount =
    duplicatesStatus === "succeeded" ? (
      duplicatesTotalItems
    ) : duplicatesStatus === "loading" ? (
      <Skeleton
        variant="text"
        width={45}
        height={30}
        sx={{ backgroundColor: "lightgray" }}
      />
    ) : null;

  return (
    <Box display={'flex'} justifyContent={'end'} alignItems={'center'} mb={1}>
      <Typography
        mr={2}
        sx={{
          color: (theme) => theme.palette.text.primary,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        Total de duplicados: {DuplicatesAmount}
      </Typography>
    </Box>
  );
}
export default DuplicatesAmount;
