import { useContext, useEffect, useState } from 'react';
import { StyleContext } from 'style/styleProvider';
import { labelContext } from 'features/Views/Label/context/LabelProvider';
// Types
import { ILabel } from '@trii/types/dist/Conversations';
// Components/ui
import { IconButton, Stack, Box, Fade, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LabelIcon from '@mui/icons-material/Label';

interface LabelItemProps {
  data: ILabel;
}

const LabelItem = ({ data }: LabelItemProps) => {
  const { fontSize, fontWeight, shadow } = useContext(StyleContext);
  const { openDelete, openEdit } = useContext(labelContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade style={{ transitionDuration: '750ms' }} in={show}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          display={'flex'}
          bgcolor="dropdownBox"
          boxShadow={shadow.md}
          borderRadius={1}
          color="text.primary"
          sx={{ width: '100%', px: 1, py: 0.5 }}
        >
          <Box
            className="contacts-d-flex contacts-justify-content-between contacts-w-100"
            alignItems={'center'}
          >
            <LabelIcon
              sx={{
                color: (color) => color.palette.text.primary,
                fontSize: '1.5rem',
              }}
            />
            <Box
              className="contacts-mr-auto contacts-py-1 contacts-pr-4 pl-2"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box display={'flex'} alignItems="center">
                <Typography fontWeight={fontWeight.normal} fontSize={fontSize.sm}>
                  {data.name}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="end"
              justifyContent="space-evenly"
            >
              <Stack direction="row">
                <IconButton
                  onClick={() => openEdit(data)}
                  sx={{ color: 'primary.main' }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{ color: 'error.main' }}
                  onClick={() => openDelete(data)}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default LabelItem;
