import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// Translation
import { useTranslation } from "react-i18next";
import "./NavMenu.css";
import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
// Utils
import { iconsData } from "./utils/iconsData";
import { useSelector } from "react-redux";
import { selectSpaceInfo } from "ReduxToolkit/features/spaceSlice/spaceSlice";

type TooltipProps = {
  className?: string;
  title: string;
  placement: "right";
  children: React.ReactElement;
};

const TooltipPers = styled(
  ({ className, children, placement, title, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      title={title}
      placement={placement}
    >
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    //@ts-ignore
    backgroundColor: theme.palette.primary.tooltip,
  },
}));

const NavMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const space = useSelector(selectSpaceInfo);

  const filteredIconsData = iconsData.filter((icon) => {
    if (space?.showItemsInDevelopment) {
      return true;
    } else {
      return !icon.isDevelopment;
    }
  });

  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);

  useEffect(() => {
    const foundIcon = filteredIconsData.find((icon) =>
      location.pathname.toLowerCase().includes(icon.to.toLowerCase())
    );
    if (foundIcon) {
      setSelectedIcon(foundIcon.to);
    }
  }, [location, filteredIconsData]);

  const handleNavigate = (route: string, button: number) => {
    if (button === 1) {
      window.open(route, '_blank');
    } else {
      navigate(route);
      setSelectedIcon(route);
    }
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "center",
          gap: 2,
          marginTop: 2,
        }}
      >
        {filteredIconsData.map((icon, index) => (
          <TooltipPers title={t(icon.title)} placement="right" key={index}>
            <IconButton
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                borderRadius: 1,
                backgroundColor:
                  icon.to === selectedIcon
                    ? (theme) => `${theme.palette.grey[200]}30`
                    : "transparent",
                "&:focus": {
                  outline: "none",
                },
                "&:hover": {
                  backgroundColor: (theme) => `${theme.palette.grey[200]}30`,
                  color: (theme) => theme.palette.primary.contrastText,
                },
                width: "1.5em",
                height: "1.5em",
              }}
              disableRipple
              onMouseDown={(e) => handleNavigate(icon.to, e.button)}
            >
              {icon.icon}
            </IconButton>
          </TooltipPers>
        ))}
      </Box>
    </Box>
  );
};

export default NavMenu;
