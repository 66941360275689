import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

//Input component with atributes and placeholder props, better used with useField hook

export default function Input({
  isDisabled,
  label,
  atributes,
  style,
  onKeyPress,
  onBlur,
}) {
  return (
    <Grid container item xs={12}>
      <TextField
        disabled={isDisabled}
        sx={{ bgcolor: isDisabled ? 'main.center' : null }}
        label={label}
        variant="outlined"
        className="fadein"
        fullWidth
        size="small"
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        {...atributes}
        {...style}
        max="5"
      />
    </Grid>
  );
}

Input.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  atributes: PropTypes.object,
};
