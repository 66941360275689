// Components/ui
import { Box, TextField } from '@mui/material';
// Types
import { UseFieldType } from 'hooks/useField';

interface Props {
  fieldData: UseFieldType;
  label: string;
}

const BusinessInfoField = ({ fieldData, label }: Props) => {
  return (
    <Box
      className="fadein"
      sx={{
        display: 'flex',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? '1e1e1e'
            : theme.palette.background.default,
        flexDirection: 'column',
        borderRadius: 1,
        width: '45%',
      }}
    >
      <Box component="form" noValidate autoComplete="off">
        <TextField
          size="small"
          label={label}
          variant="outlined"
          {...fieldData.attributes}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          className='contacts-smallInput'
        />
      </Box>
    </Box>
  );
};

export default BusinessInfoField;
