import { memo, useContext, useEffect, useState } from 'react';
import { FilesCardContext } from '../../../../../../context/FilesCardContext';
import { useSelector } from 'react-redux';
import { selectFilesDeleteStatus } from 'ReduxToolkit/features/fileSlice/utils/selectors';
import moment from 'moment/moment';
import {
  TableCell,
  TableRow,
  Link,
  Typography,
  styled,
  IconButton,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IFile } from '@trii/types/dist/Contacts';
import { formatBytes } from './utils/formatBytes';
import useSAS from 'hooks/useSAS';  // Importa el hook useSAS

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  height: 35,
}));

const StyledTypography = styled(Typography)({
  userSelect: 'none',
});

interface FilesRowProps {
  fileData: IFile;
}

const FilesRow = memo(({ fileData }: FilesRowProps) => {
  const { openModalDeleteFile } = useContext(FilesCardContext);
  const filesDeleteStatus = useSelector(selectFilesDeleteStatus);
  const isDeleteLoading = filesDeleteStatus.includes(fileData.id);

  const { getURLWithAccessToken } = useSAS();  // Usamos el hook useSAS
  const [secureUrl, setSecureUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchSecureUrl = async () => {
      if (fileData.url) {
        const urlWithToken = await getURLWithAccessToken(fileData.url);
        setSecureUrl(urlWithToken);
      }
    };

    fetchSecureUrl();
  }, [fileData.url, getURLWithAccessToken]);

  return (
    <StyledTableRow>
      <TableCell sx={{ padding: '0 24px' }}>
        <Link href={secureUrl || fileData.url} target="_blank" rel="noopener">
          <StyledTypography variant="body2">{fileData.fileName}</StyledTypography>
        </Link>
      </TableCell>
      <TableCell sx={{ padding: '0 24px' }}>
        <Typography variant="body2" style={{ userSelect: 'none' }}>
          {fileData.createdBy.name}
        </Typography>
      </TableCell>
      <TableCell sx={{ padding: '0 24px' }}>
        <Typography variant="body2" style={{ userSelect: 'none' }}>
          {moment(fileData.createdAt).format('L, h:mm ')}
        </Typography>
      </TableCell>
      <TableCell sx={{ padding: '0 24px' }}>
        <Typography variant="body2" style={{ userSelect: 'none' }}>
          {formatBytes(fileData.size)}
        </Typography>
      </TableCell>
      <TableCell sx={{ padding: '0 24px' }}>
        {isDeleteLoading ? (
          <CircularProgress size={22} />
        ) : (
          <IconButton
            onClick={() => openModalDeleteFile(fileData.id)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </StyledTableRow>
  );
});

export default FilesRow;
