import { NavLink } from 'react-router-dom';
// Translation
import { useTranslation } from 'react-i18next';
// Components
import HandymanIcon from '@mui/icons-material/Handyman';
import { Box, Card, CardActionArea, Theme, Typography } from '@mui/material';
// Types
import type For from 'types/For';

type Props = {
  variant: For;
};

const CustomFields = ({ variant }: Props) => {
  const { t } = useTranslation();

  const Subtitle =
    variant === 'contact' ? t('global.contacts') : t('settingsView.enterprises');

  const PathURL =
    variant === 'contact'
      ? '/a/contacts/settings/customfields-contact'
      : '/a/contacts/settings/customfields-business';

  return (
    <Box
      component={Card}
      borderRadius={2}
      width={"fit-content"}
      data-aos={"fade-up"}
      minWidth={"225px"}
      sx={{
        backgroundColor: (theme: Theme) => theme.palette.background.default,
      }}
    >
      <CardActionArea>
        <NavLink to={PathURL}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
            padding={4}
            sx={{ color: (theme: Theme) => theme.palette.text.primary }}
          >
            <Box marginBottom={4}>
              <HandymanIcon sx={{ fontSize: "2.5rem" }} />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography sx={{ marginBottom: 1 }} variant={"body2"}>
                {t("settingsView.customFields")}
              </Typography>
              <Typography variant={"subtitle2"}>{Subtitle}</Typography>
            </Box>
          </Box>
        </NavLink>
      </CardActionArea>
    </Box>
  );
};

export default CustomFields;
