import { INavIcon } from '../types/types';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PersonIcon from '@mui/icons-material/Person';
import ViewListIcon from '@mui/icons-material/ViewList';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import BusinessIcon from '@mui/icons-material/Business';

export const iconsData: INavIcon[] = [
  // {
  //   title: 'navMenu.contacts',
  //   to: '/a/contacts/contacts',
  //   icon: <PersonIcon />,
  // },
  {
    title: 'navMenu.contacts',
    to: '/a/contacts/Contacts',
    icon: <PersonIcon />,
    // isDevelopment: true,
  },
  {
    title: 'navMenu.business',
    to: '/a/contacts/business',
    icon: <BusinessIcon />,
    // isDevelopment: true,
  },
  // {
  //   title: 'navMenu.manageLists',
  //   to: '/a/contacts/listManagement',
  //   icon: <ViewListIcon />,
  // },
  {
    title: 'navMenu.manageLists',
    to: '/a/contacts/ListManagement',
    icon: <ViewListIcon />,
    // isDevelopment: true,
  },
  {
    title: 'navMenu.segments',
    to: '/a/contacts/segments',
    icon: <AltRouteIcon />,
    // isDevelopment: true,
  },
  // {
  //   title: 'navMenu.duplicates',
  //   to: '/a/contacts/duplicates',
  //   icon: <ContentCopyIcon />,
  // },
  {
    title: 'navMenu.duplicates',
    to: '/a/contacts/Duplicates',
    icon: <ContentCopyIcon />,
    // isDevelopment: true,
  },
  // {
  //   title: 'navMenu.import',
  //   to: '/a/contacts/importContacts',
  //   icon: <FileUploadIcon />,
  // },
  {
    title: 'navMenu.import',
    to: '/a/contacts/ImportContacts',
    icon: <FileUploadIcon />,
//    isDevelopment: true,
  },
  {
    title: 'navMenu.export',
    to: '/a/contacts/exportContacts',
    icon: <CloudDownloadIcon />,
    // isDevelopment: true,
  },
  // {
  //   title: 'navMenu.settings',
  //   to: '/a/contacts/settings',
  //   icon: <SettingsIcon />,
  // },
  {
    title: 'navMenu.settings',
    to: '/a/contacts/Settings',
    icon: <SettingsIcon />,
    // isDevelopment: true,
  },
];
