import { ChangeEvent, useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectCustomContactFields } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { selectUser } from 'ReduxToolkit/features/userSlice/userSlice';
// Types
import { IContactAddress, IContactInfo, Property } from '@trii/types/dist/Contacts';
import { NewAddress } from 'types/NewAddress';
import type { TableContactField } from 'ReduxToolkit/features/contactsSlice/types/TableContactField';
import type UseCreateBusiness from './types/UseCreateBusiness';
import { ChannelType } from '@trii/types/dist/Common/Channels';
// Hooks
import useField from 'hooks/useField';
import useImage from 'hooks/useImage';
import { useMultipleSelect } from 'hooks/useMultipleSelect';
// Utils
import contactHelper from 'utils/contactHelper';
import useSelect from 'hooks/useSelect';

const useCreateBusiness = (): UseCreateBusiness => {
  const user = useSelector(selectUser);
  const customContactFields = useSelector(selectCustomContactFields);
  const [customProperties, setCustomProperties] = useState<Property[]>([]);
  // Contact general fields
  const name = useField('text');
  const contactPropietary = useSelect(user.display_name);
  const contactImage = useImage('');
  const labelMultipleSelect = useMultipleSelect();
  const [selectedContacts, setSelectedContacts] = useState<IContactInfo[]>([]);
  // Contact information fields
  const [imsFacebooks, setImsFacebooks] = useState<IContactAddress[]>([]);
  const [imsWebchats, setImsWebchats] = useState<IContactAddress[]>([]);
  const [imsInstagrams, setImsInstagrams] = useState<IContactAddress[]>([]);
  const [imsWhatsapp, setImsWhatsApp] = useState<NewAddress[]>([]);
  const [emails, setEmails] = useState<NewAddress[]>([]);
  const [phoneNumbers, setPhoneNumbers] = useState<NewAddress[]>([]);
  // Address information fields
  const homeAddress = useField('text');
  const secondaryHomeAddress = useField('text');
  const city = useField('text');
  const secondaryCity = useField('text');
  const state = useField('text');
  const secondaryState = useField('text');
  const postalCode = useField('text');
  const secondaryPostalCode = useField('text');
  const country = useField('text');
  const secondaryCountry = useField('text');

  //Utils
  const getField = (key: string, value) => {
    if (value !== '') {
      const field = {};
      field[key] = value;
      return field;
    }
    return null;
  };
  const createProperty = (customField: TableContactField): Property => {
    const property = {
      nameKey: customField.name,
      value: '',
      type: customField.type,
    };
    return property;
  };
  // Actions
  const getBusiness = () => {
    const modifiedPhones = phoneNumbers.map((phoneNumber) => {
      const { id, ...rest } = phoneNumber;
      return rest;
    });
    const modifiedEmails = emails.map((email) => {
      const { id, ...rest } = email;
      return rest;
    });
    const modifiedImsWhatsapp = imsWhatsapp.map((whatsapp) => {
      const { id, ...rest } = whatsapp;
      return rest;
    });
    const address1 = {
      street: homeAddress.attributes.value,
      city: city.attributes.value,
      state: state.attributes.value,
      zipcode: postalCode.attributes.value,
      country: country.attributes.value,
    };
    const address2 = {
      street: secondaryHomeAddress.attributes.value,
      city: secondaryCity.attributes.value,
      state: secondaryState.attributes.value,
      zipcode: secondaryPostalCode.attributes.value,
      country: secondaryCountry.attributes.value,
    };
    const properties = [...customProperties];

    const newBusiness = {
      ...getField('name', name.attributes.value),
      owner: contactPropietary.attributes.value,
      imageUrl: contactImage.imageUrl,
      members: selectedContacts,
      tags: labelMultipleSelect.attributes.value,
      emails: modifiedEmails,
      phones: modifiedPhones,
      ims_whatsapp: modifiedImsWhatsapp,
      properties,
      address1,
      address2,
    };

    return newBusiness;
  };
  const addPhone = () => {
    const newPhone = contactHelper.createNewAddress(ChannelType.PHONE);
    const newPhoneNumbers = [...phoneNumbers, newPhone];
    setPhoneNumbers(newPhoneNumbers);
  };
  const addEmail = () => {
    const newEmail = contactHelper.createNewAddress(ChannelType.EMAIL);
    const newEmails = [...emails, newEmail];
    setEmails(newEmails);
  };
  const addWhatsapp = () => {
    const newWhatsapp = contactHelper.createNewAddress(ChannelType.WHATSAPP);
    const newWhatsapps = [...imsWhatsapp, newWhatsapp];
    setImsWhatsApp(newWhatsapps);
  };
  const handlePhoneNumbersNumberFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string
  ) => {
    const newPhoneNumbers = phoneNumbers.map((phoneNumber) => {
      if (phoneNumber.id === fieldId) {
        return {
          ...phoneNumber,
          address: e.target.value,
        };
      }
      return phoneNumber;
    });
    setPhoneNumbers(newPhoneNumbers);
  };
  const handleEmailsEmailFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string
  ) => {
    const newEmails = emails.map((email) => {
      if (email.id === fieldId) {
        return {
          ...email,
          address: e.target.value,
        };
      }
      return email;
    });
    setEmails(newEmails);
  };
  const handleWhatsappsAddressFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string
  ) => {
    const newWhatsapp = imsWhatsapp.map((whatsapp) => {
      if (whatsapp.id === fieldId) {
        return {
          ...whatsapp,
          address: e.target.value,
        };
      }
      return whatsapp;
    });
    setImsWhatsApp(newWhatsapp);
  };
  const handleNoteFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldId: string,
    addressType: ChannelType
  ) => {
    if (addressType === 14) {
      const newImsFacebooks = imsFacebooks.map((imsFacebook) => {
        if (imsFacebook.id === fieldId) {
          return {
            ...imsFacebook,
            note: e.target.value,
          };
        }
        return imsFacebook;
      });
      setImsFacebooks(newImsFacebooks);
    }
    if (addressType === 20) {
      const newImsWebchats = imsWebchats.map((imsWebchat) => {
        if (imsWebchat.id === fieldId) {
          return {
            ...imsWebchat,
            note: e.target.value,
          };
        }
        return imsWebchat;
      });
      setImsWebchats(newImsWebchats);
    }
    if (addressType === 16) {
      const newImsInstagrams = imsInstagrams.map((imsInstagram) => {
        if (imsInstagram.id === fieldId) {
          return {
            ...imsInstagram,
            note: e.target.value,
          };
        }
        return imsInstagram;
      });
      setImsInstagrams(newImsInstagrams);
    }
    if (addressType === 3) {
      const newEmails = emails.map((email) => {
        if (email.id === fieldId) {
          return {
            ...email,
            note: e.target.value,
          };
        }
        return email;
      });
      setEmails(newEmails);
    }
    if (addressType === 5) {
      const newPhoneNumbers = phoneNumbers.map((phoneNumber) => {
        if (phoneNumber.id === fieldId) {
          return {
            ...phoneNumber,
            note: e.target.value,
          };
        }
        return phoneNumber;
      });
      setPhoneNumbers(newPhoneNumbers);
    }
  };
  const handleDeleteAddress = (fieldId: string, addressType: ChannelType) => {
    if (addressType === 14) {
      const newImsFacebooks = imsFacebooks.filter(
        (imsFacebook) => imsFacebook.id !== fieldId
      );
      setImsFacebooks(newImsFacebooks);
    }
    if (addressType === 20) {
      const newImsWebchats = imsWebchats.filter(
        (imsWebchat) => imsWebchat.id !== fieldId
      );
      setImsWebchats(newImsWebchats);
    }
    if (addressType === 16) {
      const newImsInstagrams = imsInstagrams.filter(
        (imsInstagram) => imsInstagram.id !== fieldId
      );
      setImsInstagrams(newImsInstagrams);
    }
    if (addressType === 3) {
      const newEmails = emails.filter((email) => email.id !== fieldId);
      setEmails(newEmails);
    }
    if (addressType === 5) {
      const newPhoneNumbers = phoneNumbers.filter(
        (phoneNumber) => phoneNumber.id !== fieldId
      );

      setPhoneNumbers(newPhoneNumbers);
    }
  };

  useEffect(() => {
    if (customContactFields) {
      const newCustomProperties = customContactFields.map((customField) =>
        createProperty(customField)
      );
      setCustomProperties(newCustomProperties);
    }
  }, [customContactFields]);

  return {
    field: {
      imsWhatsapp,
      name,
      contactPropietary,
      contactImage,
      imsFacebooks,
      imsWebchats,
      imsInstagrams,
      emails,
      phoneNumbers,
      homeAddress,
      secondaryHomeAddress,
      city,
      secondaryCity,
      state,
      secondaryState,
      postalCode,
      secondaryPostalCode,
      country,
      secondaryCountry,
      customProperties,
      labelMultipleSelect,
    },
    action: {
      setCustomProperties,
      getBusiness,
      handleNoteFieldChange,
      handlePhoneNumbersNumberFieldChange,
      handleEmailsEmailFieldChange,
      handleDeleteAddress,
      addPhone,
      addEmail,
      addWhatsapp,
      handleWhatsappsAddressFieldChange,
    },
    selectedContacts,
    setSelectedContacts,
  };
};

export default useCreateBusiness;
