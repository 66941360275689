const mailStyle = {
  emailField: {
    width: '60%',
  },
  numberCountryContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '50%',
  },
  warningMessage: {
    position: "absolute",
    bottom: "-30px"
  },
  adressInputBox: {
    position: "relative",
    width: "60%"
  }
};

export default mailStyle;
