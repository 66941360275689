import { useState, useEffect, useContext } from 'react';
// Context
import { CustomFieldContext } from '../../context/CustomFieldContext';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCustomContactFields,
  selectCustomContactFields,
  setCustomContactFields,
  updateCustomContactFieldsOrder,
} from 'ReduxToolkit/features/configSlice/configSlice';
// Drag and drop
import { arrayMove } from '@dnd-kit/sortable';
import { DndContext } from '@dnd-kit/core';
import { closestCenter } from '@dnd-kit/core';
// Components/ui
import { Body, Header } from './components';
import { Box, Divider } from '@mui/material';
// Types
import { IContactField } from '@trii/types/dist/Contacts';
import type For from 'types/For';

type Props = {
  fetchFor: For;
};

const CustomFieldsList = ({ fetchFor }: Props) => {
  const { openPanelCP, handleDrawerOpen } = useContext(CustomFieldContext);
  const dispatch = useDispatch();
  const contactFields = useSelector(selectCustomContactFields);
  // Change order state
  const [canSaveOrder, setCanSaveOrder] = useState(false);
  const [loadingSaveOrder, setLoadingSaveOrder] = useState(false);
  const [originalFieldsOrder, setOriginalFieldsOrder] = useState<IContactField[]>(
    []
  );

  // Drag and Drop functionality
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = contactFields.findIndex((item) => item.id === active.id);
      const newIndex = contactFields.findIndex((item) => item.id === over.id);
      const newContactFields = arrayMove(contactFields, oldIndex, newIndex);
      const isSameOrder = compareFieldsOrder(newContactFields);

      setCanSaveOrder(!isSameOrder);
      dispatch(setCustomContactFields(newContactFields));
    }
  };
  const handleSaveOrder = async () => {
    const updatedOrderCustomContactFields = contactFields.map((fieldA) => ({
      ...fieldA,
      order: contactFields.indexOf(fieldA),
    }));
    const updatedOrderCustomContactFieldsJson = JSON.stringify({
      fields: updatedOrderCustomContactFields,
    });

    setLoadingSaveOrder(true);
    await dispatch(
      updateCustomContactFieldsOrder(updatedOrderCustomContactFieldsJson)
    );
    setOriginalFieldsOrder(updatedOrderCustomContactFields);
    setLoadingSaveOrder(false);
  };
  const compareFieldsOrder = (newFieldsArr) => {
    for (let i = 0; i < originalFieldsOrder.length; i++) {
      if (originalFieldsOrder[i].name !== newFieldsArr[i].name) {
        return false;
      }
    }

    return true;
  };
  // End drag and drop functionality

  useEffect(() => {
    const initApp = async () => {
      const response = await dispatch(fetchCustomContactFields(fetchFor));
      // @ts-ignore
      setOriginalFieldsOrder(response.payload);
    };

    initApp();
  }, []);

  useEffect(() => {
    setCanSaveOrder(!compareFieldsOrder(contactFields));
  }, [originalFieldsOrder]);

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        height={'100%'}
        width={'100%'}
        className={'contacts-paddingmd paddingContent'}
        borderRadius={2}
        id={'listContentCP'}
      >
        <Header
          canSaveOrder={canSaveOrder}
          loadingSaveOrder={loadingSaveOrder}
          handleSaveOrder={handleSaveOrder}
          open={handleDrawerOpen}
          show={openPanelCP}
          fetchFor={fetchFor}
        />
        <Divider sx={{ marginY: '10px', marginX: '10px' }} />
        <Body contactFields={contactFields} />
      </Box>
    </DndContext>
  );
};

export default CustomFieldsList;
