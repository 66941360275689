import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsListContext } from '../../context/ButtonListContext.tsx';
// Components/ui
import CloseIcon from '@mui/icons-material/Close';
import {
  CreateEditPanel,
  DeletePanel,
  DeleteButtons,
  EditButtons,
  CreateButtons,
  PanelHeader,
} from './components';
import {
  Box,
  Typography,
  IconButton,
  Divider,
  TextField,
  Modal,
  Card,
} from '@mui/material';

function CrudPanel() {
  const { isEditing, isDelete, openModalBoton, handleCloseModalTry } =
    useContext(ButtonsListContext);
  const { t } = useTranslation();

  const PanelContent = isDelete ? <DeletePanel /> : <CreateEditPanel />;

  const PanelButtons = isDelete ? (
    <DeleteButtons />
  ) : isEditing ? (
    <EditButtons />
  ) : (
    <CreateButtons />
  );

  return (
    <Box
      sx={{
        width: '25%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '25%',
        },
        marginLeft: '20px',
        // marginTop: '5px',
        height: 'calc(100vh - 86px)',
        position: 'relative',
        zIndex: 0,
        backgroundColor: (theme) => theme.palette.background.panel,
      }}
    >
      <PanelHeader />
      <Divider />
      {PanelContent}
      <Box position={'absolute'} bottom={'65px'} width="100%" >
        <Divider />
      </Box>
      {PanelButtons}
      <Modal
        open={false}
        // open={openModalBoton}
        // onClose={handleCloseModalTry}
      >
        <Card
          sx={{
            margin: 2,
          }}
        >
          <Box
            display={'flex'}
            justifyContent="space-between"
            alignItems={'center'}
            py={1}
            px={2}
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
            }}
          >
            <Typography variant="subtitle2">General</Typography>
            <Box display={'flex'} alignItems={'center'}>
              <IconButton onClick={handleCloseModalTry}>
                <CloseIcon
                  sx={{
                    fontSize: '20px',
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection="column"
            justifyContent="space-between"
            alignItems={'start'}
            p={2}
          >
            <TextField
              className="fadein"
              variant="outlined"
              label={t('global.name')}
              type=""
              size="small"
              defaultValue={''}
              fullWidth
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
          </Box>
        </Card>
      </Modal>
    </Box>
  );
}

export default CrudPanel;
