import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchContact, setContactAddress, updateContactAddress } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { Box, IconButton, TextField, CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import noteDeleteButtonStyle from './style';
import { ChannelType } from '@trii/types/dist/Common/Channels';
import { NewAddress } from 'types/NewAddress';
import { SetContactAddressData } from 'ReduxToolkit/features/contactsSlice/types/SetContactAddressData';
import { UpdateContactAddressData } from 'ReduxToolkit/features/contactsSlice/types/UpdateContactAddressData';
import { Dispatch, SetStateAction } from 'react';
import { IContactAddress } from '@trii/types/dist/Contacts';
import OptionsMenu from '../../components/Phones/components/Body/components/Phone/components/OptionsMenu';

type Props = {
  addressData: NewAddress;
  handleNoteFieldChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    addressId: string,
    addressType: ChannelType
  ) => void;
  handleDeleteAddress: (addressId: string, addressType: ChannelType) => void;
  isDeletingAddress?: string | false;
  setWarnings?: Dispatch<SetStateAction<string[]>>;
  mode?: 'edit' | 'create';
  replaceAddress?: (addressId: string, newAddress: IContactAddress) => void;
  addWhatsappVerified?: (newAddress: IContactAddress) => void;
  checked?: boolean;  // Make checked optional
};

const NoteDeleteButton = ({
  addressData,
  handleNoteFieldChange,
  handleDeleteAddress,
  isDeletingAddress,
  setWarnings,
  mode,
  replaceAddress,
  addWhatsappVerified,
  checked = true,  // Set default value for checked
}: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const { businessId, contactId } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { note, channelType, id, address } = addressData;
  const addressIsSaved = !addressData.hasOwnProperty('isSaved');

  const handleSetAddress = async (type: ChannelType) => {
    const contactAddress: SetContactAddressData = {
      requestData: {
        address,
        contactId: contactId || businessId,
        type,
      },
      addressId: id,
    };
    setWarnings([]);
    
    const response = await dispatch(setContactAddress(contactAddress)) as { payload: { newAddress?: IContactAddress } };
    
    if (response.payload.newAddress) {
      replaceAddress(id, response.payload.newAddress);
      addWhatsappVerified(response.payload.newAddress);

    } else {
      console.error('Failed to get new address from response:', response);
    }
    
    return response;
  };
  

  const handleUpdateAddress = async () => {
    const updatedContactAddress: UpdateContactAddressData = {
      contactAddressId: id,
      contactId: contactId || businessId,
      note,
    };

    await dispatch(updateContactAddress(updatedContactAddress));
  };

  const handleSave = async () => {
    setIsSaving(true);

    if (addressIsSaved) await handleUpdateAddress();
    else await handleSetAddress(channelType);

    setIsSaving(false);
  };

  const handleLinkAccountSave = async () => {
    setIsSaving(true);
    await handleSetAddress(13);    
    setIsSaving(false);
  };

  return (
    <Box
      sx={{
        ...noteDeleteButtonStyle.container,
      }}
    >
      {addressIsSaved && (
        <TextField
          size="small"
          label={t('global.note')}
          variant="outlined"
          onChange={(e) => handleNoteFieldChange(e, id, channelType)}
          value={note}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          className="contacts-smallInput"
          sx={noteDeleteButtonStyle.noteTextField}
        />
      )}
      {mode === 'edit' && (
        <IconButton onClick={handleSave}>
          {isSaving ? <CircularProgress size={24} /> : <SaveIcon color="primary" />}
        </IconButton>
      )}
      <OptionsMenu
        onDelete={() => handleDeleteAddress(id, channelType)}
        addressData={addressData}
        handleSave={handleSave}
        handleLinkAccountSave={handleLinkAccountSave}
        checked={checked && !isSaving}  // Pass the checked prop to OptionsMenu
      />
    </Box>
  );
};

export default NoteDeleteButton;
