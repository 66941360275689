const subheaderStyle = {
  backgroundColor: (theme) => theme.palette.background.dropdownMenu,
  color: (theme) => theme.palette.text.primary,
  height: '40px',
  lineHeight: '40px',
};

export const fieldsSelectStyle = {
  subheaderStyle,
};
