import React, { useContext, useEffect, useState } from 'react';
// Context
import { SegmentsEditContext } from '../../../../context/SegmentsEditProvider';
//Components/ui
import { Box, TextField, Fade, Typography, Theme } from '@mui/material';
import { ActionButtons, ContentSkeleton, ListsCheckboxes, TransferList } from './components';
import { useTranslation } from 'react-i18next';

interface ISegmentHeaderProps {
  dataReady: boolean;
}

const SegmentTransferList = ({ dataReady }: ISegmentHeaderProps) => {
  const { segmentNameField } = useContext(SegmentsEditContext);
  const [show, setShow] = useState<boolean>(false);
  const {t} = useTranslation()

  useEffect(() => {
    if (dataReady) {
      setShow(true);
    }
  }, [dataReady]);

  if (!dataReady) {
    return <ContentSkeleton />;
  }
  return (
    <Fade in={show}>
      <Box
        display={'flex'}
        sx={{
          borderRadius: 1,
          justifyContent: 'space-between',
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.dropdownBox,
          boxShadow: 1,
          p: 2,
          alignItems: 'flex-start',
          mb: 1,
          flexDirection: "column",

          // height: '20%',
        }}
      >
        {/* <Box
          display={'flex'}
          gap={2}
          flexDirection={'column'}
          // width={'40%'}
          sx={{
            width: { xs: '55%', lg: '60%', xl: '65%' },
          }}
          justifyContent={'space-around'}
          height={'100%'}
        >

        </Box> */}

          <Typography fontWeight={'500'} sx={{ color: (theme: Theme) => theme.palette.text.primary }} mb={2}>
            {t('segmentsEdit.contactList')}
          </Typography>
          <TransferList></TransferList>
      </Box>
    </Fade>
  );
};

export default SegmentTransferList;
