import { useContext } from "react";
// Context
import { SegmentsEditContext } from "features/Views/SegmentsEdit/context/SegmentsEditProvider";
// Translation
import { useTranslation } from "react-i18next";
// Dayjs
import dayjs, { Dayjs } from "dayjs";
// Components/ui
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Box } from "@mui/material"

type Props = {
  groupId: string;
  value: string;
  itemId: string;
};

const TimePickerField = ({ groupId, value, itemId }: Props) => {
  const { t } = useTranslation();
  const { handleTimeDatePickerChange } = useContext(SegmentsEditContext);

  const handleChange = (value: Dayjs) => {
    const formatedValue = dayjs(value).format("HH:mm");

    handleTimeDatePickerChange(formatedValue, itemId, groupId);
  };
  return (
    <Box sx={{ width: "30%" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={["TimePicker"]}
          sx={{
            minWidth: "0px",
            overflow: "visible",
            paddingTop: "0px",
          }}
        >
          <TimePicker
            label={t("segmentsEdit.value")}
            value={dayjs(value)}
            onChange={handleChange}
            slotProps={{
              textField: {
                size: "small",
                sx: {
                  width: "100%",
                  minWidth: "0px !important",
                },
              },
            }}
            ampm={false}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default TimePickerField;
