import { BusinessCreateContext } from './BusinessCreateContext';
// Redux
import { useDispatch } from 'react-redux';
import { createBusiness } from 'ReduxToolkit/features/businessSlice/businessSlice';
// Types
import type { ReactNode } from 'react';
import type BusinessCreateContextType from './types/BusinessCreateContextType';
// Hook
import useCreateBusiness from '../hooks/useCreateBusiness/useCreateBusiness';
import { useNavigate } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

const BusinessCreateContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const business = useCreateBusiness();

  const handleCreateButton = async () => {
    const newBusiness = business.action.getBusiness();

    await dispatch(createBusiness(newBusiness));
    navigate(`/a/contacts/business`);
  };

  const contextValue: BusinessCreateContextType = {
    business,
    handleCreateButton,
  };

  return (
    <BusinessCreateContext.Provider value={contextValue}>
      {children}
    </BusinessCreateContext.Provider>
  );
};

export default BusinessCreateContextProvider;
