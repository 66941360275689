// Components/ui
import { TextField, Box } from '@mui/material';
// Types
import { UseFieldType } from 'hooks/useField';
// Translation
import { useTranslation } from 'react-i18next';

type BodyProps = {
  isEditing: boolean;
  noteText: string;
  noteTitle: string;
  noteEditField: UseFieldType;
  titleEditField: UseFieldType;
  isEditingLoading: boolean;
};
const Body = ({
  isEditing,
  noteText,
  noteTitle,
  noteEditField,
  titleEditField,
  isEditingLoading,
}: BodyProps) => {
  const { t } = useTranslation();
  return (
    <>
      {isEditing ? (
        <>
          <TextField
            disabled={isEditingLoading}
            fullWidth
            multiline
            {...titleEditField.attributes}
            sx={{ mb: 1 }}
            placeholder={t('contactsView.notes.noteTitle')}
            size="small"
          />
          <TextField
            disabled={isEditingLoading}
            fullWidth
            multiline
            {...noteEditField.attributes}
          />
        </>
      ) : (
        <>
          <Box sx={{ fontWeight: 'bold' }} className="msg-nota__text">
            {noteTitle}
          </Box>
          <Box className="msg-nota__text">{noteText}</Box>
        </>
      )}
    </>
  );
};

export default Body;
