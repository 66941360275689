import React, { useContext, useEffect, useState } from 'react';
import { StyleContext } from '../../../../../../../../../../../style/styleProvider';
// Types
import { Style } from '../../../../../../../../../../../style/types/Style';
import { Contacts } from '@trii/types';

// Components/ui
import { Box, Typography, Avatar, Card, Fade } from '@mui/material';

interface IGoogleAccountCardProps {
  userData: Contacts.GoogleAccountInfo;
}

const GoogleAccountCard = ({ userData }: IGoogleAccountCardProps) => {
  const [show, setShow] = useState(false);
  const { fontSize } = useContext<Style>(StyleContext);
  const { imageUrl, name, email } = userData;

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <Card
        sx={{
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          padding: 2,
          width: 290,
          maxWidth: 330,
          backgroundColor: "dropdownBox"
        }}
      >
        <Avatar
          sx={{
            width: 48,
            height: 48,
            backgroundColor: (theme) => theme.palette.action.disabledBackground,
            color: 'white',
            mr: 2,
          }}
          src={imageUrl}
          alt={name.slice(0, 1)}
        />
        <Box>
          <Typography fontSize={fontSize.sm} sx={{ fontWeight: 'bold' }}>
            {name}
          </Typography>
          <Typography fontSize={fontSize.xs} color="text.secondary">
            {email}
          </Typography>
        </Box>
      </Card>
    </Fade>
  );
};

export default GoogleAccountCard;
