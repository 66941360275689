import React from 'react';
import { Column } from './components';
import { TableHead, TableRow, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Head = () => {
  const {t} = useTranslation()
  return (
    <TableHead
      sx={{
        backgroundColor: (theme: Theme) => theme.palette.background.default, 
        position: 'sticky',
        top: '0',
        zIndex: '1',
      }}
    >
     
        <TableRow>

          <Column title={t("ticketsTable.issue")} />
          <Column title={t("ticketsTable.date")} />
          <Column title={t("ticketsTable.category")} />
          <Column title={t("ticketsTable.participants")} />
          <Column title={t("ticketsTable.status")} />
        </TableRow>
   
    </TableHead>
  );
};

export default Head;
