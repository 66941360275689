// Types
import type { ReactNode } from 'react';
// Components/ui
import informationItemInputsContainerStyle from './style';
import { Box } from '@mui/material';

type Props = {
  children: ReactNode;
};

const InformationItemInputsContainer = ({ children }: Props) => {
  return <Box sx={informationItemInputsContainerStyle.container}>{children}</Box>;
};

export default InformationItemInputsContainer;
