import React from 'react';
// components/ui
import { FormControl, Select, MenuItem } from '@mui/material';

const FieldSelect = ({ columns, fieldSelect, updateChecked }) => {
  const FieldItems = columns.map((column) => (
    <MenuItem key={column.name} value={column.name}>
      {column.name}
    </MenuItem>
  ));

  return (
    <FormControl fullWidth disabled={!updateChecked} size="small">
      <Select {...fieldSelect.attributes}  MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}>{FieldItems}</Select>
    </FormControl>
  );
};

export default FieldSelect;
