import { useContext } from 'react';
import { Button, Box, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

interface ActionButtonsProps {
  closeEventPopUp: () => void;
  handleDeleteFile: (fileId?: string) => void;
  fileId: string | null;
}

const ActionButtons = ({ closeEventPopUp, handleDeleteFile, fileId }: ActionButtonsProps) => {
  const { t } = useTranslation();

  const handleDeleteClick = () => {
    if (fileId) {
      handleDeleteFile(fileId);
    }
  };

  return (
    <DialogActions>
      <Button variant="outlined" size="small" onClick={closeEventPopUp}>
        {t('global.cancel')}
      </Button>
      <LoadingButton
        size="small" variant="contained" color="error" onClick={handleDeleteClick}>
        {t('global.delete')}
      </LoadingButton>
    </DialogActions>
  );
};

export default ActionButtons;
