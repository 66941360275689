// Provider
import ContactBusinessTableContextProvider from './context/ContactBusinessTableContextProvider';
// Components/ui
import { Box } from '@mui/material';
import { AppHeader, AppBody, ModalList } from './components';

const ContactsBusinessTable = () => {
  return (
    <ContactBusinessTableContextProvider>
      <Box
        display="flex"
        width="100%"
        height={'100%'}
        flexDirection={'column'}
        className={'contacts-paddingmd'}
      >
        <AppHeader />
        <AppBody />
        <ModalList />
      </Box>
    </ContactBusinessTableContextProvider>
  );
};

export default ContactsBusinessTable;
