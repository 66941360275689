import { useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Dialog, DialogTitle, DialogContent, useTheme } from '@mui/material';
import {
  DatePicker,
  DescriptionTextField,
  ReminderNotifications,
  ShareUsersSelect,
  TitleTextField,
  LinkContactsSelect,
  ActionButtons,
} from './components';
// Custom hooks
import { useActivitiesCardContext } from '../../../../hooks/useActivitiesCardContext';

const CreateActivityPopUp = () => {
  const { t } = useTranslation();
  const {
    isCreatePopUpOpen,
    closeEventPopUp,
    newActivity,
    handleCreateActivity,
    handleUpdateActivity,
    isEditing,
  } = useActivitiesCardContext();
  const {
    description,
    title,
    date: {
      state: { selectedEndDate, selectedStartDate, error },
      action: { handleSelect, setError, setSelectedEndDate, setSelectedStartDate },
    },
    notifications,
    shareWithUsers,
    linkContact,
  } = newActivity.field;

  useEffect(() => {
    if (
      selectedStartDate.isAfter(selectedEndDate) &&
      !selectedStartDate.isSame(selectedEndDate, 'minute')
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [selectedStartDate, selectedEndDate]);
  const theme = useTheme();

  return (
    <>
      <Dialog
          sx={{
            '& .MuiDialog-paper': { width: '50%', backgroundColor: 
            //@ts-ignore
            theme.palette.background.panel,
          backgroundImage: 'unset' },
          }}
        maxWidth={false}
        open={isCreatePopUpOpen}
        onClose={closeEventPopUp}
      >
        <DialogTitle>{t('contactsView.activities.createEvent')}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TitleTextField titleField={title} />
          {/* Event select field  */}
          {/* <BasicInputContainer name={t('contactsView.activities.eventType')}>
            <Select size="small" fullWidth />
          </BasicInputContainer> */}
          <DescriptionTextField descriptionField={description} />
          <DatePicker
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            handleSelectStart={handleSelect(setSelectedStartDate)}
            handleSelectEnd={handleSelect(setSelectedEndDate)}
            isError={error}
          />
          {/* Reminder notifications */}
          <ReminderNotifications notificationsField={notifications} />
          {/* Calendar select */}
          {/* <BasicInputContainer name={t('contactsView.activities.selectCalendar')}>
            <Select size="small" fullWidth />
          </BasicInputContainer> */}
          <LinkContactsSelect linkContactsHandler={linkContact} />
          <ShareUsersSelect shareWithUsersField={shareWithUsers} />
        </DialogContent>
        <ActionButtons
          closeEventPopUp={closeEventPopUp}
          handleCreateActivity={handleCreateActivity}
          isEditing={isEditing}
          handleUpdateActivity={handleUpdateActivity}
        />
      </Dialog>
    </>
  );
};

export default CreateActivityPopUp;
