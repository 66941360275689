// Provider
import NotesCardContextProvider from './context/NotesCardContextProvider';
//Redux
import { useSelector } from 'react-redux';
import { selectNotes } from 'ReduxToolkit/features/noteSlice/noteSlice';
// Components/ui
import { InputAddNote, Note } from './components';
import { AccordionCard } from '../../layout';

interface FilesCardProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
}
const NotesCard = ({ open, setOpen, title }) => {
  const notes = useSelector(selectNotes);
  return (
    <NotesCardContextProvider>
      <AccordionCard title={title} id="notes" open={open} setOpen={setOpen}>
        {notes && notes.map((note) => <Note key={note.id} data={note} />)}
        <InputAddNote />
      </AccordionCard>
    </NotesCardContextProvider>
  );
};

export default NotesCard;
