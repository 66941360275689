// Components/ui
import ContactTable from './components/ContactTable/ContactTable';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';

export default function ContactListPanel() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        width: '85%',
        overflow: 'auto',
        //@ts-ignore
        backgroundColor: (theme: Theme) => theme.palette.background.dropdownMenu,
        borderRadius: '2px',
        boxShadow: '0px 1px 2px rgba(0,0,0,0.2)',
      }}
    >
      <ContactTable />
    </Box>
  );
}
