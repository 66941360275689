import * as React from 'react';
// components/ui
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Typography from '@mui/material/Typography';
import ImportUpload from './Steps/ImportUpload';
import ImportValidation from './Steps/ImportValidation';
import ImportAccept from './Steps/ImportAccept';
import { StepLabel, Theme } from '@mui/material';
import { XslxFile } from './types';

const steps = [
  'Seleccionar archivo',
  'Validación y confirmación',
  'Resultados de la importación',
];

export default function HorizontalNonLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [xslxFile, setXslxFile] = React.useState<XslxFile>({
    name: null,
    workbookSheet: null,
    rowsAmount: 0,
  });

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  //eslint-disable-next-line
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  //eslint-disable-next-line
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  //eslint-disable-next-line
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  //   console.log("step", activeStep);
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ my: '20px', textAlign: 'center' }}>
        <Typography
          variant="h4"
          sx={{ color: (theme: Theme) => theme.palette.text.primary }}
        >
          Importar contactos
        </Typography>
      </Box>

      <Box
        mt={'50px'}
        //@ts-ignore
        sx={{ backgroundColor: (theme: Theme) => theme.palette.dropdownBox }}
        className="panel-inner contacts-my-0 px-2 py-2"
        minHeight={'300px'}
      >
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={completed[index]}
              sx={{ p: 2, userSelect: 'none' }}
            >
              {/* ICONO PASO ACTUAL */}
              <StepLabel
                sx={{
                  cursor: 'default',
                  color: (theme: Theme) => theme.palette.text.primary,
                }}
              >
                {label}
              </StepLabel>
              {/* ICONO PASO FINALIZADO */}
              {/* <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel> */}
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <ImportUpload
            xslxFile={xslxFile}
            setXslxFile={setXslxFile}
            handleNext={handleNext}
          />
        )}
        {activeStep === 1 && (
          <ImportValidation xslxFile={xslxFile} handleNext={handleNext} />
        )}
        {activeStep === 2 && <ImportAccept />}
      </Box>
    </Box>
  );
}
