import React from 'react';
// Components/ui
import { Skeleton } from '@mui/material';

const ContentSkeleton = () => {
  return (
    <Skeleton
      variant="rectangular"
      height={'20%'}
      // width={'100%'}
      sx={{
        borderRadius: 1,
        bgcolor: 'lightgray',
      }}
    />
  );
};

export default ContentSkeleton;
