import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Box } from '@mui/material';

type Props = {
  nameKey: string;
  value: string;
  onChange: (name: string, value: any) => void;
  name: string;
};

const CustomPropertyDatePicker = ({ nameKey, value, onChange, name }: Props) => {
  const handleChange = (value: string) => {
    const formatedValue = dayjs(value).format('YYYY-MM-DD');

    onChange(nameKey, formatedValue);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? '1e1e1e'
            : theme.palette.background.default,
        // padding: '0 10px',
        flexDirection: 'column',
        borderRadius: 1,
        width: '45%',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={['DatePicker']}
          sx={{
            minWidth: '0px',
            overflow: 'visible',
            paddingTop: '0px',
          }}
        >
          <DatePicker
            label={name}
            value={value}
            onChange={handleChange}
            sx={{
              width: '20%',
            }}
            slotProps={{
              textField: {
                size: 'small',
                sx: {
                  width: '100%',
                  minWidth: '0px !important',
                },
                className: 'contacts-smallInput',
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default CustomPropertyDatePicker;
