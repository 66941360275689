import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ListsContext } from '../../../../../../context/ListsContext';
import { IListsContext } from '../../../../../../context/types';
// Components/ui
import { Box, TextField } from '@mui/material';

const Inputs = () => {
  const { t } = useTranslation();
  const { createListDescriptionField, createListNameField } =
    useContext<IListsContext>(ListsContext);

  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('listsView.listName')}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        {...createListNameField.attributes}
      />
      <TextField
        id="outlined-multiline-static"
        multiline
        label={t('listsView.listDescription')}
        rows={4}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        {...createListDescriptionField.attributes}
      />
    </Box>
  );
};

export default Inputs;
