import { createContext, useState, ReactNode, useEffect } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { createNote, deleteNote, fetchNotes, setDeletingNoteStatus, removeDeletingNoteStatus } from 'ReduxToolkit/features/noteSlice/noteSlice';
import useField, { UseFieldType } from 'hooks/useField';
import { NotesCardContextType } from './types/NotesCardContextType';
import { NotesCardContext } from './NotesCardContext';

type NotesCardContextProps = {
  children: ReactNode;
};

const NotesCardContextProvider = ({ children }: NotesCardContextProps) => {
  const dispatch = useAppDispatch();
  const noteField = useField('text');
  const [editingNoteIds, setEditingNoteIds] = useState<string[]>([]);
  const [isModalDeleteNoteOpen, setIsModalDeleteNoteOpen] = useState(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState<string | null>(null);

  const handleCreateNote = async () => {
    const newNoteText = noteField.attributes.value;
    await dispatch(createNote(newNoteText));
    noteField.actions.resetValue();
  };

  const openModalDeleteNote = (noteId: string) => {
    setNoteIdToDelete(noteId);
    setIsModalDeleteNoteOpen(true);
  };

  const closeModalDeleteNote = () => {
    setNoteIdToDelete(null);
    setIsModalDeleteNoteOpen(false);
  };

  const handleDeleteNote = async (noteId?: string) => {
    const idToDelete = noteId || noteIdToDelete;
    if (idToDelete) {
      await dispatch(setDeletingNoteStatus(idToDelete));
      await dispatch(deleteNote(idToDelete));
      await dispatch(removeDeletingNoteStatus(idToDelete));
      closeModalDeleteNote();
    }
  };

  const handleEditNoteStart = (noteIdToAdd: string) => {
    setEditingNoteIds((existingIds) => [...existingIds, noteIdToAdd]);
  };

  const handleEditNoteEnd = (noteIdToRemove: string) => {
    const filteredEditingNoteIds = editingNoteIds.filter(
      (noteId) => noteId !== noteIdToRemove
    );
    setEditingNoteIds(filteredEditingNoteIds);
  };

  const value: NotesCardContextType = {
    noteField,
    handleCreateNote,
    handleDeleteNote,
    handleEditNoteStart,
    handleEditNoteEnd,
    editingNoteIds,
    closeModalDeleteNote,
    openModalDeleteNote,
    isModalDeleteNoteOpen,
    noteIdToDelete,
  };

  useEffect(() => {
    dispatch(fetchNotes());
  }, []);

  return (
    <NotesCardContext.Provider value={value}>
      {children}
    </NotesCardContext.Provider>
  );
};

export default NotesCardContextProvider;
