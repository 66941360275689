import { useContext } from 'react';
// Context
import { ContactCreateContext } from 'features/Views/ContactCreate/context/ContactCreateContext';
import { ContactEditContext } from 'features/Views/ContactEdit/context/ContactEditContext';
// Components/ui
import {
  InformationItemContainer,
  InformationItemInputsContainer,
  NoteDeleteButton,
} from 'components/ContactCreateEditAppBody/components/InformationSection/layout';
import { Typography } from '@mui/material';
import imsStyle from './style';
// Types
import { NewAddress } from 'types/NewAddress';

type Props = {
  data: NewAddress;
};

const Ims = ({ data }: Props) => {
  const mode: 'edit' | 'create' = window.location.pathname.includes('edit')
    ? 'edit'
    : 'create';
  const { editContact } = useContext(ContactEditContext);
  const { contact } = useContext(ContactCreateContext);
  const editContactActions = editContact.action;
  const createContactActions = contact.action;

  return (
    <InformationItemContainer>
      <InformationItemInputsContainer>
        <Typography sx={imsStyle.code} variant="body2">
          {data.address}
        </Typography>
        <NoteDeleteButton
          mode={mode}
          addressData={data}
          handleNoteFieldChange={
            mode === 'edit'
              ? editContactActions.handleNoteFieldChange
              : createContactActions.handleNoteFieldChange
          }
          handleDeleteAddress={
            mode === 'edit'
              ? editContactActions.handleDeleteAddress
              : createContactActions.handleDeleteAddress
          }
          isDeletingAddress={mode === 'edit' && editContact.state.isDeletingAddress}
          replaceAddress={mode === 'edit' && editContactActions.replaceAddress}
        />
      </InformationItemInputsContainer>
    </InformationItemContainer>
  );
};

export default Ims;
