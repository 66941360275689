import moment from 'moment';
let DateHour = moment().format('L, h:mm ');
let OnlyDate = moment().format('L, h:mm ');

export const tableDifussion = [
  {
    id: 0,
    date: OnlyDate,
    title: 'Difussion1',
    status: 'leído',
  },
  {
    id: 1,
    date: OnlyDate,
    title: 'Difussion2',
    status: 'entregado',
  },
  {
    id: 2,
    date: OnlyDate,
    title: 'Difussion3',
    status: 'error al enviar',
  },
];

export const tableTasks = [
  {
    id: 0,
    date: OnlyDate,
    title: 'Tasks1',
    status: 'leído',
  },
  {
    id: 1,
    date: OnlyDate,
    title: 'Tasks2',
    status: 'entregado',
  },
  {
    id: 2,
    date: OnlyDate,
    title: 'Tasks3',
    status: 'error al enviar',
  },
];
export const tableRepair = [
  {
    id: 0,
    title: 'Repair1',
    date: OnlyDate,
    duration: '2 hs',
    host: 'Leandro Francia',
    participants: 'Lautaro Croatto',
  },
  {
    id: 1,
    title: 'Repair2',
    date: OnlyDate,
    duration: '2 hs',
    host: 'Leandro Francia',
    participants: 'Lautaro Croatto',
  },
  {
    id: 2,
    title: 'Repair3',
    date: OnlyDate,
    duration: '2 hs',
    host: 'Leandro Francia',
    participants: 'Lautaro Croatto',
  },
];
export const tableTickets = [
  {
    id: 0,
    issue: 'asunto1',
    date: DateHour,
    category: '',
    participants: 'Lautaro Croatto',
    status: '',
  },
  {
    id: 1,
    issue: 'asunto2',
    date: DateHour,
    category: '',
    participants: 'Lautaro Croatto',
    status: '',
  },
  {
    id: 2,
    issue: 'asunto3',
    date: DateHour,
    category: '',
    participants: 'Lautaro Croatto',
    status: '',
  },
];
export const tableDebts = [
  {
    id: 0,
    management: 'management1',
    amount: DateHour,
    balance: '',
    agent: 'Lautaro Croatto',
    status: '',
  },
  {
    id: 1,
    management: 'management2',
    amount: DateHour,
    balance: '',
    agent: 'Lautaro Croatto',
    status: '',
  },
  {
    id: 2,
    management: 'management3',
    amount: DateHour,
    balance: '',
    agent: 'Lautaro Croatto',
    status: '',
  },
];
