import React, { useContext, useEffect, useState } from 'react';
// Context
import { SegmentsEditContext } from '../../../../context/SegmentsEditProvider';
//Components/ui
import { Box, TextField, Fade } from '@mui/material';
import { ContentSkeleton } from './components';
import { useTranslation } from 'react-i18next';

interface ISegmentHeaderProps {
  dataReady: boolean;
}

const SegmentInputName = ({ dataReady }: ISegmentHeaderProps) => {
  const { segmentNameField } = useContext(SegmentsEditContext);
  const [show, setShow] = useState<boolean>(false);
  const {t} = useTranslation()
  useEffect(() => {
    if (dataReady) {
      setShow(true);
    }
  }, [dataReady]);

  if (!dataReady) {
    return <ContentSkeleton />;
  }

  return (
    <Fade in={show}>
      <Box
        display={'flex'}
        sx={{
          borderRadius: 1,
          justifyContent: 'space-between',
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.dropdownBox,
          boxShadow: 1,
          p: 2,
          alignItems: 'center',
          mb: 1
          // height: '20%',
        }}
      >
      <Box
          display={'flex'}
          gap={2}
          flexDirection={'column'}
          // width={'40%'}
          sx={{
            width: { xs: '55%', lg: '60%', xl: '65%' },
          }}
          justifyContent={'space-around'}
          height={'100%'}
        >
          <TextField
            sx={{
              width: { xs: '75%', lg: '65%', xl: '50%' },
            }}
            size="small"
            label={t('segments.segmentName')}
            {...segmentNameField.attributes}
          />
        </Box>
      </Box>
    </Fade>
  );
};

export default SegmentInputName;
