// Translations
import { useTranslation } from "react-i18next";
// Icons
import { Close } from "@mui/icons-material";
// Components/ui
import {
  Box,
  Divider,
  IconButton,
  Modal,
  Slide,
  Typography,
} from "@mui/material"
// Components
import {
  Label,
} from './components';
// Types
import { ILabel } from '@trii/types/dist/Conversations';

interface Props {
  labels: ILabel[];
  open: boolean;
  handleClose: () => void;
}

const LabelModal = ({
  labels,
  open,
  handleClose,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        marginTop: '5rem',
        background: 'transparent',
        '& .MuiBackdrop-root': {
          background: 'transparent',
          border: 'none',
        },
      }}
    >
      <Slide
        direction="down"
        in={open}
        mountOnEnter
        unmountOnExit
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '30rem',
            maxHeight: '40rem',
            minHeight: '10rem',
            overflow: 'auto',
            borderRadius: 2,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
            backgroundColor: (theme) => theme.palette.background.default,
            outline: 'none'
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1em',
            }}
          >
            <Typography
              variant="h6"
              color={(theme) => theme.palette.text.primary}
            >
              {t('labelsView.labels')}
            </Typography>
            <IconButton
              onClick={handleClose}
            >
              <Close
                sx={{
                  color: (theme) => theme.palette.text.disabled,
                }}
              />
            </IconButton>
          </Box>
          <Divider />
          <Label
            labels={labels}
          />
        </Box>
      </Slide>
    </Modal>
  )
}

export default LabelModal