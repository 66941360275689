import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//Components/ui
import { Alert, AlertTitle, Fade } from '@mui/material';

const SuccessExport = () => {
  const { t } = useTranslation();
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <Fade in={fadeIn}>
      <Alert severity="success">
        <AlertTitle>{t('global.success')}</AlertTitle>
        {t('exportContactsView.success')}
      </Alert>
    </Fade>
  );
};

export default SuccessExport;
