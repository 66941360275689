// Components/ui
import { Box } from '@mui/material';
import { SubsectionHeaderWithButton } from '../../layout';
import { Body } from './components';
// Types
import { NewAddress } from 'types/NewAddress';

type Props = {
  whatsapps: NewAddress[];
  handleAdd: () => void;
};
const Whatsapps = ({ whatsapps, handleAdd }: Props) => {
  return (
    <Box sx={{ width: '100%' }}>
      <SubsectionHeaderWithButton handleAdd={handleAdd} title="WhatsApp" />
      <Body whatsapps={whatsapps} />
    </Box>
  );
};

export default Whatsapps;
