import { ReactNode } from 'react';
// Components/ui
import { Box } from '@mui/material';

interface Props {
  children: ReactNode;
}
const SectionContainer = ({ children }: Props) => {
  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={'start'}
      p={2}
      position={"relative"}
      // sx={{ bgcolor: (theme) => theme.palette.background.default }}
    >
      {children}
    </Box>
  );
};

export default SectionContainer;
 