import { TableRow, TableCell, Typography, styled } from '@mui/material';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  height: 35,
}));
export const StyledTypography = styled(Typography)({
  userSelect: 'none',
});
export const StyledTableCell = styled(TableCell)({
  padding: '0 24px',
});
