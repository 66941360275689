import { useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectCustomContactFieldsCreateStatus } from 'ReduxToolkit/features/configSlice/configSlice';
// Context
import { CustomFieldContext } from '../../context/CustomFieldContext';
// Translation
import { useTranslation } from 'react-i18next';
// components/ui
import { Snackbar, Alert } from '@mui/material';

const CreateFieldErrorAlert = () => {
  const { handleCloseCreateError } = useContext(CustomFieldContext);
  const customContactFieldsCreateStatus = useSelector(
    selectCustomContactFieldsCreateStatus
  );
  const { t } = useTranslation();

  return (
    <Snackbar
      open={typeof customContactFieldsCreateStatus === 'object' ? true : false}
      autoHideDuration={6000}
      onClose={handleCloseCreateError}
    >
      <Alert
        onClose={handleCloseCreateError}
        severity="error"
        sx={{ width: '100%' }}
      >
        Error
      </Alert>
    </Snackbar>
  );
};

export default CreateFieldErrorAlert;
