// Components/ui
import { CircularProgress, IconButton } from '@mui/material';
// Types
import { ReactNode } from 'react';

type ActionButtonProps = {
  isLoading: boolean;
  activityId: string;
  handleActivityAction: (activityId: string) => void;
  children: ReactNode;
};

const ActionButton = ({
  isLoading,
  handleActivityAction,
  activityId,
  children,
}: ActionButtonProps) => {
  return (
    <>
      {isLoading ? (
        <CircularProgress size={16} />
      ) : (
        <IconButton onClick={() => handleActivityAction(activityId)}>
          {children}
        </IconButton>
      )}
    </>
  );
};

export default ActionButton;
