import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectContactFields } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const FieldSelect = ({ fieldSelect }) => {
  const { t } = useTranslation();
  const columns = useSelector(selectContactFields);
  const renderFieldItems = columns.map((column) => (
    <MenuItem key={column.name} value={column.nameKey}>
      {column.name}
    </MenuItem>
  ));

  return (
    <Box mb={'12px'} display="flex">
      <FormControl size="small" sx={{ width: '100%' }}>
        <InputLabel>{t('contactsView.contactBusinessTable.field')}</InputLabel>
        <Select label="Campo" {...fieldSelect.attributes}  MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}>
          {renderFieldItems}
          <MenuItem value={'List'}>{t('global.list')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default FieldSelect;
