import axios from 'axios';
// Helper
import getRequestConfig from '../../functions/getRequestConfig';
// Types
import { UserInfo, UserTrii } from '@trii/types/dist/Users';

const fetchUserInfo = async (
  jwtToken: string,
  URL_CONTACTS: string
): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  console.log('FETCHING USER: ', URL_CONTACTS);
  const response = await axios.get<UserTrii>(URL_CONTACTS, config);
  return response.data;
};

const fetchUsers = async (
  jwtToken: string,
  URL_SETTINGS: string
): Promise<UserInfo[]> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserInfo[]>(URL_SETTINGS, config);

  return response.data;
};

const fetchUserById = async (
  jwtToken: string,
  URL: string,
  userId: string
): Promise<UserTrii> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<UserTrii>(`${URL}/${userId}`, config);

  return response.data;
};

const userSliceService = {
  fetchUserInfo,
  fetchUsers,
  fetchUserById,
};

export default userSliceService;
