import { ISegmentWithId } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { ISegment } from '@trii/types/dist/Contacts';

const addIds = (segmentData: ISegment): ISegmentWithId => {
  return {
    ...segmentData,
    filter: {
      operator: segmentData.filter.operator,
      groups: segmentData.filter.groups.map((group) => ({
        ...group,
        items: group.items.map((item) => ({
          ...item,
          id: uuidv4(),
        })),
        id: uuidv4(),
      })),
    },
  };
};

export default addIds;
