// Components/ui
import { TableBody } from '@mui/material';
import { ActivitiesRow } from './components';
// Types
import { IEvent } from '@trii/types/dist/Calendar';

interface BodyProps {
  activities: IEvent[];
}
const Body = ({ activities }: BodyProps) => {
  const Activities = activities?.map((activity) => (
    <ActivitiesRow activityData={activity} key={activity.id} />
  ));

  return <TableBody>{Activities}</TableBody>;
};

export default Body;
