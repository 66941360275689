export const style = {
  secondaryContainer: {
    display: 'flex',
    alignItems: 'baseline',
    height: '100%',
    gap: 0.5,
  },
  icon: { height: 15 },
  chip: {
    borderRadius: '6px',
    height: '22px'
  }
};
