import React, { useContext } from 'react';
import { SegmentsContext } from '../../../../../../context/SegmentsProvider';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectSelectedSegment } from '../../../../../../../../../ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const { t } = useTranslation();
  const { closeDeletePanel } = useContext(SegmentsContext);
  const selectedSegment = useSelector(selectSelectedSegment);

  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
    >
      <Box display={'flex'} gap={1}>
        <Typography variant="subtitle2" color="text.primary">{t('segments.deleteSegment')}</Typography>
        <Typography variant="subtitle2" color="text.primary">{selectedSegment?.name}</Typography>
      </Box>
      <IconButton onClick={closeDeletePanel}>
        <CloseIcon
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  );
};

export default Header;
