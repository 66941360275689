import { useTranslation } from 'react-i18next';
import { DialogActions, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

type ActionButtonsProps = {
  closeEventPopUp: () => void;
  handleDeleteNote: (noteId?: string) => void;
  noteId?: string;
};

const ActionButtons = ({
  closeEventPopUp,
  handleDeleteNote,
  noteId
}: ActionButtonsProps) => {
  const { t } = useTranslation();

  return (
    <DialogActions>
      <Button variant="outlined" size="small" onClick={closeEventPopUp}>
        {t('global.cancel')}
      </Button>
      <LoadingButton
        size="small"
        onClick={() => handleDeleteNote(noteId)}
        variant="contained"
        color="error"
      >
        {t('global.delete')}
      </LoadingButton>
    </DialogActions>
  );
};

export default ActionButtons;
