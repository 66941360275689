import { useState } from 'react';
import { IconButton, Menu, MenuItem, CircularProgress, styled } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { deleteContact } from 'ReduxToolkit/features/contactsSlice/contactsSlice';

interface EditMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  contactId: string;
  removeContactById: (id: string) => void;
}

const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
});

const StyledIconButton = styled(IconButton)({
  size: 'small',
});

const StyledBox = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const MoreOptionsMenu = ({
  anchorEl,
  open,
  handleClose,
  contactId,
  removeContactById,
}: EditMenuProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const editContactUrl = `/a/contacts/contacts/${contactId}/edit`;
  const [isDeleting, setIsDeleting] = useState(false);

  const handleEditClick = () => {
    window.location.href = editContactUrl;
  };

  const handleOpenInNewTab = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.open(editContactUrl, '_blank');
  };

  const handleDeleteContact = async () => {
    setIsDeleting(true);
    await dispatch(deleteContact(contactId));
    setIsDeleting(false);
    handleClose();
    removeContactById(contactId);
  };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <StyledMenuItem onClick={handleEditClick}>
        <StyledBox>
          <EditOutlinedIcon color="action" />
          {t('global.edit')}
        </StyledBox>
        <StyledIconButton onClick={handleOpenInNewTab}>
          <OpenInNewIcon fontSize="small" />
        </StyledIconButton>
      </StyledMenuItem>
      <StyledMenuItem onClick={handleDeleteContact}>
        {isDeleting ? (
          <CircularProgress size={18} color="error" />
        ) : (
          <DeleteIcon color="error" />
        )}
        {t('global.delete')}
      </StyledMenuItem>
    </Menu>
  );
};

export default MoreOptionsMenu;
