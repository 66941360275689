// Context provider
import ContactEditContextProvider from './context/ContactEditContextProvider';
// Components/ui
import { ContactContainer } from 'components';
import { App } from './components';

const ContactEdit = () => {
  return (
    <ContactEditContextProvider>
      <ContactContainer>
        <App />
      </ContactContainer>
    </ContactEditContextProvider>
  );
};

export default ContactEdit;
