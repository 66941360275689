import { useEffect, useState } from 'react';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
// React Router
import { useNavigate } from 'react-router-dom';
// Translations
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
// Slice
import {
  clearStatus,
  getCloneContactFetchStatus,
  selectCloneContact,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { Alert, Box, Snackbar } from '@mui/material';
import { HeaderContactViewer, SectionsMenu, BodyContactViewer } from './components';
import { ContactContainer } from 'components';

const ContactView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cloneStatus = useSelector(getCloneContactFetchStatus);
  const cloneContact = useSelector(selectCloneContact);
  const navigate = useNavigate();
  const [severity, setSeverity] = useState<'success' | 'error' | 'info'>('success');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
    dispatch(clearStatus());
  };

  const handleNavigate = () => {
    if (cloneStatus === 'succeeded') {
      navigate(`/a/contacts/contacts/${cloneContact.id}`);
    }
  };

  useEffect(() => {
    if (cloneStatus !== 'idle') {
      setOpen(true);
      if (typeof cloneStatus === 'string') {
        if (cloneStatus === 'loading') {
          setMessage('contactsView.actions.cloneContact.loading');
          setSeverity('info');
        } else if (cloneStatus === 'succeeded') {
          setMessage('contactsView.actions.cloneContact.success');
          setSeverity('success');
        }
      } else {
        const error: string = cloneStatus.error;
        setMessage(error);
        setSeverity('error');
      }
    } else {
      setOpen(false);
    }
  }, [cloneStatus]);

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} borderRadius={2}>
      <ContactContainer>
        <HeaderContactViewer />
        <Box display={'flex'} sx={{ height: 'calc(100vh - 175px)' }}>
          <SectionsMenu />
          <BodyContactViewer />
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          onClick={handleNavigate}
          sx={{
            cursor: cloneStatus === 'succeeded' ? 'pointer' : 'default',
          }}
        >
          <Alert severity={severity}>{t(message)}</Alert>
        </Snackbar>
      </ContactContainer>
    </Box>
  );
};

export default ContactView;
