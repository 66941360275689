import React, { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Divider, Typography } from '@mui/material';
import { Buttons, GoogleSync } from './Integrations';
import {
  CustomFieldsContacts,
  CustomFieldsEnterprises,
  Subscriptions,
  Labels
} from './Settings';

const SettingsView = () => {
  const { t } = useTranslation();
  return (
    <Box className={'contacts-paddingmd'}>
      <Box
        style={{
          display: 'flex',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {t('settingsView.settings')}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        margin={2}
        gap={2}
      >
        <CustomFieldsContacts />
        <CustomFieldsEnterprises />
        <Subscriptions />
        <Labels />
      </Box>

      <Divider sx={{ marginTop: '30px' }} />
      {/* Integraciones */}
      <Box
        style={{
          display: 'flex',
          marginTop: '20px',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {t('settingsView.integrations')}
        </Typography>
      </Box>
      <Box display={'flex'} margin={2}>
        <Buttons />
        <GoogleSync />
      </Box>
    </Box>
  );
};

export default SettingsView;
