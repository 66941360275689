import React, { useState } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import {
  changeContactOrder,
  selectPagination,
  fetchContactsTableData,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
//components/ui
import { HeadCellTitle } from './components';
import { TableCell, IconButton, Fade, styled } from '@mui/material';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

const StyledArrowUpwardRoundedIcon = styled(ArrowUpwardRoundedIcon)(({ theme }) => ({
  fontSize: '18px',
}));

const Column = ({ title }) => {
  const dispatch = useDispatch();
  const pagination = useSelector(selectPagination);
  const [isHovered, setIsHovered] = useState(false);
  const ArrowIcon = () => {
    switch (pagination.order) {
      case 'ASC':
        return <StyledArrowUpwardRoundedIcon color="primary" />;
      case 'DESC':
        return (
          <ArrowDownwardRoundedIcon sx={{ fontSize: '18px' }} color="primary" />
        );
      default:
        return <StyledArrowUpwardRoundedIcon color="disabled" />;
    }
  };

  const handleHover = () => setIsHovered(true);

  const handleLeave = () => setIsHovered(false);

  const getNewSortDirection = (order) => {
    switch (order) {
      case 'ASC':
        return 'DESC';
      case 'DESC':
        return 'ASC';
      default:
        return 'ASC';
    }
  };

  const handleColumnClick = async () => {
    const order = getNewSortDirection(pagination.order);
    const orderColumn = title.name;
    const contactOrder = { order, orderColumn };

    localStorage.setItem('contactOrder', JSON.stringify(contactOrder));

    await dispatch(changeContactOrder(contactOrder));
    // await dispatch(fetchContactsTableData({ ...pagination, ...contactOrder }));
  };

  return (
    <TableCell
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      sx={{
        py: '10px',
        '&:hover': { cursor: 'pointer' },
        userSelect: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      onClick={handleColumnClick}
    >
      <HeadCellTitle title={title} />
      <Fade in={isHovered || title.sort !== false}>
        <IconButton sx={{ padding: '2px', ml: '2px', mb: '2px' }}>
          {ArrowIcon()}
        </IconButton>
      </Fade>
    </TableCell>
  );
};

export default Column;
