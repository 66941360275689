// Types
import { IContactAddress } from '@trii/types/dist/Contacts';
// Components/ui
import { Box } from '@mui/material';

type Props = {
  item: IContactAddress;
};

const ContactMethodItem = ({ item }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '40%',
        }}
      >
        Direccion: {item.address}
      </Box>
      <Box
        sx={{
          width: '40%',
        }}
      >
        Nota: {item.note}
      </Box>
    </Box>
  );
};

export default ContactMethodItem;
