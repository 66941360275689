// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectListsData } from 'ReduxToolkit/features/listsSlice/listsSlice';
// Components/ui
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { style } from './style';
// Types
import { UseSelectType } from 'hooks/useSelect';

interface Props {
  contactListSelectState: UseSelectType;
}

const ContactListSelect = ({ contactListSelectState }: Props) => {
  const { t } = useTranslation();
  const lists = useSelector(selectListsData);
  const contactListValue = contactListSelectState?.attributes?.value

  const selectedList = lists.find((user) => user.id === contactListValue);

  const MenuItems = lists.map((list) => {
    return (
      <MenuItem key={list.id} value={list.id}>
        {list.name}
      </MenuItem>
    );
  });

  return (
    <FormControl className="fadein " sx={{ marginBottom: '15px' }}>
      <InputLabel shrink id="owner-select-label">
        {t('contactDetails.contactList')}
      </InputLabel>
      <Select
        labelId="owner-select-label"
        label={t('contactDetails.contactList')}
        size="small"
        {...contactListSelectState?.attributes}
        sx={style.select}
        value={selectedList ? selectedList.id : ''}
        MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'transparent' }} value={''}>
          {'No value'}
        </MenuItem>
        {MenuItems}
      </Select>
    </FormControl>
  );
};

export default ContactListSelect;
