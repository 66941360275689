import React from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Card,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';

function ResponseSection({
  templateFieldState,
  outputFormatSelectState,
  isEditing,
}) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        margin: 2,
      }}
    >
      <Box
        display={'flex'}
        justifyContent="space-between"
        alignItems={'center'}
        py={1}
        px={2}
        sx={{
          backgroundColor: (theme) => theme.palette.background.dropdownMenu,
        }}
      >
        <Typography variant="subtitle2">{t('settingsView.response')}</Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems={'start'}
        p={2}
        sx={{
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
        }}
      >
        <FormControl
          size="small"
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
        >
          <InputLabel>{t('settingsView.outputFormat')}</InputLabel>
          <Select label="Formato de salida" {...outputFormatSelectState.attributes}  MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}>
            <MenuItem value={1}>JSON</MenuItem>
            <MenuItem value={2}>HTML - iframe</MenuItem>
            <MenuItem value={3}>HTML - New Tab</MenuItem>
          </Select>
        </FormControl>
        <TextField
          {...templateFieldState.attributes}
          multiline
          label={t('settingsView.template')}
          rows={4}
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
        />
      </Box>
    </Card>
  );
}

export default ResponseSection;
