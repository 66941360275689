import React from 'react';
// Components/ui
import { Box } from '@mui/material';

interface ISyncSectionProps {
  children: React.ReactNode;
}

const SyncSection = ({ children }: ISyncSectionProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        p: 4,
        display: 'flex',
        color: (theme) => theme.palette.text.primary,
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 110,
      }}
    >
      {children}
    </Box>
  );
};

export default SyncSection;
