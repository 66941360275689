import React, { useEffect } from 'react';
import SegmentsEditProvider from './context/SegmentsEditProvider';
import { useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSegmentById,
  selectFetchSegmentByIdStatus,
} from 'ReduxToolkit/features/segmentsSlice/segmentsSlice';
import {
  fetchLists,
  selectListsStatus,
} from 'ReduxToolkit/features/listsSlice/listsSlice';
import {
  fetchCustomContactFields,
  selectCustomContactFieldsFetchStatus,
} from 'ReduxToolkit/features/configSlice/configSlice';

// Components/ui
import { Box } from '@mui/material';
import { EditPanel, DeletePanel } from './components';

const SegmentsEditContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const fetchSegmentByIdStatus = useSelector(selectFetchSegmentByIdStatus);
  const fetchListsStatus = useSelector(selectListsStatus);
  const fetchCustomContactFieldsStatus = useSelector(
    selectCustomContactFieldsFetchStatus
  );
  const dataReady: boolean =
    fetchSegmentByIdStatus === 'succeeded' &&
    fetchListsStatus === 'succeeded' &&
    fetchCustomContactFieldsStatus === 'succeeded';

  useEffect(() => {
    dispatch(fetchSegmentById(id));
    dispatch(fetchLists());
    dispatch(fetchCustomContactFields('contact'));
  }, [dispatch, id]);

  return (
    <SegmentsEditProvider>
      <Box height={'100%'} display={'flex'} width={'100%'}>
        <EditPanel dataReady={dataReady} />
        <DeletePanel />
      </Box>
    </SegmentsEditProvider>
  );
};

export default SegmentsEditContainer;
