import { RootState } from 'ReduxToolkit/store';
import { createSelector } from '@reduxjs/toolkit';

export const selectActivitiesSlice = (state: RootState) => state.Activities;
export const selectActivities = createSelector(
  (state: RootState) => state.Activities.activities,
  (activities) => activities
);
export const selectActivitiesFetchStatus = createSelector(
  (state: RootState) => state.Activities.status.fetch,
  (fetch) => fetch
);
export const selectActivitiesAddStatus = createSelector(
  (state: RootState) => state.Activities.status.add,
  (add) => add
);
export const selectActivitiesDeleteStatus = createSelector(
  (state: RootState) => state.Activities.status.delete,
  (status) => status
);
export const selectActivitiesUpdateEndingStatus = createSelector(
  (state: RootState) => state.Activities.status.updateEnding,
  (status) => status
);
export const selectActivitiesUpdateStatus = createSelector(
  (state: RootState) => state.Activities.status.update,
  (status) => status
);
