import React, { createContext, useState, ReactNode } from 'react';
// Types
import type { ButtonsListContextType, SelectedButtonType } from './types';
import type { UseFieldType } from '../../../../../../../../hooks/useField';
// Hooks
import useField from '../../../../../../../../hooks/useField';
import useCreateButton from '../hooks/useCreateButton';
import useEditButton from '../hooks/useEditButtons';
// Redux
import {
  addButton,
  deleteButton,
  updateButton,
  updateButtonsOrder,
} from '../../../../../../../../ReduxToolkit/features/configSlice/configSlice';
import { useDispatch } from 'react-redux';

export const ButtonsListContext = createContext<ButtonsListContextType>(null);

const ButtonsListProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  // General state
  const [selectedButton, setSelectedButton] = useState<SelectedButtonType>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  // Create state
  const createButtonState = useCreateButton();
  // Edit state
  const editButtonState = useEditButton();
  // Delete state
  const deleteField: UseFieldType = useField('text');
  // Order state
  const [canSaveOrder, setCanSaveOrder] = useState(false);
  const [originalButtonsOrder, setOriginalButtonsOrder] = useState([]);
  // Drawer state
  const [openPanelCP, setOpenPanelCP] = useState(false);

  // Create functions
  const handleCreateButton = async () => {
    const newButton = createButtonState.action.getNewButton();
    // const newButtonJson = JSON.stringify(newButton);

    await dispatch(addButton(newButton));
    endCreating();
    handleDrawerClose();
  };
  const endCreating = () => {
    createButtonState.action.resetAll();
  };
  // end Create functions

  // Edit functions
  const openEdit = (button) => {
    editButtonState.action.setToBeEditedButton(button);
    console.log('Button to be Edited:', button);
    setOpenPanelCP(true);
    setIsEditing(true);
    setIsDelete(false);
  };
  const handleEditButton = async () => {
    const editedButton = editButtonState.action.getEditedButton();
    // const editedButtonJson = JSON.stringify(editedButton);

    await dispatch(updateButton(editedButton));
    handleDrawerClose();
  };
  // end Edit functions

  // Delete functions
  const handleDeleteButton = async () => {
    const { id } = selectedButton;

    await dispatch(deleteButton(id));
    handleDrawerClose();
  };
  const openDelete = (button) => {
    setSelectedButton(button);
    setOpenPanelCP(true);
    setIsEditing(false);
    setIsDelete(true);
  };
  // end Delete functions

  // Order functions
  const handleSaveOrder = async (buttons) => {
    const updatedOrderButtons = buttons.map((fieldA) => ({
      ...fieldA,
      order: buttons.indexOf(fieldA),
    }));
    // const updatedOrderButtonsJson = JSON.stringify({
    //   fields: updatedOrderButtons,
    // });

    await dispatch(updateButtonsOrder(updatedOrderButtons));
    setOriginalButtonsOrder(updatedOrderButtons);
  };
  const compareButtonsOrder = (newButtonsArr) => {
    for (let i = 0; i < originalButtonsOrder.length; i++) {
      if (originalButtonsOrder[i].name !== newButtonsArr[i].name) {
        return false;
      }
    }

    return true;
  };
  // end Order functions

  // Drawer functions
  const handleDrawerOpen = () => {
    setOpenPanelCP(true);
  };
  const handleDrawerClose = () => {
    setOpenPanelCP(false);
    setIsEditing(false);
    setIsDelete(false);
  };
  // end Drawer functions

  return (
    <ButtonsListContext.Provider
      value={{
        openPanelCP,
        handleDrawerOpen,
        handleDrawerClose,
        openEdit,
        openDelete,
        selectedButton,
        isEditing,
        isDelete,
        createButtonState,
        handleCreateButton,
        endCreating,
        deleteField,
        handleDeleteButton,
        editButtonState,
        handleEditButton,
        canSaveOrder,
        setCanSaveOrder,
        setOriginalButtonsOrder,
        handleSaveOrder,
        originalButtonsOrder,
        compareButtonsOrder,
      }}
    >
      {children}
    </ButtonsListContext.Provider>
  );
};

export default ButtonsListProvider;
