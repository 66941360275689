// Types
import {
  ContactField_type,
  Segment_Filter_Operator,
} from '@trii/types/dist/Contacts';
// Utils
import conditionOperators from './conditionOperators';
import ConditionOperators from '../types/ConditionOperators';

const getTextConditionOperators = () => {
  return conditionOperators.filter(
    (operator) =>
      operator.value === Segment_Filter_Operator.CONTAINS ||
      operator.value === Segment_Filter_Operator.NOT_CONTAINS ||
      operator.value === Segment_Filter_Operator.START_WITH ||
      operator.value === Segment_Filter_Operator.END_WITH ||
      operator.value === Segment_Filter_Operator.EQUAL
  );
};

const getNumberConditionOperators = () => {
  return conditionOperators.filter(
    (operator) =>
      operator.value === Segment_Filter_Operator.EQUAL ||
      operator.value === Segment_Filter_Operator.GREATER_THAN ||
      operator.value === Segment_Filter_Operator.LESS_THAN ||
      operator.value === Segment_Filter_Operator.GREATER_THAN_EQUAL ||
      operator.value === Segment_Filter_Operator.LESS_THAN_EQUAL
  );
};

const getDateTimeConditionOperators = () => {
  return conditionOperators.filter(
    (operator) =>
      operator.value === Segment_Filter_Operator.EQUAL ||
      operator.value === Segment_Filter_Operator.GREATER_THAN ||
      operator.value === Segment_Filter_Operator.LESS_THAN ||
      operator.value === Segment_Filter_Operator.GREATER_THAN_EQUAL ||
      operator.value === Segment_Filter_Operator.LESS_THAN_EQUAL
  );
};

const getDateTimeRangeConditionOperators = () => {
  return conditionOperators.filter(
    (operator) => operator.value === Segment_Filter_Operator.CONTAINS
  );
};

const getEqualConditionOperators = () => {
  return conditionOperators.filter(
    (operator) => operator.value === Segment_Filter_Operator.EQUAL
  );
};

const getConditions = (filterType: ContactField_type): ConditionOperators[] => {
  if (
    filterType === ContactField_type.TEXT ||
    filterType === ContactField_type.LONGTEXT
  )
    return getTextConditionOperators();
  else if (
    filterType === ContactField_type.NUMBER_ACCOUNTING ||
    filterType === ContactField_type.NUMBER_CURRENCY ||
    filterType === ContactField_type.NUMBER_DECIMAL ||
    filterType === ContactField_type.NUMBER_INTEGER ||
    filterType === ContactField_type.CURRENCY
  )
    return getNumberConditionOperators();
  else if (
    filterType === ContactField_type.DATE ||
    filterType === ContactField_type.TIME
  )
    return getDateTimeConditionOperators();
  else if (filterType === ContactField_type.OPTIONS)
    return getEqualConditionOperators();
  else if (
    filterType === ContactField_type.DATERANGE ||
    filterType === ContactField_type.TIMERANGE
  )
    return getDateTimeRangeConditionOperators();
};

export default {
  getConditions,
};
