import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// components/ui
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import { CustomFieldContext } from '../../../../context/CustomFieldContext';

const CreateFieldButtons = ({ handleDrawerClose, createField, editField }) => {
  const { t } = useTranslation();
  const {
    createLoading,
    handleCreateContactField,
    editLoading,
    isEditing,
    handleEditContactField,
  } = useContext(CustomFieldContext);
  const isLoading = createLoading || editLoading;
  const startIcon = isEditing ? <EditIcon /> : <AddBoxIcon />;
  const isDisabled = isEditing
    ? editField.attributes.value === '' || editLoading
    : createField.attributes.value === '' || createLoading;

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{ marginRight: '15px' }}
        onClick={handleDrawerClose}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={startIcon}
        variant="contained"
        size="small"
        // color="success"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.text.primary,
        }}
        disabled={isDisabled}
        onClick={isEditing ? handleEditContactField : handleCreateContactField}
      >
        {isEditing ? t('global.save') : t('global.accept')}
      </LoadingButton>
    </Box>
  );
};

export default CreateFieldButtons;
