// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  ContactInfoField,
  ContactSection,
} from 'components/ContactCreateEditAppBody/layout';
// Types
import type UseEditContact from 'features/Views/ContactEdit/hooks/useEditContact/types/UseEditContact';
import type UseCreateContact from 'features/Views/ContactCreate/hooks/useCreateContact/types/UseCreateContact';

interface Props {
  contact: UseEditContact | UseCreateContact;
}
const AdressInformationSection = ({ contact }: Props) => {
  const { t } = useTranslation();
  const {
    homeAddress,
    secondaryHomeAddress,
    city,
    secondaryCity,
    state,
    secondaryState,
    postalCode,
    secondaryPostalCode,
    country,
    secondaryCountry,
  } = contact.field;

  const fields = [
    { tabIndex: 1, label: t('contactDetails.street'), fieldData: homeAddress },
    {
      tabIndex: 6,
      label: t('contactDetails.anotherStreet'),
      fieldData: secondaryHomeAddress,
    },
    { tabIndex: 2, label: t('contactDetails.city'), fieldData: city },
    {
      tabIndex: 7,
      label: t('contactDetails.anotherCity'),
      fieldData: secondaryCity,
    },
    { tabIndex: 3, label: t('contactDetails.state'), fieldData: state },
    {
      tabIndex: 8,
      label: t('contactDetails.anotherState'),
      fieldData: secondaryState,
    },
    { tabIndex: 4, label: t('contactDetails.postcode'), fieldData: postalCode },
    {
      tabIndex: 9,
      label: t('contactDetails.anotherPostcode'),
      fieldData: secondaryPostalCode,
    },
    { tabIndex: 5, label: t('contactDetails.country'), fieldData: country },
    {
      tabIndex: 10,
      label: t('contactDetails.anotherCountry'),
      fieldData: secondaryCountry,
    },
  ];

  return (
    <ContactSection sectionTitle={t('contactDetails.addressInformation')}>
      {fields.map((field) => {
        return (
          <ContactInfoField
            key={field.tabIndex}
            tabIndex={field.tabIndex}
            label={field.label}
            fieldData={field.fieldData}
          />
        );
      })}
    </ContactSection>
  );
};

export default AdressInformationSection;
