import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components/ui
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

const Header = ({
  open,
  show,
  handleSaveOrder,
  loadingSaveOrder,
  canSaveOrder,
  fetchFor,
}) => {
  const { t } = useTranslation();

  const BreadcrumbItems = [
    <NavLink key="1" to="/a/contacts/settings">
      <Typography color="secondary">{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink
      key="2"
      to={
        fetchFor === 'business'
          ? '/a/contacts/settings/customfields-business'
          : '/a/contacts/settings/customfields-contact'
      }
    >
      <Typography color="secondary">
        {fetchFor === 'business'
          ? t('settingsView.customFieldsBusiness')
          : t('settingsView.customFieldsContacts')}
      </Typography>
    </NavLink>,
  ];
  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      gap={'1rem'}
      borderRadius={'4px'}
      height="50px"
      className="m-0"
      position={'relative'}
    >
      <Box position={'absolute'} left="0rem" top="-1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {BreadcrumbItems}
        </Breadcrumbs>
      </Box>
      <LoadingButton
        variant="contained"
        size="small"
        loadingPosition="start"
        onClick={handleSaveOrder}
        loading={loadingSaveOrder}
        startIcon={<SaveIcon />}
        disabled={loadingSaveOrder || !canSaveOrder}
      >
        {t('settingsView.saveOrder')}
      </LoadingButton>
      <Button
        sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
        variant="contained"
        size="small"
        onClick={open}
        disabled={show ? true : false}
      >
        {t('settingsView.newCustomField')}
      </Button>
    </Box>
  );
};

export default Header;
