import { createContext, useState, ReactNode } from 'react';
// Hooks
import useCreateLabel from '../hooks/useCreateLabel/useCreateLabel';
import useField from 'hooks/useField';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import createLabelInitialState from '../hooks/useCreateLabel/createLabelInitialState';
// Redux
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'ReduxToolkit/rootReducer';
import { useDispatch } from 'react-redux';
import {
  createLabel,
  deleteLabel,
  updateLabel,
} from 'ReduxToolkit/features/labelSlice/labelSlice';
// Types
import type { CrudPanelState } from './types';
import { LabelContext } from './types';
import { ILabel } from '@trii/types/dist/Conversations';
import { UseFieldType } from 'hooks/useField';

export const labelContext = createContext<LabelContext>({
  crudPanelState: 'closed',
  endCreating: () => { },
  openCreate: () => { },
  handleCreateLabel: () => { },
  openDelete: () => { },
  endDeleting: () => { },
  selectedLabel: null,
  deleteLabelName: fieldInitialDef,
  createLabelState: createLabelInitialState,
  editLabelState: createLabelInitialState,
  handleDeleteLabel: () => { },
  endEditing: () => { },
  handleEditLabel: () => { },
  openEdit: () => { },
});

const LabelProvider = ({ children }: { children: ReactNode }) => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // General state
  const [selectedLabel, setSelectedLabel] = useState<ILabel>(null);
  // Create state
  const createLabelState = useCreateLabel();
  // Edit state
  const editLabelState = useCreateLabel();
  // Delete state
  const deleteLabelName: UseFieldType = useField('text');
  // Drawer state
  const [crudPanelState, setCrudPanelState] = useState<CrudPanelState>('closed');

  // Create functions
  const handleCreateLabel = async () => {
    const newLabel = createLabelState.actions.getNewLabel();
    await dispatch(createLabel(newLabel));

    endCreating();
  };
  const openCreate = () => {
    setCrudPanelState('create');
  };
  const endCreating = () => {
    createLabelState.actions.resetAll();
    closeCrudPanel();
  };
  // end Create functions

  // Edit functions
  const openEdit = (label: ILabel) => {
    editLabelState.actions.setToBeEditedLabel(label);
    setSelectedLabel(label);
    setCrudPanelState('edit');
  };
  const handleEditLabel = async () => {
    const editedLabel = editLabelState.actions.getNewLabel();
    const editedLabelWithId: ILabel = {
      ...editedLabel,
      id: selectedLabel.id,
    };

    await dispatch(updateLabel(editedLabelWithId));
    endEditing();
  };
  const endEditing = () => {
    editLabelState.actions.resetAll();
    closeCrudPanel();
  };
  // end Edit functions

  // Delete functions
  const handleDeleteLabel = async () => {
    const { id } = selectedLabel;

    await dispatch(deleteLabel(id));
    endDeleting();
  };

  const openDelete = (label: ILabel) => {
    setSelectedLabel(label);
    setCrudPanelState('delete');
  };
  const endDeleting = () => {
    deleteLabelName.actions.resetValue();
    setSelectedLabel(null);
    closeCrudPanel();
  };
  // end Delete functions

  // Drawer functions
  const closeCrudPanel = () => {
    setCrudPanelState('closed');
  };
  // end Drawer functions

  return (
    <labelContext.Provider
      value={{
        crudPanelState,
        endCreating,
        openCreate,
        handleCreateLabel,
        openDelete,
        endDeleting,
        deleteLabelName,
        createLabelState,
        editLabelState,
        selectedLabel,
        handleDeleteLabel,
        endEditing,
        handleEditLabel,
        openEdit,
      }}
    >
      {children}
    </labelContext.Provider>
  );
};

export default LabelProvider;
