import * as React from 'react';
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
  Typography, // Importamos el componente Typography para el texto
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectListsData } from '../../../../../../../../../ReduxToolkit/features/listsSlice/listsSlice';
import {
  addList,
  removeList,
  selectSelectedSegment,
  setList,
} from '../../../../../../../../../ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Types
import { IList } from '@trii/types/dist/Contacts';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function not(a: readonly any[], b: readonly any[]) {
  return a.filter((value) => b.findIndex((item) => item.id === value.id) === -1);
}

function intersection(a: readonly any[], b: readonly any[]) {
  return a.filter((value) => b.findIndex((item) => item.id === value.id) !== -1);
}

function union(a: readonly string[], b: readonly string[]) {
  return [...a, ...not(b, a)];
}

export default function TransferList() {
  const dispatch = useDispatch();
  const contactLists = useSelector(selectListsData);
  const selectedSegment = useSelector(selectSelectedSegment);
  const selectedLists = selectedSegment.lists;
  const [contactListsSelected, setContactListsSelected] = useState();
  const isChecked = (list: IList): boolean => {
    return selectedLists.some((selectedList) => selectedList.id === list.id);
  };
  const [checked, setChecked] = React.useState<readonly any[]>([]);
  const [left, setLeft] = React.useState<readonly any[]>([]);
  const [right, setRight] = React.useState<readonly any[]>([]);
  const {t} = useTranslation()

  useEffect(() => {
    let arrayLeft = [];
    let arrayRight = [];
    contactLists.map((itemList) => {
      let itemListChecked = isChecked(itemList);
      if (itemListChecked) {
        arrayRight.push(itemList);
      } else {
        arrayLeft.push(itemList);
      }
    });
    setLeft(arrayLeft);
    setRight(arrayRight);
  }, [contactLists]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.findIndex((item) => item.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly string[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly string[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    let newRight = right.concat(leftChecked);
    setRight(newRight);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    updateSelectedList(newRight);
  };

  const handleCheckedLeft = () => {
    let newRight = not(right, rightChecked);
    setLeft(left.concat(rightChecked));
    setRight(newRight);
    setChecked(not(checked, rightChecked));
    updateSelectedList(newRight);
  };

  const updateSelectedList = (newListRight: any) => {
    dispatch(setList(newListRight));
  };

  const customList = (title: React.ReactNode, items: readonly any[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, bgcolor: 'background.dropdownMenu' }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${t('segmentsEdit.selected')}`}
      />
      <Divider />
      <List
        sx={{
          width: 300,
          height: 230,
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: any) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.findIndex((item) => item.id === value.id) >= 0}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
      {/* Lista izquierda */}
      <Grid item>{customList(t('segmentsEdit.available'), left)}</Grid>
      {/* Botones de cambio */}
      <Grid item sx={{alignSelf: 'center'}}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      {/* Lista derecha con texto descriptivo */}
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Grid item>{customList(t('segmentsEdit.selectedTitle'), right)}</Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" align="center" mt={1} sx={{maxWidth: '300px'}}>
              {t('segmentsEdit.noListSelected')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
