import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
// Utils
import initRequestData from 'ReduxToolkit/functions/initRequestData';
import noteSliceService from './noteSliceService';
// Types
import InitRequestDataReturn from 'ReduxToolkit/types/InitRequestDataReturn';
import { NoteSlice } from './types/NoteSlice';
import type { RootState } from 'ReduxToolkit/store';
import type { CreateNoteData } from './types/CreateNoteData';
import { UpdateNoteData } from './types/UpdateNoteData';
import { BaseNoteData } from './types/BaseNoteData';

const initialState: NoteSlice = {
  notes: null,
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: [],
    update: [],
  },
};

export const fetchNotes = createAsyncThunk(
  'notes/fetchNotes',
  async (_, { dispatch, getState }) => {
    const { Contacts } = getState() as RootState;
    const selectedContactId = Contacts.contactViewer.contactData.id;
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const notes = await noteSliceService.fetchNotes(
      jwtToken,
      `${URL_CONTACTS}/notes/${selectedContactId}`
    );

    return notes;
  }
);

export const createNote = createAsyncThunk(
  'notes/createNote',
  async (newNoteText: string, { dispatch, getState }) => {
    const { Contacts } = getState() as RootState;
    const selectedContactId = Contacts.contactViewer.contactData.id;
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const newNoteData: CreateNoteData = {
      contactId: selectedContactId,
      text: newNoteText,
    };

    const note = await noteSliceService.createNote(
      jwtToken,
      `${URL_CONTACTS}/notes`,
      newNoteData
    );

    return note;
  }
);

export const deleteNote = createAsyncThunk(
  'notes/deleteNote',
  async (noteId: string, { dispatch, getState }) => {
    const { Contacts } = getState() as RootState;
    const selectedContactId = Contacts.contactViewer.contactData.id;
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await noteSliceService.deleteNote(
      jwtToken,
      `${URL_CONTACTS}/notes/${selectedContactId}/${noteId}`
    );

    return noteId;
  }
);

export const updateNote = createAsyncThunk(
  'notes/updateNote',
  async (baseUpdatedNote: BaseNoteData, { dispatch, getState }) => {
    const { Contacts } = getState() as RootState;
    const selectedContactId = Contacts.contactViewer.contactData.id;
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const updateNoteData = {
      ...baseUpdatedNote,
      contactId: selectedContactId,
    };

    const updatedNote = await noteSliceService.updateNote(
      jwtToken,
      `${URL_CONTACTS}/notes`,
      updateNoteData
    );

    return updatedNote;
  }
);

const noteSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setDeletingNoteStatus(state, action: PayloadAction<string>) {
      state.status.delete.push(action.payload);
    },
    removeDeletingNoteStatus(state, action: PayloadAction<string>) {
      const filteredNotes = state.status.delete.filter(
        (note) => note !== action.payload
      );
      state.status.delete = filteredNotes;
    },
    setUpdatingNoteStatus(state, action: PayloadAction<string>) {
      state.status.update.push(action.payload);
    },
    removeUpdatingNoteStatus(state, action: PayloadAction<string>) {
      const filteredNotes = state.status.update.filter(
        (note) => note !== action.payload
      );
      state.status.update = filteredNotes;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotes.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.notes = action.payload;
      })
      .addCase(createNote.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.status.create = 'idle';
        state.notes.push(action.payload);
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        const filteredNotes = state.notes.filter(
          (note) => note.id !== action.payload
        );
        const filteredNoteIds = state.status.delete.filter(
          (noteId) => noteId !== action.payload
        );
        state.status.delete = filteredNoteIds;
        state.notes = filteredNotes;
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        const updatedNote = action.payload;
        const filteredNoteIds = state.status.update.filter(
          (noteId) => noteId !== updatedNote.id
        );
        const oldNoteIndex = state.notes.findIndex(
          (note) => note.id === updatedNote.id
        );

        state.notes[oldNoteIndex] = updatedNote;
        state.status.update = filteredNoteIds;
      });
  },
});

export const {
  setDeletingNoteStatus,
  removeDeletingNoteStatus,
  setUpdatingNoteStatus,
  removeUpdatingNoteStatus,
} = noteSlice.actions;

const selectNoteState = (state: RootState) => state.Note;
export const selectNotes = createSelector(
  selectNoteState,
  (noteState) => noteState.notes
);
export const selectNoteCreateStatus = createSelector(
  selectNoteState,
  (noteState) => noteState.status.create
);
export const selectNotesDeleteStatus = createSelector(
  selectNoteState,
  (noteState) => noteState.status.delete
);
export const selectNotesUpdateStatus = createSelector(
  selectNoteState,
  (noteState) => noteState.status.update
);

export default noteSlice.reducer;
