// Types
import type { IContactWithCheckbox } from 'ReduxToolkit/features/contactsSlice/types/IContactWithCheckBox';
import type { IBusinessWithCheckbox } from 'ReduxToolkit/features/contactsSlice/types/IBusinessWithCheckBox';
import { Property } from '@trii/types/dist/Contacts';

const getCustomPropertyCellValue = (
  properties: Property[] = [],
  columnName: string
) => {
  if (!properties.length) {
    return '';
  }

  const customPropertyCell = properties.find((prop) => prop.nameKey === columnName);

  if (customPropertyCell) {
    return customPropertyCell.value;
  }
  return '';
};

const getContactCellValue = (
  columnName: string,
  isDefault: boolean,
  contact: IContactWithCheckbox
) => {
  const { properties } = contact;

  if (isDefault) {
    switch (columnName) {
      case 'Id':
        return contact.id;
      case 'Name':
        return contact.name;
      case 'Email':
        return contact.email;
      case 'Phone':
        return contact.phone;
      case 'Address':
        return contact.address1?.street || contact.address2?.street;
      case 'Tags':
        return contact.tags?.length > 0 ? contact.tags[0]?.name : '';
      case 'BirthDate':
        return contact.birthDate
          ? new Date(contact.birthDate).toLocaleDateString() // Formatea la fecha
          : '';
      default:
        return '';
    }
  } else {
    return getCustomPropertyCellValue(properties, columnName);
  }
};


const getBusinessCellValue = (
  columnName: string,
  isDefault: boolean,
  business: IBusinessWithCheckbox
) => {
  const { properties } = business;
  if (isDefault) {
    switch (columnName) {
      case 'Id':
        return business.id;
      case 'Name':
        return business.name;
      case 'Email':
        return business.email;
      case 'Phone':
        return business.phone;
      case 'Tags':
        return business.tags?.length > 0 ? business.tags[0]?.name : '';
      default:
        return '';
    }
  } else return getCustomPropertyCellValue(properties, columnName);
};

export default {
  getContactCellValue,
  getBusinessCellValue,
};
