import { useContext } from 'react';
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
//  Components/ui
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
interface Props {
  onClick: () => void;
}

const ApplyFilterButton = ({ onClick }: Props) => {
  const { isFetchingFilters } = useContext(ContactBusinessTableContext);
  const { t } = useTranslation();

  return (
    <Box mb={'12px'} display="flex" justifyContent="end">
      <LoadingButton
        variant="contained"
        size="small"
        onClick={onClick}
        loading={isFetchingFilters}
      >
        {t('contactsView.contactBusinessTable.applyFilter')}
      </LoadingButton>
    </Box>
  );
};

export default ApplyFilterButton;
