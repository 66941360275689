import { useContext } from 'react';
// Context
import { ContactEditContext } from 'features/Views/ContactEdit/context/ContactEditContext';
// Components/ui
import { Body } from './components';
import { SubsectionHeader } from '..';
// Types
import IMS from './types/IMS';
import { Box } from '@mui/material';

type Props = {
  socialNetwork: IMS;
};

const InformationSocialNetworkItem = ({ socialNetwork }: Props) => {
  const { editContact } = useContext(ContactEditContext);
  const { field } = editContact;
  const title =
    socialNetwork === 'facebook'
      ? 'Facebook'
      : socialNetwork === 'instagram'
      ? 'Instagram'
      : 'Webchat';
  const IMSs =
    socialNetwork === 'facebook'
      ? field.imsFacebooks
      : socialNetwork === 'instagram'
      ? field.imsInstagrams
      : field.imsWebchats;

  return (
    <Box sx={{ width: '100%' }}>
      <SubsectionHeader title={title} />
      <Body IMSs={IMSs} />
    </Box>
  );
};

export default InformationSocialNetworkItem;
