// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button } from '@mui/material';
import { ReminderItem } from './components';
import AddIcon from '@mui/icons-material/Add';
import { ReminderNotificationsContainer, HeaderContainer } from './styles';
// Types
import { UseNotificationsType } from '../../../../../../hooks/useNotifications';

type ReminderNotificationsProps = {
  notificationsField: UseNotificationsType;
};

const ReminderNotifications = ({
  notificationsField,
}: ReminderNotificationsProps) => {
  const { t } = useTranslation();
  const {
    addNotification,
    deleteNotification,
    handleReminderChange,
    handleTimeFieldChange,
    handleTimePeriodChange,
  } = notificationsField.action;

  return (
    <ReminderNotificationsContainer>
      <HeaderContainer>
        {t('contactsView.activities.reminders')}
        <Button
          startIcon={<AddIcon />}
          size="small"
          variant="outlined"
          onClick={addNotification}
        >
          {t('contactsView.activities.addReminder')}
        </Button>
      </HeaderContainer>
      <Box sx={{ pt: 1, px: 0.5 }}>
        {notificationsField.notifications.map((notification) => (
          <ReminderItem
            key={notification.id}
            data={notification}
            deleteHandler={deleteNotification}
            sendToContactHandler={handleReminderChange}
            timePeriodHandler={handleTimePeriodChange}
            timeValueHandler={handleTimeFieldChange}
          />
        ))}
      </Box>
    </ReminderNotificationsContainer>
  );
};

export default ReminderNotifications;
