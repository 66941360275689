import React, { useEffect } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { fetchLists } from '../../../ReduxToolkit/features/listsSlice/listsSlice';
// Components
import ListsProvider from './context/ListsContext.tsx';
import { Box, Divider } from '@mui/material';
import { ManageListContent, ManageListHeader, SidePanel } from './components';

export default function ManageListContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLists());
  }, [dispatch]);

  return (
    <ListsProvider>
      <Box display={'flex'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          height={'100%'}
          width={'100%'}
          className={'contacts-paddingmd'}
          borderRadius={2}
        >
          <ManageListHeader />
          <Divider sx={{ marginTop: '10px', marginX: '10px' }} />
          <ManageListContent />
        </Box>
        <SidePanel />
      </Box>
    </ListsProvider>
  );
}
