import { useContext, memo } from 'react';
// Context
import { FilesCardContext } from '../../context/FilesCardContext';
// Components/ui
import { Table } from '@mui/material';
import { Head, Body } from './components';
import { StyledTableContainer } from './styles';
// Types
import { IFile } from '@trii/types/dist/Contacts';
import DeleteFileModal from '../DeleteFileModal';

interface FileTableProps {
  tableFiles: IFile[];
}
export default memo(function FilesTable({ tableFiles }: FileTableProps) {
  const { columns, sortByColumn } = useContext(FilesCardContext);

  return (
    <StyledTableContainer>
      <Table>
        <Head sortFunction={sortByColumn} columns={columns} />
        <Body files={tableFiles} />
      </Table>
      <DeleteFileModal/>
    </StyledTableContainer>
  );
});
