import useField from '../../../../../../../../hooks/useField';
import useSelect from '../../../../../../../../hooks/useSelect';
import useCheckbox from '../../../../../../../../hooks/useCheckbox';
import useColor from '../../../../../../../../hooks/useColor';
import type { UseFieldType } from '../../../../../../../../hooks/useField';
import { IconsSVG } from '../../../../../../../../components/IconsSVG/IconsSVG';
import {
  IIntegrationButton,
  IntegrationButton_method,
} from '@trii/types/dist/Contacts';

const useCreateButton = () => {
  // General section
  const enabledCheckbox = useCheckbox();
  const nameField: UseFieldType = useField('text');
  const iconField: UseFieldType = useField('text', IconsSVG[0].icon);
  const iconColorField = useColor();
  // Api section
  const methodSelect = useSelect(1);
  const urlField: UseFieldType = useField('text');
  const authTypeSelect = useSelect('Ninguna');
  const bodyField: UseFieldType = useField('text');
  const contentTypeField: UseFieldType = useField('text');
  //  "Basic" security inputs
  const usernameField: UseFieldType = useField('text');
  const passwordField: UseFieldType = useField('text');
  //  "Bearer Token" security inputs
  const fixedTokenField: UseFieldType = useField('text');
  const dynamicUrlField: UseFieldType = useField('text');
  const bearerTokenBodyField: UseFieldType = useField('text');
  //  "API Key" security inputs
  const apiKeyField: UseFieldType = useField('text');
  const valueField: UseFieldType = useField('text');
  const addToSelect = useSelect('Header');
  // General section
  const templateField: UseFieldType = useField('text');
  const outputFormatSelect = useSelect(1);

  const resetAll = () => {
    nameField.actions.resetValue();
    iconField.actions.changeValue(IconsSVG[0].icon);
    iconColorField.actions.resetValue();
    urlField.actions.resetValue();
    templateField.actions.resetValue();
    bodyField.actions.resetValue();
    contentTypeField.actions.resetValue();
    methodSelect.actions.resetValue();
    authTypeSelect.actions.changeValue('Ninguna');
    outputFormatSelect.actions.resetValue();
    enabledCheckbox.actions.resetValue();
    usernameField.actions.resetValue();
    passwordField.actions.resetValue();
    fixedTokenField.actions.resetValue();
    dynamicUrlField.actions.resetValue();
    bearerTokenBodyField.actions.resetValue();
    apiKeyField.actions.resetValue();
    valueField.actions.resetValue();
    addToSelect.actions.resetValue();
  };

  const getNewButton = (): IIntegrationButton => {
    return {
      id: 0,
      name: nameField.attributes.value,
      enabled: enabledCheckbox.attributes.checked,
      icon_class: iconField.attributes.value,
      icon_color: iconColorField.attributes.value,
      api_url: urlField.attributes.value,
      api_Template: templateField.attributes.value,
      api_body: bodyField.attributes.value,
      api_contenttype: contentTypeField.attributes.value,
      api_method: methodSelect.attributes.value as any,
      api_AuthType: authTypeSelect.attributes.value,
      api_formatosalida: outputFormatSelect.attributes.value as any,
      api_Auth_username: usernameField.attributes.value,
      api_Auth_password: passwordField.attributes.value,
      api_Auth_token: fixedTokenField.attributes.value,
      api_Auth_token_url: dynamicUrlField.attributes.value,
      api_Auth_token_body: bearerTokenBodyField.attributes.value,
      api_Auth_APIkey_Key: apiKeyField.attributes.value,
      api_Auth_APIkey_Value: valueField.attributes.value,
      api_Auth_APIkey_Addto: addToSelect.attributes.value,
    };
  };

  return {
    action: { resetAll, getNewButton },
    input: {
      nameField,
      iconField,
      iconColorField,
      urlField,
      templateField,
      bodyField,
      contentTypeField,
      methodSelect,
      authTypeSelect,
      outputFormatSelect,
      enabledCheckbox,
      usernameField,
      passwordField,
      fixedTokenField,
      dynamicUrlField,
      bearerTokenBodyField,
      apiKeyField,
      valueField,
      addToSelect,
    },
  };
};

export default useCreateButton;
