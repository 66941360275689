import React from 'react';
// Components/ui
import { Skeleton } from '@mui/material';

const skeletonItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const ListItemSkeleton = () => {
  return (
    <>
      {skeletonItems.map((item) => (
        <Skeleton
          key={item}
          variant="rectangular"
          width="100%"
          height={90}
          sx={{
            borderRadius: 1,
            backgroundColor: 'lightgrey',
          }}
        />
      ))}
    </>
  );
};

export default ListItemSkeleton;
