import { useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Types
import type UseEditContact from 'features/Views/ContactEdit/hooks/useEditContact/types/UseEditContact';
import type UseCreateContact from 'features/Views/ContactCreate/hooks/useCreateContact/types/UseCreateContact';
// Components/ui
import { ContactSection } from 'components/ContactCreateEditAppBody/layout';
import {
  Avatar,
  styled,
  Badge,
  Box,
  TextField,
  CircularProgress,
} from '@mui/material';
import { style } from './style';
import {
  ContactPropietarySelect,
  BusinessSelect,
  ContactListSelect,
  DateSelect,
} from './components';
import TagSelect from './components/TagSelect';
import CustomFieldSection from '../CustomFieldSection';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
// Redux
import { useSelector } from 'react-redux';
import { selectContactUploadPhotoStatus } from 'ReduxToolkit/features/contactsSlice/contactsSlice';

interface Props {
  contact: UseEditContact | UseCreateContact;
}

const DetailsSection = ({ contact }: Props) => {
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  const {
    contactPropietary,
    contactFirstname,
    contactLastname,
    contactListSelectState,
    contactImage,
    birthDate,
  } = contact.field;

  const { action, inputAtributes, imageUrl } = contactImage;

  const updatePhotoStatus = useSelector(selectContactUploadPhotoStatus);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    mr: '5px',
    color: 'red',
  }));

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <ContactSection sectionTitle={'General'}>
      <Box sx={style.container}>
        <Box sx={style.contactName}>
          <Box sx={style.avatarContainer}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <SmallAvatar>
                  <DeleteIcon sx={style.avatarDelete} onClick={action.deleteImage} />
                </SmallAvatar>
              }
            >
              <Avatar
                src={imageUrl}
                alt={
                  contactFirstname?.attributes?.value ||
                  contactLastname?.attributes?.value
                }
                sx={style.avatar}
                onClick={action.uploadImage}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {isHovered && (
                  <AddAPhotoIcon
                    style={{
                      position: 'absolute',
                      //@ts-ignore
                      color: (theme) =>
                        theme.palette.mode === 'dark'
                          ? theme.palette.color.primary
                          : 'rgb(0 0 0 / 50%)',
                      zIndex: 1,
                    }}
                  />
                )}
                {contactFirstname?.attributes?.value?.charAt(0) ||
                  contactLastname?.attributes?.value?.charAt(0)}
              </Avatar>
              {isHovered && imageUrl && (
                <AddAPhotoIcon sx={style.avatarUpdateIcon} />
              )}
              {updatePhotoStatus === 'loading' && (
                <Box sx={style.avatarUpdateIcon}>
                  <CircularProgress size={30} />
                </Box>
              )}
              <input {...inputAtributes} style={{ display: 'none' }} />
            </Badge>
          </Box>
          <TextField
            size="small"
            {...contactFirstname.attributes}
            label={t('global.name')}
            InputLabelProps={{
              shrink: true,
            }}
            className="contacts-smallInput"
          />
          <TextField
            sx={{ marginTop: '15px' }}
            size="small"
            {...contactLastname.attributes}
            label={t('global.lastname')}
            InputLabelProps={{
              shrink: true,
            }}
            className="contacts-smallInput"
          />
          <DateSelect
            birthDate={birthDate}
            setBirthDate={contact.action.setBirthDate}
          />
        </Box>
        <Box sx={style.contactData}>
          <ContactPropietarySelect contactPropietary={contactPropietary} />
          <ContactListSelect contactListSelectState={contactListSelectState} />
          <BusinessSelect contact={contact} />
          <TagSelect contact={contact} />
        </Box>
      </Box>
      <CustomFieldSection contact={contact} />
    </ContactSection>
  );
};

export default DetailsSection;
