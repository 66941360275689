import { useEffect } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Divider } from '@mui/material';
import {
  GeneralSection,
  InformationSection,
  AdressInformationSection,
} from './components';
import { BusinessPanel } from './layout';
// Types
import UseEditBusiness from 'features/Views/BusinessEdit/hooks/useEditBusiness/types/UseEditBusiness';
import UseCreateBusiness from 'features/Views/BusinessCreate/hooks/useCreateBusiness/types/UseCreateBusiness';
// Redux
import { fetchLabels } from 'ReduxToolkit/features/labelSlice/labelSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

interface Props {
  business: UseEditBusiness | UseCreateBusiness;
  mode: 'create' | 'edit';
}

const BusinessCreateEditAppBody = ({ business }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchLabels());
  }, []);

  return (
    <BusinessPanel title={t('contactsView.details')}>
      {business && (
        <>
          <GeneralSection business={business} />
          <Divider />
          <AdressInformationSection business={business} />
          <Divider />
          <InformationSection business={business} />
          <Box />
        </>
      )}
    </BusinessPanel>
  );
};

export default BusinessCreateEditAppBody;
