import React from 'react';

// components/ui
import {
  CreatePanelContent,
  CreateButtons,
  DeletePanelContent,
  DeleteButtons,
  Header,
} from './components';
import { Box, Divider } from '@mui/material';

function CrudPanel({ t, isDelete, handleDrawerClose }) {
  return (
    <Box
      sx={{
        width: '25%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '25%',
        },
        marginLeft: '20px',
        // marginTop: '5px',
        height: 'calc(100vh - 86px)',
        position: 'relative',
        zIndex: 0,
        backgroundColor: (theme) => theme.palette.background.panel,
      }}
    >
      <Header />
      <Divider />
      {!isDelete && <CreatePanelContent t={t} />}
      {isDelete && <DeletePanelContent t={t} />}
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      {isDelete ? (
        <DeleteButtons handleDrawerClose={handleDrawerClose} t={t} />
      ) : (
        <CreateButtons t={t} />
      )}
    </Box>
  );
}

export default CrudPanel;
