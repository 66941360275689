import { Close } from "@mui/icons-material";
import { Avatar, Box, Fade, IconButton, Modal } from "@mui/material";

interface AvatarViewerProps {
  avatar: string;
  name: string;
  open: boolean;
  handleClose: () => void;
}
const AvatarViewer = ({
  avatar,
  name,
  open,
  handleClose,
}: AvatarViewerProps) => {


  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'grid',
        placeItems: 'center',
        background: "rgba(0, 0, 0, 0.5)",
        "& .MuiBackdrop-root": {
          background: "rgba(0, 0, 0, 0.5)",
          border: "none",
        },
      }}
    >
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            padding: '1rem',
          }}
        >
          <IconButton
            // size="large"
            onClick={handleClose}
            sx={{
              color: 'primary.contrastText',
            }}
          >
            <Close
              fontSize="large"
            />
          </IconButton>
        </Box>
        <Fade
          in={open}
          timeout={600}
        >
          <Box
            sx={{
              width: '100%',
              height: 'calc(100% - 4rem)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.12)',
                bgcolor: (theme) => theme.palette.primary.light,
                color: (theme) => theme.palette.text.secondary,
                width: 500,
                height: 500,
                fontSize: '8rem',
              }}
              src={avatar}
              children={name}
            />
          </Box>
        </Fade>
      </Box>
    </Modal>
  )
}

export default AvatarViewer;