import { createContext } from 'react';
// Types
import type BusinessCreateContextType from './types/BusinessCreateContextType';
// Utils
import { initialDef } from '../hooks/useCreateBusiness/utils/initialDef';

export const BusinessCreateContext = createContext<BusinessCreateContextType>({
  business: initialDef,
  handleCreateButton: () => {},
});
