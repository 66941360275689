import { useContext } from 'react';
import { StyleContext } from 'style/styleProvider';
// Components/io
import { Box, Typography } from '@mui/material';
// Types
import { Property } from '@trii/types/dist/Contacts';

type Props = {
  title: string;
  value: string;
};

const ContactCustomFieldLabel = ({ title, value }: Props) => {
  const { fontSize, mediaQuery } = useContext(StyleContext);
  return (
    <Box
    className="fadein contacts-col-12"
    sx={{
      display: 'flex',
      backgroundColor: (theme) =>
        theme.palette.mode === 'dark'
          ? '1e1e1e'
          : theme.palette.background.default,
      // padding: '0 10px',
      flexDirection: 'column',
      borderRadius: 1,
      minWidth: "200px"

    }}
    >
      <Typography
        letterSpacing={-0.5}
        fontSize={mediaQuery.xl ? fontSize.sm : fontSize.xs}
        fontWeight={'600'}
        color={(theme) => theme.palette.text.primary}
      >
        {title}
      </Typography>
      <Box width={'50%'} position="relative">
      <Typography
          fontSize={mediaQuery.xl ? fontSize.md : fontSize.sm}
          fontWeight={'400'}
          sx={{ padding: '2.5px 14px 8.5px 0px' }}
          color={(theme) => theme.palette.text.primary}
        >
          {value === '' ? '-' : value}
        </Typography>
        {/* {date && (
          <Typography
            position="absolute"
            letterSpacing={-0.5}
            style={{ padding: '0px 14px' }}
            color={(theme) => theme.palette.text.secondary}
            variant="caption"
          >
            {date}
          </Typography>
        )} */}
      </Box>
    </Box>
  );
};

export default ContactCustomFieldLabel;
