import React, { useContext } from 'react';
import { SegmentsEditContext } from '../../context/SegmentsEditProvider';
// Components
import { Box, Container } from '@mui/material';
import {
  SegmentHeader,
  SegmentFilters,
  SegmentTransferList,
  SegmentInputName,
} from './components';

interface EditPanelProps {
  dataReady: boolean;
}

const EditPanel = ({ dataReady }: EditPanelProps) => {
  const { selectedSegment, deletePanelOpen } = useContext(SegmentsEditContext);
  const panelWidth = deletePanelOpen ? '75%' : '100%';

  return (
    <Container maxWidth="md">
      <Box
        display={'flex'}
        flexDirection={'column'}
        height={'100%'}
        maxHeight={`calc(100vh - 74px)`}
        width={panelWidth}
        p={2}
        gap={1}
      >
        <SegmentHeader dataReady={dataReady} />
        <Box maxHeight={'calc(100vh - 160px)'} overflow={'auto'} width="100%">
          <SegmentInputName dataReady={dataReady}></SegmentInputName>
          <SegmentTransferList dataReady={dataReady} />
          <SegmentFilters segmentData={selectedSegment} dataReady={dataReady} />
        </Box>
      </Box>
    </Container>
  );
};

export default EditPanel;
