import React, { useEffect, useState } from 'react';
import './MainLayout.css';
// Components/ui
import { Box, useTheme } from '@mui/material';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectThemeToggleStatus } from '../../../ReduxToolkit/features/themeSlice';
import { selectUser } from '../../../ReduxToolkit/features/userSlice/userSlice';
import { selectSession } from '../../../ReduxToolkit/features/sessionSlice/sessionSlice';
// Components
import { NavMenu } from './components';
import { PageLoading } from '../../../components';
import { useLocation } from 'react-router-dom';
import { useOpenCallPanelWidth } from './utils/useOpenCallPanelWidth';

function MainLayout(props) {
  const [session, setSession] = useState(null);
  const user = useSelector(selectUser);
  const sessionSelector = useSelector(selectSession);
  const themeToggleStatus = useSelector(selectThemeToggleStatus);

  const location = useLocation();

  const showNavMenu = !location.pathname.startsWith('/view/');

  useEffect(() => {
    if (sessionSelector) {
      setSession(sessionSelector);
    }
  }, [sessionSelector]);
  const theme = useTheme();
  const { boxWidth } = useOpenCallPanelWidth();
  return (
    <Box
      sx={{
        display: 'flex',
        width: boxWidth,
        height: 'calc(100vh - 4.3rem)',
      }}
    >
      {showNavMenu && (
        <Box
          sx={{
            //@ts-ignore
            backgroundImage: theme.palette.background.mainGradient,
            maxWidth: '3rem',
            minWidth: '3rem',
            width: '100%',
            maxHeight: 'calc(100vh - 4.5rem)',
            // boxShadow: 'inset rgba(0, 0, 0, 0.25) 0px 3px 6px 0px',
            borderRight: `1px solid ${theme.palette.divider}`,
          }}
        >
          <NavMenu />
        </Box>
      )}
      {session && user && themeToggleStatus === 'toggled' ? (
        <Box
          sx={{
            //@ts-ignore
            backgroundColor: theme.palette.background.mainCenter,
            width: '100%',
            overflow: 'auto',
            // boxShadow: 'inset rgba(0, 0, 0, 0.25) 0px 3px 6px 0px',
          }}
        >
          <Box
            id="js-page-content"
            // p={2}
            sx={{
              // maxHeight: 'calc(100vh - 5.8rem)',
              height: '100%',
            }}
          >
            {props.children}
          </Box>
        </Box>
      ) : (
        <PageLoading />
      )}
    </Box>
  );
}

export default MainLayout;
