import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Contacts } from '@trii/types';
// Redux
import { useSelector } from 'react-redux';
import { selectGoogleAccountData } from '../../../../../../../../../../../../../ReduxToolkit/features/googleSyncSlice/googleSyncSlice';
// Components/ui
import { Fade, Chip } from '@mui/material';

function StateChip() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const googleAccountData: Contacts.GoogleAccountInfo = useSelector(
    selectGoogleAccountData
  );
  const chipLabel = googleAccountData?.name
    ? t('settingsView.enabled')
    : t('settingsView.disabled');

  console.log();

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <Chip
        size="small"
        label={chipLabel}
        sx={{
          backgroundColor: googleAccountData?.name ? '#4ade80' : '#fca5a5',
        }}
      />
    </Fade>
  );
}

export default StateChip;
