import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Context
import { ContactBusinessTableContext } from './ContactBusinessTableContext';
// Hooks
import useSelect from 'hooks/useSelect';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  selectContacts,
  selectPagination,
  fetchContactsTableData,
  fetchContactFields,
  changeFilterPanelVisibility,
  changeItemsPerPage,
  setPagination,
  getContactFieldsFetchStatus,
  changeColumnVisibility,
  fetchBusinessTableData,
  checkContact,
  checkBusiness,
  changeCurrentPage,
  changeContactOrder,
  checkPageContacts,
  checkPageBusiness,
  clearTableData,
  clearStatus,
  getContactTableSetStatus,
  getPreviousPath,
  setContactTableSetStatus,
  setBusinessTableSetStatus,
  getBusinessTableSetStatus,
  setPaginationFilter,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { fetchLabels } from 'ReduxToolkit/features/labelSlice/labelSlice';
import { fetchLists } from 'ReduxToolkit/features/listsSlice/listsSlice';
import {
  deleteInitialFields,
  setList,
  setTag,
} from 'ReduxToolkit/features/contactsFilterSlice/contactsFilterSlice';
// Types
import type Mode from './types/Mode';
import type ColumnOrderData from './types/ColumnOrderData';
import type Checked from './types/Checked';
import type ContactFieldsFetchData from 'ReduxToolkit/features/contactsSlice/types/ContactFieldsFetchData';
import type { Filter } from 'ReduxToolkit/features/contactsSlice/types/Filter';
import type { Pagination } from 'ReduxToolkit/features/contactsSlice/types/Pagination';
import type { TableContactField } from 'ReduxToolkit/features/contactsSlice/types/TableContactField';
import type { PaginationFolder } from 'ReduxToolkit/features/contactsSlice/types/PaginationFolder';
// Utils
import tableHelper from '../utils/tableHelper';
import tableItemHelper from '../utils/tableItemHelper';

const ContactBusinessTableContextProvider = ({ children }) => {
  const mode: Mode = window.location.pathname.includes('business')
    ? 'business'
    : 'contact';

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const segmentId = searchParams.get('segmentId');

  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [isFetchingFilters, setIsFetchingFilters] = useState(false);
  const [openModalList, setOpenModalList] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const paginationFolderSelect = useSelect('Main');

  const pagination = useSelector(selectPagination);
  const contacts = useSelector(selectContacts);
  const contactFieldsFetchStatus = useSelector(getContactFieldsFetchStatus);
  const hasContactTableDataBeenSet = useSelector(getContactTableSetStatus);
  const hasBusinessTableDataBeenSet = useSelector(getBusinessTableSetStatus);
  const previousPath = useSelector(getPreviousPath);

  const { getBusinessCellValue, getContactCellValue } = tableItemHelper;

  // General Functions
  const handleCloseModal = () => {
    setModalTitle('');
    setOpenModalList(false);
  };

  // Filter panel
  const handleFilterButton = () => {
    setIsFilterPanelOpen((isFilterPanelOpen) => !isFilterPanelOpen);
  };
  // Filter functions
  const resetFilterFields = () => {
    dispatch(setTag(''));
    dispatch(setList(''));
    dispatch(deleteInitialFields(null));
  };

  const applyFilter = async (newFilters: Filter[]) => {
    setIsFetchingFilters(true);

    const newPaginationFetchData: Pagination = {
      ...pagination,
      filter: [...newFilters],
    };

    if (mode === 'contact')
      await dispatch(fetchContactsTableData(newPaginationFetchData));
    if (mode === 'business')
      await dispatch(fetchBusinessTableData(newPaginationFetchData));

    dispatch(setPaginationFilter(newFilters));
    setIsFetchingFilters(false);
  };

  // Table
  const fetchTableData = async (fetchData: Pagination) => {
    mode === 'contact'
      ? await dispatch(fetchContactsTableData(fetchData))
      : await dispatch(fetchBusinessTableData(fetchData));
  };
  const handleItemCheck = (id: string) => {
    if (mode === 'contact') dispatch(checkContact(id));
    if (mode === 'business') dispatch(checkBusiness(id));
  };
  const handlePageNumberChange = (pageNumber: number) => {
    const fetchData = {
      currentPage: pageNumber,
    };

    dispatch(changeCurrentPage(pageNumber));

    if (mode === 'contact')
      dispatch(fetchContactsTableData({ ...pagination, ...fetchData }));
    if (mode === 'business')
      dispatch(fetchBusinessTableData({ ...pagination, ...fetchData }));
  };
  const handleContactClick = (id: string) => {
    if (mode === 'contact') navigate(`/a/contacts/contacts/${id}`);
    if (mode === 'business') navigate(`/a/contacts/business/${id}`);
  };
  const handlePageAmountChange = async (e) => {
    const fetchData = {
      perPage: e.target.value,
    };

    tableHelper.setAmoutOfItems(mode, e.target.value);
    dispatch(changeItemsPerPage(e.target.value));
    await fetchTableData({ ...pagination, ...fetchData });
  };
  const handleColumnClick = async (orderColumn: string) => {
    const order = tableHelper.getNewSortDirection(pagination.order);
    const contactOrder: ColumnOrderData = { order, orderColumn };
    tableHelper.setItemsOrder(mode, contactOrder);
    await dispatch(changeContactOrder(contactOrder));
    await fetchTableData({ ...pagination, ...contactOrder });
  };
  const handleParentCheck = (checked: Checked) => {
    const getCheck = tableHelper.getParentCheck(checked);

    if (mode === 'contact') dispatch(checkPageContacts(getCheck));
    else dispatch(checkPageBusiness(getCheck));

    return;
  };
  const setColumnsVisibility = (columns: TableContactField[]) => {
    columns.forEach((column) =>
      dispatch(
        changeColumnVisibility({
          columnName: column.name,
          visible: column.visible,
        })
      )
    );
  };
  const handleColumnsChange = (columns: TableContactField[]) => {
    tableHelper.setFields(mode, columns);
    setColumnsVisibility(columns);
  };
  const getFetchData = (folder: PaginationFolder) => {
    const format = mode === 'contact' ? 'IContact' : 'IBusiness';
    const amountOfTableItems = tableHelper.getAmountOfItems(mode);
    const contactOrder = tableHelper.getItemsOrder(mode) || {
      order: 'ASC',
      orderColumn: 'Id',
    };
    const filter: Filter[] = segmentId
      ? [{ column: 'Segment', value: segmentId, condition: 'equal' }]
      : [];

    const newPaginationData: Pagination = {
      ...pagination,
      ...contactOrder,
      format,
      filter,
      folder,
      perPage: amountOfTableItems,
    };

    return newPaginationData;
  };
  const handlePaginationFolderChange = (event) => {
    paginationFolderSelect.attributes.onChange(event);

    const { value } = event.target;
    const newPaginationData = getFetchData(value as PaginationFolder);

    dispatch(clearTableData());
    dispatch(setPagination(newPaginationData));

    fetchTableData(newPaginationData);
  };

  useEffect(() => {
    const isFilterPanelOpen = tableHelper.getIsFilterPanelOpen(mode) || 'false';

    if (isFilterPanelOpen) {
      dispatch(changeFilterPanelVisibility(isFilterPanelOpen === 'true'));
    }
  }, [dispatch]);

  useEffect(() => {
    const storageFields = tableHelper.getFields(mode) || null;
    if (storageFields) setColumnsVisibility(storageFields);
  }, [contactFieldsFetchStatus]);

  useEffect(() => {
    const fetchData = async () => {
      const amountOfTableItems = tableHelper.getAmountOfItems(mode);
      const fetchFieldsData: ContactFieldsFetchData = {
        filterBy: 'all',
        fetchFor: mode,
      };
      const newPaginationData: Pagination = getFetchData(
        paginationFolderSelect.attributes.value as PaginationFolder
      );
      await dispatch(setPagination(newPaginationData));
      await dispatch(changeItemsPerPage(amountOfTableItems));
      await dispatch(fetchContactFields(fetchFieldsData));
      await dispatch(fetchLabels());
      await dispatch(fetchLists());
      fetchTableData(newPaginationData);
    };
    if (
      mode === 'contact' &&
      (!hasContactTableDataBeenSet ||
        (hasContactTableDataBeenSet &&
          !previousPath.toLowerCase().startsWith('/a/contacts/contacts')))
    ) {
      fetchData();
      dispatch(setContactTableSetStatus(true));
    } else if (
      mode === 'business' &&
      (!hasBusinessTableDataBeenSet ||
        (hasBusinessTableDataBeenSet &&
          !previousPath.toLowerCase().startsWith('/a/contacts/business')))
    ) {
      fetchData();
      dispatch(setBusinessTableSetStatus(true));
    }
  }, [location, hasContactTableDataBeenSet, previousPath, dispatch]);

  useEffect(() => {
    const cleanUp = () => {
      if (
        (mode === 'contact' &&
          !location.pathname.toLowerCase().startsWith('/a/contacts/contacts')) ||
        (mode === 'business' &&
          !location.pathname.toLowerCase().startsWith('/a/contacts/business'))
      ) {
        dispatch(clearStatus());
        dispatch(clearTableData());
      }
    };
    return cleanUp;
  }, []);

  useEffect(() => {
    if (
      (mode === 'contact' &&
        location.pathname.toLowerCase().startsWith('/a/contacts/contacts') &&
        previousPath.toLowerCase().startsWith('/a/contacts/contacts')) ||
      (mode === 'business' &&
        location.pathname.toLowerCase().startsWith('/a/contacts/business') &&
        previousPath.toLowerCase().startsWith('/a/contacts/business'))
    ) {
      if (pagination?.filter.length > 0) {
        // On first load filter logic
      }
    } else {
      resetFilterFields();
    }
  }, []);

  return (
    <ContactBusinessTableContext.Provider
      value={{
        getFetchData,
        fetchTableData,
        handlePaginationFolderChange,
        paginationFolderSelect,
        applyFilter,
        contacts,
        isFilterPanelOpen,
        handleFilterButton,
        getContactCellValue,
        getBusinessCellValue,
        isFetchingFilters,
        mode,
        handleItemCheck,
        handlePageNumberChange,
        handleContactClick,
        handlePageAmountChange,
        handleColumnClick,
        handleParentCheck,
        handleColumnsChange,
        openModalList,
        setOpenModalList,
        modalTitle,
        setModalTitle,
        handleCloseModal,
      }}
    >
      {children}
    </ContactBusinessTableContext.Provider>
  );
};

export default ContactBusinessTableContextProvider;
