import { useContext } from 'react';
import { useParams } from 'react-router-dom';
// Context
import { BusinessEditContext } from '../../context/BusinessEditContext';
// Components
import { SaveButtonGroup } from './components';
import { BusinessCreateEditAppBody, BusinessCreateEditAppHeader } from 'components';

const App = () => {
  const { editBusiness } = useContext(BusinessEditContext);
  const { businessId } = useParams();

  return (
    <div>
      <BusinessCreateEditAppHeader
        mode="edit"
        navigateBackPath={`/a/contacts/business/${businessId}`}
        business={editBusiness}
      >
        <SaveButtonGroup businessId={businessId} />
      </BusinessCreateEditAppHeader>
      <BusinessCreateEditAppBody mode="edit" business={editBusiness} />
    </div>
  );
};

export default App;
