import { useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MultiInputTimeRangeField } from '@mui/x-date-pickers-pro/MultiInputTimeRangeField';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { Box } from '@mui/material';

type Props = {
  nameKey: string;
  value: string;
  onChange: (name: string, value: any) => void;
  name: string;
};

const CustomPropertyTimeRangePicker = ({
  value,
  onChange,
  nameKey,
  name,
}: Props) => {
  const { t } = useTranslation();
  const initDates: DateRange<Dayjs> = [
    dayjs().set('hour', 0).set('minute', 0),
    dayjs().set('hour', 0).set('minute', 0),
  ];
  const [dates1, setDates1] = useState<DateRange<Dayjs>>(initDates);

  const handleFirstHour = (e: any) => {
    const startHour = `${e[0]?.$H}:${e[0]?.$m === 0 ? '00' : e[0]?.$m}`;
    const endHour = `${e[1]?.$H}:${e[1]?.$m === 0 ? '00' : e[1]?.$m}`;
    setDates1(e);
  };

  return (
    <Box
      className="fadein"
      sx={{
        display: 'flex',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? '1e1e1e'
            : theme.palette.background.default,
        // padding: '0 10px',
        flexDirection: 'column',
        borderRadius: 1,
        width: '45%',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MultiInputTimeRangeField
          slotProps={{
            textField: ({ position }) => ({
              label:
                position === 'start'
                  ? `${name} - ${t('contactEdit.from')}`
                  : `${name} - ${t('contactEdit.to')}`,
              size: 'small',
              className: 'contacts-smallInput',
            }),
          }}
          defaultValue={[
            dayjs().set('hour', 0).set('minute', 0),
            dayjs().set('hour', 0).set('minute', 0),
          ]}
          value={dates1}
          format="HH:mm"
          onChange={(e) => handleFirstHour(e)}
          sx={{
            '& .MuiTypography-body1': {
              color: (theme) => theme.palette.text.primary,
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomPropertyTimeRangePicker;
