// Custom hooks
import useField, { UseFieldType } from 'hooks/useField';
import { useActivityDate, UseActivityDateType } from './useActivityDate';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
import { useActivityDateInitialDef } from './useActivityDate';
import {
  useNotifications,
  useNotificationsInitialDef,
  UseNotificationsType,
} from './useNotifications';
import {
  useShareWithUsers,
  useShareWithUsersInitialDef,
  UseShareWithUsersType,
} from './useShareWithUsers';
import {
  useLinkContacts,
  useLinkContactsInitialDef,
  UseLinkContactsType,
} from './useLinkContacts';
// Types
import { AddActivityRequestData } from 'ReduxToolkit/features/activitiesSlice/types/AddActivityRequestData';
import { IEvent } from '@trii/types/dist/Calendar';
import { UserInfo } from '@trii/types/dist/Users';

export const useCreateActivityInitialDef = {
  field: {
    title: fieldInitialDef,
    description: fieldInitialDef,
    date: useActivityDateInitialDef,
    notifications: useNotificationsInitialDef,
    shareWithUsers: useShareWithUsersInitialDef,
    linkContact: useLinkContactsInitialDef,
  },
  action: { resetFields: () => {}, getActivity: () => {} },
};

export type UseCreateActivityType = {
  field: {
    title: UseFieldType;
    description: UseFieldType;
    date: UseActivityDateType;
    notifications: UseNotificationsType;
    shareWithUsers: UseShareWithUsersType;
    linkContact: UseLinkContactsType;
  };
  action: { resetFields: () => void; getActivity: () => any };
};

export function useCreateActivity(activity?: IEvent): UseCreateActivityType {
  const title = useField('text', activity?.title);
  const description = useField('text', activity?.details);
  const date = useActivityDate(activity?.startAt, activity?.endAt);
  const notifications = useNotifications(activity?.notifications);
  const linkContact = useLinkContacts(activity?.relatedContact.id);
  const shareWithUsers = useShareWithUsers(
    activity && getUserInfoIds(activity.sharedWith)
  );

  function getUserInfoIds(userInfos: UserInfo[]): string[] {
    return userInfos.map((userInfo) => userInfo.id);
  }

  function resetFields() {
    title.actions.resetValue();
    description.actions.resetValue();
    date.action.resetDates();
    notifications.action.resetNotifications();
    linkContact.action.resetField();
    shareWithUsers.action.resetField();
  }

  function getActivity(): AddActivityRequestData {
    return {
      title: title.attributes.value,
      details: description.attributes.value,
      startAt: date.state.selectedStartDate.toDate(),
      endAt: date.state.selectedEndDate.toDate(),
      contactId: linkContact.state.contactSelectedId,
      sharedWith: shareWithUsers.field.userIds,
      notifications: notifications.notifications,
      isPrivate: false,
      allDay: false,
      location: '',
      eventTypeId: '', //null
      calendarId: '', //null
      repeatId: '', //null
    };
  }

  return {
    field: { title, description, date, notifications, shareWithUsers, linkContact },
    action: { resetFields, getActivity },
  };
}
