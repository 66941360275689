import React, { useEffect, useRef } from 'react';
import { Contacts } from '@trii/types';
// Redux
import { useSelector } from 'react-redux';
import {
  selectGoogleAccountData,
  selectFetchSyncConfigStatus,
} from '../../../../../../../../../ReduxToolkit/features/googleSyncSlice/googleSyncSlice';
// Types
import { RequestStatus } from '../../../../../../../../../types/reduxTypes';
// Components/ui
import SyncSection from '../SyncSection';
import { Box } from '@mui/material';
import {
  GoogleAccountCard,
  GoogleLogInButton,
  GoogleLogOutButton,
  GoogleButtonSkeleton,
  Status,
} from './components';

const GoogleSignInSection = () => {
  const googleAccountData: Contacts.GoogleAccountInfo = useSelector(
    selectGoogleAccountData
  );
  const fetchSyncConfigStatus: RequestStatus = useSelector(
    selectFetchSyncConfigStatus
  );
  const wrapperRef = useRef(null);
  const GoogleButton =
    fetchSyncConfigStatus === 'loading' ? (
      <GoogleButtonSkeleton />
    ) : googleAccountData?.name ? (
      <GoogleLogOutButton />
    ) : null;

  useEffect(() => {
    if (googleAccountData?.name && wrapperRef.current) {
      wrapperRef.current.style.height = '140px';
    } else {
      wrapperRef.current.style.height = '0px';
    }
  }, [googleAccountData]);

  return (
    <SyncSection>
      <Box
        ref={wrapperRef}
        width="100%"
        sx={{
          transition: 'all 0.2s ease',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Status fetchSyncConfigStatus={fetchSyncConfigStatus} />
          {GoogleButton}
          <GoogleLogInButton />
        </Box>
        {googleAccountData?.name && (
          <GoogleAccountCard userData={googleAccountData} />
        )}
      </Box>
    </SyncSection>
  );
};

export default GoogleSignInSection;
