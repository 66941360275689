import Box from '@mui/material/Box';

import {
  DuplicatesFilter,
  Pagination,
  DuplicatesAmount,
  ContactSearch,
} from './components';

interface Props {
  quantity: number;
  setQuantity: (value: number) => void;
}

export default function DuplicatesHeader({ quantity, setQuantity }: Props) {
  return (
    <Box
      sx={{
        paddingX: 2,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
      className="m-0"
      borderRadius={'4px'}
      p={1}
      height={'9rem'}
    >
      {/* Filtro y pagincion */}
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <DuplicatesFilter quantity={quantity} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <DuplicatesAmount />
            <Pagination quantity={quantity} setQuantity={setQuantity} />
          </Box>
          <ContactSearch />
        </Box>
      </Box>
    </Box>
  );
}
