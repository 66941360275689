import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentsContext } from '../../context/SegmentsProvider';
// Components/ui
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

export default function SegmentsHeader() {
  const { t } = useTranslation();
  const { openCreatePanel, createPanelOpen } = useContext(SegmentsContext);
  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      sx={{ paddingRight: 2 }}
      borderRadius={'4px'}
      height="50px"
      className="m-0"
    >
      <Button
        sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
        variant="contained"
        size="small"
        onClick={openCreatePanel}
        disabled={createPanelOpen}
      >
        {t('segments.createSegment')}
      </Button>
    </Box>
  );
}
