import { useEffect, ReactNode } from 'react';
// Redux
import {
  fetchContactFields,
  getContactFieldsFetchStatus,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { getUsers } from 'ReduxToolkit/features/userSlice/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLists } from 'ReduxToolkit/features/listsSlice/listsSlice';
// Components/ui
import { Box } from '@mui/material';
// Types
import type ContactFieldsFetchData from 'ReduxToolkit/features/contactsSlice/types/ContactFieldsFetchData';
import { ErrorAlert } from './components';
import { fetchLabels } from 'ReduxToolkit/features/labelSlice/labelSlice';

type Props = {
  children: ReactNode;
};

const ContactCreateContainer = ({ children }: Props) => {
  const dispatch = useDispatch();
  const contactFieldsFetchStatus = useSelector(getContactFieldsFetchStatus);

  useEffect(() => {
    // Fetching the custom fields
    if (contactFieldsFetchStatus !== 'succeeded') {
      const fetchData: ContactFieldsFetchData = {
        filterBy: 'custom',
        fetchFor: 'contact',
      };

      dispatch(fetchContactFields(fetchData));
    }
    dispatch(fetchLists());
    dispatch(getUsers());
    dispatch(fetchLabels());
  }, [dispatch]);

  return (
    <>
      <Box width={'100%'} py={'0.5rem'} px={'0.5rem'} borderRadius={2}>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark'
                ? '1e1e1e'
                : theme.palette.background.default,
          }}
        >
          {children}
        </Box>
      </Box>
      <ErrorAlert />
    </>
  );
};

export default ContactCreateContainer;
