// Components/ui
import {
  CustomPropertyDatePicker,
  CustomPropertyDateRangePicker,
  CustomPropertyInput,
  CustomPropertyOptionSelect,
  CustomPropertyTimePicker,
  CustomPropertyTimeRangePicker,
} from './components';
// Types
import type UseEditBusiness from 'features/Views/BusinessEdit/hooks/useEditBusiness/types/UseEditBusiness';
import type UseCreateBusiness from 'features/Views/BusinessCreate/hooks/useCreateBusiness/types/UseCreateBusiness';
import { ContactField_type } from '@trii/types/dist/Contacts';
// Redux
import { useSelector } from 'react-redux';
import { selectBusinessFields } from 'ReduxToolkit/features/businessSlice/selectors';

interface Props {
  business: UseEditBusiness | UseCreateBusiness;
}

const CustomFields = ({ business }: Props) => {
  const { customProperties } = business.field;
  const { setCustomProperties } = business.action;
  const customBusinessFields = useSelector(selectBusinessFields);

  const handleInputChange = (name: string, value: any) => {
    const updatedProperties = customProperties.map((property) =>
      property.nameKey === name ? { ...property, value } : property
    );

    setCustomProperties(updatedProperties);
  };
  const Fields = customProperties.map((customProperty) => {
    if (customProperty.type === ContactField_type.DATE)
      return (
        <CustomPropertyDatePicker
          key={customProperty.nameKey}
          nameKey={customProperty.nameKey}
          value={customProperty.value}
          onChange={handleInputChange}
        />
      );
    else if (customProperty.type === ContactField_type.OPTIONS)
      return (
        <CustomPropertyOptionSelect
          key={customProperty.nameKey}
          nameKey={customProperty.nameKey}
        />
      );
    else if (customProperty.type === ContactField_type.TIME)
      return (
        <CustomPropertyTimePicker
          key={customProperty.nameKey}
          nameKey={customProperty.nameKey}
          value={customProperty.value}
          onChange={handleInputChange}
        />
      );
    else if (customProperty.type === ContactField_type.TIMERANGE)
      return (
        <CustomPropertyTimeRangePicker
          key={customProperty.nameKey}
          nameKey={customProperty.nameKey}
          value={customProperty.value}
          onChange={handleInputChange}
        />
      );
    else if (customProperty.type === ContactField_type.DATERANGE)
      return (
        <CustomPropertyDateRangePicker
          key={customProperty.nameKey}
          nameKey={customProperty.nameKey}
          value={customProperty.value}
          onChange={handleInputChange}
        />
      );
    else
      return (
        <CustomPropertyInput
          type={customProperty.type}
          key={customProperty.nameKey}
          nameKey={customProperty.nameKey}
          value={customProperty.value}
          onChange={handleInputChange}
        />
      );
  });

  return <>{customBusinessFields && Fields}</>;
};

export default CustomFields;
