import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionListContext } from '../context/SubscriptionListContext';
// redux
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSubscriptions,
  selectSubscriptionsFetchStatus,
  selectSubscriptions,
} from '../../../../../../../ReduxToolkit/features/configSlice/configSlice';
// components/ui
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import {
  ListHeader,
  CrudPanel,
  SubscriptionErrorAlert,
  SubscriptionItem,
} from './components';
import { ConfigListItemsSkeleton } from '../../../../components';

const SubscriptionList = () => {
  const {
    openPanelCP,
    handleDrawerOpen,
    openEdit,
    openDelete,
    isEditing,
    isDelete,
    handleDrawerClose,
    closeDelete,
  } = useContext(SubscriptionListContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subscriptionsFetchStatus = useSelector(selectSubscriptionsFetchStatus);
  const subscriptions = useSelector(selectSubscriptions);

  const Subscriptions = subscriptions?.map((item, i) => (
    <SubscriptionItem
      key={i}
      item={item}
      openEdit={openEdit}
      openDelete={openDelete}
    />
  ));

  React.useEffect(() => {
    if (subscriptionsFetchStatus === 'idle') {
      dispatch(fetchSubscriptions());
    }

    return () => {
      closeDelete();
    };
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SubscriptionErrorAlert />
      <Box display={'flex'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          height={'100%'}
          width={'100%'}
          className={'contacts-paddingmd paddingContent'}
          borderRadius={2}
          id={'listContentCP'}
        >
          <ListHeader open={handleDrawerOpen} show={openPanelCP} />
          <Divider sx={{ marginY: '10px', marginX: '10px' }} />
          <Box
            sx={{
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 185px)',
              gap: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {subscriptionsFetchStatus === 'loading' ? (
              <ConfigListItemsSkeleton />
            ) : (
              Subscriptions
            )}
          </Box>
        </Box>
        {/* Box desplegable */}
        {openPanelCP && (
          <CrudPanel
            isEditing={isEditing}
            t={t}
            isDelete={isDelete}
            handleDrawerClose={handleDrawerClose}
          />
        )}
      </Box>
    </>
  );
};

export default SubscriptionList;
