// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
  SelectChangeEvent,
} from '@mui/material';
import {
  StyledReminderItemContainer,
  StyledReminderInputsContainer,
  StyledReminderTextField,
  StyledReminderSelect,
  StyledReminderActionsContainer,
  StyledReminderDeleteIconButton,
} from './style';
import DeleteIcon from '@mui/icons-material/Delete';
// Utils
import { selectOptions } from './utils/selectOptions';
// Types
import { EventNotificationFront } from 'ReduxToolkit/features/activitiesSlice/types/EventNotificationFront';
import { ChangeEvent } from 'react';

type ReminderItemProps = {
  data: EventNotificationFront;
  timeValueHandler: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    notificationId: string
  ) => void;
  timePeriodHandler: (
    event: SelectChangeEvent<unknown>,
    notificationId: string
  ) => void;
  sendToContactHandler: (
    event: ChangeEvent<HTMLInputElement>,
    notificationId: string
  ) => void;
  deleteHandler: (notificationId: string) => void;
};

const ReminderItem = ({
  data,
  timeValueHandler,
  timePeriodHandler,
  sendToContactHandler,
  deleteHandler,
}: ReminderItemProps) => {
  const { t } = useTranslation();

  return (
    <StyledReminderItemContainer>
      <StyledReminderInputsContainer>
        {t('contactsView.activities.sendReminder.pt1')}
        <StyledReminderTextField
          onChange={(e) => timeValueHandler(e, data.id)}
          value={data.timeValue}
          type="text"
          variant="outlined"
          size="small"
        />
        <StyledReminderSelect
          onChange={(e) => timePeriodHandler(e, data.id)}
          value={data.timePeriod}
          variant="outlined"
          size="small"
        >
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {t(`contactsView.activities.timePeriod.${option.title}`)}
            </MenuItem>
          ))}
        </StyledReminderSelect>
        {t('contactsView.activities.sendReminder.pt2')}
      </StyledReminderInputsContainer>
      <StyledReminderActionsContainer>
        <Checkbox
          checked={data.sendToContact}
          onChange={(e) => sendToContactHandler(e, data.id)}
        />
        {t(`contactsView.activities.sendReminderToContact`)}
        <StyledReminderDeleteIconButton
          onClick={() => deleteHandler(data.id)}
          sx={{ ml: 'auto' }}
        >
          <DeleteIcon />
        </StyledReminderDeleteIconButton>
      </StyledReminderActionsContainer>
    </StyledReminderItemContainer>
  );
};

export default ReminderItem;
