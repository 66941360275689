import { useState } from 'react';
import useField from 'hooks/useField';
import useSelect from 'hooks/useSelect';
import useCheckbox from 'hooks/useCheckbox';
import useColor from 'hooks/useColor';
import { IIntegrationButton } from '@trii/types/dist/Contacts';

const useEditButton = () => {
  const [toBeEdditedButton, setToBeEditedButton] =
    useState<IIntegrationButton | null>(null);

  // General section
  const enabledCheckbox = useCheckbox(toBeEdditedButton?.enabled);
  const nameField = useField('text', toBeEdditedButton?.name);
  const iconField = useField('text', toBeEdditedButton?.icon_class);
  const iconColorField = useColor(toBeEdditedButton?.icon_color);
  // Api section
  const methodSelect = useSelect(toBeEdditedButton?.api_method || 1);
  const urlField = useField('text', toBeEdditedButton?.api_url);
  const authTypeSelect = useSelect(toBeEdditedButton?.api_AuthType || 'Ninguna');
  const bodyField = useField('text', toBeEdditedButton?.api_body);
  const contentTypeField = useField('text', toBeEdditedButton?.api_contenttype);
  //  "Basic" security inputs
  const usernameField = useField('text', toBeEdditedButton?.api_Auth_username);
  const passwordField = useField('text', toBeEdditedButton?.api_Auth_password);
  //  "Bearer Token" security inputs
  const fixedTokenField = useField('text', toBeEdditedButton?.api_Auth_token);
  const dynamicUrlField = useField('text', toBeEdditedButton?.api_Auth_token_url);
  const bearerTokenBodyField = useField(
    'text',
    toBeEdditedButton?.api_Auth_token_body
  );
  //  "API Key" security inputs
  const apiKeyField = useField('text', toBeEdditedButton?.api_Auth_APIkey_Key);
  const valueField = useField('text', toBeEdditedButton?.api_Auth_APIkey_Value);
  const addToSelect = useSelect(
    toBeEdditedButton?.api_Auth_APIkey_Addto || 'Header'
  );
  // General section
  const templateField = useField('text', toBeEdditedButton?.api_Template);
  const outputFormatSelect = useSelect(toBeEdditedButton?.api_formatosalida || 1);

  const getEditedButton = (): IIntegrationButton => {
    return {
      id: toBeEdditedButton.id,
      name: nameField.attributes.value,
      enabled: enabledCheckbox.attributes.checked,
      icon_class: iconField.attributes.value,
      icon_color: iconColorField.attributes.value,
      api_url: urlField.attributes.value,
      api_Template: templateField.attributes.value,
      api_body: bodyField.attributes.value,
      api_contenttype: contentTypeField.attributes.value,
      api_method: methodSelect.attributes.value as any,
      api_AuthType: authTypeSelect.attributes.value,
      api_formatosalida: outputFormatSelect.attributes.value as any,
      api_Auth_username: usernameField.attributes.value,
      api_Auth_password: passwordField.attributes.value,
      api_Auth_token: fixedTokenField.attributes.value,
      api_Auth_token_url: dynamicUrlField.attributes.value,
      api_Auth_token_body: bearerTokenBodyField.attributes.value,
      api_Auth_APIkey_Key: apiKeyField.attributes.value,
      api_Auth_APIkey_Value: valueField.attributes.value,
      api_Auth_APIkey_Addto: addToSelect.attributes.value,
    };
  };

  return {
    action: { getEditedButton, setToBeEditedButton },
    input: {
      nameField,
      iconField,
      iconColorField,
      urlField,
      templateField,
      bodyField,
      contentTypeField,
      methodSelect,
      authTypeSelect,
      outputFormatSelect,
      enabledCheckbox,
      usernameField,
      passwordField,
      fixedTokenField,
      dynamicUrlField,
      bearerTokenBodyField,
      apiKeyField,
      valueField,
      addToSelect,
    },
  };
};

export default useEditButton;
