import React from 'react';
// components/ui
import { Box } from '@mui/material';
import ImportContent from './ImportContent';

export default function ImportView() {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      height={'85vh'}
      width={'100%'}
      // boxShadow={
      //   "rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 10%) 0px 1px 2px -1px;"
      // }
      // bgcolor="containerBox.main"
      className={'contacts-paddingmd'}
      
      borderRadius={2}
    >
      <ImportContent />
    </Box>
  );
}
