import axios from 'axios';
// Types
import { INote } from '@trii/types/dist/Contacts';
import type { UpdateNoteData } from './types/UpdateNoteData';
import type { CreateNoteData } from './types/CreateNoteData';
// Utils
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';

const fetchNotes = async (jwtToken: string, URL: string): Promise<INote[]> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<INote[]>(URL, config);

  return response.data;
};
const createNote = async (
  jwtToken: string,
  URL: string,
  newNote: CreateNoteData
): Promise<INote> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.post<INote>(URL, newNote, config);

  return response.data;
};
const updateNote = async (
  jwtToken: string,
  URL: string,
  updatedNote: UpdateNoteData
): Promise<INote> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.put<INote>(URL, updatedNote, config);

  return response.data;
};
const deleteNote = async (jwtToken: string, URL: string) => {
  const config = getRequestConfig.basic(jwtToken);
  await axios.delete(URL, config);
};

export default { fetchNotes, createNote, updateNote, deleteNote };
