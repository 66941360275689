import { Box } from '@mui/material';
import React from 'react';

const Spinner = ({ texto }) => {
  return (
    <>
      <Box className="centered-element-spinner-panel">
        <Box className="spinner-border" role="status"></Box>
        <Box className="ml-25less mt-3">{texto}</Box>
      </Box>
    </>
  );
};

export default Spinner;
