// Components/ui
import { Box, TextField } from '@mui/material';
import { InputContainer } from '../../layout';
// Types
import { UseFieldType } from 'hooks/useField';
import { useTranslation } from 'react-i18next';

type DescriptionTextFieldProps = {
  descriptionField: UseFieldType;
};

const DescriptionTextField = ({ descriptionField }: DescriptionTextFieldProps) => {
  const { t } = useTranslation();

  return (
    <InputContainer title={t('global.description')}>
      <TextField
        type="text"
        multiline
        fullWidth
        variant="outlined"
        {...descriptionField.attributes}
      />
    </InputContainer>
  );
};

export default DescriptionTextField;
