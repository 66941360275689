export const style = {
  avatar: {
    width: "86px",
    height: "86px",
    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#484f65' : '#f5f5f5'),
    color: (theme) => theme.palette.text.secondary,
    position: "relative", // Add this to position the icon within the Avatar
    overflow: "visible", // Ensure the icon is visible outside the Avatar's bounds
    "&:hover": {
      background: "rgba(0, 0, 0, 0.10)",
      cursor: "pointer",
      zIndex: 0
    },
    border: "1px solid gray",
    "& img": {
      borderRadius: "50%",
    },
    "& img:hover": {
      opacity: 0.5
    },
  },
  avatarDelete: {
    fontSize: "16px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.10)",
      cursor: "pointer",
    },
  },
  avatarUpdateIcon: {
    position: "absolute",
    top: "53%",
    left: "50%",
    zIndex: 1,
    color: "rgb(0 0 0 / 50%)",
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none'
  },
  avatarContainer: {
    position: "absolute",
    left: "-100px",
    top: "-10px",
    "@media (max-width: 1100px)": {
    position: "relative",

      left: "0", // Adjust the left position for smaller screens
      top: "-20px", // Adjust the top position for smaller screens
    },
  },
  contactName: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "45%",
    // marginRight: "10%",
  },
  contactData: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "45%",
  },
  container: {
    display: "flex",
    width: "100%",
    gap: "15px 50px",
    justifyContent: "flex-end",
  },
  contactCustomFields: {
    display: "flex",
    width: "100%",
    gap: "15px 50px",
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },
};
