import React from 'react';
// components/ui
import { Button } from '@mui/material';

const CheckAllButton = ({ isSelected, handleClick }) => {
  return (
    <Button
      onClick={handleClick}
      variant={isSelected ? 'contained' : 'outlined'}
      sx={{ fontSize: 11, py: 0.15, px: 0.9 }}
    >
      Todos los registros
    </Button>
  );
};

export default CheckAllButton;
