export function formatBytes(x): string {
  if (isNaN(Number(x))) {
    return '0 bytes';
  }

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let n = parseInt(x, 10) || 0;
  let unitIndex;

  for (unitIndex = 0; n >= 1024; unitIndex++) {
    n = n / 1024;
  }

  return `${n.toFixed(n < 10 && unitIndex > 0 ? 1 : 0)} ${units[unitIndex]}`;
}
