// Components/ui
import { Box, Card, Typography } from "@mui/material";

const ContactPanel = ({ children, title }) => {
  return (
    <Card
      sx={{
        margin: 2,
        border: "1px solid",
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
        borderColor: (theme) => theme.palette.divider,
        marginY: 0,
        marginBottom: 2,
        maxHeight: "calc(100vh - 195px)",
        borderRadius: 0,
        overflow: "auto",
        boxShadow: "unset",
        // @ts-ignore
        backgroundColor: (theme) => theme.palette.dropdownBox,
      }}
    >
      {title !== '' && (
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
          py={1}
          px={2}
          sx={{
            // @ts-ignore
            backgroundColor: (theme) => theme.palette.background.dropdownMenu,
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: (theme) => theme.palette.text.primary }}
            fontWeight={"bold"}
          >
            {title}
          </Typography>
        </Box>
      )}
      <Box>{children}</Box>
    </Card>
  );
};

export default ContactPanel;
