// Components/ui
import subsectionBodyContainerStyle from './style';
import { Box } from '@mui/material';
// Types
import type { ReactNode } from 'react';
import type Variant from './types/Variant';

type Props = {
  children: ReactNode;
  variant: Variant;
};

const SubsectionBodyContainer = ({ children, variant }: Props) => {
  return (
    <Box
      sx={{
        ...subsectionBodyContainerStyle.container,
      }}
    >
      {children}
    </Box>
  );
};

export default SubsectionBodyContainer;
