import { Box, Card, CardActionArea, Typography } from '@mui/material';
import LabelIcon from '@mui/icons-material/Label';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Labels = () => {
  const { t } = useTranslation();

  return (
    <Box
      component={Card}
      borderRadius={2}
      width={"fit-content"}
      data-aos={'fade-up'}
      minWidth={"225px"}
      sx={{ backgroundColor: (theme) => theme.palette.background.default }}
    >
      <CardActionArea sx={{ height: "100%", display: 'flex', alignItems: 'flex-start' }}>
        <NavLink to="/a/contacts/settings/labels">

          <Box display={'flex'} flexDirection={'column'} justifyContent="center" alignItems={"center"}
            padding={4} sx={{ color: (theme) => theme.palette.text.primary }}
          >
            <Box
              marginBottom={4}

            >
              <LabelIcon sx={{ fontSize: "2.5rem" }} />
            </Box>
            <Typography variant={'body2'}>{t('settingsView.labels')}</Typography>
          </Box>
        </NavLink>

      </CardActionArea>
    </Box>
  );
};

export default Labels;
