// Types
import { FileTableColumn } from '../types/FileTableColumn';

export const fileTableColumns: FileTableColumn[] = [
  {
    name: 'fileName',
    title: 'fileName',
    sortDirection: 'DESC',
    sorted: false,
  },
  {
    name: 'createdBy',
    title: 'attachedBy',
    sortDirection: 'DESC',
    sorted: false,
  },
  {
    name: 'createdAt',
    title: 'dateAdded',
    sortDirection: 'DESC',
    sorted: false,
  },
  { name: 'size', title: 'size', sortDirection: 'DESC', sorted: false },
];
