import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  joinDuplicates,
  selectJoinDuplicatesStatus,
} from 'ReduxToolkit/features/duplicatesSlice/duplicatesSlice';
// Types
import { RequestStatus } from 'types/reduxTypes';
// Components/ui
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Context
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';


const JoinButton = () => {
  const {
    contactsId,
    handleJoinDuplicates,
  } = useContext(DuplicateContext);
  const { t } = useTranslation();
  const joinDuplicatesStatus: RequestStatus = useSelector(
    selectJoinDuplicatesStatus
  );
  const isDisabled =
    joinDuplicatesStatus === 'loading' || contactsId.length < 2;

  return (
    <Box
      justifyContent={'end'}
      display={'flex'}
      sx={{
        bottom: 0,
        paddingRight: 1,
        height: '2rem',
      }}
    >
      <LoadingButton
        onClick={handleJoinDuplicates}
        disabled={isDisabled}
        loading={joinDuplicatesStatus === 'loading'}
        variant="contained"
        size="small"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {t('duplicatesView.combineSelected')}
      </LoadingButton>
    </Box>
  );
};

export default JoinButton;
