import { useEffect, useState } from 'react';
// Style
import './style.css';

type SvgIconProps = {
  src: string;
  className?: 'light' | 'dark';
};
function SvgIcon({ src, className }: SvgIconProps) {
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    fetch(src)
      .then((response) => response.text())
      .then((text) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(text, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');
        if (svgElement) {
          if (!svgElement.getAttribute('viewBox')) {
            const width = svgElement.getAttribute('width');
            const height = svgElement.getAttribute('height');
            svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
          }
          setSvgContent(svgElement.outerHTML);
        }
      });
  }, [src]);
  console.log(className);
  return (
    <div
      className={`svg-icon ${className}`}
      dangerouslySetInnerHTML={{ __html: svgContent }}
      style={{display: 'flex'}}
    />
  );
}

export default SvgIcon;
