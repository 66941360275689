// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  BusinessInfoField,
  BusinessSection,
} from 'components/BusinessCreateEditAppBody/layout';
// Types
import type UseEditBusiness from 'features/Views/BusinessEdit/hooks/useEditBusiness/types/UseEditBusiness';
import type UseCreateBusiness from 'features/Views/BusinessCreate/hooks/useCreateBusiness/types/UseCreateBusiness';

interface Props {
  business: UseEditBusiness | UseCreateBusiness;
}
const AdressInformationSection = ({ business }: Props) => {
  const { t } = useTranslation();
  const {
    homeAddress,
    secondaryHomeAddress,
    city,
    secondaryCity,
    state,
    secondaryState,
    postalCode,
    secondaryPostalCode,
    country,
    secondaryCountry,
  } = business.field;
  const fields = [
    { label: t('contactDetails.street'), fieldData: homeAddress },
    { label: t('contactDetails.anotherStreet'), fieldData: secondaryHomeAddress },
    { label: t('contactDetails.city'), fieldData: city },
    { label: t('contactDetails.anotherCity'), fieldData: secondaryCity },
    { label: t('contactDetails.state'), fieldData: state },
    { label: t('contactDetails.anotherState'), fieldData: secondaryState },
    { label: t('contactDetails.postcode'), fieldData: postalCode },
    { label: t('contactDetails.anotherPostcode'), fieldData: secondaryPostalCode },
    { label: t('contactDetails.country'), fieldData: country },
    { label: t('contactDetails.anotherCountry'), fieldData: secondaryCountry },
  ];

  const Fields = fields.map((field) => {
    return (
      <BusinessInfoField
        key={field.label}
        label={field.label}
        fieldData={field.fieldData}
      />
    );
  });

  return (
    <BusinessSection sectionTitle={t('contactDetails.addressInformation')}>
      {Fields}
    </BusinessSection>
  );
};

export default AdressInformationSection;
