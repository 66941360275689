// Components/ui
import { TableBody } from '@mui/material';
import { FilesRow } from './components';
// Types
import { IFile } from '@trii/types/dist/Contacts';

interface BodyProps {
  files: IFile[];
}
const Body = ({ files }: BodyProps) => {
  const Files = files?.map((file) => <FilesRow fileData={file} key={file.id} />);

  return <TableBody>{Files}</TableBody>;
};

export default Body;
