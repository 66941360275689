import { useContext } from 'react';
// Context
import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useSelector } from 'react-redux';
import { selectContactFields } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { TableCell } from '@mui/material';
// Types
import type { IContactWithCheckbox } from 'ReduxToolkit/features/contactsSlice/types/IContactWithCheckBox';
import type { IBusinessWithCheckbox } from 'ReduxToolkit/features/contactsSlice/types/IBusinessWithCheckBox';

type Props = {
  contact: IContactWithCheckbox | IBusinessWithCheckbox;
};

const Cells = ({ contact }: Props) => {
  const { getContactCellValue, handleContactClick, mode, getBusinessCellValue } =
    useContext(ContactBusinessTableContext);
  const columns = useSelector(selectContactFields);
  return (
    <>
      {columns.map(
        (column, i) =>
          column.visible && (
            <TableCell key={i} sx={{ padding: '0 24px' }}>
              <span
                onClick={() => handleContactClick(contact.id)}
                style={{ userSelect: 'none' }}
              >
                {mode === 'contact'
                  ? getContactCellValue(
                      column.nameKey,
                      column.isDefault,
                      contact as IContactWithCheckbox
                    )
                  : getBusinessCellValue(
                      column.nameKey,
                      column.isDefault,
                      contact as IBusinessWithCheckbox
                    )}
              </span>
            </TableCell>
          )
      )}
    </>
  );
};

export default Cells;
