import React from 'react';
// //components/ui
import { TableBody } from '@mui/material';
import { DebtsRow } from './components';

const Body = ({ tableDebts }) => {
  const Files = tableDebts?.map((filesData, i) => (
    <DebtsRow filesData={filesData} key={i} />
  ));

  return (
    <TableBody>
      {/* {columnsFetchStatus === 'loading' || contactsFetchStatus === 'loading' ? (
        <FilesSkeleton />
      ) : (
        Contacts
        )} */}
      {/* {Files} */}
    </TableBody>
  );
};

export default Body;
