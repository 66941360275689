import { useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { SegmentsEditContext } from 'features/Views/SegmentsEdit/context/SegmentsEditProvider';
// Types
import { ContactField_type } from '@trii/types/dist/Contacts';
// Components
import { TextField } from '@mui/material';

type Props = {
  fieldFilterType: ContactField_type;
  value: string;
  itemId: string;
  groupId: string;
};

const TextNumberField = ({ fieldFilterType, value, itemId, groupId }: Props) => {
  const { t } = useTranslation();
  const { handleTextFieldChange, handleNumberFieldChange } =
    useContext(SegmentsEditContext);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      fieldFilterType === ContactField_type.CURRENCY ||
      fieldFilterType === ContactField_type.NUMBER_ACCOUNTING ||
      fieldFilterType === ContactField_type.NUMBER_DECIMAL ||
      fieldFilterType === ContactField_type.NUMBER_INTEGER ||
      fieldFilterType === ContactField_type.NUMBER_CURRENCY
    ) {
      handleNumberFieldChange(event, itemId, groupId);
    }
    if (
      fieldFilterType === ContactField_type.LONGTEXT ||
      fieldFilterType === ContactField_type.TEXT
    ) {
      handleTextFieldChange(event, itemId, groupId);
    }
  };

  return (
    <TextField
      sx={{ width: '30%' }}
      label={t('segmentsEdit.value')}
      size="small"
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      type="text"
      onChange={handleChange}
    />
  );
};

export default TextNumberField;
