// Components/ui
import { Box } from '@mui/material';
import { Header, SectionsMenu, BodyBusinessViewer } from './components';
import BusinessContainer from 'components/BusinessContainer/BusinessContainer';

const BusinessView = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      borderRadius={2}
    >
      <BusinessContainer>
        <Header />
        <Box display={'flex'} sx={{ height: 'calc(100vh - 175px)' }}>
          <SectionsMenu />
          <BodyBusinessViewer />
        </Box>
      </BusinessContainer>
    </Box>
  );
};

export default BusinessView;
