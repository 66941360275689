import React, { useContext, useEffect } from 'react';
import { labelContext } from 'features/Views/Label/context/LabelProvider';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Divider, Box } from '@mui/material';
import { CreateButtons } from './components';
import CrudContainer from '../CrudContainer';
import PanelHeader from '../PanelHeader';
import CreateEditPanel from '../CreateEditPanel';

const CreatePanel = () => {
  const { endCreating, createLabelState } = useContext(labelContext);
  const { t } = useTranslation();

  useEffect(() => {
    const cleanUp = () => {
      createLabelState.actions.resetAll();
    };

    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CrudContainer>
      <PanelHeader
        title={t('labelsView.addLabel')}
        handleCloseButton={endCreating}
      />
      <Divider />
      <CreateEditPanel labelState={createLabelState} />
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <CreateButtons />
    </CrudContainer>
  );
};

export default CreatePanel;
