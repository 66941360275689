// Components/ui
import { Divider, Box } from '@mui/material';
import { Header, Inputs, ActionButtons } from './components';
import PanelContainer from 'components/PanelContainer';

const CreatePanel = () => {
  return (
    <PanelContainer>
      <Header />
      <Divider />
      <Inputs />
      <Box position={'absolute'} bottom={'65px'} width="100%">
        <Divider />
      </Box>
      <ActionButtons />
    </PanelContainer>
  );
};

export default CreatePanel;
