import { useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import {
  selectContactsFetchStatus,
  resetContactsTableDataStatus,
  clearTableData,
  fetchContactsTableData,
  selectContactsTableData,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { style } from './style';
// Types
import { Pagination } from 'ReduxToolkit/features/contactsSlice/types/Pagination';
import { IContactInfo } from '@trii/types/dist/Contacts';
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';

const PER_PAGE = 10;
const ORDER = 'DESC';
const ORDER_COLUMN = 'name';
const FORMAT = 'IContactInfo';

const DATA = {
  currentPage: 1,
  perPage: PER_PAGE,
  order: ORDER,
  orderColumn: ORDER_COLUMN,
  format: FORMAT,
  filter: [
    {
      column: 'Name',
      condition: '',
      value: '',
    },
  ],
};

const ContactSearch = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { addContactById, contactList } = useContext(DuplicateContext);

  const [contacts, setContacts] = useState<IContactInfo[]>([]);
  const [globalTimer, setGlobalTimer] = useState<NodeJS.Timeout | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [inputValue, setInputValue] = useState<string>('');
  const [isNextPage, setIsNextPage] = useState<boolean>(false);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const [isLoadingTimer, setIsLoadingTimer] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const contactsTableData = useSelector(selectContactsTableData);
  const contactInfoFetchStatus = useSelector(selectContactsFetchStatus);

  const isLoading = contactInfoFetchStatus === 'loading';

  function resetContacts() {
    dispatch(resetContactsTableDataStatus());
    dispatch(clearTableData());
  }

  function handleAutocompleteChange(event, newValue) {
    const selectedContact = newValue[newValue.length - 1];

    if (selectedContact) {
      addContactById(selectedContact.id);
      setContacts(contacts.filter((contact) => contact.id !== selectedContact.id));
    }
  }

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setInputValue(value);
    setIsNextPage(false);
    setAllLoaded(false);
    setContacts([]);
    setCurrentPage(1);

    if (!value) return;
    if (globalTimer) clearTimeout(globalTimer);

    setIsLoadingTimer(true);

    const timer = setTimeout(() => {
      resetContacts();

      const data = {
        ...DATA,
        filter: [
          {
            ...DATA.filter[0],
            value,
          },
        ],
      } as Pagination;

      handleSearchContact(data);
      setIsLoadingTimer(false);
    }, 3000);

    setGlobalTimer(timer);

    return () => clearTimeout(timer);
  }

  function handleClose() {
    setInputValue('');
    setContacts([]);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();

      if (!isLoading && !isLoadingTimer && contacts.length === 0) {
        setInputValue('');
      }
    }
  }

  async function handleSearchContact(data: Pagination) {
    resetContacts();

    await dispatch(fetchContactsTableData(data));
  }

  async function handleScroll(e: React.UIEvent<HTMLElement>) {
    const element = e.target as HTMLElement;
    const bottom =
      element.scrollHeight - element.scrollTop < element.clientHeight + 1;

    if (bottom && !allLoaded) {
      setIsNextPage(true);

      const newPage = currentPage + 1;

      setCurrentPage(newPage);

      const newData = {
        ...DATA,
        currentPage: newPage,
        filter: [
          {
            ...DATA.filter[0],
            value: inputValue,
          },
        ],
      };

      handleSearchContact(newData as Pagination);
    }
  }

  useEffect(() => {
    if (contactsTableData && contactsTableData.contactos.length > 0) {
      const filteredContacts = contactsTableData.contactos.filter(
        (contact) =>
          !contactList.some((listContact) => listContact.id === contact.id)
      );

      if (isNextPage) {
        if (contactsTableData?.paginacion.total === contacts.length) {
          setAllLoaded(true);
          return;
        }
        // @ts-ignore
        setContacts([...contacts, ...filteredContacts]);
      } else {
        // @ts-ignore
        setContacts(filteredContacts);
      }
    }
  }, [contactsTableData]);

  return (
    <Autocomplete
      id="asynchronous-search-contact"
      fullWidth
      multiple
      noOptionsText={'No hay resultados'}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => option.name}
      options={contacts}
      onChange={handleAutocompleteChange}
      onClose={handleClose}
      onOpen={handleClose}
      filterOptions={(x) => x}
      renderTags={() => null} // Prevent tags from being displayed
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          sx={style.textField}
          ref={inputRef}
          fullWidth
          label={t('global.contacts')}
          size="small"
          InputProps={{
            ...params.InputProps,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={inputValue}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
        />
      )}
      ListboxProps={{
        sx: {
          maxHeight: '15rem',
          overflowY: 'auto',
        },
        onScroll: (e) => handleScroll(e),
      }}
      popupIcon={
        <ArrowDropDown
          sx={{
            color: (theme) => theme.palette.text.disabled,
          }}
          fontSize="small"
        />
      }
      clearIcon={
        ((isLoading && inputRef.current?.value === inputValue) ||
          isLoadingTimer) && <CircularProgress size={20} />
      }
    />
  );
};

export default ContactSearch;
