// Utils
import {
  Segment_Filter_Details,
  Segment_Filter_Account,
  Segment_Filter_Geography,
} from '@trii/types/dist/Contacts';

const getFilterType = (filterNameKey: string) => {
  const allFilters = [
    ...Segment_Filter_Details,
    ...Segment_Filter_Account,
    ...Segment_Filter_Geography,
  ];

  const filter = allFilters.find((filter) => filter.key === filterNameKey);
  return filter?.type;
};

export default {
  getFilterType,
};
