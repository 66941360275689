import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { IBusiness, IContact, IContactInfo } from "@trii/types/dist/Contacts";
// import { HeaderContext } from "components/Header/components/context/HeaderProvider";
import { useTranslation } from "react-i18next";

interface Props {
  memberData: IContactInfo;
}

const MembersItem = ({ memberData }: Props) => {

  // const { handleClose } = useContext(HeaderContext);


  const [isHovered, setIsHovered] = useState(false);

  function stringAvatar(name: string) {
    if (name !== undefined) {
      const splittedName = name?.split(" ");

      if (splittedName) {
        const firstLetter = splittedName[0][0];

        switch (splittedName.length) {
          case 1:
            return {
              children: `${firstLetter}`,
            };
          case 2:
            const secondLetter = splittedName[1][0];
            return {
              children: `${firstLetter}${secondLetter}`,
            };
          default:
            return {
              children: `${firstLetter}`,
            };
        }
      }
    } else {
      return (
        <CircularProgress
          sx={{
            marginLeft: 5,
            marginRight: 5,
            height: 54,
            width: 54,
            fontSize: "14px",
            color: "lightgray",
          }}
        />
      );
    }
  }


  const { t } = useTranslation();
  return (
    <Button
      variant="text"
      size="small"
      sx={{
        minWidth: "200px",
        justifyContent: "start",
        px: 1,
        py: 0.5,
        textAlign: "left",
        minHeight: "55px",
      }}
      // onMouseEnter={() => setIsHovered(true)}
      // onMouseLeave={() => setIsHovered(false)}
    >
      <Box display="flex" alignItems="center" width="100%">
        <Avatar
          {...stringAvatar(memberData.name)}
          alt=""
          src={memberData.imageUrl}
          sx={{
            width: 32,
            height: 32,
            bgcolor: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.secondary,
          }}
        />
        <Box ml={1.5} display="flex" flexDirection="column" flexGrow={1}>
          <Typography
            color="text.primary"
            variant="body2"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {memberData.name}
          </Typography>
      
        </Box>
      </Box>
    </Button>
  );
};

export default MembersItem;
