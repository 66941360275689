// Components/ui
import { Typography, Box } from '@mui/material';

type Props = {
  title: string;
};

const SubsectionHeader = ({ title }: Props) => {
  return (
    <Box>
      <Typography variant="subtitle2">{title}</Typography>
    </Box>
  );
};

export default SubsectionHeader;
