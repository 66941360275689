import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import sessionReducer from './features/sessionSlice/sessionSlice';
import domainReducer from './features/domainSlice/domainSlice';
import poolReducer from './features/poolSlice/poolSlice';
import themeReducer from './features/themeSlice';
import contactsReducer from './features/contactsSlice/contactsSlice';
import contactsFilterReducer from './features/contactsFilterSlice/contactsFilterSlice';
import configReducer from './features/configSlice/configSlice';
import userReducer from './features/userSlice/userSlice';
import googleSyncReducer from './features/googleSyncSlice/googleSyncSlice';
import duplicatesReducer from './features/duplicatesSlice/duplicatesSlice';
import listsReducer from './features/listsSlice/listsSlice';
import segmentsReducer from './features/segmentsSlice/segmentsSlice';
import spaceSlice from './features/spaceSlice/spaceSlice';
import spacesSlice from './features/spacesSlice/spacesSlice';
import labelSlice from './features/labelSlice/labelSlice';
import noteSlice from './features/noteSlice/noteSlice';
import fileSlice from './features/fileSlice/fileSlice';
import activitiesSlice from './features/activitiesSlice/activitiesSlice';
import businessReducer from './features/businessSlice/businessSlice';

const rootReducer = combineReducers({
  Session: sessionReducer,
  Domain: domainReducer,
  Pool: poolReducer,
  Theme: themeReducer,
  User: userReducer,
  Contacts: contactsReducer,
  ContactsFilter: contactsFilterReducer,
  Business: businessReducer,
  Config: configReducer,
  GoogleSync: googleSyncReducer,
  Duplicates: duplicatesReducer,
  Lists: listsReducer,
  Segments: segmentsReducer,
  Spaces: spacesSlice,
  Space: spaceSlice,
  Label: labelSlice,
  Note: noteSlice,
  File: fileSlice,
  Activities: activitiesSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
