import { ReactNode } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UseEditBusiness from 'features/Views/BusinessEdit/hooks/useEditBusiness/types/UseEditBusiness';
import UseCreateBusiness from 'features/Views/BusinessCreate/hooks/useCreateBusiness/types/UseCreateBusiness';
import { style } from './style';

interface Props {
  mode: 'create' | 'edit';
  navigateBackPath: string;
  children: ReactNode;
  business: UseEditBusiness | UseCreateBusiness;
}

const BusinessCreateEditAppHeader = ({
  navigateBackPath,
  children,
  mode,
  business,
}: Props) => {
  const { t } = useTranslation();
  const { name } = business.field;
  const navigate = useNavigate();

  const Title =
    mode === 'create'
      ? t('businessDetails.createBusiness')
      : t('businessDetails.editBusiness');

  const navigateBack = () => {
    navigate(navigateBackPath);
  };

  return (
    <Box
      height={'75px'}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      //@ts-ignore
      bgcolor={(theme) => theme.palette.dropdownBox}
      color={(theme) => theme.palette.text.primary}
      sx={{
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',

        margin: '0px 16px',
        marginRight: '20px',
      }}
      px={2}
      pl={1}
      py={1}
    >
      <Box width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box sx={style.titleContainter}>
              <Typography variant="h6" mb={'-5px'} className="ellipsified">
                {Title}
              </Typography>
              <Typography
                variant="h6"
                mb={'-5px'}
                className="ellipsified"
                sx={style.businessTitle}
              >
                {name.attributes.value}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-end" position="relative">
            <Box mr={1}>
              <Button
                sx={{ alignSelf: 'center' }}
                variant="outlined"
                size="small"
                onClick={navigateBack}
              >
                {t('global.cancel')}
              </Button>
            </Box>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BusinessCreateEditAppHeader;
