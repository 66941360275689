// Types
import { Segment_Filter_Operator } from '@trii/types/dist/Contacts';
import type ConditionOperators from '../types/ConditionOperators';

const conditionOperators: ConditionOperators[] = [
  {
    name: 'lessThan',
    value: Segment_Filter_Operator.LESS_THAN,
  },
  {
    name: 'lessThanOrEqual',
    value: Segment_Filter_Operator.LESS_THAN_EQUAL,
  },
  {
    name: 'equal',
    value: Segment_Filter_Operator.EQUAL,
  },
  {
    name: 'greaterThanOrEqual',
    value: Segment_Filter_Operator.GREATER_THAN_EQUAL,
  },
  {
    name: 'greaterThan',
    value: Segment_Filter_Operator.GREATER_THAN,
  },
  {
    name: 'startsWith',
    value: Segment_Filter_Operator.START_WITH,
  },
  {
    name: 'endsWith',
    value: Segment_Filter_Operator.END_WITH,
  },
  {
    name: 'contains',
    value: Segment_Filter_Operator.CONTAINS,
  },
  {
    name: 'notContains',
    value: Segment_Filter_Operator.NOT_CONTAINS,
  },
  {
    name: 'anyValue',
    value: Segment_Filter_Operator.ANY_VALUE,
  },
];

export default conditionOperators;
