import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSpaceSubdomain } from '../../../services/domain';
// Types
import { DomainSlice } from './types/DomainSlice';

const initialState: DomainSlice = {
  domain: '',
  status: 'idle',
  error: null,
};

export const getDomainInfo = createAsyncThunk(
  'domain/getDomainInfo',
  async (domain: string) => {
    const info = await getSpaceSubdomain(domain);
    return info;
  }
);

const domainSlice = createSlice({
  name: 'domain',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDomainInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDomainInfo.fulfilled, (state, action) => {
        const domainInfo = action.payload;
        console.log('success', action.payload)
        state.status = 'succeeded';
        state.domain = domainInfo;
      });
  },
});

export const selectDomain = (state) => state.Domain.domain;
export const getDomainStatus = (state) => state.Domain.status;

export default domainSlice.reducer;
