import { useTranslation } from 'react-i18next';
// Components
import { Select, InputLabel, FormControl } from '@mui/material';

type Props = {
  groupId: string;
  value: string;
  itemId: string;
};

const OptionSelect = ({ groupId, value, itemId }: Props) => {
  const { t } = useTranslation();
  return (
    <FormControl sx={{ width: '30%' }}>
      <InputLabel htmlFor="option-select">{t('segmentsEdit.value')}</InputLabel>
      <Select id="option-select" label={t('segmentsEdit.value')} size="small"  MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }} />
    </FormControl>
  );
};

export default OptionSelect;
