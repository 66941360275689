import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsListContext } from '../../context/ButtonListContext';
// Redux
import { useSelector } from 'react-redux';
import {
  selectButtonsUpdateOrderStatus,
  selectButtons,
} from '../../../../../../../../../ReduxToolkit/features/configSlice/configSlice';
// Components/ui
import { NavLink } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';

const ListHeader = ({ open, show }) => {
  const { handleSaveOrder, canSaveOrder } = useContext(ButtonsListContext);
  const { t } = useTranslation();
  const buttons = useSelector(selectButtons);
  const buttonsUpdateOrderStatus = useSelector(selectButtonsUpdateOrderStatus);

  const loadingSaveOrder = buttonsUpdateOrderStatus === 'loading';

  const breadcrumbs = [
    <NavLink underline="hover" key="1" to="/a/contacts/settings">
      <Typography color="secondary">{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink underline="hover" key="2" to="/a/contacts/settings/buttons">
      <Typography color="secondary">{t('settingsView.buttons')}</Typography>
    </NavLink>,
  ];
  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      borderRadius={'4px'}
      height="50px"
      className="m-0"
      gap={'1rem'}
      position={'relative'}
    >
      <Box position={'absolute'} left="0rem" top="-1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <LoadingButton
        variant="contained"
        size="small"
        loadingPosition="start"
        onClick={() => handleSaveOrder(buttons)}
        loading={loadingSaveOrder}
        startIcon={<SaveIcon />}
        disabled={loadingSaveOrder || !canSaveOrder}
      >
        {t('settingsView.saveOrder')}
      </LoadingButton>
      <Button
        sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
        variant="contained"
        size="small"
        onClick={open}
        disabled={show ? true : false}
      >
        {t('settingsView.addButton')}
      </Button>
    </Box>
  );
};

export default ListHeader;
