import { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// Context
import { BusinessEditContext } from 'features/Views/BusinessEdit/context/BusinessEditContext';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useDispatch } from 'react-redux';
import { updateBusiness } from 'ReduxToolkit/features/businessSlice/businessSlice';
// Components
import {
  ButtonGroup,
  Box,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Typography,
  MenuItem,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';

interface Props {
  businessId: string;
}

const SaveButtonGroup = ({ businessId }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { editBusiness } = useContext(BusinessEditContext);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  const save = async () => {
    const editedBusiness = editBusiness.action.getEditedBusiness();
    await dispatch(updateBusiness(editedBusiness));
  };

  const handleSave = async () => {
    await save();
    setOpen(false);
  };

  const handleSaveAndClose = async () => {
    await save();
    navigate(`/a/contacts/business/${businessId}`);
  };

  const handleToggleSave = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseSavePopup = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <ButtonGroup size="small" variant="contained" aria-label="split button">
      <Box sx={{ position: 'relative' }}>
        <Button
          onClick={handleSaveAndClose}
          startIcon={<SaveAsIcon />}
          ref={anchorRef}
          className="contacts-rightBorderless"
        >
          {t('contactDetails.saveClose')}
        </Button>
        <Popper
          sx={{
            zIndex: 1,
            width: '100%',
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper
                sx={{
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  p: 0,
                }}
                elevation={0}
              >
                <ClickAwayListener onClickAway={handleCloseSavePopup}>
                  <MenuList id="split-button-menu" autoFocusItem sx={{ p: 0 }}>
                    <MenuItem onClick={() => handleSave()} sx={{ px: 1.2 }}>
                      <SaveIcon sx={{ fontSize: 18, marginRight: 1 }} />
                      <Typography sx={{ fontSize: 14 }}>
                        {t('contactDetails.save')}
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      <Button
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggleSave}
        sx={{ padding: 0 }}
        className="contacts-leftBorderless .contacts-mw-0"
      >
        <ArrowDropDownIcon />
      </Button>
    </ButtonGroup>
  );
};

export default SaveButtonGroup;
