// Components/ui
import { Typography, Grid, Box } from '@mui/material';
import { style } from './style';
import WarningIcon from '@mui/icons-material/Warning';
import Chip from '@mui/material/Chip';
type WarningProps = {
  text: string;
};

const Warning = ({ text }: WarningProps) => {
  return (
    <Grid item >
      <Box sx={style.secondaryContainer}>
      <Chip icon={<WarningIcon sx={style.icon} />} sx={style.chip} label={text} color="error" variant='outlined' />
        {/* <WarningIcon color="warning" sx={style.icon} />
        <Typography variant="caption">{text}</Typography> */}
      </Box>
    </Grid>
  );
};

export default Warning;
