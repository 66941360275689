// Components/ui
import { Box, Divider } from '@mui/material';
import {
  DetailsSection,
  InformationSection,
  AdressInformationSection,
  ImageSection,
  CustomFieldSection,
  LabelSection,
} from './components';
import { ContactPanel } from './layout';
// Types
import UseEditContact from 'features/Views/ContactEdit/hooks/useEditContact/types/UseEditContact';
import UseCreateContact from 'features/Views/ContactCreate/hooks/useCreateContact/types/UseCreateContact';

interface Props {
  contact: UseEditContact | UseCreateContact;
  mode: 'create' | 'edit';
}

const ContactCreateEditAppBody = ({ contact }: Props) => {
  return (
    <ContactPanel title={''}>
      {contact && (
        <>
          <DetailsSection contact={contact} />
          <Divider />
          <AdressInformationSection contact={contact} />
          <Divider />
          <InformationSection contact={contact} />
          <Box />
        </>
      )}
    </ContactPanel>
  );
};

export default ContactCreateEditAppBody;
