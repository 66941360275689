import React, { createContext, useState } from 'react';
import useField from '../../../../../../../hooks/useField';
// Redux
import { useDispatch } from 'react-redux';
import {
  createSubscription,
  changeSubscriptionsStatus,
  editSubscription,
  deleteSubscription,
} from '../../../../../../../ReduxToolkit/features/configSlice/configSlice';
// Types
import { SuscriptionList, SelectedField } from './types/SuscriptionList';
import { ISubscription } from '@trii/types/dist/Contacts';
// Utils
import { fieldInitialDef } from '../../../../../../../utils/fieldInitialDef';

export const SubscriptionListContext = createContext<SuscriptionList>({
  handleCloseAlertError: () => {},
  openPanelCP: false,
  isEditing: false,
  openDelete: () => {},
  openEdit: () => {},
  handleEdit: () => {},
  handleDrawerOpen: () => {},
  handleDrawerClose: () => {},
  isDelete: false,
  handleCreate: () => {},
  createNameField: fieldInitialDef,
  createDescriptionField: fieldInitialDef,
  editNameField: fieldInitialDef,
  editDescriptionField: fieldInitialDef,
  setSelectedField: () => {},
  resetDelete: () => {},
  deleteNameField: fieldInitialDef,
  selectedField: null,
  closeResetDrawer: () => {},
  handleDelete: () => {},
  closeDelete: () => {},
});

const ListaSuscripcionesProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [selectedField, setSelectedField] = useState<SelectedField | null>(null);
  // Drawer state
  const [openPanelCP, setOpenPanelCP] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  // Create state
  const createNameField = useField('text');
  const createDescriptionField = useField('text');
  // Edit state
  const editNameField = useField('text');
  const editDescriptionField = useField('text');
  // Delete state
  const deleteNameField = useField('text', '');

  // Edit functions
  const openEdit = (field) => {
    setSelectedField(field);
    editNameField.actions.changeValue(field.name);
    editDescriptionField.actions.changeValue(field.description || '');
    setOpenPanelCP(true);
    setIsEditing(true);
    setIsDelete(false);
  };
  const closeEdit = () => {
    resetEdit();
    handleDrawerClose();
  };
  const handleEdit = async () => {
    const updatedSubscription = {
      id: selectedField.id,
      name: editNameField.attributes.value,
      description: editDescriptionField.attributes.value,
    };
    const updatedSubscriptionJSON = JSON.stringify(updatedSubscription);

    await dispatch(editSubscription(updatedSubscriptionJSON));
    resetEdit();
    handleDrawerClose();
  };
  const resetEdit = () => {
    setSelectedField(null);
    editNameField.actions.resetValue();
    editDescriptionField.actions.resetValue();
  };
  // Create functions
  const closeCreate = () => {
    resetCreate();
    handleDrawerClose();
  };
  const handleCreate = async () => {
    const newSubscription = {
      name: createNameField.attributes.value,
      description: createDescriptionField.attributes.value,
    };
    const newSubscriptionJson = JSON.stringify(newSubscription);

    await dispatch(createSubscription(newSubscriptionJson));
    closeResetDrawer();
  };
  const resetCreate = () => {
    createNameField.actions.resetValue();
    createDescriptionField.actions.resetValue();
  };
  // end Create functions

  // Delete functions
  const handleDelete = async () => {
    await dispatch(deleteSubscription(selectedField.id));
    closeDelete();
  };
  const openDelete = (field) => {
    setSelectedField(field);
    setOpenPanelCP(true);
    setIsEditing(false);
    setIsDelete(true);
  };
  const closeDelete = () => {
    resetDelete();
    handleDrawerClose();
  };
  const resetDelete = () => {
    setSelectedField(null);
    deleteNameField.actions.resetValue();
  };
  // Drawer functions
  const handleDrawerOpen = () => {
    setOpenPanelCP(true);
  };
  const handleDrawerClose = () => {
    setOpenPanelCP(false);
    setIsEditing(false);
    setIsDelete(false);
  };
  const closeResetDrawer = () => {
    if (isEditing) {
      closeEdit();
    } else if (isDelete) {
      closeDelete();
    } else {
      closeCreate();
    }
  };
  // end Drawer functions

  // Snackbar functions
  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    // dispatch(changeSubscriptionsStatus());
  };

  return (
    <SubscriptionListContext.Provider
      value={{
        handleCloseAlertError,
        openPanelCP,
        isEditing,
        openDelete,
        openEdit,
        handleEdit,
        handleDrawerOpen,
        handleDrawerClose,
        isDelete,
        handleCreate,
        createNameField,
        createDescriptionField,
        editNameField,
        editDescriptionField,
        setSelectedField,
        resetDelete,
        deleteNameField,
        selectedField,
        closeResetDrawer,
        handleDelete,
        closeDelete,
      }}
    >
      {children}
    </SubscriptionListContext.Provider>
  );
};

export default ListaSuscripcionesProvider;
