import { Box, Card, CardActionArea, Theme, Typography } from '@mui/material'
import React from 'react'
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
const Subscriptions = () => {
  const { t } = useTranslation();

  return (
    <Box
      component={Card}
      borderRadius={2}
      width={"fit-content"}
      data-aos={'fade-up'}
      minWidth={"225px"}
      sx={{ backgroundColor: (theme: Theme) => theme.palette.background.default }}
    >
      <CardActionArea sx={{height: "100%", display: 'flex', alignItems: 'flex-start'}}>
        <NavLink to="/a/contacts/settings/subscriptions">

          <Box display={'flex'} flexDirection={'column'} justifyContent="center" alignItems={"center"}
            padding={4} sx={{ color: (theme: Theme) => theme.palette.text.primary }}
          >
            <Box
              marginBottom={4}

            >
              <CardMembershipIcon sx={{ fontSize: "2.5rem" }} />
            </Box>
            <Typography variant={'body2'} sx={{ marginBottom: 1 }}>{t("settingsView.subscriptions")}</Typography>
          </Box>
        </NavLink>

      </CardActionArea>
    </Box>
  )
}

export default Subscriptions