import React, { useContext } from 'react';
import { ListsContext } from '../../context/ListsContext';
// Types
import { IListsContext } from '../../context/types';
// Components/ui
import { CreatePanel, DeletePanel, EditPanel } from './components';

const SidePanel = () => {
  const { createPanelOpen, deletePanelOpen, editPanelOpen } = useContext(
    ListsContext
  ) as IListsContext;

  return (
    <>
      {(createPanelOpen && <CreatePanel />) ||
        (deletePanelOpen && <DeletePanel />) ||
        (editPanelOpen && <EditPanel />)}
    </>
  );
};

export default SidePanel;
