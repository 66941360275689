import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsListContext } from '../../../../context/ButtonListContext.tsx';
// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function PanelHeader() {
  const { t } = useTranslation();
  const { isEditing, isDelete, handleDrawerClose } = useContext(ButtonsListContext);

  const PanelTitle = isEditing
    ? t('settingsView.editButton')
    : isDelete
    ? t('settingsView.deleteButton')
    : t('settingsView.createButton');
  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
    >
      <Typography variant="subtitle2" sx={{color: 'text.primary'}}>{PanelTitle}</Typography>
      <IconButton onClick={handleDrawerClose}>
        <CloseIcon
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  );
}

export default PanelHeader;
