import React from 'react';
// Coponents/ui
import { Skeleton } from '@mui/material';

const skeletons = [1, 2, 3];

const DuplicatedContactPanelSkeleton = () => {
  return (
    <>
      {skeletons.map((skeleton) => (
        <Skeleton
          variant="rectangular"
          height={170}
          key={skeleton}
          sx={{
            borderRadius: 1,
            backgroundColor: 'lightgrey',
          }}
        />
      ))}
    </>
  );
};

export default DuplicatedContactPanelSkeleton;
