// Redux
import { useSelector } from 'react-redux';
import { selectFilterPanelVisibility } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { Box } from '@mui/material';
import { SidebarFilter, ContactListPanel } from './components';

const AppBody = () => {
  const isFilterPanelOpen = useSelector(selectFilterPanelVisibility);

  return (
    <Box
      display="flex"
      mt={1}
      height={'calc(95% - 11px)'}
      maxHeight={'95%'}
      width="100%"
    >
      <SidebarFilter isFilterPanelOpen={isFilterPanelOpen} />
      <ContactListPanel />
    </Box>
  );
};

export default AppBody;
