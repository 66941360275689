import axios from 'axios';
// Types
import { IEvent } from '@trii/types/dist/Calendar';
import { AddActivityRequestData } from '../types/AddActivityRequestData';
// Utils
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';

const fetchActivities = async (jwtToken: string, URL: string): Promise<IEvent[]> => {
  const config = getRequestConfig.basic(jwtToken);
  const response = await axios.get<IEvent[]>(URL, config);

  return response.data;
};
const addActivity = async (
  jwtToken: string,
  URL: string,
  addActivityRequestData: AddActivityRequestData
): Promise<IEvent> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.post<IEvent>(URL, addActivityRequestData, config);

  return response.data;
};
const deleteActivity = async (jwtToken: string, URL: string) => {
  const config = getRequestConfig.basic(jwtToken);
  await axios.delete<null>(URL, config);
};
const updateActivity = async (
  jwtToken: string,
  URL: string,
  updateActivityRequestData: object
): Promise<IEvent> => {
  const config = getRequestConfig.contentTypeJson(jwtToken);
  const response = await axios.put<IEvent>(URL, updateActivityRequestData, config);

  return response.data;
};

export default { fetchActivities, addActivity, deleteActivity, updateActivity };
