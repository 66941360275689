// Types
import CustomPropertyInputProps from './types/CustomPropertyInputProps';
import { ContactField_type } from '@trii/types/dist/Contacts';
// Components
import { Box, TextField } from '@mui/material';

const CustomPropertyInput: React.FC<CustomPropertyInputProps> = ({
  nameKey,
  value,
  onChange,
  type,
  name,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      type === ContactField_type.CURRENCY ||
      type === ContactField_type.NUMBER_ACCOUNTING ||
      type === ContactField_type.NUMBER_DECIMAL ||
      type === ContactField_type.NUMBER_INTEGER
    ) {
      handleNumberTypeChange(event);
    }
    if (type === ContactField_type.LONGTEXT || type === ContactField_type.TEXT) {
      handleTextTypeChange(event);
    }
  };

  const handleTextTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(nameKey, event.target.value);
  };

  const handleNumberTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const sanitizedInput = inputValue.replace(/[^0-9.]/g, '');
    onChange(nameKey, sanitizedInput);
  };

  return (
    <Box
      className="fadein"
      sx={{
        display: 'flex',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? '1e1e1e'
            : theme.palette.background.default,
        // padding: '0 10px',
        flexDirection: 'column',
        borderRadius: 1,
        width: '45%',
      }}
    >
      <Box component="form" noValidate autoComplete="off">
        <TextField
          size="small"
          label={name}
          variant="outlined"
          value={value}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          onChange={handleChange}
          inputProps={{
            pattern: '[0-9]*', // This pattern allows only numbers
          }}
          className="contacts-smallInput"
        />
      </Box>
    </Box>
  );
};

export default CustomPropertyInput;
