import { v4 as uuidv4 } from 'uuid';
// Types
import { ChannelType } from '@trii/types/dist/Common/Channels';
import { NewAddress } from 'types/NewAddress';

function createNewAddress(
  channelType: ChannelType.PHONE | ChannelType.EMAIL | ChannelType.WHATSAPP
): NewAddress {
  return {
    id: uuidv4(),
    address: '',
    note: '',
    channelType,
    isSaved: false,
  };
}

export default { createNewAddress };
