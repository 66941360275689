import { useState } from 'react';
// Components/ui
import { style } from './style';
import { Box, Badge, Avatar, styled, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
// Types
import { UseImageType } from 'hooks/useImage';
// Redux
import { useSelector } from 'react-redux';
import { selectBusinessUpdatePhotoStatus } from 'ReduxToolkit/features/businessSlice/selectors';

type AvatarSelectProps = {
  contactImage: UseImageType;
  businessName: string;
};

const AvatarSelect = ({ contactImage, businessName }: AvatarSelectProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const { action, inputAtributes, imageUrl } = contactImage;

  const updatePhotoStatus = useSelector(selectBusinessUpdatePhotoStatus);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    mr: '5px',
    color: 'red',
  }));

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box sx={style.avatarContainer}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <SmallAvatar>
            <DeleteIcon onClick={action.deleteImage} sx={style.avatarDelete} />
          </SmallAvatar>
        }
      >
        <Avatar
          src={imageUrl}
          sx={style.avatar}
          alt={businessName}
          onClick={action.uploadImage}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {isHovered && (
            <AddAPhotoIcon
              style={{
                position: 'absolute',
                color: 'rgb(0 0 0 / 50%)',
                zIndex: 1,
              }}
            />
          )}

          {businessName?.charAt(0)}
        </Avatar>
        {isHovered && imageUrl && <AddAPhotoIcon sx={style.avatarUpdateIcon} />}
        {updatePhotoStatus === 'loading' && (
          <Box sx={style.avatarUpdateIcon}>
            <CircularProgress size={30} />
          </Box>
        )}
        <input {...inputAtributes} style={{ display: 'none' }} />
      </Badge>
    </Box>
  );
};

export default AvatarSelect;
