import { useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { SegmentsEditContext } from 'features/Views/SegmentsEdit/context/SegmentsEditProvider';
// Components/ui
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Dayjs
import dayjs from 'dayjs';
import { Box } from '@mui/material';

type Props = {
  groupId: string;
  value: string;
  itemId: string;
};

const DatePickerField = ({ groupId, value, itemId }: Props) => {
  const { t } = useTranslation();
  const { handleTimeDatePickerChange } = useContext(SegmentsEditContext);

  const handleChange = (newValue: string) => {
    const formatedValue = dayjs(newValue).format('YYYY-MM-DD');
    handleTimeDatePickerChange(formatedValue, itemId, groupId);
  };

  return (
    <Box sx={{ width: "30%" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={["DatePicker"]}
          sx={{
            minWidth: "0px",
            overflow: "visible",
            paddingTop: "0px",
          }}
        >
          <DatePicker
            label={t("segmentsEdit.value")}
            value={value}
            onChange={handleChange}
            sx={{
              width: "30%",
            }}
            slotProps={{
              textField: {
                size: "small",
                sx: {
                  width: "100%",
                  minWidth: "0px !important",
                },
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerField;
