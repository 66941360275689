import React, { useContext } from 'react';
import { ButtonsListContext } from '../../../../context/ButtonListContext';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectButtonEditStatus } from '../../../../../../../../../../../ReduxToolkit/features/configSlice/configSlice';
// Components/ui
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import SaveAsIcon from '@mui/icons-material/SaveAs';

function EditButtons() {
  const { t } = useTranslation();
  const { handleDrawerClose, handleOpenModalTry, handleEditButton } =
    useContext(ButtonsListContext);
  const buttonEditStatus = useSelector(selectButtonEditStatus);
  const isButtonLoading = buttonEditStatus === 'loading';

  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      width="100%"
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={handleOpenModalTry}
      >
        {t('global.tryIt')}
      </Button>
      <Box display={'flex'}>
        <Button
          variant="contained"
          size="small"
          sx={{
            marginRight: '15px',
          }}
          onClick={handleDrawerClose}
        >
          {t('global.cancel')}
        </Button>
        <LoadingButton
          startIcon={<SaveAsIcon />}
          loading={isButtonLoading}
          disabled={isButtonLoading}
          variant="contained"
          size="small"
          color="success"
          onClick={handleEditButton}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {t('global.save')}
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default EditButtons;
