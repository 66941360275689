import { useContext } from 'react';
// Context
import { BusinessCreateContext } from 'features/Views/BusinessCreate/context/BusinessCreateContext';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectBusinessStatusCreate } from 'ReduxToolkit/features/businessSlice/selectors';
// Components
import { LoadingButton } from '@mui/lab';

const CreateButton = () => {
  const { t } = useTranslation();
  const { handleCreateButton } = useContext(BusinessCreateContext);
  const businessCreateStatus = useSelector(selectBusinessStatusCreate);
  const isLoading = businessCreateStatus === 'loading';

  return (
    <LoadingButton
      onClick={handleCreateButton}
      loading={isLoading}
      disabled={isLoading}
      size="small"
      variant="contained"
    >
      {t('global.create')}
    </LoadingButton>
  );
};

export default CreateButton;
