// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  selectActivitiesAddStatus,
  selectActivitiesUpdateStatus,
} from 'ReduxToolkit/features/activitiesSlice/utils/selectors';
// Components/ui
import { DialogActions, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

type ActionButtonsProps = {
  closeEventPopUp: () => void;
  handleCreateActivity: () => void;
  handleUpdateActivity: () => void;
  isEditing: boolean;
};
const ActionButtons = ({
  closeEventPopUp,
  handleCreateActivity,
  handleUpdateActivity,
  isEditing,
}: ActionButtonsProps) => {
  const { t } = useTranslation();
  const activitiesAddStatus = useSelector(selectActivitiesAddStatus);
  const activitiesUpdateStatus = useSelector(selectActivitiesUpdateStatus);
  const isLoading =
    (!isEditing && activitiesAddStatus === 'loading') ||
    (isEditing && activitiesUpdateStatus === 'loading');
  const onClick = isEditing ? handleUpdateActivity : handleCreateActivity;
  const ButtonText = isEditing
    ? t('contactsView.activities.updateEvent')
    : t('contactsView.activities.createEvent');

  return (
    <DialogActions>
      <Button variant="outlined" size="small" onClick={closeEventPopUp}>
        {t('global.cancel')}
      </Button>
      <LoadingButton
        size="small"
        loading={isLoading}
        disabled={isLoading}
        onClick={onClick}
        variant="contained"
      >
        {ButtonText}
      </LoadingButton>
    </DialogActions>
  );
};

export default ActionButtons;
