// Components/ui
import { Box } from '@mui/material';
// Types
import { ReactNode } from 'react';

type InputContainerProps = {
  children: ReactNode;
  title: string;
};

const InputContainer = ({ children, title }: InputContainerProps) => {
  return (
    <Box>
      <Box sx={{ mb: 1 }}>{title}</Box>
      {children}
    </Box>
  );
};

export default InputContainer;
