export const style = {
  titleContainer: {
    width: '100%',
    py: 1,
    px: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardContainer: {
    margin: 2,
    '& .MuiAccordionSummary-root': {
      backgroundColor: (theme) => theme.palette.background.accordionHeader,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
    },
    '& .MuiAccordionSummary-root .MuiAccordionSummary-content': {
      margin: 0,
    }

  },
};
