import React, { useContext } from 'react';
import { SegmentsContext } from '../../context/SegmentsProvider';
// Components/ui
import { CreatePanel, DeletePanel } from './components';

const SegmentsSidePanel = () => {
  const { createPanelOpen, deletePanelOpen } = useContext(SegmentsContext);

  return (
    <>
      {(createPanelOpen && <CreatePanel />) || (deletePanelOpen && <DeletePanel />)}
    </>
  );
};

export default SegmentsSidePanel;
