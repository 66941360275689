import { Box, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';

const ListHeader = ({open, show}) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    <NavLink underline="hover" key="1"  to="/a/contacts/settings" >
      <Typography color="secondary">

      {t('settingsView.settings')}
      </Typography>
    </NavLink>,
    <NavLink
      underline="hover"
      key="2"
      to="/a/contacts/settings/googleSync"
      
    >
      <Typography color="secondary">

      google Sync
      </Typography>

    </NavLink>,

  ];
  return (
    
    <Box
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
        // sx={{ paddingRight: 2, visibility: show? 'hidden' : 'visible' }}
        borderRadius={"4px"}
        // bgcolor='background.panel'
        height="50px"
        className="m-0"
        position={'relative'}

      >
        <Box position={"absolute"} left="0rem" top="-1rem">

           <Breadcrumbs separator="-" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
        </Box>
      </Box>
  )
}

export default ListHeader