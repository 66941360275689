import {
  useEffect,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
// Components/ui
import {
  AvatarGroup,
  Box,
  Checkbox,
  Chip,
  Fade,
  FormControlLabel,
  useTheme,
} from '@mui/material';
// Types
import { ILabel } from '@trii/types/dist/Conversations';
// Components
import { LabelModal } from './components';
// Context
import { DuplicateContext } from 'features/Views/Duplicates/context/DuplicateContext';

interface LabelsProps {
  labels: ILabel[];
  contactId: string;
  setTagsSelected: Dispatch<SetStateAction<boolean>>;
  isMerged?: boolean;
}
const Labels = ({
  labels,
  contactId,
  setTagsSelected,
  isMerged = false,
}: LabelsProps) => {
  const { tags, handleSelectContactTag, triggerSelectAllId, setTriggerSelectAllId } =
    useContext(DuplicateContext);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [contactLabel, setContactLabel] = useState<ILabel[] | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<boolean>(false);

  const checkboxRef = useRef(null);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    // @ts-ignore
    setTagsSelected(e.target.checked);
    const data = {
      contactId,
      value: contactLabel,
    };
    handleSelectContactTag(data);
  };

  useEffect(() => {
    if (
      triggerSelectAllId !== '' &&
      triggerSelectAllId === contactId &&
      !selectedLabels
    ) {
      checkboxRef.current?.click();
    }
  }, [triggerSelectAllId, contactId]);

  useEffect(() => {
    setOpen(true);
    return () => {
      setOpen(false);
    };
  }, []);

  useEffect(() => {
    if (labels) {
      setContactLabel(labels);
    }
  }, [labels]);

  useEffect(() => {
    if (isMerged) {
      const newTags = tags?.map((tag) => tag.value).flat();
      setContactLabel(newTags);
    }
    const contactsIds = tags?.map((tag) => tag.contactId);
    setSelectedLabels(contactsIds?.includes(contactId));
  }, [tags]);

  return (
    <>
      <FormControlLabel
        control={
          !isMerged ? (
            <Checkbox size="small" checked={selectedLabels} ref={checkboxRef} />
          ) : (
            <> </>
          )
        }
        onClick={!isMerged && handleClick}
        disabled={labels?.length == 0}
        label={
          <AvatarGroup
            max={3}
            total={contactLabel?.length}
            sx={{
              '& .MuiAvatar-root': {
                width: 20,
                height: 20,
                fontSize: '0.7rem',
              },
              '&:hover': {
                cursor: 'pointer',
              },
              // Add some spacing between avatars
              '& .MuiAvatar-groupAvatar': {
                marginRight: '5px',
              },
              alignItems: 'center',
              maxWidth: '100%',
            }}
            spacing={'medium'}
            onClick={handleOpen}
          >
            {contactLabel &&
              contactLabel?.length > 0 &&
              contactLabel?.map((label, i) => (
                <Fade key={i} in={open}>
                  <Box
                    sx={{
                      minWidth: '45px',
                      maxWidth: '100px',
                    }}
                  >
                    <Chip
                      label={label.name}
                      size="small"
                      sx={{
                        color: (theme) => theme.palette.text.primary,
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                        height: '20px',
                        minWidth: '100%',
                        fontSize: '0.7125rem',
                        boxShadow:
                          i === 0
                            ? 'none'
                            : //@ts-ignore
                              `-3px 0px 1px 0px ${theme.palette.boxShadow}`,
                      }}
                    />
                  </Box>
                </Fade>
              ))}
          </AvatarGroup>
        }
      />
      <LabelModal labels={contactLabel} open={openModal} handleClose={handleClose} />
    </>
  );
};

export default Labels;
