// Components/ui
import { Box } from '@mui/material';
import { SubsectionHeaderWithButton } from '../../layout';
import { Body } from './components';
// Types
import { NewAddress } from 'types/NewAddress';

type Props = {
  emails: NewAddress[];
  handleAdd: () => void;
};
const Mails = ({ emails, handleAdd }: Props) => {
  return (
    <Box sx={{ width: '100%' }}>
      <SubsectionHeaderWithButton handleAdd={handleAdd} title="Mails" />
      <Body emails={emails} />
    </Box>
  );
};

export default Mails;
