import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// components/ui
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CustomFieldContext } from '../../../../context/CustomFieldContext';

const DeleteButtons = ({ handleDrawerClose, deleteField, selectedField }) => {
  const { t } = useTranslation();
  const { handleDeleteContactField, loadingDelete } = useContext(CustomFieldContext);

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{ marginRight: '15px' }}
        onClick={handleDrawerClose}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loadingPosition="start"
        variant="contained"
        onClick={handleDeleteContactField}
        disabled={
          loadingDelete || deleteField.attributes.value !== selectedField?.name
        }
        loading={loadingDelete}
        startIcon={<DeleteIcon />}
        size="small"
        sx={{
          backgroundColor: (theme) => theme.palette.error.main,
          '&:hover': {
            backgroundColor: '#b91c1c',
          },
          color: (theme) => theme.palette.text.primary,
        }}
      >
        {t('global.delete')}
      </LoadingButton>
    </Box>
  );
};

export default DeleteButtons;
