import { useContext } from 'react';
// Translations
import { useTranslation } from "react-i18next";
// Icons
import { Close } from "@mui/icons-material";
// Components/ui
import {
  Box,
  Divider,
  IconButton,
  Modal,
  Slide,
  Typography,
} from "@mui/material"
// Components
import {
  DeleteContact,
} from './components';

interface Props {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  contactName: string;
}

const DeleteContactModal = ({
  openModal,
  setOpenModal,
  contactName,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <Modal
      open={openModal}
      onClose={() => handleClose()}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        marginTop: '5rem',
        background: 'transparent',
        '& .MuiBackdrop-root': {
          background: 'transparent',
        },
      }}
    >
      <Slide
        direction="down"
        in={openModal}
        mountOnEnter
        unmountOnExit
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '30rem',
            maxHeight: '40rem',
            minHeight: '10rem',
            overflow: 'auto',
            borderRadius: 2,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
            backgroundColor: 'white',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1em',
            }}
          >
            <Typography
              variant="h6"
              color={(theme) => theme.palette.grey[600]}
            >
              {t('contactsView.actions.deleteContact')}
            </Typography>
            <IconButton
              onClick={handleClose}
            >
              <Close
                sx={{
                  color: (theme) => theme.palette.text.disabled,
                }}
              />
            </IconButton>
          </Box>
          <Divider />
          <DeleteContact
            handleCloseModal={handleClose}
            contactName={contactName}
          />
        </Box>
      </Slide>
    </Modal>
  )
}

export default DeleteContactModal