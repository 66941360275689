import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, useTheme, Typography } from '@mui/material';
import { ActionButtons } from './components';
import { NotesCardContext } from '../../../context/NotesCardContext';

const DeleteNoteModal = () => {
  const { t } = useTranslation();
  const {
    isModalDeleteNoteOpen,
    closeModalDeleteNote,
    handleDeleteNote,
    noteIdToDelete
  } = useContext(NotesCardContext);

  const theme = useTheme();
  console.log("click", isModalDeleteNoteOpen);

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '50%',
          //@ts-ignore
          backgroundColor: theme.palette.background.panel,
          backgroundImage: 'unset',
        },
      }}
      maxWidth={false}
      open={isModalDeleteNoteOpen}
      onClose={closeModalDeleteNote}
    >
      <DialogTitle>{t('global.delete')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography>

        {t('contactsView.notes.deleteNote')}
        </Typography>
      </DialogContent>
      <ActionButtons
        closeEventPopUp={closeModalDeleteNote}
        handleDeleteNote={handleDeleteNote}
        noteId={noteIdToDelete}
      />
    </Dialog>
  );
};

export default DeleteNoteModal;
