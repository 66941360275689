import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box,
  Card,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from '@mui/material';

const ApiSection = ({
  urlFieldState,
  methodSelectState,
  authTypeSelectState,
  bodyFieldState,
  contentTypeFieldState,
  usernameFieldState,
  passwordFieldState,
  fixedTokenFieldState,
  dynamicUrlFieldState,
  bearerTokenBodyFieldState,
  apiKeyFieldState,
  valueFieldState,
  addToSelectState,
}) => {
  const { t } = useTranslation();
  const authType = authTypeSelectState.attributes.value;

  const handleAuthTypeChange = () => {
    usernameFieldState.actions.resetValue();
    passwordFieldState.actions.resetValue();
    fixedTokenFieldState.actions.resetValue();
    dynamicUrlFieldState.actions.resetValue();
    bearerTokenBodyFieldState.actions.resetValue();
    apiKeyFieldState.actions.resetValue();
    valueFieldState.actions.resetValue();
    addToSelectState.actions.resetValue();
  };

  useEffect(() => {
    handleAuthTypeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authType]);

  return (
    <Card
      sx={{
        margin: 2,
      }}
    >
      <Box
        display={'flex'}
        justifyContent="space-between"
        alignItems={'center'}
        py={1}
        px={2}
        sx={{
          backgroundColor: (theme) => theme.palette.background.dropdownMenu,
        }}
      >
        <Typography variant="subtitle2">API</Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection="column"
        justifyContent="space-between"
        alignItems={'start'}
        p={2}
        sx={{
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.panel,
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
        }}
      >
        <FormControl
          size="small"
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
        >
          <InputLabel>{t('settingsView.method')}</InputLabel>
          <Select label="Metodo" {...methodSelectState.attributes}  MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
              backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            },
          },
        }}>
            <MenuItem value={1}>GET</MenuItem>
            <MenuItem value={2}>POST</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className="fadein"
          variant="outlined"
          label="URL_CONTACTS"
          size="small"
          {...urlFieldState.attributes}
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
        />
        <Divider
          sx={{
            marginBottom: '10px',
            width: '100%',
          }}
        ></Divider>
        <Typography
          sx={{
            marginBottom: '10px',
          }}
        >
          Authorization type
        </Typography>
        <FormControl
          size="small"
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
        >
          <InputLabel>{t('settingsView.security')}</InputLabel>
          <Select {...authTypeSelectState.attributes} label="Seguridad"  MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
            },
          },
        }}>
            <MenuItem value={'Ninguna'}>Ninguna</MenuItem>
            <MenuItem value={'Basic'}>Basic</MenuItem>
            <MenuItem value={'Bearer Token'}>Bearer Token</MenuItem>
            <MenuItem value={'API-key'}>API-key</MenuItem>
          </Select>
        </FormControl>
        {authType === 'Basic' ? (
          <>
            <TextField
              {...usernameFieldState.attributes}
              className="fadein"
              variant="outlined"
              size="small"
              label="Basic - Username"
              fullWidth
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
            <TextField
              {...passwordFieldState.attributes}
              className="fadein"
              variant="outlined"
              size="small"
              fullWidth
              label="Basic - Password"
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
          </>
        ) : authType === 'Bearer Token' ? (
          <>
            <TextField
              {...fixedTokenFieldState.attributes}
              className="fadein"
              variant="outlined"
              size="small"
              label="Fix Token"
              fullWidth
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
            <TextField
              {...dynamicUrlFieldState.attributes}
              className="fadein"
              variant="outlined"
              size="small"
              label="Dynamic URL_CONTACTS"
              fullWidth
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
            <TextField
              {...bearerTokenBodyFieldState.attributes}
              className="fadein"
              variant="outlined"
              size="small"
              label="Body"
              fullWidth
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
          </>
        ) : authType === 'Api-key' ? (
          <>
            <TextField
              className="fadein"
              variant="outlined"
              {...apiKeyFieldState.attributes}
              size="small"
              fullWidth
              label="Api-key - Key"
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
            <TextField
              className="fadein"
              variant="outlined"
              {...valueFieldState.attributes}
              size="small"
              fullWidth
              label="API-key - Value"
              sx={{
                marginBottom: '15px',
              }}
            ></TextField>
            <FormControl
              size="small"
              fullWidth
              sx={{
                marginBottom: '15px',
              }}
            >
              <InputLabel>API-key - Add to</InputLabel>
              <Select {...addToSelectState.attributes} label="API-key - Add to"  MenuProps={{
          PaperProps: {
            sx: {
              //@ts-ignore
              backgroundColor: theme => theme.palette.background.panel,
            },
          },
        }}>
                <MenuItem value={'Header'}>Header</MenuItem>
                <MenuItem value={'QueryParams'}>Query Params</MenuItem>
              </Select>
            </FormControl>
          </>
        ) : null}
        <Divider
          sx={{
            marginBottom: '10px',
            width: '100%',
          }}
        ></Divider>
        <Typography
          sx={{
            marginBottom: '10px',
          }}
        >
          {t('settingsView.parameters')}
        </Typography>
        <TextField
          multiline
          label={'body'}
          rows={4}
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
          {...bodyFieldState.attributes}
        />
        <TextField
          className="fadein"
          variant="outlined"
          {...contentTypeFieldState.attributes}
          label="ContentType"
          size="small"
          fullWidth
          sx={{
            marginBottom: '15px',
          }}
        />
      </Box>
    </Card>
  );
};

export default ApiSection;
