import { useEffect, useState } from 'react';
// Router
import { useLocation, useParams } from 'react-router-dom';
// Redux
import { useDispatch } from 'react-redux';
import { fetchCustomContactFields } from '../../../ReduxToolkit/features/configSlice/configSlice';
import {
  clearDuplicatesState,
  fetchDuplicates,
  setFetchData,
} from '../../../ReduxToolkit/features/duplicatesSlice/duplicatesSlice';
// Components/ui
import Box from '@mui/material/Box';
import { DuplicatesContent, DuplicatesHeader } from './components';
import { Divider } from '@mui/material';
// Provider
import { DuplicateProvider } from './context/DuplicateProvider';
import { IFetchDuplicatesData } from 'ReduxToolkit/features/duplicatesSlice/types';

export default function DuplicatesContainer() {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(10);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const contactIdParam = searchParams.get('contactId');
  const contactsIdsParam = searchParams.get('ids');

  useEffect(() => {
    dispatch(fetchCustomContactFields('contact'));

    if (!contactsIdsParam) {
      const data: IFetchDuplicatesData = {
        page: 1,
        key: 'name',
        quantity,
        contactId: contactIdParam,
      };
      dispatch(setFetchData(data));
      dispatch(fetchDuplicates(data));
    }
  }, [dispatch, contactIdParam]);

  useEffect(() => {
    return () => {
      dispatch(clearDuplicatesState());
    };
  }, []);

  return (
    <DuplicateProvider>
      <Box
        display={'flex'}
        flexDirection={'column'}
        height={'91vh'}
        width={'100%'}
        className={'contacts-paddingmd'}
        borderRadius={2}
        justifyContent={'space-between'}
      >
        <DuplicatesHeader quantity={quantity} setQuantity={setQuantity} />
        <Divider sx={{ marginTop: '10px', marginX: '10px' }} />
        <DuplicatesContent />
      </Box>
    </DuplicateProvider>
  );
}
