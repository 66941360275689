import React, { useContext } from 'react';
import { ListsContext } from '../../../../../../context/ListsContext';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from 'types/reduxTypes';
import { IList } from '@trii/types/dist/Contacts';
// Redux
import { useSelector } from 'react-redux';
import {
  selectListsDeleteStatus,
  selectSelectedList,
} from 'ReduxToolkit/features/listsSlice/listsSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeDeletePanel, sendDeleteList, deleteListNameField } =
    useContext(ListsContext);
  const deleteStatus: RequestStatus = useSelector(selectListsDeleteStatus);
  const selectedList: IList = useSelector(selectSelectedList);
  const isLoading: boolean = deleteStatus === 'loading';
  const isDisabled: boolean =
    deleteStatus === 'loading' ||
    deleteListNameField.attributes.value !== selectedList.name;

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeDeletePanel}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<DeleteIcon />}
        variant="contained"
        size="small"
        sx={{
          backgroundColor: (theme) => theme.palette.success.main,
          color: 'text.primary',
        }}
        disabled={isDisabled}
        onClick={() => sendDeleteList(selectedList.id)}
      >
        {t('global.delete')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;
