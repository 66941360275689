// Types
import { UseFileType } from 'hooks/useFile';

export const fileHandlerInitialDef: UseFileType = {
  inputAtributes: {
    onChange: () => {},
    ref: { current: null },
    type: '',
  },
  action: {
    uploadFile: () => {},
    handleDeleteFile: () => {},
  },
};
