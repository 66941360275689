import { useContext } from 'react';
// Context
import { NotesCardContext } from '../../context/NotesCardContext';
// Redux
import { useSelector } from 'react-redux';
import {
  selectNotesDeleteStatus,
  selectNotesUpdateStatus,
} from 'ReduxToolkit/features/noteSlice/noteSlice';
// Components/ui
import { Box, useTheme } from '@mui/material';
import { Footer, Header, Body } from './components';
import './style.css';
// Types
import { INote } from '@trii/types/dist/Contacts';
// Custom hooks
import useField from 'hooks/useField';
import { useNoteEditorActions } from './hooks/useNoteEditorActions';
import DeleteNoteModal from '../../DeleteNoteModal';

type NoteProps = {
  data: INote;
};

const Note = ({ data }: NoteProps) => {
  const {
    handleEditNoteStart,
    handleEditNoteEnd,
    editingNoteIds,
  } = useContext(NotesCardContext);
  const noteEditField = useField('text', data.text);
  const titleEditField = useField('text', data.title);
  const { cancelNoteEdit, acceptNoteEdit } = useNoteEditorActions(
    handleEditNoteEnd,
    data.id,
    noteEditField,
    titleEditField
  );
  const notesDeleteStatus = useSelector(selectNotesDeleteStatus);
  const isDeletingLoading = notesDeleteStatus.includes(data.id);
  const isEditing = editingNoteIds.includes(data.id);
  const updatingNoteIds: string[] = useSelector(selectNotesUpdateStatus);
  const isEditingLoading = updatingNoteIds.includes(data.id);
  const theme = useTheme()

  return (
    <>
    <Box className="msg-nota" sx={{backgroundColor: theme.palette.mode === "light" ? "#ffeb3b" : "#ff9800",
      '&.msg-nota::before': {
        //@ts-ignore
        borderColor: `#f3f3f3 ${theme.palette.background.panel} #c1b52a #c1b52a`
      }
    }}>
      <Header
        createdBy={data.createdBy.name}
        noteId={data.id}
        handleEditNoteStart={handleEditNoteStart}
        isDeletingLoading={isDeletingLoading}
      />
      <Body
        noteEditField={noteEditField}
        titleEditField={titleEditField}
        isEditing={isEditing}
        noteText={data.text}
        noteTitle={data.title}
        isEditingLoading={isEditingLoading}
      />
      <Footer
        noteFieldResetValue={noteEditField.actions.resetValue}
        isEditing={isEditing}
        createdAtDate={data.createdAt}
        isEditingLoading={isEditingLoading}
        cancelNoteEdit={cancelNoteEdit}
        acceptNoteEdit={acceptNoteEdit}
      />
    </Box>
    <DeleteNoteModal />
    </>
  );
};

export default Note;
