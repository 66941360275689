import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SubscriptionListContext } from '../../../../../context/SubscriptionListContext';

function Header() {
  const { t } = useTranslation();
  const { isDelete, isEditing, closeResetDrawer } = useContext(
    SubscriptionListContext
  );
  const Title = isEditing
    ? t('settingsView.editSubscription')
    : isDelete
    ? t('settingsView.deleteSubscription')
    : t('settingsView.createSubscription');

  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
    >
      <Typography variant="subtitle2" sx={{color: 'text.primary'}}>{Title}</Typography>
      <IconButton onClick={closeResetDrawer}>
        <CloseIcon
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  );
}

export default Header;
