import { useContext, memo } from 'react';
// Context
import { ActivitiesCardContext } from '../../context/ActivitiesCardContext';
// Components/ui
import { Table } from '@mui/material';
import { Head, Body, CreateActivityPopUp } from './components';
import { StyledTableContainer } from './styles';
// Types
import { IEvent } from '@trii/types/dist/Calendar';

interface FileTableProps {
  activities: IEvent[];
}
export default memo(function ActivitiesTable({ activities }: FileTableProps) {
  const { columns, sortByColumn } = useContext(ActivitiesCardContext);

  return (
    <>
      <StyledTableContainer>
        <Table>
          <Head sortFunction={sortByColumn} columns={columns} />
          <Body activities={activities} />
        </Table>
      </StyledTableContainer>
      <CreateActivityPopUp />
    </>
  );
});
