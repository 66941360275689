import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Types
import InitRequestDataReturn from 'ReduxToolkit/types/InitRequestDataReturn';
import { IEvent } from '@trii/types/dist/Calendar';
import type { RootState } from 'ReduxToolkit/store';
import type { ActivitiesSlice } from './types/ActivitiesSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SortOrder } from 'types/SortOrder';
import type { ActivitiesTableColumnName } from './types/ActivitiesTableColumnName';
import type { AddActivityRequestData } from './types/AddActivityRequestData';
import type { UpdatedActivityRequestData } from './types/UpdateActivityRequestData';
// Utils
import activitiesSliceService from './utils/activitiesSliceService';
import initRequestData from 'ReduxToolkit/functions/initRequestData';

const initialState: ActivitiesSlice = {
  activities: [],
  status: {
    fetch: 'idle',
    add: 'idle',
    delete: [],
    updateEnding: [],
    update: 'idle',
  },
};

export const fetchActivities = createAsyncThunk<IEvent[], void>(
  'activities/fetchActivities',
  async (_, { dispatch, getState }) => {
    const { Contacts } = getState() as RootState;
    const selectedContactId = Contacts.contactViewer.contactData.id;
    const { jwtToken, URL_CALENDAR } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const fetchedActivities = await activitiesSliceService.fetchActivities(
      jwtToken,
      `${URL_CALENDAR}/events?contactId=${selectedContactId}`
    );

    return fetchedActivities;
  }
);

export const addActivity = createAsyncThunk<IEvent, AddActivityRequestData>(
  'activities/addActivity',
  async (activity, { dispatch }) => {
    const { jwtToken, URL_CALENDAR } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const newFile = await activitiesSliceService.addActivity(
      jwtToken,
      `${URL_CALENDAR}/events`,
      activity
    );

    return newFile;
  }
);

export const deleteActivity = createAsyncThunk<string, string>(
  'activities/deleteActivity',
  async (activityId, { dispatch }) => {
    const { jwtToken, URL_CALENDAR } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await activitiesSliceService.deleteActivity(
      jwtToken,
      `${URL_CALENDAR}/events/${activityId}`
    );

    return activityId;
  }
);

export const endActivity = createAsyncThunk<IEvent, string>(
  'activities/endActivity',
  async (activityId, { dispatch }) => {
    const { jwtToken, URL_CALENDAR } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const endedActivity = { finalized: true };
    const updatedActivity = await activitiesSliceService.updateActivity(
      jwtToken,
      `${URL_CALENDAR}/events/${activityId}`,
      endedActivity
    );

    return updatedActivity;
  }
);

export const updateActivity = createAsyncThunk<IEvent, UpdatedActivityRequestData>(
  'activities/updateActivity',
  async (updateActivityRequestData, { dispatch }) => {
    const { jwtToken, URL_CALENDAR } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { activityId, updatedActivity } = updateActivityRequestData;

    const activity = await activitiesSliceService.updateActivity(
      jwtToken,
      `${URL_CALENDAR}/events/${activityId}`,
      updatedActivity
    );

    return activity;
  }
);

const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    setDeletingActivityStatus(state, action: PayloadAction<string>) {
      state.status.delete.push(action.payload);
    },
    removeDeletingActivityStatus(state, action: PayloadAction<string>) {
      const filteredActivities = state.status.delete.filter(
        (activity) => activity !== action.payload
      );
      state.status.delete = filteredActivities;
    },
    setEndingActivityStatus(state, action: PayloadAction<string>) {
      state.status.updateEnding.push(action.payload);
    },
    removeEndingActivityStatus(state, action: PayloadAction<string>) {
      const filteredActivities = state.status.updateEnding.filter(
        (activity) => activity !== action.payload
      );
      state.status.updateEnding = filteredActivities;
    },
    sortActivitiesByColumn(
      state,
      action: PayloadAction<{
        columnName: ActivitiesTableColumnName;
        sortOrder: SortOrder;
      }>
    ) {
      const { columnName, sortOrder } = action.payload;
      // if (columnName !== 'createdBy') {
      //   state.activities.sort((a, b) => {
      //     const valueA =
      //       columnName === 'title'
      //         ? String(a[columnName]).toLowerCase()
      //         : a[columnName];
      //     const valueB =
      //       columnName === 'title'
      //         ? String(b[columnName]).toLowerCase()
      //         : b[columnName];
      //     switch (sortOrder) {
      //       case 'ASC':
      //         return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      //       case 'DESC':
      //         return valueB < valueA ? -1 : valueB > valueA ? 1 : 0;
      //       default:
      //         throw new Error('Invalid sortOrder. Use "ASC" or "DESC".');
      //     }
      //   });
      // } else {
      //   state.activities.sort((a, b) => {
      //     if (a[columnName].name < b[columnName].name) {
      //       return sortOrder === 'ASC' ? -1 : 1;
      //     }
      //     if (a[columnName].name > b[columnName].name) {
      //       return sortOrder === 'ASC' ? 1 : -1;
      //     }
      //     return 0;
      //   });
      // }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchActivities.pending, (state, action) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.activities = action.payload;
      })
      .addCase(addActivity.pending, (state, action) => {
        state.status.add = 'loading';
      })
      .addCase(addActivity.fulfilled, (state, action) => {
        state.activities.push(action.payload);
        state.status.add = 'idle';
      })
      .addCase(addActivity.rejected, (state, action) => {
        state.status.add = 'idle';
        console.log(action.error.message);
      })
      .addCase(deleteActivity.fulfilled, (state, action) => {
        const filteredActivities = state.activities.filter(
          (activity) => activity.id !== action.payload
        );
        const filteredActivityIds = state.status.delete.filter(
          (activityId) => activityId !== action.payload
        );
        state.status.delete = filteredActivityIds;
        state.activities = filteredActivities;
      })
      .addCase(endActivity.fulfilled, (state, action) => {
        const updatedActivity = action.payload;
        const filteredActivityIds = state.status.delete.filter(
          (activityId) => activityId !== updatedActivity.id
        );
        const updatedActivities = state.activities.map((activity) => {
          if (activity.id !== updatedActivity.id) return activity;
          return updatedActivity;
        });

        state.status.delete = filteredActivityIds;
        state.activities = updatedActivities;
      })
      .addCase(updateActivity.pending, (state, action) => {
        state.status.update = 'loading';
      })
      .addCase(updateActivity.fulfilled, (state, action) => {
        const updatedActivity = action.payload;
        const updatedActivities = state.activities.map((activity) => {
          if (activity.id !== updatedActivity.id) return activity;
          return updatedActivity;
        });
        state.status.update = 'idle';
        state.activities = updatedActivities;
      });
  },
});

export const {
  setDeletingActivityStatus,
  removeDeletingActivityStatus,
  setEndingActivityStatus,
  removeEndingActivityStatus,
  sortActivitiesByColumn,
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
