import { useContext } from "react"
// Context
import { ContactBusinessTableContext } from "components/ContactsBusinessTable/context/ContactBusinessTableContext";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch"
// Redux
import { useSelector } from "react-redux"
// Selectors
import {
  selectUsers,
  selectUsersStatus,
} from "ReduxToolkit/features/userSlice/userSlice"
// Components/ui
import { Box, CircularProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs } from "@mui/material"
// Icons
import { Person } from "@mui/icons-material";
// Types
import { UserInfo } from "@trii/types/dist/Users";
import { IGroup } from "@trii/types/dist/Conversations/Groups/Group";
import { UpdateContactLabels } from "ReduxToolkit/features/contactsSlice/types/UpdateContactLabels";
// Slice
import { getCheckedContactsIds, updateContactLabels } from "ReduxToolkit/features/contactsSlice/contactsSlice";

const ChangeOwner = () => {
  const {
    handleCloseModal
  } = useContext(ContactBusinessTableContext)
  const dispatch = useAppDispatch()
  const users = useSelector(selectUsers)
  const usersStatus = useSelector(selectUsersStatus)
  const isLoadingUsers = usersStatus === 'loading'
  const contactIds = useSelector(getCheckedContactsIds);

  const handleSelect = (id: string) => {
    const data = {
      ids: contactIds,
      action: 'setOwner',
      ownerId: id,
    } as UpdateContactLabels
    dispatch(updateContactLabels(data))
    handleCloseModal();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        overflow: 'auto',
      }}
    >
      {
        isLoadingUsers ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: '1em',
            }}
          >
            <CircularProgress
              color="primary"
              size={30}
            />
          </Box>
        ) : (
          users && users.length > 0 &&
          users.map((data: UserInfo | IGroup, i: number) => (
            <ListItem
              key={data.id}
              onClick={() => handleSelect(data.id)}
              sx={{
                padding: 0,
              }}
            >
              <ListItemButton
                sx={{
                  borderBottom: (theme) => i === users.length - 1 ? 'none' : `1px solid ${theme.palette.grey[300]}`,
                }}
              >
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary={data.name} />
              </ListItemButton>
            </ListItem>
          ))

        )
      }
    </Box>
  )
}

export default ChangeOwner