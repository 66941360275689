import { useMemo, useContext } from 'react';
// Context
import { SegmentsEditContext } from 'features/Views/SegmentsEdit/context/SegmentsEditProvider';
// Redux
import { useSelector } from 'react-redux';
import { selectCustomContactFieldById } from 'ReduxToolkit/features/configSlice/configSlice';
// Style
import { itemFilterInputsStyle } from './style/styles';
// Types
import type {
  ItemWithId,
  Operator,
} from 'ReduxToolkit/features/segmentsSlice/types';
// Components/ui
import { Box } from '@mui/material';
import {
  ToggleConditionButtons,
  FieldsSelect,
  ConditionSelect,
  ValueField,
  DeleteItemButton,
} from './components';
// Utils
import segmentsHelper from 'features/Views/SegmentsEdit/utils/segmentsHelper';

interface ItemFilterInputsProps {
  itemData: ItemWithId;
  index: number;
  operator: Operator;
  groupId: string;
}

const ItemFilterInputs = ({
  itemData,
  index,
  operator,
  groupId,
}: ItemFilterInputsProps) => {
  const { handleTextFieldChange } = useContext(SegmentsEditContext);

  const customFieldFilter = useSelector(
    selectCustomContactFieldById(itemData.field)
  );

  const fieldFilterType = customFieldFilter
    ? customFieldFilter?.type
    : segmentsHelper.getFilterType(String(itemData.field));

  return useMemo(() => {
    return (
      <Box sx={itemFilterInputsStyle.containerStyle}>
        {index > 0 && (
          <ToggleConditionButtons itemOperator={operator} groupId={groupId} />
        )}
        <Box sx={itemFilterInputsStyle.inputContainerStyle}>
          <FieldsSelect
            groupId={groupId}
            value={itemData.field}
            itemId={itemData.id}
          />
          <ConditionSelect
            condition={itemData.condition}
            fieldFilterType={fieldFilterType}
            itemId={itemData.id}
            groupId={groupId}
          />
          <ValueField
            fieldFilterType={fieldFilterType}
            value={itemData.value}
            itemId={itemData.id}
            groupId={groupId}
          />
          <DeleteItemButton itemId={itemData.id} groupId={groupId} />
        </Box>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData, operator]);
};

export default ItemFilterInputs;
