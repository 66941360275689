import axios from 'axios';
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
// Functions
import initRequestData from '../../../ReduxToolkit/functions/initRequestData';
// Types
import type {
  IInitialState,
  IFetchDuplicatesData,
  IDuplicatesFetchResponse,
  IDuplicatesJoinResponse,
  JoinDuplicates,
  IDuplicatesGroup,
} from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { IContact, Property } from '@trii/types/dist/Contacts';
import ContactFieldsFetchData from '../contactsSlice/types/ContactFieldsFetchData';
import getRequestConfig from 'ReduxToolkit/functions/getRequestConfig';
import { RequestStatus } from 'types/reduxTypes';

const initialState: IInitialState = {
  status: 'idle',
  fetchData: null,
  fetchedFromContacts: false,
  data: {
    key: 'name',
    items: [],
    pagination: { totalItems: 0, pagesCount: 1, currentPage: 1 },
  },
  joinDuplicates: {
    status: 'idle',
  },
  contactFields: [],
  contactFieldsStatus: 'idle',
};

export const fetchDuplicates = createAsyncThunk(
  'duplicates/fetchDuplicates',
  async (fetchData: IFetchDuplicatesData, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { key, page, quantity, contactId } = fetchData;
    const config = getRequestConfig.basic(jwtToken);

    const URL = contactId
      ? `${URL_CONTACTS}/DuplicateContacts/contact/${contactId}`
      : `${URL_CONTACTS}/DuplicateContacts?page=${page}&key=${key}&limit=${quantity}`;

    const response = await axios.get(URL, config);

    return {
      data: response.data,
      key: fetchData.key,
      page: fetchData.page,
    } as IDuplicatesFetchResponse;
  }
);

export const joinDuplicates = createAsyncThunk(
  'duplicates/joinDuplicates',
  async (data: JoinDuplicates, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await axios.post(
      `${URL_CONTACTS}/DuplicateContacts/join`,
      data,
      config
    );

    return {
      newContact: response.data,
      duplicatesIds: data.contactsId,
    } as IDuplicatesJoinResponse;
  }
);

export const fetchContactFields = createAsyncThunk<
  Property[],
  ContactFieldsFetchData,
  { state: RootState }
>('contacts/fetchContactFields', async (fetchData, { dispatch }) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const { filterBy, fetchFor } = fetchData;

  const config = {
    headers: {
      Authorization: `bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.get<Property[]>(
    `${URL_CONTACTS}/ContactFields?filter=${filterBy}&for=${fetchFor}`,
    config
  );

  return response.data;
});

export const duplicatesSlice = createSlice({
  name: 'duplicates',
  initialState,
  reducers: {
    setFetchData: (state, action: PayloadAction<IFetchDuplicatesData>) => {
      state.fetchData = action.payload;
    },
    setDuplicatesStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status = action.payload;
    },
    setItems: (state, action: PayloadAction<IDuplicatesGroup[]>) => {
      state.data.items = action.payload;
    },
    addContact: (state, action: PayloadAction<IContact>) => {
      state.data.items[0].contacts.push(action.payload);
    },
    clearDuplicatesState: (state) => {
      state.status = 'idle';
      state.fetchData = null;
      state.fetchedFromContacts = false;
      state.data = {
        key: 'name',
        items: [],
        pagination: { totalItems: 0, pagesCount: 1, currentPage: 1 },
      };
      state.joinDuplicates = {
        status: 'idle',
      };
      state.contactFields = [];
      state.contactFieldsStatus = 'idle';
    },
    setFetchedFromContacts: (state, action: PayloadAction<boolean>) => {
      state.fetchedFromContacts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDuplicates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDuplicates.fulfilled, (state, action) => {
        const { data, key, page } = action.payload;
        console.log('fetchDuplicates.fulfilled', action.payload);
        state.status = 'succeeded';
        state.data.key = key;
        state.data.items = data.items;
        state.data.pagination.totalItems = data.totalItems;
        state.data.pagination.pagesCount = data.pagesCount;
        state.data.pagination.currentPage = page;
      })
      .addCase(fetchDuplicates.rejected, (state) => {
        state.status = 'idle';
      })
      .addCase(joinDuplicates.pending, (state) => {
        state.joinDuplicates.status = 'loading';
      })
      .addCase(joinDuplicates.fulfilled, (state, action) => {
        const { newContact, duplicatesIds } = action.payload;
        const { items } = state.data;
        const filteredItems = items.filter((item) => {
          let filteredContacts = item.contacts.filter(
            (contact) => !duplicatesIds.includes(contact.id)
          );
          filteredContacts.push(newContact);
          item.contacts = filteredContacts;
          return item.contacts.length > 0;
        });

        state.data.items = filteredItems;
        state.joinDuplicates.status = 'succeeded';
      })
      .addCase(joinDuplicates.rejected, (state) => {
        state.joinDuplicates.status = 'idle';
      })
      .addCase(fetchContactFields.pending, (state) => {
        state.contactFieldsStatus = 'loading';
      })
      .addCase(fetchContactFields.fulfilled, (state, action) => {
        state.contactFields = action.payload;
        state.contactFieldsStatus = 'succeeded';
      })
      .addCase(fetchContactFields.rejected, (state) => {
        state.contactFieldsStatus = 'idle';
      });
  },
});

export const {
  setFetchData,
  setDuplicatesStatus,
  clearDuplicatesState,
  setItems,
  setFetchedFromContacts,
  addContact,
} = duplicatesSlice.actions;

const selectDuplicates = (state: RootState) => state.Duplicates;
export const selectDuplicatesData = createSelector(
  selectDuplicates,
  (state) => state.data
);
export const selectFetchedFromContacts = createSelector(
  selectDuplicates,
  (state) => state.fetchedFromContacts
);
export const selectDuplicatesFetchData = createSelector(
  selectDuplicates,
  (state) => state.fetchData
);
export const selectDuplicatesItems = createSelector(
  selectDuplicates,
  (state) => state.data.items
);
export const selectDuplicatesTotalItems = createSelector(
  selectDuplicates,
  (state) => state.data.pagination.totalItems
);
export const selectDuplicatesPagesCount = createSelector(
  selectDuplicates,
  (state) => state.data.pagination.pagesCount
);
export const selectDuplicatesCurrentPage = createSelector(
  selectDuplicates,
  (state) => state.data.pagination.currentPage
);
export const selectDuplicatesKey = createSelector(
  selectDuplicates,
  (state) => state.data.key
);
export const selectDuplicatesStatus = createSelector(
  selectDuplicates,
  (state) => state.status
);
export const selectJoinDuplicatesStatus = createSelector(
  selectDuplicates,
  (state) => state.joinDuplicates.status
);
export const selectContactFields = createSelector(
  selectDuplicates,
  (state) => state.contactFields
);
export const selectContactFieldsStatus = createSelector(
  selectDuplicates,
  (state) => state.contactFieldsStatus
);

export default duplicatesSlice.reducer;
