import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  googleLogOut,
  selectGoogleAccountStatus,
} from '../../../../../../../../../../../ReduxToolkit/features/googleSyncSlice/googleSyncSlice';
// Components/ui
import { Fade } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import GoogleImage from './utils/google.png';

const GoogleLogOutButton = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const googleAccountStatus = useSelector(selectGoogleAccountStatus);
  const isLoading = googleAccountStatus === 'loginOut';

  const handleLogOut = async () => {
    await dispatch(googleLogOut());
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show}>
      <LoadingButton
        onClick={handleLogOut}
        loading={isLoading}
        disabled={isLoading}
        variant="contained"

        startIcon={
          <img
            src={GoogleImage}
            style={{ height: 'auto', width: '14px' }}
            alt="logout"
          />
        }
        size="small"
      >
        {t('global.logout')}
      </LoadingButton>
    </Fade>
  );
};

export default GoogleLogOutButton;
