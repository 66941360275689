import { v4 as uuidv4 } from 'uuid';
import { ItemWithId, GroupWithId } from '../types';
import { Segment_Filter_Details } from '@trii/types/dist/Contacts';

const getItemMockup = (): ItemWithId => {
  return {
    field: Segment_Filter_Details[0].key,
    condition: '',
    value: '',
    id: uuidv4(),
  };
};

const getGroupMockup = (): GroupWithId => {
  return { operator: 'AND', items: [getItemMockup()], id: uuidv4() };
};

export const segmentMockup = {
  getItemMockup,
  getGroupMockup,
};
