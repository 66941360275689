import React from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { UseCreateLabel } from 'features/Views/Label/hooks/useCreateLabel/types';
// Components/ui
import { Box, TextField } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';

interface CreateEditPanelProps {
  labelState: UseCreateLabel;
}

const CreateEditPanel = ({ labelState }: CreateEditPanelProps) => {
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      justifyContent="space-between"
      alignItems={'center'}
      p={2}
      gap={2}
    >
      <TextField
        {...labelState.input.nameField.attributes}
        className="fadein"
        variant="outlined"
        size="small"
        fullWidth
        label={t('global.name')}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default CreateEditPanel;
