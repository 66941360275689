import React from 'react';
//components/ui
import { IconButton, Menu, MenuItem, Box } from '@mui/material';
import { TuneRounded } from '@mui/icons-material';
import ColumnOptionsDialog from './components/ColumnOptionsDialog/ColumnOptionsDialog';
import { useTranslation } from 'react-i18next';

const TuneButton = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openColumnsModal, setOpenColumnsModal] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleOpenColumnsModal = () => {
    handleCloseMenu();
    setOpenColumnsModal(true);
  };

  const handleCloseColumnsModal = () => setOpenColumnsModal(false);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickMenu}
      >
        <TuneRounded />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOpenColumnsModal}>{t('contactsView.contactBusinessTable.manageColumns')}</MenuItem>
        <MenuItem disabled={true}>{t('contactsView.contactBusinessTable.resetColumnSize')}</MenuItem>
        <MenuItem disabled={true}>{t('contactsView.contactBusinessTable.clipText')}</MenuItem>
      </Menu>
      <ColumnOptionsDialog
        open={openColumnsModal}
        handleClose={handleCloseColumnsModal}
      />
    </Box>
  );
};

export default TuneButton;
