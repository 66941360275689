import { memo } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Provider
import FilesCardContextProvider from './context/FilesCardContextProvider';
// Redux
import { useSelector } from 'react-redux';
import { selectFiles } from 'ReduxToolkit/features/fileSlice/utils/selectors';
// Components/ui
import { FilesTable, UploadButton } from './components';
import { AccordionCard } from '../../layout';

interface FilesCardProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
}
const FilesCard = memo(({ open, setOpen, title }: FilesCardProps) => {
  const { t } = useTranslation();
  const files = useSelector(selectFiles);

  return (
    <FilesCardContextProvider>
      <AccordionCard
        title={title}
        id="files"
        open={open}
        setOpen={setOpen}
        button={<UploadButton />}
      >
        <FilesTable tableFiles={files} />
      </AccordionCard>
    </FilesCardContextProvider>
  );
});

export default FilesCard;
