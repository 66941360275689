import { useEffect } from 'react';
import { Route, Routes as ReactRoutes, useNavigate } from 'react-router-dom';
/** Components */
import Dashboard from './features/Views/Dashboard';
import DuplicatesContainer from './features/Views/Duplicates';
import ImportView from './features/Views/ImportView';
import ExportView from './features/Views/ExportView';
import ManageListContainer from './features/Views/ManageList/ManageListContainer';
import MainLayout from './features/Layout/MainLayout/MainLayout';
import SettingsView from './features/Views/SettingsView';
import Segments from './features/Views/Segments';
import SubscriptionList from './features/Views/SettingsView/Settings/Subscriptions/Components/SubscriptionList';
import ButtonsList from './features/Views/SettingsView/Integrations/Buttons/Components/ButtonsList/ButtonsList';
import ContactsTable from './features/Views/ContactsTable';
import BusinessTable from 'features/Views/BusinessTable';
import SegmentsEditContainer from './features/Views/SegmentsEdit/SegmentsEditContainer';
import { CustomFields } from 'features/Views/SettingsView/components';
import { GoogleSyncView } from './features/Views/SettingsView/Integrations/GoogleSync/Components';
import ContactEdit from 'features/Views/ContactEdit/ContactEdit';
import ContactView from 'features/Views/ContactView/ContactView';
import ContactCreate from 'features/Views/ContactCreate/ContactCreate';
import BusinessEdit from 'features/Views/BusinessEdit/BusinessEdit';
import BusinessView from 'features/Views/BusinessView/BusinessView';
import BusinessCreate from 'features/Views/BusinessCreate/BusinessCreate';
import LabelContainer from 'features/Views/Label/LabelContainer';
import ContactsTableIFRAME from 'features/Views/ContactsTableIFRAME';
import ManageListIFRAME from 'features/Views/ManageListIFRAME';
import DuplicatesIFRAME from 'features/Views/DuplicatesIFRAME';
import ImportIFRAME from 'features/Views/ImportIFRAME';
import SettingsIFRAME from 'features/Views/SettingsIFRAME';

const Routes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/a/contacts') navigate('/a/contacts/contacts');
  }, [navigate]);

  return (
    <MainLayout>
      <ReactRoutes>
        <Route path="/" element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />
        {/* <Route
          caseSensitive
          path="/a/contacts/contacts"
          element={<ContactsTableIFRAME />}
        /> */}
        <Route
          // caseSensitive
          path="/a/contacts/contacts"
          element={<ContactsTable />}
        />
        <Route path="/a/contacts/contacts/:contactId" element={<ContactView />} />
        <Route
          path="/a/contacts/contacts/:contactId/edit"
          element={<ContactEdit />}
        />
        <Route
          path="/view/contacts/contacts/:contactId/edit"
          element={<ContactEdit />}
        />
        <Route path="/a/contacts/contacts/create" element={<ContactCreate />} />
        <Route path="/a/contacts/business" element={<BusinessTable />} />
        <Route path="/a/contacts/business/:businessId" element={<BusinessView />} />
        <Route
          path="/a/contacts/business/:businessId/edit"
          element={<BusinessEdit />}
        />
        <Route path="/a/contacts/business/create" element={<BusinessCreate />} />
        <Route
          // caseSensitive
          path="/a/contacts/ListManagement"
          element={<ManageListContainer />}
        />
        {/* <Route
          caseSensitive
          path="/a/contacts/listManagement"
          element={<ManageListIFRAME />}
        /> */}
        <Route path="/a/contacts/segments" element={<Segments />} />
        <Route
          path="/a/contacts/segments/edit/:id"
          element={<SegmentsEditContainer />}
        />
        <Route
          // caseSensitive
          path="/a/contacts/Duplicates/:contactId?"
          element={<DuplicatesContainer />}
        />
        {/* <Route
          caseSensitive
          path="/a/contacts/duplicates/:contactId?"
          element={<DuplicatesIFRAME />}
        /> */}
        <Route
          // caseSensitive
          path="/a/contacts/ImportContacts"
          element={<ImportView />}
        />
        {/* <Route
          caseSensitive
          path="/a/contacts/importContacts"
          element={<ImportIFRAME />}
        /> */}
        <Route path="/a/contacts/exportContacts" element={<ExportView />} />
        <Route
          // caseSensitive
          path="/a/contacts/Settings"
          element={<SettingsView />}
        />
        {/* <Route
          caseSensitive
          path="/a/contacts/settings"
          element={<SettingsIFRAME />}
        /> */}
        <Route
          path="/a/contacts/settings/customfields-contact"
          element={<CustomFields fetchFor="contact" />}
        />
        <Route
          path="/a/contacts/settings/customfields-business"
          element={<CustomFields fetchFor="business" />}
        />
        <Route
          path="/a/contacts/settings/subscriptions"
          element={<SubscriptionList />}
        />
        <Route path="/a/contacts/settings/buttons" element={<ButtonsList />} />
        <Route path="/a/contacts/settings/googlesync" element={<GoogleSyncView />} />
        <Route path="/a/contacts/settings/labels" element={<LabelContainer />} />
      </ReactRoutes>
    </MainLayout>
  );
};

export default Routes;
