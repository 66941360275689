import { useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDuplicatesPagesCount,
  selectDuplicatesCurrentPage,
  selectDuplicatesStatus,
  selectDuplicatesKey,
  fetchDuplicates,
} from '../../../../../../../ReduxToolkit/features/duplicatesSlice/duplicatesSlice';
// Types
import { RequestStatus } from '../../../../../../../types/reduxTypes';
// Components/ui
import { Box, IconButton, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

interface Props {
  quantity: number;
  setQuantity: (quantity: number) => void;
}

const Pagination = ({ quantity, setQuantity }: Props) => {
  const dispatch = useDispatch();
  const currentPage: number = useSelector(selectDuplicatesCurrentPage);
  const duplicatesPagesCount: number = useSelector(selectDuplicatesPagesCount);
  const duplicatesStatus: RequestStatus = useSelector(selectDuplicatesStatus);
  const duplicatesKey: string = useSelector(selectDuplicatesKey);
  const isPrevDisabled = duplicatesStatus === 'loading' || currentPage === 1;
  const isNextDisabled =
    duplicatesStatus === 'loading' || currentPage === duplicatesPagesCount;

  const handleNextPage = (page: number) => {
    dispatch(fetchDuplicates({ page, key: duplicatesKey, quantity }));
  };

  useEffect(() => {
    if (quantity !== 10) {
      handleNextPage(currentPage);
    }
  }, [quantity]);

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        style={{ alignSelf: 'center' }}
        size="small"
        sx={{ color: (theme) => theme.palette.text.primary }}
        disabled={isPrevDisabled}
        onClick={() => handleNextPage(1)}
      >
        <KeyboardDoubleArrowLeftIcon />
      </IconButton>
      <IconButton
        style={{ alignSelf: 'center' }}
        size="small"
        sx={{ color: (theme) => theme.palette.text.primary }}
        disabled={isPrevDisabled}
        onClick={() => handleNextPage(currentPage - 1)}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
        {`${currentPage}/${duplicatesPagesCount}`}
      </Typography>
      <IconButton
        disabled={isNextDisabled}
        style={{ alignSelf: 'center' }}
        size="small"
        sx={{ color: (theme) => theme.palette.text.primary }}
        onClick={() => handleNextPage(currentPage + 1)}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton
        disabled={isNextDisabled}
        style={{ alignSelf: 'center' }}
        size="small"
        sx={{ color: (theme) => theme.palette.text.primary }}
        onClick={() => handleNextPage(duplicatesPagesCount)}
      >
        <KeyboardDoubleArrowRightIcon />
      </IconButton>
    </Box>
  );
};

export default Pagination;
