// Components/ui
import { StyledBoxContainer, StyledDialogContextText } from './styles';
// Types
import { ReactNode } from 'react';

type BasicInputContainerProps = {
  name: string;
  children: ReactNode;
};

const BasicInputContainer = ({ name, children }: BasicInputContainerProps) => {
  return (
    <StyledBoxContainer>
      <StyledDialogContextText>{name}</StyledDialogContextText>
      {children}
    </StyledBoxContainer>
  );
};

export default BasicInputContainer;
