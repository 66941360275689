import React from 'react';
// Components/ui
import { Box, Skeleton } from '@mui/material';

const SwitchSkeleton = () => {
  return (
    <Box display={'flex'} gap={1} alignItems={'center'}>
      <Skeleton
        variant="rectangular"
        width={75}
        height={22}
        sx={{
          borderRadius: '2px',
          bgcolor: 'grey.400',
        }}
      />
      <Skeleton
        variant="rectangular"
        width={50}
        height={22}
        sx={{
          borderRadius: '20px',
          bgcolor: 'grey.400',
        }}
      />
    </Box>
  );
};

export default SwitchSkeleton;
