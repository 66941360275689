import { useContext, useEffect, useState } from 'react';
// Context
import { StyleContext } from 'style/styleProvider';
import { CustomFieldContext } from '../../../../../../context/CustomFieldContext';
// Drag and Drop
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
// Components/ui
import { IconButton, Stack, Box, Fade, Typography, Theme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
// Types
import { Style } from 'style/types/Style';
import { Contacts } from '@trii/types';
import { IContactField } from '@trii/types/dist/Contacts';

interface Props {
  field: IContactField;
}

const CustomContactFieldItem = ({ field }: Props) => {
  const { openDelete, openEdit, getFieldType } = useContext(CustomFieldContext);
  const { fontSize, fontWeight, shadow } = useContext<Style>(StyleContext);
  const [show, setShow] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: field.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade style={{ transitionDuration: '750ms' }} in={show}>
      <Box
        ref={setNodeRef}
        style={style}
        {...attributes}
        sx={{
          display: 'flex',
          alignItems: 'center',
          //@ts-ignore
          bgcolor: (theme) => theme.palette.background.panel,
          boxShadow: shadow.md,
          borderRadius: 1,
          color: (theme: Theme) => theme.palette.text.primary,
          width: '100%',
          p: 0.5,
        }}
      >
        <Box
          className="contacts-w-100"
          display="flex"
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box {...listeners} sx={{ cursor: 'pointer' }}>
            <DragIndicatorIcon
              sx={{
                color: field.isDefault ? 'lightgray' : 'color.icons',
                fontSize: '1.5rem',
                marginRight: '10px',
              }}
            />
          </Box>
          <Box
            className="contacts-mr-auto contacts-py-1 contacts-pr-4 pl-2"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography
              sx={{
                marginRight: '20px',
                color: 'text.primary',
                fontWeight: fontWeight.bold,
                fontSize: fontSize.sm,
                minWidth: '150px',
              }}
            >
              {field.name}
            </Typography>
            <Typography
              sx={{
                color: 'text.primary',
                fontWeight: fontWeight.normal,
                fontSize: fontSize.sm,
              }}
            >
              {getFieldType(Contacts.ContactField_type[field.type])}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="end"
            justifyContent="space-evenly"
          >
            <Stack direction="row">
              <IconButton
                onClick={() => openEdit(field)}
                sx={{ color: 'primary.main' }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                sx={{ color: 'error.main' }}
                onClick={() => openDelete(field)}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default CustomContactFieldItem;
