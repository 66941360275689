import { ReactNode } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { style } from './style';
import UseEditContact from 'features/Views/ContactEdit/hooks/useEditContact/types/UseEditContact';
import UseCreateContact from 'features/Views/ContactCreate/hooks/useCreateContact/types/UseCreateContact';

interface Props {
  mode: 'create' | 'edit';
  navigateBackPath: string;
  children: ReactNode;
  contact: UseEditContact | UseCreateContact;
  contactId?: string;
}

const ContactCreateEditAppHeader = ({
  navigateBackPath,
  children,
  mode,
  contact,
  contactId,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contactFirstname, contactLastname } = contact.field;
  const Title =
    mode === 'create'
      ? t('contactDetails.createContact')
      : t('contactDetails.editContact');

  const handleCancel = () => {
    if (mode === 'create') {
      navigate(navigateBackPath);
      return;
    }

    const editContactCloseData = {
      status: 'true',
      contactId: contactId,
    };

    localStorage.setItem('editContactClose', JSON.stringify(editContactCloseData)); // item used in the edit contact IFRAME of conversations

    navigate(navigateBackPath);
  };

  return (
    <Box
      height={'75px'}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      //@ts-ignore
      bgcolor={(theme) => theme.palette.dropdownBox}
      color={(theme) => theme.palette.text.primary}
      sx={{
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        borderColor: (theme) => theme.palette.divider,
        margin: '0px 16px',
      }}
      px={2}
      pl={1}
      py={1}
    >
      <Box width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box sx={style.titleContainter}>
              <Typography variant="h6" mb={'-5px'} className="ellipsified">
                {Title}
              </Typography>
              <Typography
                variant="h6"
                mb={'-5px'}
                className="ellipsified"
                sx={style.contactTitle}
              >
                {contactFirstname.attributes.value}{' '}
                {contactLastname.attributes.value}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-end" position="relative">
            <Box mr={1}>
              <Button
                sx={{ alignSelf: 'center' }}
                variant="outlined"
                size="small"
                onClick={handleCancel}
              >
                {t('global.cancel')}
              </Button>
            </Box>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactCreateEditAppHeader;
