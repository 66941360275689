// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, TextField } from '@mui/material';
import {
  AvatarSelect,
  BusinessPropietarySelect,
  CustomFields,
  BusinessMembers,
  TagSelect,
} from './components';
import { BusinessSection } from 'components/BusinessCreateEditAppBody/layout';
import { style } from './style';
// Types
import type UseEditBusiness from 'features/Views/BusinessEdit/hooks/useEditBusiness/types/UseEditBusiness';
import type UseCreateBusiness from 'features/Views/BusinessCreate/hooks/useCreateBusiness/types/UseCreateBusiness';

interface Props {
  business: UseEditBusiness | UseCreateBusiness;
}

const GeneralSection = ({ business }: Props) => {
  const { t } = useTranslation();
  const { contactPropietary, name, contactImage } = business.field;

  return (
    <BusinessSection sectionTitle={'General'}>
      <Box sx={style.container}>
        <Box sx={style.subContainer}>
          <AvatarSelect
            businessName={name.attributes.value}
            contactImage={contactImage}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}
          >
            <TextField
              size="small"
              {...name.attributes}
              label={t('global.name')}
              InputLabelProps={{
                shrink: true,
              }}
              className="contacts-smallInput"
            />
            <CustomFields business={business} />
          </Box>
        </Box>
        <Box sx={style.businessData}>
          <BusinessPropietarySelect contactPropietary={contactPropietary} />
          <BusinessMembers />
          <TagSelect business={business} />
        </Box>
      </Box>
    </BusinessSection>
  );
};

export default GeneralSection;
