// Types
import { IContactAddress } from '@trii/types/dist/Contacts';

const getEditedField = (
  key: string,
  originalValue: string | number,
  editedValue: string | number
) => {
  if (
    originalValue !== editedValue &&
    !(originalValue === null && editedValue === '')
  ) {
    const editedField = {};
    editedField[key] = editedValue;
    return editedField;
  }
  return null;
};

const getAddressesWithUpdatedValues = (
  newValue: string,
  newValueAddressId: string,
  addresses: IContactAddress[]
): IContactAddress[] => {
  const newAddresses = addresses.map((address) => {
    if (address.id === newValueAddressId) {
      return {
        ...address,
        address: newValue,
      };
    }
    return address;
  });

  return newAddresses;
};

export default { getEditedField, getAddressesWithUpdatedValues };
