import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './i18n'; //Traduccion
import { I18nextProvider } from 'react-i18next';
import DataProvider from './context/contextApi';
import store from './ReduxToolkit/store';
import { Provider } from 'react-redux';
import i18next from 'i18next';
import StyleProvider from './style/styleProvider';

export default function Root(props) {
  return (
    <Suspense fallback={null}>
      <I18nextProvider i18n={i18next}>
        <DataProvider>
          <Provider store={store}>
            <StyleProvider>
              <BrowserRouter>
                <App rootProps={props} />
              </BrowserRouter>
            </StyleProvider>
          </Provider>
        </DataProvider>
      </I18nextProvider>
    </Suspense>
  );
}
