import { ReactNode } from 'react';
// Components
import SectionContainer from '../SectionContainer';
import { Typography, Box } from '@mui/material';

type Props = {
  sectionTitle: string;
  children: ReactNode;
};

const ContactSection = ({ sectionTitle, children }: Props) => {
  return (
    <SectionContainer>
      <Typography
        fontWeight={'600'}
        sx={{ color: (theme) => theme.palette.text.primary }}
        variant="subtitle2"
      >
        {sectionTitle}
      </Typography>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        py={4}
        flexWrap={'wrap'}
        style={{ gap: 20 }}
        px={'5%'}
        className="contacts-rowContacts"
        marginX={'auto'}
        width={'100%'}
      >
        {children}
      </Box>
    </SectionContainer>
  );
};

export default ContactSection;
