import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentsContext } from '../../../../../../context/SegmentsProvider';
// Components/ui
import { Box, TextField } from '@mui/material';

const Inputs = () => {
  const { t } = useTranslation();
  const { createSegmentDescriptionField, createSegmentNameField } =
    useContext(SegmentsContext);

  return (
    <Box
      sx={{
        padding: '15px',
      }}
    >
      <TextField
        className="fadein"
        variant="outlined"
        size="small"
        label={t('segments.segmentName')}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        {...createSegmentNameField.attributes}
      />
      <TextField
        id="outlined-multiline-static"
        multiline
        label={t('segments.segmentDescription')}
        rows={4}
        fullWidth
        sx={{
          marginBottom: '15px',
        }}
        {...createSegmentDescriptionField.attributes}
      />
    </Box>
  );
};

export default Inputs;
