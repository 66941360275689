import React, { useContext } from 'react';
import { SegmentsContext } from '../../../../../../context/SegmentsProvider';
import { useTranslation } from 'react-i18next';
// Types
import { RequestStatus } from '../../../../../../../../../types/reduxTypes';
// Redux
import { useSelector } from 'react-redux';
import {
  selectSegmentsDeleteStatus,
  selectSelectedSegment,
} from '../../../../../../../../../ReduxToolkit/features/segmentsSlice/segmentsSlice';
// Components/ui
import { Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

const ActionButtons = () => {
  const { t } = useTranslation();
  const { closeDeletePanel, sendDeleteSegment, deleteSegmentNameField } =
    useContext(SegmentsContext);
  const deleteStatus: RequestStatus = useSelector(selectSegmentsDeleteStatus);
  const selectedSegment = useSelector(selectSelectedSegment);
  const isLoading: boolean = deleteStatus === 'loading';
  const isDisabled: boolean =
    deleteStatus === 'loading' ||
    deleteSegmentNameField.attributes.value !== selectedSegment?.name;

  return (
    <Box
      display={'flex'}
      justifyContent="flex-end"
      alignItems={'center'}
      p={2}
      position="absolute"
      right={0}
      bottom={0}
    >
      <Button
        variant="contained"
        size="small"
        sx={{
          marginRight: '15px',
        }}
        onClick={closeDeletePanel}
      >
        {t('global.cancel')}
      </Button>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={<DeleteIcon />}
        variant="contained"
        size="small"
        color="success"
        sx={{ backgroundColor: (theme) => theme.palette.success.main, color: "text.primary" }}
        disabled={isDisabled}
        onClick={() => sendDeleteSegment(selectedSegment.id)}
      >
        {t('global.delete')}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;
