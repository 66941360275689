import { memo } from 'react';
// Provider
import ActivitiesCardContextProvider from './context/ActivitiesCardContextProvider';
// Redux
import { useSelector } from 'react-redux';
import { selectActivities } from 'ReduxToolkit/features/activitiesSlice/utils/selectors';
// Components/ui
import { ActivitiesTable, CreateEventButton } from './components';
import { AccordionCard } from '../../layout';

interface ActivitiesCardProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
}
const ActivitiesCard = memo(({ open, setOpen, title }: ActivitiesCardProps) => {
  const activities = useSelector(selectActivities);

  return (
    <ActivitiesCardContextProvider>
      <AccordionCard
        title={title}
        id="activities"
        open={open}
        setOpen={setOpen}
        button={<CreateEventButton />}
      >
        <ActivitiesTable activities={activities} />
      </AccordionCard>
    </ActivitiesCardContextProvider>
  );
});

export default ActivitiesCard;
