// React Router
import { useNavigate, useParams } from "react-router-dom";
// Hooks
import { useAppDispatch } from "hooks/useAppDispatch";
// Translations
import { useTranslation } from "react-i18next";
// Components/ui
import { Box, Button, Divider, Typography } from "@mui/material"
// Slice
import {
  deleteContact,
} from "ReduxToolkit/features/contactsSlice/contactsSlice";

interface Props {
  handleCloseModal: () => void;
  contactName: string;
}

const DeleteContact = ({
  handleCloseModal,
  contactName,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { contactId } = useParams();

  const handleDelete = async () => {
    dispatch(deleteContact(contactId));
    handleCloseModal();
    navigate('/a/contacts/contacts');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          margin: '1rem',
        }}
      >
        {t('contactsView.actions.deleteLabel', { name: contactName })}
      </Typography>
      <Divider
        sx={{
          width: '100%',
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '.5rem',
        }}
      >
        <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: ".5rem",
          }}
          onClick={handleCloseModal}
        >
          {t('global.cancel')}
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          sx={{
            borderRadius: '0.5rem',
            margin: ".5rem",
          }}
          onClick={handleDelete}
        >
          {t('global.confirm')}
        </Button>
      </Box>
    </Box>
  )
}

export default DeleteContact