import { Dispatch, SetStateAction, useEffect, useState } from 'react';
// Types
import {
  IContact,
  IContactAddress,
  IContactPostalAddress,
  Property,
} from '@trii/types/dist/Contacts';
import {
  TextSelected,
  LabelSelected,
  DateSelected,
  BusinessSelected,
} from './types/FieldSelected';
// Context
import { DuplicateContext } from './DuplicateContext';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  addContact,
  fetchContactFields,
  fetchDuplicates,
  joinDuplicates,
  selectDuplicatesFetchData,
  selectFetchedFromContacts,
  setDuplicatesStatus,
  setFetchedFromContacts,
  setItems,
} from 'ReduxToolkit/features/duplicatesSlice/duplicatesSlice';
import { ContactFieldsFetchData } from 'ReduxToolkit/features/duplicatesSlice/types';
import { useSelector } from 'react-redux';
import {
  fetchContact,
  updateDeleteContacts,
} from 'ReduxToolkit/features/contactsSlice/contactsSlice';
import { useLocation } from 'react-router-dom';

export const DuplicateProvider = ({ children }) => {
  const dispatch = useAppDispatch();

  // General State
  const [contactList, setContactList] = useState<IContact[]>([]);
  const [contactMerged, setContactMerged] = useState(null);
  const [contactsId, setContactsId] = useState<string[]>([]);
  // Contact Data State
  const [name, setName] = useState<TextSelected>(null);
  const [firstName, setFirstName] = useState<TextSelected>(null);
  const [lastName, setLastName] = useState<TextSelected>(null);
  const [imageUrl, setImageUrl] = useState<TextSelected>(null);
  const [birthDate, setBirthDate] = useState<DateSelected>(null);
  const [owner, setOwner] = useState<TextSelected>(null);
  const [listId, setListId] = useState<TextSelected>(null);
  const [businessId, setBusinessId] = useState<string>(null);
  const [business, setBusiness] = useState<BusinessSelected>(null);
  const [phone, setPhone] = useState<TextSelected>(null);
  const [phones, setPhones] = useState<IContactAddress[]>([]);
  const [email, setEmail] = useState<TextSelected>(null);
  const [emails, setEmails] = useState<IContactAddress[]>([]);
  const [ims_instagram, setIms_instagram] = useState<IContactAddress[]>([]);
  const [ims_facebook, setIms_facebook] = useState<IContactAddress[]>([]);
  const [ims_webchat, setIms_webchat] = useState<IContactAddress[]>([]);
  const [ims_whatsapp, setIms_whatsapp] = useState<IContactAddress[]>([]);
  const [address1, setAddress1] = useState<IContactPostalAddress>(null);
  const [address2, setAddress2] = useState<IContactPostalAddress>(null);
  const [tags, setTags] = useState<LabelSelected[]>(null);
  const [properties, setProperties] = useState<Property[]>([]);
  const [triggerSelectAllId, setTriggerSelectAllId] = useState<string>('');

  const fetchData = useSelector(selectDuplicatesFetchData);
  const fetchedFromContacts = useSelector(selectFetchedFromContacts);

  // Functions
  const compareAddress = (
    firstAddress: IContactPostalAddress,
    secondAddress: IContactPostalAddress
  ) => {
    if (!firstAddress || !secondAddress) return false;
    return (
      firstAddress.city === secondAddress.city &&
      firstAddress.country === secondAddress.country &&
      firstAddress.state === secondAddress.state &&
      firstAddress.street === secondAddress.street &&
      firstAddress.zipcode === secondAddress.zipcode
    );
  };

  const addContactById = async (id: string): Promise<IContact> => {
    const response = await dispatch(fetchContact(id));
    const contact = response.payload as IContact;

    if (!contact) return;

    setContactList((prev) => [...prev, contact]);
    dispatch(addContact(contact));

    return contact;
  };

  const removeContactById = (id: string) => {
    setContactList((prev) => prev.filter((contact) => contact.id !== id));
  };

  const initializeFetchedFromContacts = async (ids: string[]) => {
    let newContactList: IContact[] = [];

    ids.forEach(async (id) => {
      const newContact = await addContactById(id);

      newContactList = [...newContactList, newContact];
    });

    const newDuplicatesGroup = [
      {
        contacts: newContactList,
        count: newContactList.length,
      },
    ];

    dispatch(setItems(newDuplicatesGroup));
  };

  // Contact Handlers
  const handleSelectString = (
    setState: Dispatch<SetStateAction<TextSelected>>,
    value: TextSelected
  ) => {
    if (!contactsId.includes(value.contactId)) {
      setContactsId([...contactsId, value.contactId]);
    }
    setState(value);
  };

  const handleSelectDate = (date: Date, contactId: string) => {
    const data = {
      contactId,
      value: date,
    };
    setBirthDate(data);
  };

  const handleSelectBusiness = (business: any, contactId: string) => {
    const data = {
      contactId,
      value: business,
    };

    setBusiness(data);
    setBusinessId(business.id);
  };

  const handleSelectContactAddress = (
    setState: Dispatch<SetStateAction<IContactAddress[]>>,
    value: IContactAddress,
    contactId: string
  ) => {
    if (!contactsId.includes(contactId)) {
      setContactsId([...contactsId, contactId]);
    }
    setState((prevState) => {
      const ids = prevState?.map((contact) => contact.id);
      if (ids?.includes(value.id)) {
        return prevState?.filter((contact) => contact.id !== value.id);
      } else {
        return [...prevState, value];
      }
    });
  };

  const handleSelectContactPostalAddress = (
    address: IContactPostalAddress,
    isAdd: boolean,
    contactId: string
  ) => {
    if (!contactsId.includes(contactId)) {
      setContactsId([...contactsId, contactId]);
    }
    if (!address1 && isAdd) {
      setAddress1(address);
    } else if (!address2 && isAdd) {
      setAddress2(address);
    } else if (compareAddress(address1, address)) {
      setAddress1(null);
    } else if (compareAddress(address2, address)) {
      setAddress2(null);
    }
  };

  const handleSelectContactTag = (newTag: LabelSelected) => {
    if (!contactsId.includes(newTag.contactId)) {
      setContactsId([...contactsId, newTag.contactId]);
    }
    if (tags && tags.length > 0) {
      const contactIds = tags?.map((tag) => tag.contactId);
      if (contactIds?.includes(newTag.contactId)) {
        setTags((prevState) => {
          return prevState?.filter((tag) => tag.contactId !== newTag.contactId);
        });
      } else {
        setTags([...tags, newTag]);
      }
    } else {
      setTags([newTag]);
    }
  };

  const handleSelectProperty = (property: Property, contactId: string) => {
    const propertyIds = properties?.map((prop) => prop.nameKey);
    if (propertyIds?.includes(property.nameKey)) {
      const updateProperty = properties?.map((prop) => {
        if (prop.nameKey === property.nameKey) {
          return property;
        } else {
          return prop;
        }
      });
      setProperties(updateProperty);
    } else {
      setProperties([...properties, property]);
    }
  };

  const resetFields = () => {
    setName(null);
    setFirstName(null);
    setLastName(null);
    setImageUrl(null);
    setBirthDate(null);
    setOwner(null);
    setListId(null);
    setBusinessId(null);
    setBusiness(null);
    setPhone(null);
    setPhones([]);
    setEmail(null);
    setEmails([]);
    setIms_instagram([]);
    setIms_facebook([]);
    setIms_webchat([]);
    setIms_whatsapp([]);
    setAddress1(null);
    setAddress2(null);
    setTags(null);
    setProperties([]);
    setContactMerged(null);
    setContactsId([]);
  };

  const handleJoinDuplicates = async () => {
    const data = {
      contactsId,
      newContact: contactMerged,
    };

    await dispatch(joinDuplicates(data));
    if (!fetchedFromContacts) {
      await dispatch(fetchDuplicates(fetchData));
    } else {
      dispatch(setDuplicatesStatus('loading'));
      setContactList([]);
      initializeFetchedFromContacts(contactsId);
      dispatch(setDuplicatesStatus('succeeded'));
    }

    resetFields();
  };

  const getContactFields = async () => {
    const fetchData: ContactFieldsFetchData = {
      filterBy: 'custom',
      fetchFor: 'contact',
    };

    dispatch(fetchContactFields(fetchData));
  };

  const resetUrl = () => {
    const newUrl = window.location.pathname;
    window.history.replaceState({}, '', newUrl);
  };

  useEffect(() => {
    const contactData = {
      name: name?.value,
      firstName: firstName?.value,
      lastName: lastName?.value,
      imageUrl: imageUrl?.value,
      birthDate: birthDate?.value,
      owner,
      listId,
      businessId,
      business: business?.value,
      phone,
      phones,
      email,
      emails,
      ims_instagram,
      ims_facebook,
      ims_webchat,
      ims_whatsapp,
      address1,
      address2,
      tags,
      properties,
    };
    setContactMerged(contactData);
  }, [
    name,
    firstName,
    lastName,
    imageUrl,
    birthDate,
    owner,
    listId,
    businessId,
    business,
    phone,
    phones,
    email,
    emails,
    ims_instagram,
    ims_facebook,
    ims_webchat,
    ims_whatsapp,
    address1,
    address2,
    tags,
    properties,
  ]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const contactsIdsParam = searchParams.get('ids');

  useEffect(() => {
    if (contactsIdsParam) {
      dispatch(setFetchedFromContacts(true));

      const ids = contactsIdsParam.split(',');

      initializeFetchedFromContacts(ids);
      resetUrl();
      dispatch(setDuplicatesStatus('succeeded'));
    }
  }, []);

  return (
    <DuplicateContext.Provider
      value={{
        // General State
        contactMerged,
        setContactMerged,
        contactsId,
        setContactsId,
        contactList,
        setContactList,
        // Contact Data State
        name,
        setName,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        imageUrl,
        setImageUrl,
        birthDate,
        setBirthDate,
        owner,
        setOwner,
        listId,
        setListId,
        businessId,
        setBusinessId,
        business,
        setBusiness,
        phone,
        setPhone,
        phones,
        setPhones,
        email,
        setEmail,
        emails,
        setEmails,
        ims_instagram,
        setIms_instagram,
        ims_facebook,
        setIms_facebook,
        ims_webchat,
        setIms_webchat,
        ims_whatsapp,
        setIms_whatsapp,
        address1,
        setAddress1,
        address2,
        setAddress2,
        tags,
        setTags,
        properties,
        setProperties,
        triggerSelectAllId,
        setTriggerSelectAllId,
        // Functions
        compareAddress,
        resetFields,
        addContactById,
        removeContactById,
        // Handlers
        handleSelectString,
        handleSelectDate,
        handleSelectBusiness,
        handleSelectContactAddress,
        handleSelectContactPostalAddress,
        handleSelectContactTag,
        handleSelectProperty,
        handleJoinDuplicates,
        getContactFields,
      }}
    >
      {children}
    </DuplicateContext.Provider>
  );
};
