// Redux
import { useSelector } from 'react-redux';
import { selectFilterPanelAnimate } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Components/ui
import { Collapse } from '@mui/material';

const CollapseAnimation = ({ children, openPanel }) => {
  const isFilterPanelAnimate = useSelector(selectFilterPanelAnimate);

  return (
    <Collapse
      timeout={isFilterPanelAnimate ? 300 : 0}
      in={openPanel}
      orientation={"horizontal"}
      sx={{
        ...(openPanel && {
          minWidth: "240px !important",
          marginRight: "8px",
          width: "auto",
        }),
        "& .MuiCollapse-wrapperInner": {
          width: "100%",
        },
      }}
    >
      {children}
    </Collapse>
  );
};

export default CollapseAnimation;
