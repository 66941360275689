import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
// Types
import { RootState } from 'ReduxToolkit/store';
import { IinitialState } from './types/IInitialState';
import { IIntegrationButton } from '@trii/types/dist/Contacts';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { IContactField } from '@trii/types/dist/Contacts';
import type For from '../../../types/For';
// Utils
import initRequestData from 'ReduxToolkit/functions/initRequestData';
import configSliceService from './configSliceService';

const initialState: IinitialState = {
  customFields: {
    fields: [],
    status: { fetch: 'idle', create: 'idle', update: 'idle' },
  },
  subscriptions: {
    subscriptions: [],
    status: {
      fetch: 'idle',
      create: 'idle',
      edit: 'idle',
      delete: 'idle',
    },
  },
  buttons: {
    buttons: [],
    status: {
      fetch: 'idle',
      create: 'idle',
      edit: 'idle',
      delete: 'idle',
      updateOrder: 'idle',
    },
  },
};

export const fetchCustomContactFields = createAsyncThunk<
  IContactField[],
  For,
  { state: RootState }
>('config/fetchCustomContactFields', async (fetchFor, { dispatch }) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const customFields = await configSliceService.fetchCustomFields(
    jwtToken,
    URL_CONTACTS,
    fetchFor
  );

  return customFields;
});

export const updateCustomContactFieldsOrder = createAsyncThunk(
  'config/updateCustomContactFieldsOrder',
  async (newOrderCustomContactFieldsJson: any, { getState, dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${URL_CONTACTS}/ContactFields/setOrder`,
      newOrderCustomContactFieldsJson,
      config
    );
    return response;
  }
);
export const createCustomContactField = createAsyncThunk(
  'config/CreateCustomContactField',
  async (fieldDataJSON: any, { dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.post(
        `${URL_CONTACTS}/ContactFields`,
        fieldDataJSON,
        config
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteCustomContactField = createAsyncThunk(
  'congif/deleteCustomContactField',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    await axios.delete(`${URL_CONTACTS}/ContactFields/${id}`, config);

    return id as unknown as string;
  }
);
export const updateCustomContactField = createAsyncThunk(
  'config/updateCustomContactField',
  async (fieldDataJSON: any, { dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.put(
        `${URL_CONTACTS}/ContactFields`,
        fieldDataJSON,
        config
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchSubscriptions = createAsyncThunk(
  'config/fetchSubscriptions',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    const response = await axios.get(`${URL_CONTACTS}/Subscriptions`, config);

    return response.data;
  }
);
export const createSubscription = createAsyncThunk(
  'config/createSubscription',
  async (subscriptionDataJSON: any, { dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.post(
        `${URL_CONTACTS}/Subscriptions`,
        subscriptionDataJSON,
        config
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const editSubscription = createAsyncThunk(
  'config/editSubscription',
  async (subscriptionDataJSON: any, { getState, dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.put(
        `${URL_CONTACTS}/Subscriptions`,
        subscriptionDataJSON,
        config
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteSubscription = createAsyncThunk(
  'config/deleteSubscription',
  async (id: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    await axios.delete(`${URL_CONTACTS}/Subscriptions/${id}`, config);

    return id as unknown as string;
  }
);

export const fetchButtons = createAsyncThunk<IIntegrationButton[], void>(
  'config/fetchButtons',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    const response = await axios.get(`${URL_CONTACTS}/buttons`, config);

    return response.data;
  }
);
export const addButton = createAsyncThunk(
  'config/addButton',
  async (buttonDataJSON: IIntegrationButton, { dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.post(
        `${URL_CONTACTS}/buttons`,
        buttonDataJSON,
        config
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteButton = createAsyncThunk(
  'config/deleteButton',
  async (buttonId: number, { dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    try {
      await axios.delete(`${URL_CONTACTS}/buttons/${buttonId}`, config);

      return buttonId;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const updateButton = createAsyncThunk(
  'config/updateButton',
  async (buttonDataJSON: IIntegrationButton, { dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.put(
        `${URL_CONTACTS}/buttons`,
        buttonDataJSON,
        config
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const updateButtonsOrder = createAsyncThunk(
  'config/updateButtonsOrder',
  async (buttonsOrderJSON: IIntegrationButton, { dispatch, rejectWithValue }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios.put(
        `${URL_CONTACTS}/buttons/setOrder`,
        buttonsOrderJSON,
        config
      );

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setCustomContactFields(state, action) {
      const newCustomFields = action.payload;

      state.customFields.fields = newCustomFields;
    },
    setButtons(state, action) {
      const newButtons = action.payload;

      state.buttons.buttons = newButtons;
    },
    changeCreateCustomFieldsStatus(state, action) {
      const status = action.payload;

      state.customFields.status = status;
    },
    changeSubscriptionsStatus(state, action) {
      state.subscriptions.status.create = 'idle';
      state.subscriptions.status.edit = 'idle';
      state.subscriptions.status.delete = 'idle';
      state.subscriptions.status.fetch = 'idle';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCustomContactFields.pending, (state, action) => {
        state.customFields.status.fetch = 'loading';
      })
      .addCase(fetchCustomContactFields.fulfilled, (state, action) => {
        const newCustomFields = action.payload;
        state.customFields.status.fetch = 'succeeded';

        state.customFields.fields = newCustomFields;
      })
      .addCase(deleteCustomContactField.fulfilled, (state, action) => {
        const deletedFieldId = action.payload;
        const newContactFields = state.customFields.fields.filter(
          (field) => field.id !== deletedFieldId
        );

        state.customFields.fields = newContactFields;
      })
      .addCase(createCustomContactField.fulfilled, (state, action) => {
        state.customFields.status.create = 'idle';
        const newContactField = action.payload;

        state.customFields.fields.push(newContactField);
      })
      .addCase(createCustomContactField.rejected, (state, action) => {
        state.customFields.status.create = { error: action.error.message };
      })
      .addCase(updateCustomContactField.fulfilled, (state, action) => {
        state.customFields.status.update = 'idle';

        const updatedField = action.payload;
        const newCustomFields = state.customFields.fields.map((field) => {
          if (field.id === updatedField.id) {
            return updatedField;
          }

          return field;
        });

        state.customFields.fields = newCustomFields;
      })
      .addCase(fetchSubscriptions.pending, (state) => {
        state.subscriptions.status.fetch = 'loading';
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.subscriptions.status.fetch = 'idle';
        state.subscriptions.subscriptions = action.payload;
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.subscriptions.status.fetch = { error: action.payload as string };
      })
      .addCase(createSubscription.pending, (state) => {
        state.subscriptions.status.create = 'loading';
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.subscriptions.status.create = 'idle';

        state.subscriptions.subscriptions.push(action.payload);
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.subscriptions.status.create = { error: action.payload as string };
      })
      .addCase(editSubscription.pending, (state) => {
        state.subscriptions.status.edit = 'loading';
      })
      .addCase(editSubscription.fulfilled, (state, action) => {
        state.subscriptions.status.edit = 'idle';

        const updatedSubscription = action.payload;
        const newSubscriptions = state.subscriptions.subscriptions.map(
          (subscription) => {
            if (subscription.id === updatedSubscription.id) {
              return updatedSubscription;
            }

            return subscription;
          }
        );

        state.subscriptions.subscriptions = newSubscriptions;
      })
      .addCase(editSubscription.rejected, (state, action) => {
        state.subscriptions.status.edit = { error: action.payload as string };
      })
      .addCase(deleteSubscription.pending, (state) => {
        state.subscriptions.status.delete = 'loading';
      })
      .addCase(deleteSubscription.fulfilled, (state, action) => {
        state.subscriptions.status.delete = 'idle';

        const deletedSubscriptionId = action.payload;
        const newSubscriptions = state.subscriptions.subscriptions.filter(
          (subscription) => subscription.id !== deletedSubscriptionId
        );

        state.subscriptions.subscriptions = newSubscriptions;
      })
      .addCase(deleteSubscription.rejected, (state, action) => {
        state.subscriptions.status.delete = { error: action.payload as string };
      })
      .addCase(fetchButtons.fulfilled, (state, action) => {
        state.buttons.status.fetch = 'succeeded';
        state.buttons.buttons = action.payload;
      })
      .addCase(fetchButtons.rejected, (state, action) => {
        state.buttons.status.fetch = { error: action.payload as string };
      })
      .addCase(fetchButtons.pending, (state) => {
        state.buttons.status.fetch = 'loading';
      })
      .addCase(addButton.fulfilled, (state, action) => {
        state.buttons.status.create = 'idle';
        state.buttons.buttons.push(action.payload);
      })
      .addCase(addButton.rejected, (state, action) => {
        state.buttons.status.create = { error: action.payload as string };
      })
      .addCase(addButton.pending, (state) => {
        state.buttons.status.create = 'loading';
      })
      .addCase(deleteButton.fulfilled, (state, action) => {
        const deletedButtonId = action.payload;
        const newButtons = state.buttons.buttons.filter(
          (button) => button.id !== deletedButtonId
        );

        state.buttons.status.delete = 'idle';
        state.buttons.buttons = newButtons;
      })
      .addCase(deleteButton.rejected, (state, action) => {
        state.buttons.status.delete = { error: action.payload as string };
      })
      .addCase(deleteButton.pending, (state) => {
        state.buttons.status.delete = 'loading';
      })
      .addCase(updateButton.pending, (state) => {
        state.buttons.status.edit = 'loading';
      })
      .addCase(updateButton.fulfilled, (state, action) => {
        const updatedButton = action.payload;
        const newButtons = state.buttons.buttons.map((button) => {
          if (button.id === action.payload) {
            return updatedButton;
          }

          return button;
        });

        state.buttons.status.edit = 'idle';
        state.buttons.buttons = newButtons;
      })
      .addCase(updateButton.rejected, (state, action) => {
        state.buttons.status.edit = { error: action.payload as string };
      })
      .addCase(updateButtonsOrder.pending, (state) => {
        state.buttons.status.updateOrder = 'loading';
      })
      .addCase(updateButtonsOrder.fulfilled, (state, action) => {
        state.buttons.status.updateOrder = 'idle';
      })
      .addCase(updateButtonsOrder.rejected, (state, action) => {
        state.buttons.status.updateOrder = { error: action.payload as string };
      });
  },
});

// Selectors
const selectConfig = (state: RootState) => state.Config;
export const selectCustomContactFieldsFetchStatus = createSelector(
  selectConfig,
  (config) => config.customFields.status.fetch
);
export const selectCustomContactFieldsCreateStatus = createSelector(
  selectConfig,
  (config) => config.customFields.status.create
);
export const selectCustomContactFields = createSelector(
  selectConfig,
  (config) => config.customFields.fields
);
export const selectCustomContactFieldById = (id: string) =>
  createSelector(selectCustomContactFields, (fields) =>
    fields.find((field) => field.id === id)
  );
export const selectSubscriptions = createSelector(
  selectConfig,
  (config) => config.subscriptions.subscriptions
);
export const selectSubscriptionsFetchStatus = createSelector(
  selectConfig,
  (config) => config.subscriptions.status.fetch
);
export const selectSubscriptionCreateStatus = createSelector(
  selectConfig,
  (config) => config.subscriptions.status.create
);
export const selectSubscriptionEditStatus = createSelector(
  selectConfig,
  (config) => config.subscriptions.status.edit
);
export const selectSubscriptionDeleteStatus = createSelector(
  selectConfig,
  (config) => config.subscriptions.status.delete
);
export const selectSubscriptionStatus = createSelector(
  selectConfig,
  (config) => config.subscriptions.status
);
export const selectButtons = createSelector(
  selectConfig,
  (config) => config.buttons.buttons
);
export const selectButtonsFetchStatus = createSelector(
  selectConfig,
  (config) => config.buttons.status.fetch
);
export const selectButtonsAddStatus = createSelector(
  selectConfig,
  (config) => config.buttons.status.create
);
export const selectButtonsDeleteStatus = createSelector(
  selectConfig,
  (config) => config.buttons.status.delete
);
export const selectButtonEditStatus = createSelector(
  selectConfig,
  (config) => config.buttons.status.edit
);
export const selectButtonsUpdateOrderStatus = createSelector(
  selectConfig,
  (config) => config.buttons.status.updateOrder
);

// Actions
export const {
  changeCreateCustomFieldsStatus,
  changeSubscriptionsStatus,
  setCustomContactFields,
  setButtons,
} = configSlice.actions;

export default configSlice.reducer;
