import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Hooks
import useField from 'hooks/useField';
// Types
import { UseFieldType } from 'hooks/useField';
import { ISegmentsEditProviderProps, ISegmentsEditContext } from './types';
import { Operator } from 'ReduxToolkit/features/segmentsSlice/types';
import { ChangeEvent } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSelectedSegment,
  setFilterOperatorToggle,
  setFilterItemOperatorToggle,
  deleteSegment,
  updateFilterTextFieldValue,
  updateFilterFieldSelectValue,
  updateConditionOperatorSelectValue,
  deleteFilterItem,
} from 'ReduxToolkit/features/segmentsSlice/segmentsSlice';

export const SegmentsEditContext = createContext<ISegmentsEditContext>({
  deleteSegmentNameField: fieldInitialDef,
  segmentNameField: fieldInitialDef,
  handleOperatorToggle: () => {},
  handleItemOperatorToggle: () => {},
  deletePanelOpen: false,
  openDeletePanel: () => {},
  sendDeleteSegment: () => {},
  closeDeletePanel: () => {},
  selectedSegment: null,
  handleTextFieldChange: () => {},
  handleFieldSelectChange: () => {},
  handleConditionOperatorChange: () => {},
  handleNumberFieldChange: () => {},
  handleTimeDatePickerChange: () => {},
  handleDeleteItem: () => {},
});

export const SegmentsEditProvider = ({ children }: ISegmentsEditProviderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedSegment = useSelector(selectSelectedSegment);
  // Delete state
  const [deletePanelOpen, setDeletePanelOpen] = useState<boolean>(false);
  const deleteSegmentNameField: UseFieldType = useField('');
  // Edit state
  const segmentNameField: UseFieldType = useField('');

  // Edit functions
  const handleOperatorToggle = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: Operator
  ) => {
    if (newValue !== null) dispatch(setFilterOperatorToggle(newValue));
  };
  const handleItemOperatorToggle = (
    event: React.MouseEvent<HTMLElement>,
    groupId: string
  ) => {
    const newValue = (event.target as HTMLButtonElement).value;

    dispatch(setFilterItemOperatorToggle({ newValue, groupId }));
  };
  const handleTextFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    itemId: string,
    groupId: string
  ) => {
    const newValue = e.target.value;

    dispatch(updateFilterTextFieldValue({ fieldId: itemId, newValue, groupId }));
  };
  const handleNumberFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    itemId: string,
    groupId: string
  ) => {
    const newValue = e.target.value.replace(/[^0-9.]/g, '');

    dispatch(updateFilterTextFieldValue({ fieldId: itemId, newValue, groupId }));
  };
  const handleTimeDatePickerChange = (
    newValue: string,
    itemId: string,
    groupId: string
  ) => {
    dispatch(updateFilterTextFieldValue({ fieldId: itemId, newValue, groupId }));
  };
  const handleFieldSelectChange = (e, itemId: string, groupId: string) => {
    const newValue = e.target.value;
    dispatch(updateFilterTextFieldValue({ fieldId: itemId, newValue: '', groupId }));
    dispatch(updateFilterFieldSelectValue({ fieldId: itemId, newValue, groupId }));
  };
  const handleConditionOperatorChange = (e, itemId: string, groupId: string) => {
    const newValue = e.target.value;

    dispatch(
      updateConditionOperatorSelectValue({ fieldId: itemId, newValue, groupId })
    );
  };
  const handleDeleteItem = (itemId: string, groupId: string) => {
    dispatch(deleteFilterItem({ itemId, groupId }));
  };

  // Delete functions
  const openDeletePanel = () => {
    setDeletePanelOpen(true);
  };
  const closeDeletePanel = () => {
    deleteSegmentNameField.actions.resetValue();
    setDeletePanelOpen(false);
  };
  const sendDeleteSegment = async () => {
    await dispatch(deleteSegment(selectedSegment.id));
    closeDeletePanel();
    navigate('/a/contacts/segments');
  };
  //

  useEffect(() => {
    if (selectedSegment) {
      segmentNameField.actions.changeValue(selectedSegment.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSegment]);

  return (
    <SegmentsEditContext.Provider
      value={{
        segmentNameField,
        handleOperatorToggle,
        handleItemOperatorToggle,
        deletePanelOpen,
        openDeletePanel,
        sendDeleteSegment,
        closeDeletePanel,
        deleteSegmentNameField,
        selectedSegment,
        handleTextFieldChange,
        handleFieldSelectChange,
        handleConditionOperatorChange,
        handleNumberFieldChange,
        handleTimeDatePickerChange,
        handleDeleteItem,
      }}
    >
      {children}
    </SegmentsEditContext.Provider>
  );
};

export default SegmentsEditProvider;
