export const style = {
  container: {
    marginBottom: '14px',
  },
  textField: {
    '& legend': {
      maxWidth: 'fit-content',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
    },
  },
  tag: {
    height: '1.2rem',
    fontSize: '.8rem',
    '& .MuiChip-deleteIcon': {
      height: '1rem',
      width: '1rem',
    },
  },
};
