import { useNavigate } from 'react-router-dom';
// Context
import { ContactCreateContext } from './ContactCreateContext';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { createContact } from 'ReduxToolkit/features/contactsSlice/contactsSlice';
// Types
import type { ReactNode } from 'react';
import type ContactCreateContextType from './types/ContactCreateContextType';
// Hook
import useCreateContact from '../hooks/useCreateContact/useCreateContact';

type Props = {
  children: ReactNode;
};

const ContactCreateContextProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const contact = useCreateContact();

  const handleCreateButton = async () => {
    const newContact = contact.action.getContact();
    if (newContact) {
      try {
        const { payload } = await dispatch(createContact(newContact));
        navigate(`/a/contacts/contacts/${payload.id}`);
      } catch (err) {
        console.log('Error al crear, faltan campos');
      }
    }
  };

  const contextValue: ContactCreateContextType = {
    contact,
    handleCreateButton,
  };

  return (
    <ContactCreateContext.Provider value={contextValue}>
      {children}
    </ContactCreateContext.Provider>
  );
};

export default ContactCreateContextProvider;
