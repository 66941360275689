import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import { selectSubscriptionStatus } from '../../../../../../../../../ReduxToolkit/features/configSlice/configSlice';
// Components/ui
import { Snackbar, Alert } from '@mui/material';
import { SubscriptionListContext } from '../../../context/SubscriptionListContext';

const SubscriptionErrorAlert = () => {
  const { t } = useTranslation();
  const { handleCloseAlertError } = useContext(SubscriptionListContext);
  const ERROR_MSG_UNDEFINED = 'ERROR_MSG_UNDEFINED';
  const subscriptionsStatus = useSelector(selectSubscriptionStatus);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(ERROR_MSG_UNDEFINED);

  const checkError = () => {
    for (let operation in subscriptionsStatus) {
      if (
        typeof subscriptionsStatus[operation] === 'object' &&
        subscriptionsStatus[operation].error
      ) {
        console.log('found error', subscriptionsStatus[operation].error);
        setOpen(true);
        setErrorMsg(subscriptionsStatus[operation].error);
      }
    }
  };

  useEffect(() => {
    checkError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionsStatus]);

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseAlertError}>
      <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
        {t(`settingsView.${errorMsg}`)}
      </Alert>
    </Snackbar>
  );
};

export default SubscriptionErrorAlert;
