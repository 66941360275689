import axios from 'axios';
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import initRequestData from 'ReduxToolkit/functions/initRequestData';
import { RootState } from '../../store';
// Utils
import { segmentMockup } from './utils/segmentMockup';
import addIds from './utils/addIds';
import findGroupById from './utils/findGroup';
// Types
import {
  IInitialState,
  INewSegment,
  ItemUpdateDataSelectField,
  ItemUpdateDataTextField,
  Operator,
  ShortList,
} from './types';
import { ISegment, ISegmentList } from '@trii/types/dist/Contacts';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { RequestStatus } from 'types/reduxTypes';

const initialState: IInitialState = {
  status: {
    fetch: 'idle',
    fetchById: 'idle',
    create: 'idle',
    delete: 'idle',
    edit: 'idle',
  },
  data: [],
  selectedSegment: null,
};

export const fetchSegments = createAsyncThunk<ISegment[]>(
  'segments/fetchSegments',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    const { data } = await axios.get(`${URL_CONTACTS}/segments`, config);

    return data;
  }
);

export const fetchSegmentById = createAsyncThunk<ISegment, string>(
  'segments/fetchSegmentById',
  async (id, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    const { data } = await axios.get(`${URL_CONTACTS}/segments/${id}`, config);

    return data;
  }
);

export const createSegment = createAsyncThunk(
  'segments/createSegment',
  async (newSegment: INewSegment, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const newSegmentJSON = JSON.stringify(newSegment);
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `${URL_CONTACTS}/segments`,
      newSegmentJSON,
      config
    );

    return data as ISegment;
  }
);

export const deleteSegment = createAsyncThunk(
  'segments/deleteSegment',
  async (segmentId: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
      },
    };

    await axios.delete(`${URL_CONTACTS}/segments/${segmentId}`, config);

    return segmentId;
  }
);

export const saveSegment = createAsyncThunk(
  'lists/saveSegment',
  async (_, { dispatch, getState }) => {
    const { Segments } = getState() as RootState;
    const { selectedSegment } = Segments;
    const { id } = selectedSegment;
    const selectedSegmentJSON = JSON.stringify(selectedSegment);
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const config = {
      headers: {
        Authorization: `bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.put(`${URL_CONTACTS}/segments/${id}`, selectedSegmentJSON, config);

    return;
  }
);

export const segmentsSlice = createSlice({
  name: 'segments',
  initialState,
  reducers: {
    selectSegmentById: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const segment = state.data.find((segment) => segment.id === id);
      const segmentWithIds = addIds(segment);

      state.selectedSegment = segmentWithIds;
    },
    removeSelectedSegment: (state) => {
      state.selectedSegment = null;
    },
    setFilterOperatorToggle: (state, action) => {
      const operator: Operator = action.payload;

      state.selectedSegment.filter.operator = operator;
    },
    setFilterItemOperatorToggle: (state, action) => {
      const { newValue, groupId } = action.payload;
      const group = state.selectedSegment.filter.groups.find(
        (group) => group.id === groupId
      );

      group.operator = newValue;
    },
    addSegmentGroup: (state) => {
      const newGroup = segmentMockup.getGroupMockup();

      state.selectedSegment.filter.groups.push(newGroup);
    },
    deleteSegmentGroup: (state, action) => {
      const groupId = action.payload;
      const groups = state.selectedSegment.filter.groups;

      state.selectedSegment.filter.groups = groups.filter(
        (group) => group.id !== groupId
      );
    },
    addFilterItem: (state, action) => {
      const groupId = action.payload;
      const newFilterItem = segmentMockup.getItemMockup();
      const group = state.selectedSegment.filter.groups.find(
        (group) => group.id === groupId
      );

      group.items.push(newFilterItem);
    },
    deleteFilterItem: (state, action) => {
      const { groupId, itemId } = action.payload;
      const group = state.selectedSegment.filter.groups.find(
        (group) => group.id === groupId
      );

      group.items = group.items.filter((item) => item.id !== itemId);
    },
    updateFilterTextFieldValue: (state, action) => {
      const { fieldId, newValue, groupId }: ItemUpdateDataTextField = action.payload;
      const groups = state.selectedSegment.filter.groups;
      const group = findGroupById(groups, groupId);
      const filterItem = group.items.find((item) => item.id === fieldId);
      filterItem.value = newValue;
    },
    updateFilterFieldSelectValue: (state, action) => {
      const { fieldId, newValue, groupId }: ItemUpdateDataSelectField =
        action.payload;
      const groups = state.selectedSegment.filter.groups;
      const group = findGroupById(groups, groupId);
      const filterItem = group.items.find((item) => item.id === fieldId);

      filterItem.field = newValue;
    },
    updateConditionOperatorSelectValue: (state, action) => {
      const { fieldId, newValue, groupId }: ItemUpdateDataSelectField =
        action.payload;
      const groups = state.selectedSegment.filter.groups;
      const group = findGroupById(groups, groupId);
      const filterItem = group.items.find((item) => item.id === fieldId);

      filterItem.condition = newValue;
    },
    addList: (state, action: PayloadAction<ISegmentList>) => {
      const list = action.payload;

      state.selectedSegment.lists.push(list);
    },
    removeList: (state, action) => {
      const listId = action.payload;

      state.selectedSegment.lists = state.selectedSegment.lists.filter(
        (list) => list.id !== listId
      );
    },
    setList: (state, action) => {
      state.selectedSegment.lists = action.payload;
      console.log('state', state.selectedSegment.lists);
    },
    setSegmentCreateStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status.create = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSegments.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchSegments.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchSegmentById.pending, (state, action) => {
        state.status.fetchById = 'loading';
      })
      .addCase(fetchSegmentById.fulfilled, (state, action) => {
        const segmentData = action.payload;
        const segmentWithIds = addIds(segmentData);
        console.log('Fetched Segment: ', action.payload);

        state.status.fetchById = 'succeeded';
        state.selectedSegment = segmentWithIds;
      })
      .addCase(createSegment.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createSegment.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.data.push(action.payload);
      })
      .addCase(createSegment.rejected, (state, action) => {
        state.status.create = { error: action.error.message };
      })
      .addCase(deleteSegment.pending, (state, action) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteSegment.fulfilled, (state, action) => {
        const deletedSegmentId = action.payload;

        state.status.delete = 'succeeded';
        state.data = state.data.filter((segment) => segment.id !== deletedSegmentId);
      })
      .addCase(saveSegment.pending, (state, action) => {
        state.status.edit = 'loading';
      })
      .addCase(saveSegment.fulfilled, (state, action) => {
        console.log('Edited success: ', action.payload);
        state.status.edit = 'succeeded';
      })
      .addCase(saveSegment.rejected, (state, action) => {
        console.log('Edited failed');
        console.log(action.error.message);
        state.status.edit = 'idle';
      });
  },
});

// Selectors
const selectSegments = (state: RootState) => state.Segments;
export const selectSegmentsData = createSelector(
  selectSegments,
  (segments) => segments.data
);
export const selectSegmentsStatus = createSelector(
  selectSegments,
  (segments) => segments.status.fetch
);
export const selectFetchSegmentByIdStatus = createSelector(
  selectSegments,
  (segments) => segments.status.fetchById
);
export const selectSegmentsCreateStatus = createSelector(
  selectSegments,
  (segments) => segments.status.create
);
export const selectSelectedSegment = createSelector(
  selectSegments,
  (segments) => segments.selectedSegment
);
export const selectSelectedSegmentFilterOperator = createSelector(
  selectSegments,
  (segments) => segments.selectedSegment.filter?.operator
);
export const selectSegmentsDeleteStatus = createSelector(
  selectSegments,
  (segments) => segments.status.delete
);
export const selectSegmentsEditStatus = createSelector(
  selectSegments,
  (segments) => segments.status.edit
);

// Actions
export const {
  selectSegmentById,
  removeSelectedSegment,
  setFilterOperatorToggle,
  setFilterItemOperatorToggle,
  addSegmentGroup,
  addFilterItem,
  addList,
  removeList,
  updateFilterTextFieldValue,
  updateFilterFieldSelectValue,
  setList,
  updateConditionOperatorSelectValue,
  deleteFilterItem,
  deleteSegmentGroup,
  setSegmentCreateStatus,
} = segmentsSlice.actions;

export default segmentsSlice.reducer;
