import React, { useContext } from 'react';
import { ListsContext } from '../../../../../../context/ListsContext';
import { useTranslation } from 'react-i18next';
// Types
import { IListsContext } from '../../../../../../context/types';
// Components/ui
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const { t } = useTranslation();
  const { closeCreatePanel } = useContext(ListsContext) as IListsContext;
  return (
    <Box
      display={'flex'}
      justifyContent="space-between"
      alignItems={'center'}
      pl={2}
    >
      <Typography variant="subtitle2" color="text.primary">{t('listsView.createList')}</Typography>
      <IconButton onClick={closeCreatePanel}>
        <CloseIcon
          sx={{
            fontSize: '20px',
          }}
        />
      </IconButton>
    </Box>
  );
};

export default Header;
