import { useTranslation } from 'react-i18next';
// Components/ui
import { TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

interface Props {
  handleSearch: (value: string) => void;
  search: string;
}
const ContactSearchBar = ({ search, handleSearch }: Props) => {
  const { t } = useTranslation();

  return (
    <TextField
      fullWidth
      className="contacts-smallInput"
      placeholder={t('contactsView.activities.searchContact')}
      size="medium"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search
              fontSize="small"
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            />
          </InputAdornment>
        ),
      }}
      value={search}
      onChange={(e) => handleSearch(e.target.value)}
    />
  );
};

export default ContactSearchBar;
